import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { changeToCamelCase } from '../../helpers/changeCase';
import { map, reduce } from 'lodash-es';
import { logout } from '../user/action';
import { setItem } from '../../helpers/localStorage';
import queryString from 'query-string';
import { prepareUserFilters } from './helpers';

const adapter = createEntityAdapter();

function startLoading(state) {
  state.loading = true;
}

const usersSlice = createSlice({
  name: 'users',
  initialState: adapter.getInitialState({
    filterValues: {},
    pages: {},
    hasNextPage: null,
    loading: false,
  }),
  reducers: {
    userLoading: startLoading,
    usersLoading: startLoading,
    setPage(state, { payload }) {
      state.filterValues.page = payload;
    },
    setFilter(state, { payload }) {
      Object.keys(state.pages).map((key) => delete state.pages[key]); // purge pagination
      state.filterValues = { ...state.filterValues, ...payload, page: 1 };
    },
    userUpdated(state, { payload }) {
      const { id, ...changes } = payload;
      adapter.updateOne(state, { id, changes: changes });
    },
    userMetaUpdated(state, { payload }) {
      const { userId, ...changes } = payload;
      adapter.updateOne(state, { id: userId, changes: { details: changes } });
    },
    userAdded(state, { payload }) {
      adapter.addOne(state, payload);
    },
    usersSet(state, { payload }) {
      const { data, currentPage, nextPageUrl } = payload;
      adapter.upsertMany(state, data);
      state.pages[currentPage] = map(data, (item) => item.id);
      state.hasNextPage = !!nextPageUrl;
      state.loading = false;
    },
  },
});

const { actions, reducer } = usersSlice;

export const {
  usersLoading,
  userLoading,
  userAdded,
  userUpdated,
  userMetaUpdated,
  usersSet,
  setFilter,
  setPage,
} = actions;

export const {
  selectEntities: selectUserEntities,
  selectById: selectUserById,
  selectAll: selectAllUsers,
} = adapter.getSelectors((state) => state.users);

const pages = (state) => state.users.pages;

export const selectPaginatedUsers = createSelector(
  pages,
  selectUserEntities,
  (pages, entities) => {
    return reduce(
      pages,
      (arr, curr) => [...arr, ...curr.map((id) => entities[id])],
      []
    );
  }
);

export default reducer;

export const fetchUser = (id) => async (dispatch, _, api) => {
  dispatch(userLoading());
  const { data } = await api('get', `users/${id}`);
  dispatch(userAdded(changeToCamelCase(data)));
  return data;
};

export const fetchUsers = (queryValues) => async (dispatch, _, api) => {
  const query = queryString.stringify(prepareUserFilters(queryValues));

  dispatch(usersLoading());

  const { data } = await api('get', `users?${query}`);

  dispatch(usersSet(changeToCamelCase(data)));

  return data;
};

export const addUser = (values) => async (dispatch, __, api) => {
  const { data } = await api('post', `users/settings/add`, values);
  dispatch(userAdded(changeToCamelCase(data.user)));
  return data;
};

export const updateUserMeta = (values, userId) => async (dispatch, __, api) => {
  const { data } = await api('post', `users/${userId}`, values);
  dispatch(userMetaUpdated(changeToCamelCase(data)));
  return data;
};

export const updateUserGoal = (values, userId) => async (dispatch, __, api) => {
  const { data } = await api('patch', `users/${userId}/goal`, values);
  dispatch(userMetaUpdated(changeToCamelCase(data)));
  return data;
};

export const verifyUserPhone = (values, userId) => async (
  dispatch,
  __,
  api
) => {
  const { data } = await api('post', 'users/phone/verify', values);
  dispatch(userUpdated({ id: userId, phoneVerifiedAt: true }));
  return data;
};

export const toggleUserStatus = (userId, status) => async (
  dispatch,
  getState,
  api
) => {
  const { data } = await api('patch', `users/${userId}?setStatus=${status}`);

  const { data: currentUser } = getState().user;

  if (userId === currentUser.id) {
    dispatch(logout());
  } else {
    dispatch(userUpdated(changeToCamelCase(data)));
  }

  return data;
};

export const updateUserSecurity = (userId, values) => async (
  dispatch,
  getState,
  api
) => {
  const { data } = await api('patch', `users/${userId}/security`, values);

  const { data: currentUser } = getState().user;

  if (userId === currentUser.id && data.access_token) {
    setItem('token', data.access_token);
  }

  dispatch(
    userUpdated(changeToCamelCase({ id: userId, is2Fa: values.is_2fa }))
  );

  return data;
};

export const applyCoupon = (userId, { coupon }) => async (dispatch, _, api) => {
  const { data } = await api('post', `coupons/apply`, {
    used_by: userId,
    coupon_code: coupon,
  });

  dispatch(
    userUpdated(
      changeToCamelCase({ id: userId, current_package: data.current_package })
    )
  );

  return data;
};

// export const reportAnalyser = (userId, values) => async (dispatch, _, api) => {
//   const { data } = await api('post', `users/${userId}/reportAnalyzer`, values);

//   dispatch(
//     userUpdated(
//       changeToCamelCase({ id: data.user_id, analysis: changeToCamelCase(data.analysis) })
//     )
//   );

//   return data;
// };
