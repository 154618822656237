import { routesMapper } from '../../../../../../Routes/routesMapper';
import { fillBlanksDropdown } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Reading',
  },
  {
    name: fillBlanksDropdown,
    path: routesMapper.reading.FillInTheBlanksDropDown.PRACTISE,
  },
];
