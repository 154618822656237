import React from 'react';
import TitleBar from '../../../../Question/TitleBar';
import { Box, Flex, Text, Button, Image } from '@chakra-ui/core';
import { UserAvatar } from '../../../../Avatar/Avatar';
import { get } from 'lodash-es';
import { getDate, DATE_TIME_FULL } from '../../../../../helpers/getDate';
import { getTime } from '../../../../../helpers/getTime';
import logoTextual from '../../../../../assets/images/logo-textual.svg';

export function MockScoreHeader({ score }) {
  return (
    <Box>
      <Flex justify="space-between">
        <Flex align="center">
          <Box
            borderRadius="4px"
            fontWeight="700"
            minWidth="50px"
            textAlign="center"
            bg="custom.blue"
            mr={2}
            fontSize="3xl"
            py={1}
            px={3}
            color="white"
          >
            {score.details.overallScore}
          </Box>
          <Text fontSize="2xl" fontWeight="600" ml={2} color="custom.blue">
            OVERALL SCORE
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export function ScoreHeader({ score }) {
  return (
    <>
      <TitleBar
        title={`Automated AI Score Analysis for ID # ${get(
          score,
          'questionId'
        )}`}
      />
      <Box mt={2}>
        <Flex justify="space-between">
          <Flex align="center">
            <Box
              borderRadius="4px"
              fontWeight="700"
              minWidth="50px"
              textAlign="center"
              bg="custom.blue"
              mr={2}
              fontSize="3xl"
              py={1}
              px={3}
              color="white"
            >
              {score.details.overallScore}
            </Box>
            <Text fontSize="2xl" fontWeight="600" ml={2} color="custom.blue">
              OVERALL SCORE
            </Text>
          </Flex>
          <Flex align="center">
            <UserAvatar size="42px" src={get(score, 'user.avatar')} />
            <Box ml={2}>
              <Text color="custom.black">
                {get(score, 'user.firstName')} {get(score, 'user.lastName')}
              </Text>
              <Text color="custom.gray5" fontSize="14px">
                {getDate(get(score, 'createdAt'), DATE_TIME_FULL)}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

export function ScoreItem({ label, value, total }) {
  return (
    <Box>
      <Text color="custom.black3" fontWeight={600}>
        {label}
      </Text>
      <Flex>
        <Text
          fontWeight={600}
          color={value === 0 ? 'custom.red' : 'custom.black2'}
        >
          {value}
        </Text>
        <Text color="custom.black" fontWeight={600} ml={1}>
          out of {total}
        </Text>
      </Flex>
    </Box>
  );
}

export function ScoreDetails({
  score,
  totalScore,
  scoreKey = 'score',
  scoreLabel = 'YOUR SCORE',
  middleScoreItem,
  isMockReport,
  ...props
}) {
  const { seconds, minutes } = getTime(
    parseInt(score.details.questionDuration)
  );

  const { seconds: yourSeconds, minutes: yourMinutes } = getTime(
    parseInt(score.details.answerDuration)
  );

  return (
    <Flex justify="space-between" mt={6} {...props}>
      <ScoreItem
        label={scoreLabel}
        value={score.details[scoreKey]}
        total={totalScore}
      />
      {middleScoreItem}
      {!isMockReport && (
        <Box mr={2}>
          <Text color="custom.black3" fontWeight={600}>
            YOUR DURATION
          </Text>
          <Flex>
            <Text
              mr={1}
              fontWeight={600}
              color={
                score.details.answerDuration <= score.details.questionDuration
                  ? 'custom.blue'
                  : 'custom.red'
              }
            >
              {yourMinutes}:{yourSeconds} minutes
            </Text>{' '}
            out of
            <Text color="custom.black" fontWeight={600} ml={1}>
              {minutes}:{seconds} minutes
            </Text>
          </Flex>
        </Box>
      )}
    </Flex>
  );
}

export function ScoreFooter({ score, practiseAgain }) {
  return (
    <>
      <Box mt={6} bg="custom.blue6" p={4} borderRadius="10px">
        <Text color="custom.blue7" fontWeight={600} mb={2}>
          EXAMINER'S ADVICE
        </Text>
        <Box
          color="custom.blue7"
          dangerouslySetInnerHTML={{ __html: score.details.advice }}
        />
      </Box>
      <Box mt={10}>
        <Text fontSize="md" color="custom.black">
          AI Score Generated by{' '}
        </Text>
        <Image mt={4} src={logoTextual} />
      </Box>
      {practiseAgain && (
        <Button
          onClick={() => practiseAgain(null)}
          variantColor="blueVariant"
          mt={10}
          size="lg"
        >
          Practice Again
        </Button>
      )}
    </>
  );
}

export function ScoreBox({ score, label }) {
  if (!score) return null;
  return (
    <Flex align="center">
      <Box
        borderRadius="50%"
        fontWeight="700"
        minWidth="50px"
        minHeight="50px"
        textAlign="center"
        borderColor="custom.yellow"
        borderWidth="5px"
        mr={2}
        pt={1}
        fontSize="xl"
        color="custom.blue"
      >
        {score}
      </Box>
      <Text color="custom.blue" textTransform="uppercase">
        {label}
      </Text>
    </Flex>
  );
}
