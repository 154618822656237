import React, { useMemo, useState } from 'react';
import { Box, Text, Link } from '@chakra-ui/core';
import Datatable from '../../Datatable/Datatable';
import { useTable } from 'react-table';
import { usePaymentsHistory } from '../../../store/payments/hooks';
import { getDate } from '../../../helpers/getDate';
import { get, capitalize } from 'lodash-es';
import { formatNumber } from '../../../helpers/number';
import NextPrevButtons from '../../Navigation/NextPrev';

const getColumns = () => [
  {
    Header: 'Date',
    accessor: ({ createdAt }) => getDate(createdAt),
  },
  {
    Header: 'Service Type',
    accessor: ({ type = '' }) =>
      type === 'institute_payment' ? 'Monthly Payment' : capitalize(type),
  },
  {
    Header: 'Payment Method',
    accessor: ({ details }) => (
      <Text>
        <Text as="strong">VISA</Text> **** {get(details, 'paymentMethod.last4')}
      </Text>
    ),
  },
  {
    Header: 'Total',
    accessor: ({ amount }) => formatNumber(amount),
  },
  {
    id: 'action_button',
    accessor: ({ details }) => (
      <Link
        isExternal
        as={Link}
        color="custom.blue"
        href={get(details, 'receiptUrl')}
      >
        VIEW RECEIPT
      </Link>
    ),
  },
];

function Billing({ userId }) {
  const [page, setPage] = useState(1);

  const [
    { data: payments = [], nextPageUrl: hasNextPage, prevPageUrl: hasPrevPage },
    fetching,
  ] = usePaymentsHistory(userId, { page });

  const columns = useMemo(() => getColumns(), []);

  const table = useTable({
    data: payments,
    columns,
  });

  return (
    <Box color="custom.black3">
      <Box>
        <Text fontSize="lg" mb={4} textTransform="uppercase" fontWeight={600}>
          Billing History
        </Text>

        <Datatable {...table} />

        <NextPrevButtons
          fetching={fetching}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          setPage={setPage}
        />
      </Box>
    </Box>
  );
}

export default Billing;
