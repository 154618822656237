import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/core';
import { getTime } from '../../helpers/getTime';

function TitleBar({ serial, title, targetTime, yourTime }) {
  const { seconds, minutes } = getTime(parseInt(targetTime));
  const { seconds: counterSeconds, minutes: counterMinutes } = getTime(
    parseInt(yourTime)
  );

  return (
    <Box
      borderBottomWidth="1px"
      borderBottomColor="white4"
      pb={serial ? 4 : 2}
      mb={serial ? 1 : 5}
    >
      {serial ? (
        <Text
          mb={3}
          backgroundColor="custom.white6"
          color="custom.gray5"
          px={3}
          py={1}
          display="inline-block"
          borderRadius={4}
          fontSize="14px"
        >
          {serial}
        </Text>
      ) : (
        ''
      )}
      <Text color="custom.gray5" mb={1}>
        {title}
      </Text>
      {targetTime && (
        <Stack isInline>
          <Text color="custom.gray5">
            Allowed Time: {minutes}:{seconds}
          </Text>
          <Text color="custom.gray5">|</Text>
          <Text color="custom.red2">
            Time Taken: {counterMinutes}:{counterSeconds}
          </Text>
        </Stack>
      )}
    </Box>
  );
}

export default TitleBar;
