import React from 'react';
import { Box, Flex, Text, Stack } from '@chakra-ui/core';
import { get } from 'lodash-es';
import {
  ScoreHeader,
  ScoreFooter,
  ScoreDetails,
  MockScoreHeader,
} from '../Common/CommonView';
import AudioPlayerControl from '../../../../AudioPlayer/AudioPlayerControl';
import { MdCancel } from 'react-icons/md';

function ListeningDictationScore({ score, practiseAgain, isMockReport }) {
  return (
    <Box mt={2} mb={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          QUESTION
        </Text>

        <AudioPlayerControl audioUrl={get(score, 'details.audio')} />
        <Flex justify="flex-end" mt={4} fontSize="10px">
          <Stack isInline spacing={2}>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.yellow" mr={1} />
              <Text color="custom.black3">Omitted Word(s)</Text>
            </Flex>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.red" mr={1} />
              <Text color="custom.black3">Unmatched Word(s)/Sequence</Text>
            </Flex>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.blue" mr={1} />
              <Text color="custom.black3">Matched Word(s)</Text>
            </Flex>
          </Stack>
        </Flex>
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          CORRECT ANSWER
        </Text>

        <Text color="custom.black">{get(score, 'details.description')}</Text>
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          YOUR ANSWER
        </Text>
        {get(score, 'details.givenAnswer')?.trim?.() ? (
          <Text color="custom.black">{get(score, 'details.givenAnswer')}</Text>
        ) : (
          <Flex align="start" color="custom.red">
            <Box>
              <Box as={MdCancel} mr={2} />
            </Box>
            <Text mt="-5px">Not Answered</Text>
          </Flex>
        )}
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          COMPARISON
        </Text>
        <Text
          fontSize="20px"
          dangerouslySetInnerHTML={{ __html: get(score, 'details.comparison') }}
        />
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={2}>
          SCORING
        </Text>
        <Text
          borderWidth="1px"
          borderColor="custom.white4"
          bg="custom.white1"
          borderRadius="5px"
          p={2}
          color="custom.black"
        >
          {get(score, 'details.scoringWords')}
          <Text as="strong" color="custom.black3">
            {' '}
            1x{get(score, 'details.actualScore')}
          </Text>
        </Text>
      </Box>
      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        scoreKey="matchedScore"
        scoreLabel="YOUR MATCHED SCORE"
        isMockReport={isMockReport}
      />
      {!isMockReport && (
        <ScoreFooter score={score} practiseAgain={practiseAgain} />
      )}
    </Box>
  );
}

export default ListeningDictationScore;
