import React, { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Form from '../../../components/Form/Form';
import FormInput from '../../../components/Form/Input';
import FormButton from '../../../components/Form/Button';
import { Flex, Text, useToast } from '@chakra-ui/core';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import { useDispatch } from 'react-redux';
import { handleApiError } from '../../../helpers/handleApiError';
import { forgetPassword } from '../../../store/user/action';
import { AnchorLink } from '../../../components/Navigation/Link';
import AuthLayout from '../AuthLayout/AuthLayout';

function ForgetPassword() {
  const dispatch = useDispatch();

  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email('Enter valid email address.')
          .required('Enter your email address.'),
      }),
    []
  );

  const form = useForm({ validationSchema });

  const submit = useCallback(
    async (values) => {
      try {
        const valuesSnakeCased = changeToSnakeCase(values);
        await dispatch(forgetPassword(valuesSnakeCased));
        toast({
          position: 'top',
          title: 'An email has been sent with a link to reset password.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast]
  );

  return (
    <AuthLayout heading="Forget Password">
      <Text mb={4}>Enter email to reset password.</Text>
      <Form form={form} onSubmit={submit}>
        <FormInput name="email" placeholder="Email Address" />
        <FormButton px={10} my={2}>
          SUBMIT
        </FormButton>
      </Form>
      <Flex mt={4} align="center">
        <Text mr={2} fontSize="sm" color="custom.black3">
          Remember password?
        </Text>
        <AnchorLink to="/login" label="Login" variant="link" size="sm" />
      </Flex>
    </AuthLayout>
  );
}

export default ForgetPassword;
