import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/core';
import FormInput from '../../../components/Form/Input';

function LimboFillBlanks({ question, name }) {
  const paragraph = useMemo(() => {
    const splitText = question.split(/___/g);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = question.match(/___/g);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <FormInput
                key={index}
                boxProps={{
                  display: 'inline-flex',
                  width: '100%',
                  maxWidth: '150px',
                  mb: 0,
                }}
                height="26px"
                mx="5px"
                p={2}
                name={name}
              />,
            ]
          : [...arr, element],
      []
    );
  }, [question, name]);

  return <Box mb={4}>{paragraph}</Box>;
}

export default LimboFillBlanks;
