import React, { useEffect } from 'react';
import { Text, useToast } from '@chakra-ui/core';
import { handleApiError } from '../../helpers/handleApiError';
import AuthLayout from './AuthLayout/AuthLayout';
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { apiRequest } from '../../api/api';

function ActivateAccount() {
  const { push } = useHistory();

  const { token } = useParams();

  const toast = useToast();

  useEffect(() => {
    async function activateRequest() {
      try {
        await apiRequest('get', `auth/signup/activate/${token}`);

        toast({
          position: 'top',
          title: 'Account activated successfully. Please login.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        handleApiError(error, { toast });
      }

      setTimeout(() => {
        push('/login');
      }, 1000);
    }

    activateRequest();
  }, [toast, token, push]);

  return (
    <AuthLayout heading="Activate Account">
      <Text mb={4}>Please wait, activating your account.</Text>
      <Loader />
    </AuthLayout>
  );
}

export default ActivateAccount;
