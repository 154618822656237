import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/core';
import { get } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { UserAvatar } from '../../../../components/Avatar/Avatar';
import { ScoreBox } from '../../../../components/Community/Scores/ScoreViews/Common/CommonView';
import reportLogo from '../../../../assets/images/pte-aiwas-logo.svg';
import Share from '../../../../components/Community/Share/Share';
import { mockTypes } from '../../../../constants/mockTypes';
import { getDate } from '../../../../helpers/getDate';
import { AiOutlineFilePdf } from 'react-icons/ai';

function ScoreHeader({ report, user }) {
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        mb={6}
        pb={2}
        borderBottomWidth={1}
      >
        <Flex>
          <Text color="custom.gray5" mr={2}>
            Automated AI Score | PTE Academic |{' '}
          </Text>
          <Share shareLink={`${window.location.href}`} />
          <Button
            leftIcon={AiOutlineFilePdf}
            backgroundColor="transparent"
            border="none"
            p={0}
            ml={4}
            mt="13px"
            height={0}
            onClick={() => window.print()}
            color="custom.blue2"
          >
            Save as PDF
          </Button>
        </Flex>
        <Image src={reportLogo} />
      </Flex>
      <Box mt={2}>
        <Flex justify="space-between">
          <Flex align="center">
            <Box
              borderRadius="4px"
              fontWeight="700"
              minWidth="50px"
              textAlign="center"
              bg="custom.blue"
              mr={2}
              fontSize="3xl"
              py={1}
              px={3}
              color="white"
            >
              {report.score.overall}
            </Box>
            <Text fontSize="2xl" fontWeight="600" ml={2} color="custom.blue">
              OVERALL SCORE
            </Text>
          </Flex>
          <Flex align="center">
            <UserAvatar
              size="42px"
              src={get(user, 'details.avatar')}
              name={get(user, 'details.firstName')}
            />
            <Box ml={2}>
              <Text color="custom.black">
                {get(user, 'details.firstName')} {get(user, 'details.lastName')}
              </Text>
              <Text color="custom.gray5" fontSize="14px">
                {get(report, 'testDate')}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

function RowItem({ label, value, ...props }) {
  return (
    <Text {...props}>
      <Text as="span" fontWeight={600}>
        {label}:{' '}
      </Text>
      {value}
    </Text>
  );
}

function Graph({ score, ...props }) {
  const ref = useRef();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((ref.current.offsetWidth * score) / 90);
  }, [score]);

  return (
    <>
      <Box ref={ref} flex={1} position="relative">
        <Box
          width={width}
          position="absolute"
          left={0}
          h="100%"
          bg="custom.yellow"
          {...props}
        />
      </Box>
    </>
  );
}

function GraphBar({ score }) {
  const ref = useRef();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(((ref.current.offsetWidth - 148) * score) / 90);
  }, [score]);

  return (
    <>
      <Text fontWeight={600} position="absolute" left={width + 114} top={-40}>
        {score} Overall
      </Text>
      <Box
        position="absolute"
        left={width + 154}
        top={-20}
        bottom={-20}
        w="2px"
        bg="#E1E6E9"
      />
      <Box ref={ref} flex={1} />
    </>
  );
}

function SkillBreakdownRow({ score, label, graphProps = {}, ...props }) {
  if (!score) return null;

  return (
    <Flex {...props}>
      <Text color="custom.gray2" minW="150px" textAlign="right" mr={2}>
        {label}{' '}
        <Text ml={2} as="span" fontWeight={600} color="custom.blue">
          {score}
        </Text>
      </Text>
      <Graph score={score} {...graphProps} />
    </Flex>
  );
}

function MockScoreGraphs({ score }) {
  return (
    <Stack spacing={2} position="relative" mt={10}>
      <Flex>
        <GraphBar score={score.overall} />
      </Flex>
      <SkillBreakdownRow score={score.listening} label="Listening" />
      <SkillBreakdownRow score={score.reading} label="Reading" />
      <SkillBreakdownRow score={score.speaking} label="Speaking" />
      <SkillBreakdownRow score={score.writing} label="Writing" />

      <Text my={2} fontSize="lg" fontWeight={600}>
        Enabling Skills
      </Text>

      <SkillBreakdownRow
        score={score.grammar}
        label="Grammar"
        graphProps={{ bg: 'custom.blue' }}
      />

      <SkillBreakdownRow
        score={score.fluency}
        label="Oral Fluency"
        graphProps={{ bg: 'custom.blue' }}
      />

      <SkillBreakdownRow
        score={score.pronunciation}
        label="Pronunciation"
        graphProps={{ bg: 'custom.blue' }}
      />

      <SkillBreakdownRow
        score={score.spelling}
        label="Spelling"
        graphProps={{ bg: 'custom.blue' }}
      />

      <SkillBreakdownRow
        score={score.vocabulary}
        label="Vocabulary"
        graphProps={{ bg: 'custom.blue' }}
      />

      <SkillBreakdownRow
        score={score.discourse}
        label="Written Discourse"
        graphProps={{ bg: 'custom.blue' }}
      />
    </Stack>
  );
}

function MockOverallReport({ data }) {
  return (
    <>
      <ScoreHeader report={data.report} user={data.user} />
      {data?.report.type === mockTypes.full && (
        <Flex
          mt={6}
          bg="custom.white6"
          py={3}
          px={4}
          borderRadius="5px"
          justify="space-between"
          fontWeight={600}
        >
          <ScoreBox
            score={get(data, 'report.score.listening')}
            label="Listening"
          />
          <ScoreBox score={get(data, 'report.score.reading')} label="Reading" />
          <ScoreBox
            score={get(data, 'report.score.speaking')}
            label="Speaking"
          />
          <ScoreBox score={get(data, 'report.score.writing')} label="Writing" />
        </Flex>
      )}
      <SimpleGrid columns={2} spacing={4} mt={8} position="relative">
        <Box
          position="absolute"
          top={20}
          bottom={20}
          w="1px"
          bg="#E1E6E9"
          left="50%"
        />
        <Box mr="60px" color="custom.black3">
          <Text mb={4} fontSize="xl" fontWeight="600" color="custom.blue">
            Skills Breakdown
          </Text>
          <MockScoreGraphs score={data.report.score} />
        </Box>
        <Box ml="60px">
          <Text mb={4} fontSize="xl" fontWeight="600" color="custom.blue">
            Test Centre Information
          </Text>
          <Stack spacing={2} color="custom.black3">
            <RowItem label="Test Date" value={data.report.testDate} />
            <RowItem label="Valid Until" value={data.report.validUntil} />
            <RowItem
              label="Report Issue Date"
              value={data.report.reportIssue}
            />
            <RowItem
              label="Test Centre Country"
              value={data.report.testCentreCountry}
            />
            <RowItem label="Test Centre ID" value={data.report.testCentreId} />
            <RowItem label="Test Centre" value={data.report.testCentre} />
          </Stack>
          <Text
            mb={4}
            mt={8}
            fontSize="xl"
            fontWeight="600"
            color="custom.blue"
          >
            Candidate Information
          </Text>
          <Stack spacing={2} color="custom.black3">
            <RowItem
              label="Date of Birth"
              value={
                data.user.details?.dob ? getDate(data.user.details?.dob) : ''
              }
            />
            <RowItem
              label="Country of Citizenship"
              value={data.user.details?.address?.citizenship?.country?.name}
            />
            <RowItem
              label="Country of Residence"
              value={data.user.details?.address?.residence?.country?.name}
            />
            <RowItem label="Gender" value={data.user.details?.gender} />
            <RowItem label="Email" value={data.user.email} />
            <RowItem
              label="First-Time Test Taker"
              value={data.report.firstTime}
            />
          </Stack>
        </Box>
      </SimpleGrid>
    </>
  );
}

export default MockOverallReport;
