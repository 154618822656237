import { reduce } from 'lodash-es';
import { mockTypes } from '../../../../constants/mockTypes';
import { sections } from '../../../../constants/sections';

export const getDataMappersByType = ({ type, questions }) => {
  if (questions.isFetching || !mockTypes[type]) return;

  let tabs = [];
  let questionModules = {};
  let questionsByType = {};

  if (type === mockTypes.full) {
    tabs.push(
      sections.speaking,
      sections.writing,
      sections.listening,
      sections.reading
    );
    questionModules = {
      [sections.speaking]: [
        ...Object.keys(questions.data[sections.speaking][sections.speaking]),
      ],
      [sections.writing]: [
        ...Object.keys(questions.data[sections.writing][sections.writing]),
      ],
      [sections.listening]: [
        ...Object.keys(questions.data[sections.listening][sections.listening]),
      ],
      [sections.reading]: [
        ...Object.keys(questions.data[sections.reading][sections.reading]),
      ],
    };
    questionsByType = {
      [sections.speaking]: questions.data[sections.speaking][sections.speaking],
      [sections.writing]: questions.data[sections.writing][sections.writing],
      [sections.listening]:
        questions.data[sections.listening][sections.listening],
      [sections.reading]: questions.data[sections.reading][sections.reading],
    };
  } else {
    tabs = Object.keys(questions.data[type]);
    questionModules = reduce(
      questions.data[type],
      (obj, value, key) => ({
        ...obj,
        [key]: Object.keys(value),
      }),
      {}
    );
    questionsByType = reduce(
      questions.data[type],
      (obj, value, key) => ({
        ...obj,
        [key]: value,
      }),
      {}
    );
  }

  return { tabs, questionModules, questions: questionsByType };
};
