import { combineReducers } from 'redux';
import questions from './questionSlice';
import comments from './commentSlice';

const readingReducer = combineReducers({
  questions,
  comments,
});

export default readingReducer;
