import React, { useCallback } from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import Form from '../../../components/Form/Form';
import MCQSingleFormField from '../Modules/Reading/MCQSingle/FormField';
import FormButton from '../../../components/Form/Button';
import { isArray, isEmpty } from 'lodash-es';
import useCustomToast from '../../../hooks/useCustomToast';
import LimboFillBlanks from './LimboFIllBlanks';
import MCQMultipleFormField from '../Modules/MCQMultipleFormField';

function SwitchFields(props) {
  const { index, question } = props;

  if (question.type === 'mcq-single') {
    return (
      <MCQSingleFormField
        idPrefix={`answer-${index}`}
        key={index}
        name={`answer[${index}]`}
        label={
          <>
            <Text>{question.question}</Text>
            {question.sentence && (
              <Text ml={4} color="custom.black3">
                {question.sentence}
              </Text>
            )}
          </>
        }
        options={question.answers.map((item) => item.answer)}
      />
    );
  }

  if (question.type === 'mcq-multiple') {
    return (
      <MCQMultipleFormField
        idPrefix={`answer-${index}`}
        name={`answer[${index}]`}
        label={question.question}
        options={question.answers.map((item) => item.answer)}
      />
    );
  }

  if (question.type === 'fib') {
    return (
      <LimboFillBlanks question={question.question} name={`answer[${index}]`} />
    );
  }

  return null;
}

function DiagnosticFormStep({
  title,
  description,
  questions,
  onSubmit,
  hasNext,
}) {
  const form = useForm();

  const { error } = useCustomToast();

  const handleSubmit = useCallback(
    ({ answer }) => {
      const answers = [];

      for (let value of answer) {
        if (isArray(value)) {
          answers.push(value.filter(Boolean));
          continue;
        }

        answers.push(value);
      }

      const hasEmpty = answers.some((answer) =>
        isEmpty(isArray(answer) ? answer : answer.trim())
      );

      if (hasEmpty) {
        error({ title: 'Please fill up all the fields.' });
        return;
      }

      onSubmit(answers);
    },
    [error, onSubmit]
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Text
        fontWeight={600}
        borderBottomWidth="1px"
        textTransform="uppercase"
        mb={2}
        pb={2}
        color="custom.blue"
      >
        {title}
      </Text>
      {description && (
        <Text mb={2} dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {questions.map((question, index) => (
        <Box mb={3} key={question.question}>
          <Flex key={index} w="100%">
            <Text mr={3} fontWeight={600} color="custom.blue">
              {index + 1}.
            </Text>
            <Box flex={1} color="custom.black3">
              <SwitchFields index={index} question={question} />
            </Box>
          </Flex>
        </Box>
      ))}
      <FormButton mt={6} px={6}>
        {hasNext ? 'Next' : 'Submit'}
      </FormButton>
    </Form>
  );
}

export default DiagnosticFormStep;
