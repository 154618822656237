import { useCallback, useEffect, useRef, useState } from 'react';
import { get } from 'lodash-es';
import { useWordCount } from '../../../../../../hooks/useWordCount';
import React from 'react';
import { Box, Button, Flex, Heading, Text, Textarea } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { useDisabledCut } from '../../../../../../hooks/useDisabledCut';

function MockQuestionWrittenSummaryView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
  isTimerRunning,
}) {
  const [answer, setAnswer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error } = useCustomToast();
  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setTimerDelay(null);

      await apiRequest('post', 'mocks/autoSave', {
        answer,
        type: mock.type,
        section: sections.writing,
        question_id: data.id,
        module: data.module,
        index,
      });
      setTimerDelay(1000);

      onAfterSubmit();
    } catch {
      error({
        title: 'Looks like something went wrong!',
        description: 'Please check your network connection and try reloading.',
      });
      setIsSubmitting(false);
      setTimerDelay(1000);
    }
  }, [onAfterSubmit, error, mock, index, data, answer, setTimerDelay]);

  const answerRef = useRef();

  useEffect(() => {
    answerRef.current.addEventListener('cut', (event) => {
      setAnswer(event.target.value);
    });
  }, []);

  const count = useWordCount(answer);

  const copiedText = useRef();

  useDisabledCut();

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box my={10} px={6}>
            <Heading size="sm">
              Read the passage below and summarize it using one sentence. Type
              your response in the box at the bottom of the screen. You have{' '}
              {Math.ceil(data.duration / 60)} minutes to finish this task. Your
              response will be judged on the quality of your writing and on how
              well your response presents the key points in the passage.
            </Heading>
            <Box mt={8}>
              <Text mb={10}>{get(data, 'description')}</Text>
              <Textarea
                my={4}
                isDisabled={!isTimerRunning}
                minHeight="220px"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                data-gramm_editor="false"
                borderRadius={0}
                borderColor="custom.blue"
                ref={answerRef}
                value={answer}
                onPaste={(event) => {
                  if (!copiedText.current) {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => setAnswer(event.target.value)}
              />
              <Flex justify="space-between" mb={4}>
                <Button
                  size="sm"
                  onClick={() => {
                    copiedText.current = window.getSelection().toString();
                    document.execCommand('cut');
                  }}
                  bg="white"
                  borderWidth={1}
                  borderColor="mock.gray"
                  w="80px"
                >
                  Cut
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    copiedText.current = window.getSelection().toString();
                    document.execCommand('copy');
                  }}
                  bg="white"
                  borderWidth={1}
                  borderColor="mock.gray"
                  w="80px"
                >
                  Copy
                </Button>
                <Button
                  size="sm"
                  onClick={async () => {
                    document.execCommand(
                      'insertText',
                      false,
                      copiedText.current
                    );
                  }}
                  bg="white"
                  borderWidth={1}
                  borderColor="mock.gray"
                  w="80px"
                >
                  Paste
                </Button>
              </Flex>
              <Text fontWeight="bold">Total Word Count: {count}</Text>
            </Box>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionWrittenSummaryView;
