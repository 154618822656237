import { cloneElement, memo, useMemo } from 'react';
import { useAuthorization } from '../../hooks/useAuthorization';
import { isArray } from 'lodash-es';

function Authorize({ roles, permissions, children, ...props }) {
  const isAuthorized = useAuthorization({ roles, permissions });

  const toRender = useMemo(() => {
    return isArray(children) ? children : cloneElement(children, props);
  }, [children, props]);

  return isAuthorized && children ? toRender : null;
}

export default memo(Authorize);
