import React, { useCallback, useMemo, useEffect } from 'react';
import {
  Flex,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Box,
  FormLabel,
  Stack,
} from '@chakra-ui/core';
import { FaSortAmountUp, FaSortAmountDown } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
// import Form from '../Form/Form';
// import FormCheckbox from '../Form/Checkbox';
// import FormButton from '../Form/Button';
import { debounce, map, get } from 'lodash-es';
import SearchBar from '../../../components/Search/SearchBar';
// import FormSelect from '../Form/Select';
import { priority } from '../../../constants/priority';
import Form from '../../../components/Form/Form';
import FormCheckbox from '../../../components/Form/Checkbox';
import FormSelect from '../../../components/Form/Select';
import FormButton from '../../../components/Form/Button';
import FormDatePicker from '../../../components/Form/DatePicker';

function Filter({ queryValues, setQueryValues }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const practiced = useMemo(() => get(queryValues, 'isPracticed') === 'yes', [
    queryValues,
  ]);
  const notPracticed = useMemo(() => get(queryValues, 'isPracticed') === 'no', [
    queryValues,
  ]);

  const defaultValues = useMemo(
    () => ({
      priority: get(queryValues, 'priority'),
      dateFrom: get(queryValues, 'dateFrom'),
      dateTo: get(queryValues, 'dateTo'),
    }),
    [queryValues]
  );

  const form = useForm({ defaultValues });

  const { reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen, reset, defaultValues]);

  const submit = useCallback(
    ({ practiced, notPracticed, priority, dateFrom, dateTo }) => {
      let isPracticed;

      if (practiced) {
        isPracticed = 'yes';
      }

      if (notPracticed) {
        isPracticed = 'no';
      }

      if (practiced && notPracticed) {
        isPracticed = undefined;
      }

      setQueryValues((state) => ({
        ...state,
        isPracticed,
        priority,
        dateFrom,
        dateTo,
      }));
    },
    [setQueryValues]
  );

  return (
    <>
      <Button
        size="sm"
        variant="link"
        leftIcon={FiFilter}
        onClick={onOpen}
        color="custom.black"
        _hover={{
          color: 'custom.blue',
        }}
      >
        Filter
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={submit}>
            <ModalHeader color="custom.black3">Filter</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack isInline spacing={4}>
                <FormDatePicker name="dateFrom" label="Date From" />
                <FormDatePicker name="dateTo" label="Date To" />
              </Stack>
              <Box>
                <FormLabel mb={1} color="custom.black5">
                  Practice Status
                </FormLabel>
                <Stack isInline color="custom.black">
                  <FormCheckbox
                    defaultIsChecked={practiced}
                    name="practiced"
                    label="Practiced"
                  />
                  <FormCheckbox
                    defaultIsChecked={notPracticed}
                    name="notPracticed"
                    label="Not Practiced"
                  />
                </Stack>
              </Box>
              <Box mt={4} maxW="50%">
                <FormLabel mb={1} color="custom.black5">
                  Priority
                </FormLabel>
                <FormSelect
                  isClearable
                  name="priority"
                  options={priorityOptions}
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                variantColor="blueVariant"
                variant="link"
                mr={3}
                onClick={onClose}
              >
                Cancel
              </Button>
              <FormButton
                _hover={{
                  backgroundColor: 'custom.blue8',
                }}
                variantColor="blueVariant"
              >
                Apply Filter
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
}

const priorityOptions = map(priority, (value, key) => ({
  label: value,
  value: key,
}));

function PredictionFilter({
  searchPlaceHolder = 'Search..',
  queryValues,
  setQueryValues,
}) {
  const search = debounce((value) => {
    setQueryValues((state) => ({ ...state, search: value }));
  }, 500);

  return (
    <Box>
      <SearchBar
        placeholder={searchPlaceHolder}
        onChange={(e) => search(e.target.value)}
      />
      <Flex
        borderBottomWidth="1px"
        borderBottomColor="custom.white4"
        pb={4}
        mb={3}
        justify="space-between"
      >
        <Filter queryValues={queryValues} setQueryValues={setQueryValues} />
        <IconButton
          size="sm"
          p={0}
          m={0}
          icon={
            queryValues.order === 'desc' ? FaSortAmountDown : FaSortAmountUp
          }
          variant="link"
          color="custom.black"
          onClick={() =>
            setQueryValues((state) => ({
              ...state,
              order: state.order === 'asc' ? 'desc' : 'asc',
            }))
          }
          _hover={{
            color: 'custom.blue',
          }}
        />
      </Flex>
    </Box>
  );
}

export default PredictionFilter;
