import React, { useMemo } from 'react';
import BarLink from '../Navigation/BarLink';
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/core';
import CustomProgressBar from '../ProgressBar/ProgressBar';
import CustomFilter from '../Filter/Filter';
import { get } from 'lodash-es';
import PrimaryButton from '../Button/PrimaryButton';
import { stripHtml } from '../../helpers/stripHtml';
import styled from '@emotion/styled';

const CustomBox = styled(Box)`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

function QuestionBar({
  questions,
  nextPage,
  setQuery,
  queryObject,
  searchPlaceHolder,
  rootPath,
  stats,
  loadMore,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure(true);

  const initialWidth = 450;

  const progressValue = useMemo(() => {
    const total = get(stats, 'totalQuestions');
    const practiced = get(stats, 'practicedQuestions');

    return total ? ((practiced / total) * 100).toFixed(0) : 0;
  }, [stats]);

  return (
    <Box
      pos="relative"
      width={isOpen ? initialWidth : 0}
      transition="width .15s ease-out"
    >
      <IconButton
        position="absolute"
        left="100%"
        top={32}
        bg="white"
        border="1px solid"
        borderColor="custom.white4"
        borderLeft="none"
        size="xs"
        p="14px 4px 14px 0"
        borderRadius={0}
        borderTopRightRadius={5}
        borderBottomRightRadius={5}
        fontSize="2xl"
        icon={isOpen ? 'arrow-back' : 'arrow-forward'}
        onClick={() => (isOpen ? onClose() : onOpen())}
        zIndex={9}
      />
      <Flex
        p={6}
        bg="custom.white2"
        borderWidth="1px"
        borderColor="custom.white4"
        borderTopWidth={0}
        height="calc(100vh - 70px)"
        direction="column"
        opacity={isOpen ? 1 : 0}
        transition="opacity .15s ease-out"
      >
        <CustomProgressBar value={progressValue} />
        <CustomFilter
          searchPlaceHolder={searchPlaceHolder}
          queryObject={queryObject}
          setQuery={setQuery}
        />
        <CustomBox overflowY="scroll">
          {questions.map(
            ({
              id,
              description,
              isFree,
              labels,
              isPracticed,
              status,
              module,
            }) => (
              <BarLink
                key={id}
                title={stripHtml(description)}
                labels={labels}
                isPracticed={isPracticed}
                hasStatus={status}
                isFree={isFree}
                to={`${rootPath}/${id}`}
                titleFormat={
                  module !== 'DescribeImage' &&
                  module !== 'ReorderParagraph' &&
                  module !== 'HighlightCorrectSummary'
                }
                hideTitleOnActive={
                  module === 'WriteFromDictation' || module === 'RepeatSentence'
                }
              />
            )
          )}
          {nextPage && (
            <Flex justify="center" mt={6}>
              <PrimaryButton
                _hover={{
                  backgroundColor: 'custom.blue',
                  color: 'white',
                  opacity: '1',
                }}
                px="30px"
                size="sm"
                height="43px"
                variant="outline"
                variantColor="blueVariant"
                opacity=".5"
                onClick={loadMore}
              >
                Load More Questions
              </PrimaryButton>
            </Flex>
          )}
        </CustomBox>
      </Flex>
    </Box>
  );
}

function QuestionLayout({
  questions = [],
  nextPage,
  stats = {},
  setQuery,
  queryObject,
  searchPlaceHolder,
  rootPath = '/',
  loadMore,
  children,
}) {
  return (
    <Flex color="custom.black" height="calc(100vh - 76px)" overflow="hidden">
      <Box>
        <QuestionBar
          nextPage={nextPage}
          stats={stats}
          rootPath={rootPath}
          questions={questions}
          searchPlaceHolder={searchPlaceHolder}
          setQuery={setQuery}
          queryObject={queryObject}
          loadMore={loadMore}
        />
      </Box>
      <Box px={10} py={4} overflowY="auto" width="100%">
        {children}
      </Box>
    </Flex>
  );
}

export default QuestionLayout;
