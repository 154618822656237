import React from 'react';
import { Box, Flex, Text, SimpleGrid } from '@chakra-ui/core';
import { get } from 'lodash-es';
import {
  ScoreHeader,
  ScoreFooter,
  ScoreDetails,
  MockScoreHeader,
} from '../Common/CommonView';
import AudioPlayerControl from '../../../../AudioPlayer/AudioPlayerControl';

function AnswerShortQuestionScore({ score, practiseAgain, isMockReport }) {
  return (
    <Box mt={2} mb={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={2}>
          QUESTION
        </Text>
        <AudioPlayerControl
          audioUrl={get(score, 'details.questionAudio')}
          waveHeight={64}
          volumeContainerProps={{ minW: '100px' }}
        />
      </Box>

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          CORRECT ANSWER
        </Text>

        <Text color="custom.black">{get(score, 'details.correctAnswer')}</Text>
      </Box>

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={2}>
          YOUR ANSWER: RECORDING
        </Text>

        <AudioPlayerControl
          audioUrl={get(score, 'details.recording')}
          waveHeight={64}
          volumeContainerProps={{ minW: '100px' }}
        />
      </Box>

      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        mt={8}
        isMockReport={isMockReport}
      />

      {!isMockReport && (
        <>
          {get(score, 'details.samples.audio') && (
            <Box mt={8}>
              <Text color="custom.black3" fontWeight={600} mb={1}>
                MODEL ANSWER (STUDENT)
              </Text>
              <SimpleGrid columns={1} spacing={2}>
                <Box mb={10}>
                  <Text color="custom.gray5">
                    {get(score, 'details.samples.gender')} - Recording
                  </Text>
                  <AudioPlayerControl
                    audioUrl={get(score, 'details.samples.audio')}
                    waveHeight={64}
                    volumeContainerProps={{ minW: '100px' }}
                  />
                </Box>
              </SimpleGrid>
            </Box>
          )}
          <ScoreFooter score={score} practiseAgain={practiseAgain} />
          <Flex justify="flex-end">
            <Text fontSize="xs" color="custom.gray5">
              {get(score, 'details.reference', '')}
            </Text>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default AnswerShortQuestionScore;
