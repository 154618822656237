import React from 'react';
import { Box, Flex, Text, Badge } from '@chakra-ui/core';
import ReportAnalyser from '../../../../components/ReportAnalyser/ReportAnalyser';

function DashboardReportAnalyser({ userId }) {
  return (
    <>
      <Box>
        <Flex align="center">
          <Text
            mr={2}
            color="custom.blue"
            textTransform="uppercase"
            fontWeight={600}
          >
            PTE Score Analyzer
          </Text>
          <Badge borderRadius="5px" variant="outline" variantColor="redVariant">
            AI
          </Badge>
        </Flex>
        <Flex mt={6} direction="column">
          <Text color="custom.gray2" mb={4}>
            Analyze your report by uploading pdf files or manually input
            results.
          </Text>
          <ReportAnalyser userId={userId} />
        </Flex>
      </Box>
    </>
  );
}

export default DashboardReportAnalyser;
