import { useCallback, useMemo, useRef, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import '../../../Mocks.css';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';
import useCustomToast from '../../../../../../hooks/useCustomToast';

const rx = /\[\.{4}\d+\.{4}\]/g;

function DragItem({ id, label }) {
  return (
    <Box
      data-type="drag"
      draggable="true"
      as="span"
      id={id}
      bg="white"
      px={2}
      py={0.5}
      mx={2}
      flex={1}
      my={1}
      textAlign="center"
      borderWidth={1}
      borderColor="black"
      onDragStart={(event) => {
        event.dataTransfer.setData('id', event.target.id);
      }}
    >
      {label}
    </Box>
  );
}

function DropItem({ refToOther }) {
  return (
    <Box
      data-type="drop"
      display="inline-block"
      minWidth="100px"
      minH="30px"
      onDragOver={(event) => {
        event.target.style.background = 'yellow';
        event.preventDefault();
      }}
      onDragLeave={(event) => {
        event.target.style.background = 'white';
        event.preventDefault();
      }}
      bg="white"
      borderColor="black"
      borderWidth={1}
      mx={2}
      px={1}
      verticalAlign="middle"
      onDrop={(event) => {
        event.preventDefault();
        event.target.style.background = 'white';
        const data = event.dataTransfer.getData('id');
        if (!event.target.hasChildNodes()) {
          event.target.appendChild(document.getElementById(data));
          return;
        }
        refToOther.appendChild(event.target.childNodes[0]); // set to other box
        event.target.appendChild(document.getElementById(data));
      }}
    />
  );
}

function MockQuestionReadingFillBlanksDragView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ref = useRef();

  const paragraphRef = useRef();

  const paragraph = useMemo(() => {
    const text = get(data, 'description', '');

    const splitText = text.split(rx);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = text.match(rx);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [...arr, element, <DropItem refToOther={ref.current} key={index} />]
          : [...arr, element],
      []
    );
  }, [data]);

  const { error } = useCustomToast();
  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setTimerDelay(null);

      const answer = [];
      Array.from(paragraphRef.current.children).forEach((child, index) => {
        if (child.getAttribute('data-type') === 'drop') {
          if (!child.children.length) {
            answer.push('Not Answered');
            return;
          }
          answer.push(child.children[0].innerText);
        }
      });

      await apiRequest('post', 'mocks/autoSave', {
        answer,
        type: mock.type,
        section: sections.reading,
        question_id: data.id,
        module: data.module,
        index,
      });

      setTimerDelay(1000);

      onAfterSubmit();
    } catch {
      error({
        title: 'Looks like something went wrong!',
        description: 'Please check your network connection and try reloading.',
      });
      setIsSubmitting(false);
      setTimerDelay(1000);
    }
  }, [onAfterSubmit, error, mock, index, data, setTimerDelay]);

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box py={10} px={6}>
            <Box>
              <Heading size="sm" mb={8}>
                In the text below some words are missing. Drag words from the
                box below to the appropriate place in the text. To undo an
                answer choice drag the word back to the box below the text.
              </Heading>
              <Box mt={6} lineHeight="2" ref={paragraphRef}>
                {paragraph}
              </Box>
              <Box>
                <Flex
                  mt={6}
                  p={2}
                  // flexWrap="wrap"
                  align="baseline"
                  minHeight="200px"
                  bg="mock.blue"
                  ref={ref}
                  onDragOver={(event) => event.preventDefault()}
                  onDrop={(event) => {
                    event.preventDefault();
                    if (!event.target.id) {
                      const data = event.dataTransfer.getData('id');
                      event.target.appendChild(document.getElementById(data));
                    }
                  }}
                >
                  {get(data, 'drag', []).map((item, index) => (
                    <DragItem id={`drag-${index}`} label={item} key={index} />
                  ))}
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={onSubmit}
              isSubmitting={isSubmitting}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionReadingFillBlanksDragView;
