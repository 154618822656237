import React, { useEffect, useCallback } from 'react';
import {
  Radio,
  FormControl,
  FormLabel,
  Stack,
  Button,
  RadioButtonGroup,
  Box,
} from '@chakra-ui/core';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { FiCircle } from 'react-icons/fi';
import { MdCheckCircle } from 'react-icons/md';
import ErrorMessage from './ErrorMessage';

export const FormCustomRadio = React.forwardRef((props, ref) => {
  const { children, isChecked, isDisabled, value, ...rest } = props;
  return (
    <Button
      ref={ref}
      aria-checked={isChecked}
      role="radio"
      isDisabled={isDisabled}
      flex={1}
      borderColor={isChecked ? 'black2' : 'custom.gray3'}
      borderWidth="1px"
      borderRadius="30px"
      backgroundColor={isChecked ? 'custom.blue' : 'transparent'}
      _hover={{ bg: 'custom.blue', color: 'white' }}
      _focus={{
        boxShadow: 'none',
      }}
      color={isChecked ? 'white' : 'custom.gray3'}
      justifyContent="flex-start"
      textAlign="left"
      fontSize="14px"
      fontWeight={400}
      minHeight={12}
      minWidth="auto"
      {...rest}
    >
      <Box as={isChecked ? MdCheckCircle : FiCircle} fontSize="18px" mr={2} />
      {children}
    </Button>
  );
});

export const FormRadioGroup = ({
  name,
  id = name,
  children,
  defaultValue,
  ...props
}) => {
  const { register, watch, setValue, unregister } = useFormContext();

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  const onChange = useCallback(
    (value) => {
      setValue(name, value || null);
    },
    [setValue, name]
  );

  const value = watch(name);

  return (
    <Box {...props} mb={6}>
      <RadioButtonGroup
        display="flex"
        onChange={onChange}
        isInline
        value={value}
        defaultValue={defaultValue}
      >
        {children}
      </RadioButtonGroup>
      <ErrorMessage name={name} />
    </Box>
  );
};

const FormRadio = ({
  name,
  id = name,
  label,
  containerProps = {},
  ...props
}) => {
  const { register, errors } = useFormContext();

  return (
    <FormControl isDisabled={props.disabled} isInvalid={!!get(errors, name)}>
      <Box {...containerProps}>
        <Stack isInline align="start">
          <Radio id={id} name={name} ref={register} {...props} />
          <FormLabel mt="-6px" htmlFor={id} display={label ? 'block' : 'none'}>
            {label}
          </FormLabel>
        </Stack>
        <ErrorMessage name={name} />
      </Box>
    </FormControl>
  );
};

export default FormRadio;
