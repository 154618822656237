import React from 'react';
import { Box, Flex, Button, Text } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import Form from '../../../components/Form/Form';
import FormInput from '../../../components/Form/Input';
import FormButton from '../../../components/Form/Button';
import { get } from 'lodash-es';

export function ApplyDiscount({ onSubmit }) {
  const form = useForm();

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex maxW="300px">
        <FormInput placeholder="Apply Discount Code..." name="couponCode" />
        <FormButton ml={4} px={10}>
          APPLY
        </FormButton>
      </Flex>
    </Form>
  );
}

export function AppliedDiscount({ discountDetails, onRemove }) {
  if (!discountDetails) return null;

  return (
    <Box mb={6}>
      <Flex align="center">
        <Text fontWeight={600} fontSize="lg" mr={2}>
          APPLIED COUPON
        </Text>
        <Button
          variantColor="redVariant"
          variant="link"
          size="sm"
          onClick={onRemove}
        >
          REMOVE
        </Button>
      </Flex>
      <Text>
        {get(discountDetails, 'couponCode')}{' '}
        <Text as="strong">({get(discountDetails, 'discount')}%)</Text>
      </Text>
    </Box>
  );
}
