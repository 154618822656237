import React, { useContext, useEffect } from 'react';
import { Flex } from '@chakra-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import MainDashboard from './Main/Main';
import Topbar from '../../components/Navigation/Topbar';
import { ContainerBox } from '../../components/Layout/Container';
import Settings from './Settings/Settings';
import MCQSinglePage from './Modules/Reading/MCQSingle';
// import Labels from './Settings/Labels/Labels';
// import LabelsAdd from './Settings/Labels/LabelsAdd';
// import LabelsEdit from './Settings/Labels/LabelsEdit';
import ScorePage from './Score/Score';
import { routesMapper } from '../../Routes/routesMapper';
import AppContext from '../../Routes/AppContext';
import { useDispatch } from 'react-redux';
import { fetchNofications } from '../../store/nofitication/notificationSlice';
import NotificationPage from './Notification/Notification';
import MCQMultiplePage from './Modules/Reading/MCQMultiple';
import ReorderPage from './Modules/Reading/Reorder';
import FillBlanksDropdownPage from './Modules/Reading/FIllBlanksDropdown';
import FillBlanksDragPage from './Modules/Reading/FillBlanksDrag';
import ListeningFillBlanksPage from './Modules/Listening/FillBlanks';
import ListeningMCQSinglePage from './Modules/Listening/MCQSingle';
import ListeningMissingWordsPage from './Modules/Listening/MissingWords';
import ListeningHighlightCorrectPage from './Modules/Listening/HighlightCorrect';
import ListeningMCQMultiplePage from './Modules/Listening/MCQMultiple';
import ListeningDictationPage from './Modules/Listening/Dictation';
import ListeningHighlightIncorrectPage from './Modules/Listening/HighlightIncorrect';
import WritingEssayPage from './Modules/Writing/Essay';
import WritingSummaryPage from './Modules/Writing/Summary';
import ListeningSummaryPage from './Modules/Listening/Summary';
import SpeakingReadAloudPage from './Modules/Speaking/ReadAloud';
import SpeakingRepeatSentencePage from './Modules/Speaking/RepeatSentence';
import SpeakingDescribeImagePage from './Modules/Speaking/DescribeImage';
import SpeakingRetellLecturePage from './Modules/Speaking/RetellLecture';
import SpeakingAnswerShortQuestionPage from './Modules/Speaking/AnswerShortQuestion';
import UserList from './User/UserList';
import UserView from './User/UserView';
import Authorize from '../../components/Authorize/Authorize';
import { roles } from '../../constants/roles';
import SubscriptionPage from './Subscription/Subscription';
import CouponPage from './Coupon/Coupon';
import CheckoutPage from './Checkout/Checkout';
import {
  useHasCompletedProfile,
  usePaymentStatus,
} from '../../store/user/hooks';
import PredictionPage from './Prediction/Prediction';
import DiagnosticPage from './Diagnostic/Diagnostic';
import { Helmet } from 'react-helmet';
import MocksPage from './Mocks/Mocks';
import MocksAddPage from './Mocks/Add/Add';
import MocksAddByTypePage from './Mocks/Add/AddByType';
import MockEditPage from './Mocks/Edit/Edit';
import MockAttendPage from './Mocks/Attend/Attend';
import MockReportPage from './Mocks/Report/Report';
import PaymentStatusPage from './Checkout/PaymentStatus';

function CompletedCheckedRoutes() {
  return (
    <>
      <Route path={routesMapper.reading.MultipleChoiceSingle.PRACTISE}>
        <MCQSinglePage />
      </Route>
      <Route path={routesMapper.reading.MultipleChoiceMultiple.PRACTISE}>
        <MCQMultiplePage />
      </Route>
      <Route path={routesMapper.reading.ReorderParagraph.PRACTISE}>
        <ReorderPage />
      </Route>
      <Route path={routesMapper.reading.FillInTheBlanksDropDown.PRACTISE}>
        <FillBlanksDropdownPage />
      </Route>
      <Route path={routesMapper.reading.FillInTheBlanksDrag.PRACTISE}>
        <FillBlanksDragPage />
      </Route>

      <Route path={routesMapper.listening.SummarySpokenText.PRACTISE}>
        <ListeningSummaryPage />
      </Route>

      <Route path={routesMapper.listening.FillInTheBlanks.PRACTISE}>
        <ListeningFillBlanksPage />
      </Route>

      <Route path={routesMapper.listening.MultipleChoiceSingle.PRACTISE}>
        <ListeningMCQSinglePage />
      </Route>

      <Route path={routesMapper.listening.SelectMissingWords.PRACTISE}>
        <ListeningMissingWordsPage />
      </Route>

      <Route path={routesMapper.listening.HighlightCorrectSummary.PRACTISE}>
        <ListeningHighlightCorrectPage />
      </Route>

      <Route path={routesMapper.listening.MultipleChoiceMultiple.PRACTISE}>
        <ListeningMCQMultiplePage />
      </Route>

      <Route path={routesMapper.listening.WriteFromDictation.PRACTISE}>
        <ListeningDictationPage />
      </Route>

      <Route path={routesMapper.listening.HighlightIncorrectWords.PRACTISE}>
        <ListeningHighlightIncorrectPage />
      </Route>

      <Route path={routesMapper.writing.Essay.PRACTISE}>
        <WritingEssayPage />
      </Route>
      <Route path={routesMapper.writing.SummaryWrittenText.PRACTISE}>
        <WritingSummaryPage />
      </Route>

      <Route path={routesMapper.speaking.ReadAloud.PRACTISE}>
        <SpeakingReadAloudPage />
      </Route>
      <Route path={routesMapper.speaking.RepeatSentence.PRACTISE}>
        <SpeakingRepeatSentencePage />
      </Route>
      <Route path={routesMapper.speaking.DescribeImage.PRACTISE}>
        <SpeakingDescribeImagePage />
      </Route>
      <Route path={routesMapper.speaking.RetellLecture.PRACTISE}>
        <SpeakingRetellLecturePage />
      </Route>
      <Route path={routesMapper.speaking.AnswerShortQuestion.PRACTISE}>
        <SpeakingAnswerShortQuestionPage />
      </Route>

      <Route exact path="/dashboard/users">
        <Authorize roles={[roles.super_admin, roles.admin, roles.instructor]}>
          <UserList />
        </Authorize>
      </Route>

      <Route path="/dashboard/users/:id">
        <Authorize roles={[roles.super_admin, roles.admin, roles.instructor]}>
          <UserView />
        </Authorize>
      </Route>

      <Route path="/dashboard/coupon">
        <Authorize roles={[roles.super_admin]}>
          <CouponPage />
        </Authorize>
      </Route>

      <Route path="/dashboard/checkout">
        <CheckoutPage />
      </Route>

      <Route path="/dashboard/checkout/payment-status">
        <PaymentStatusPage />
      </Route>

      <Route path="/dashboard/prediction/:section/:moduleName">
        <PredictionPage />
      </Route>

      <Route path="/dashboard/diagnostic">
        <DiagnosticPage />
      </Route>

      {/* <Route exact path="/dashboard/labels">
        <Labels />
      </Route>
      <Route exact path="/dashboard/labels/add">
        <LabelsAdd />
      </Route>
      <Route exact path="/dashboard/labels/edit/:id">
        <LabelsEdit />
      </Route> */}

      <Route exact path="/dashboard/score/:id">
        <ScorePage />
      </Route>

      <Route exact path="/dashboard/subscription">
        <SubscriptionPage />
      </Route>

      <Route exact path="/dashboard/notifications">
        <NotificationPage />
      </Route>

      <Route exact path="/dashboard/mocks">
        <MocksPage />
      </Route>
      <Route exact path="/dashboard/mocks/add">
        <Authorize permissions="mocks.canAdd">
          <MocksAddPage />
        </Authorize>
      </Route>
      <Route exact path="/dashboard/mocks/edit/:id">
        <Authorize permissions="mocks.canEdit">
          <MockEditPage />
        </Authorize>
      </Route>
      <Route exact path="/dashboard/mocks/add/:type">
        <Authorize permissions="mocks.canAdd">
          <MocksAddByTypePage />
        </Authorize>
      </Route>
      <Route exact path="/dashboard/mocks/attend/:type">
        <MockAttendPage />
      </Route>
      <Route exact path="/dashboard/mocks/report/:id">
        <MockReportPage />
      </Route>
    </>
  );
}

function Dashboard() {
  const hasCompletedProfile = useHasCompletedProfile();

  const paymentStatus = usePaymentStatus();

  const { notificationsChannel } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    notificationsChannel.bind('notifications-event', function () {
      dispatch(fetchNofications());
    });
  }, [notificationsChannel, dispatch]);

  return (
    <Flex backgroundColor="custom.white1" minHeight="100vh" direction="column">
      <Helmet>
        <title>
          AIWAS Plus - Artificial Intelligence With Automated Scoring
        </title>
      </Helmet>
      <Topbar />
      <ContainerBox>
        <Switch>
          <Route path="/dashboard/settings">
            <Settings />
          </Route>
          <Route exact path="/dashboard">
            <MainDashboard />
          </Route>
          {!hasCompletedProfile ? (
            <Redirect to="/dashboard/settings/profile/edit" />
          ) : paymentStatus === 'lock' ? (
            <Redirect to="/dashboard" />
          ) : (
            <CompletedCheckedRoutes />
          )}
        </Switch>
      </ContainerBox>
    </Flex>
  );
}

export default Dashboard;
