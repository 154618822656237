import { createSlice } from '@reduxjs/toolkit';
import { changeToCamelCase } from '../../helpers/changeCase';
import { keyBy, mapValues } from 'lodash-es';
import { DateTime } from 'luxon';

const initialState = {
  byId: {},
  allIds: [],
  totalUnseen: 0,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotifications(state, action) {
      const { notifications, totalUnseenNotifications } = action.payload;

      state.allIds = notifications.map((item) => item.id);
      state.byId = keyBy(notifications, 'id');
      state.totalUnseen = totalUnseenNotifications;
    },
    markNotificationAsSeen(state, action) {
      const { id } = action.payload;

      state.totalUnseen = state.totalUnseen === 0 ? 0 : state.totalUnseen - 1;

      state.byId[id].isSeen = 1;
    },
    markAllNotificationsAsSeen(state) {
      state.totalUnseen = 0;

      state.byId = mapValues(state.byId, (o) => ({
        ...o,
        seenAt: DateTime.local(),
      }));
    },
  },
});

const { actions, reducer } = notificationSlice;
export const {
  addNotifications,
  markNotificationAsSeen,
  markAllNotificationsAsSeen,
} = actions;

export default reducer;

export const fetchNofications = () => async (dispatch, _, api) => {
  const { data } = await api('get', `notifications`);
  dispatch(addNotifications(changeToCamelCase(data)));
  return data;
};

export const markNotificationAsSeenRequest = (id) => async (
  dispatch,
  _,
  api
) => {
  const { data } = await api('patch', `notifications/seen/${id}`, {
    is_seen: 1,
  });
  dispatch(markNotificationAsSeen(changeToCamelCase(data)));
  return data;
};

export const markAllNotificationAsSeenRequest = () => async (
  dispatch,
  _,
  api
) => {
  const { data } = await api('put', `notifications/seen`, { is_seen: 1 });
  dispatch(markAllNotificationsAsSeen());
  return data;
};
