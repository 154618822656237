import { modules } from '../../constants/modules';

const generateRoutes = (base, path) => ({
  PRACTISE: `/dashboard/practise/${base}/${path}`,
});

export const routesMapper = {
  reading: {
    [modules.reading.MultipleChoiceSingle]: {
      ...generateRoutes('reading', 'mcq-single'),
    },
    [modules.reading.MultipleChoiceMultiple]: {
      ...generateRoutes('reading', 'mcq-multiple'),
    },
    [modules.reading.ReorderParagraph]: {
      ...generateRoutes('reading', 'reorder'),
    },
    [modules.reading.FillInTheBlanksDropDown]: {
      ...generateRoutes('reading', 'fillblanks-dropdown'),
    },
    [modules.reading.FillInTheBlanksDrag]: {
      ...generateRoutes('reading', 'fillblanks-drag'),
    },
  },
  listening: {
    [modules.listening.SummarySpokenText]: {
      ...generateRoutes('listening', 'summary'),
    },
    [modules.listening.FillInTheBlanks]: {
      ...generateRoutes('listening', 'fillblanks'),
    },
    [modules.listening.MultipleChoiceSingle]: {
      ...generateRoutes('listening', 'mcq-single'),
    },
    [modules.listening.SelectMissingWords]: {
      ...generateRoutes('listening', 'missing-words'),
    },
    [modules.listening.HighlightCorrectSummary]: {
      ...generateRoutes('listening', 'highlight-correct'),
    },
    [modules.listening.MultipleChoiceMultiple]: {
      ...generateRoutes('listening', 'mcq-multiple'),
    },
    [modules.listening.WriteFromDictation]: {
      ...generateRoutes('listening', 'dictation'),
    },
    [modules.listening.HighlightIncorrectWords]: {
      ...generateRoutes('listening', 'highlight-incorrect'),
    },
  },
  writing: {
    [modules.writing.Essay]: {
      ...generateRoutes('writing', 'essay'),
    },
    [modules.writing.SummaryWrittenText]: {
      ...generateRoutes('writing', 'summary'),
    },
  },
  speaking: {
    [modules.speaking.ReadAloud]: {
      ...generateRoutes('speaking', 'readaloud'),
    },
    [modules.speaking.RepeatSentence]: {
      ...generateRoutes('speaking', 'repeatsentence'),
    },
    [modules.speaking.DescribeImage]: {
      ...generateRoutes('speaking', 'describeimage'),
    },
    [modules.speaking.RetellLecture]: {
      ...generateRoutes('speaking', 'retelllecture'),
    },
    [modules.speaking.AnswerShortQuestion]: {
      ...generateRoutes('speaking', 'answershortquestion'),
    },
  },
};
