import React, { useState, useEffect, useCallback } from 'react';
import { Box, Heading, Flex, Text } from '@chakra-ui/core';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { apiRequest } from '../../../api/api';
import Details from './Details';
import { changeToCamelCase } from '../../../helpers/changeCase';
import { handleApiError } from '../../../helpers/handleApiError';
import useCustomToast from '../../../hooks/useCustomToast';
import { get } from 'lodash-es';
import { AppliedDiscount, ApplyDiscount } from './Discount';
import Loader from '../../../components/Loader/Loader';
import { types } from './constants';
import Payment from './Payment';

function CheckoutInfo({ type, id }) {
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);

      if (type === types.package) {
        const { data } = await apiRequest('get', `packages/${id}`);
        setData(
          changeToCamelCase({
            ...data,
            type,
          })
        );
      }
      if (type === types.extension) {
        const { data } = await apiRequest('get', `packages/extension/${id}`);
        setData(
          changeToCamelCase({
            ...data,
            type,
          })
        );
      }

      setFetching(false);
    }

    fetchData();
  }, [type, id]);

  const { success, toast } = useCustomToast();

  const onSubmitDiscount = useCallback(
    async ({ couponCode }) => {
      if (!couponCode) return;

      try {
        const response = await apiRequest('post', 'coupons/discount', {
          ...(type === types.package && {
            package_id: id,
          }),
          ...(type === types.extension && {
            package_extension_id: id,
          }),
          coupon_code: couponCode,
        });

        setData({
          ...data,
          discountDetails: {
            ...response.data,
            couponCode,
          },
        });

        success({ title: response.data.message });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [toast, success, id, type, data]
  );

  if (fetching) {
    return <Loader />;
  }

  return (
    <>
      <Details data={data} />
      <Flex flexDirection="column">
        <Box w="50%">
          <>
            <Text fontStyle="italic" mb={3} color="custom.black3" fontSize="sm">
              If you have a <strong>Discount Coupon</strong>, please apply first
              then click the "CONFIRM & PAY"
            </Text>
            <AppliedDiscount
              discountDetails={get(data, 'discountDetails')}
              onRemove={() => setData({ ...data, discountDetails: undefined })}
            />
          </>
          <ApplyDiscount onSubmit={onSubmitDiscount} />
          <Payment data={data} />
        </Box>
      </Flex>
    </>
  );
}

function CheckoutPage() {
  const { search } = useLocation();

  const { id, type } = queryString.parse(search);

  if (!(id && [types.package, types.extension].includes(type))) return null;

  return (
    <Box maxWidth="1140px" margin="30px auto 0">
      <Heading size="lg" color="custom.black3" mb={6}>
        CHECKOUT
      </Heading>
      <Box bg="white" borderRadius="6px" boxShadow="custom.primary" p={10}>
        <CheckoutInfo type={type} id={id} />
      </Box>
    </Box>
  );
}

export default CheckoutPage;
