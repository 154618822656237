import { sections } from './sections';

export const mockTypes = {
  full: 'full',
  speaking: sections.speaking,
  writing: sections.writing,
  listening: sections.listening,
  reading: sections.reading,
};

export const mockAttendTypes = {
  editor: 'editor',
  auto: 'auto',
};

export const mockParts = {
  one: 'one',
  two: 'two',
  three: 'three',
};

export const mockVersions = {
  v1: 1,
  v2: 2,
};
