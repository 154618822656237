import React, { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/core';
import { get, map } from 'lodash-es';
import { stripHtml } from '../../../../helpers/stripHtml';

function PredictionReorderView({ description, data, reorder }) {
  const answers = useMemo(
    () =>
      stripHtml(get(data, 'answer'))
        .split(';')
        .map((item) => item.trim()),
    [data]
  );

  const optionsMapped = useMemo(
    () =>
      reorder.reduce(
        (result, item, index) => ({
          ...result,
          [item]: String.fromCharCode(97 + index),
        }),
        {}
      ),
    [reorder]
  );

  return (
    <Box>
      <Box>
        {map(optionsMapped, (key, value) => (
          <Text key={key}>
            <Text as="strong" textTransform="uppercase">
              {key}
            </Text>{' '}
            : {value}
          </Text>
        ))}
      </Box>
      <Text textTransform="uppercase" mt={4} fontWeight={600}>
        <Text color="custom.blue" as="span" mr={1}>
          Answer:
        </Text>
        {map(answers, (item, key) => optionsMapped[item]).join(', ')}
      </Text>
    </Box>
  );
}

export default PredictionReorderView;
