import React, { useMemo, useCallback, useEffect } from 'react';
import {
  Box,
  Heading,
  useToast,
  Flex,
  Stack,
  Text,
  Image,
} from '@chakra-ui/core';
import * as Yup from 'yup';
import { objectToFormData } from 'object-to-formdata';

import Form from '../../../components/Form/Form';
import FormInput from '../../../components/Form/Input';
import FormButton from '../../../components/Form/Button';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { completeSignup } from '../../../store/signup/action';
import FormDatePicker from '../../../components/Form/DatePicker';
import FormSelect, { BareSelect } from '../../../components/Form/Select';
import { map, get } from 'lodash-es';
import { handleApiError } from '../../../helpers/handleApiError';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import { useHistory } from 'react-router-dom';
import {
  FormRadioGroup,
  FormCustomRadio,
} from '../../../components/Form/Radio';
import FileUpload from '../../../components/Form/FileUpload';
import { GENDER_OPTIONS } from '../../../constants/gender';
import {
  useStatesByCountryId,
  useCitiesByStateId,
} from '../../../store/global/hooks';

function CompleteProfileStep() {
  const { assessment, user, goal } = useSelector((state) => state.signup);

  const dispatch = useDispatch();

  const toast = useToast();

  const { push } = useHistory();

  const defaultValues = useMemo(
    () => ({
      firstName: get(assessment, 'examReportDetails.firstName', ''),
      lastName: get(assessment, 'examReportDetails.lastName', ''),
      gender: get(assessment, 'examReportDetails.gender', ''),
    }),
    [assessment]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('Required.'),
        countryCode: Yup.string().required('Required.'),
        phoneNumber: Yup.string().required('Required.'),
        gender: Yup.string().required('Required.'),
        dateOfBirth: Yup.string().nullable().required('Required.'),
        residence: Yup.object({
          country: Yup.object().nullable().required('Required.'),
          state: Yup.object().nullable().required('Required.'),
        }),
        citizenship: Yup.object({
          country: Yup.object().nullable().required('Required.'),
        }),
      }),
    []
  );

  const { countries } = useSelector((state) => state.global);

  const countryOptions = useMemo(() => {
    return map(countries.byId, ({ id, name }) => ({ name, id }));
  }, [countries]);

  const countryCodeOptions = useMemo(
    () =>
      map(countries.byId, ({ sortname, phonecode }) => ({
        label: `${sortname}(+${phonecode})`,
        value: `+${phonecode}`,
      })),
    [countries]
  );

  const form = useForm({ defaultValues, validationSchema });

  const { watch, setValue, control } = form;

  const residenceCountryId = get(watch('residence.country'), 'id');
  const stateId = get(watch('residence.state'), 'id');

  const statesById = useStatesByCountryId(residenceCountryId);

  const stateOptions = useMemo(
    () =>
      map(statesById[residenceCountryId], ({ name, id }) => ({
        name,
        id,
      })),
    [residenceCountryId, statesById]
  );

  const cities = useCitiesByStateId(stateId);

  const cityOptions = useMemo(
    () =>
      map(cities, ({ name, id }) => ({
        name,
        id,
      })),
    [cities]
  );

  useEffect(() => {
    setValue('residence.state', null);
    setValue('residence.city', null);
  }, [setValue, residenceCountryId]);

  useEffect(() => {
    setValue('residence.city', null);
  }, [setValue, stateId]);

  const avatar = watch('avatar');

  const submit = useCallback(
    async ({
      firstName,
      lastName,
      phoneNumber,
      countryCode,
      gender,
      avatar,
      dateOfBirth: dob,
      residence,
      citizenship,
    }) => {
      try {
        const formData = objectToFormData(
          changeToSnakeCase({
            firstName,
            lastName,
            countryCode,
            phone: phoneNumber,
            gender,
            dob,
            address: {
              residence,
              citizenship,
            },
            userId: user.id,
            goals: goal,
            examReportDetails: get(assessment, 'examReportDetails', {}),
          })
        );

        if (avatar) {
          formData.append('avatar', avatar);
        }

        await dispatch(completeSignup(formData));

        toast({
          position: 'top',
          title: 'Thank you! You have completed AWIAS Plus Sign Up.',
          description:
            'Please check your email to activate AIWAS Plus account.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        push('/login');
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, user, assessment, goal, push]
  );

  return (
    <Box mt={6}>
      <Heading mb={2} color="custom.black3">
        COMPLETE YOUR PROFILE
      </Heading>
      <Form mt={6} form={form} onSubmit={submit} width="600px">
        <Stack isInline alignItems="center" mb={6} spacing={4}>
          {avatar ? (
            <Image src={avatar.preview} rounded="full" size="60px" />
          ) : (
            <Box
              width="60px"
              height="60px"
              borderRadius="30px"
              borderWidth="1px"
              borderColor="custom.gray"
              borderStyle="dashed"
            />
          )}
          <FileUpload label="Upload your image" name="avatar" />
        </Stack>
        <FormInput name="firstName" placeholder="First Name" />
        <FormInput name="lastName" placeholder="Last Name" />
        <Flex width="100%">
          <FormSelect
            placeholder="Code"
            name="countryCode"
            options={countryCodeOptions}
            width="150px"
            mr={4}
          />
          <FormInput placeholder="Phone Number" name="phoneNumber" />
        </Flex>
        <Stack isInline spacing={4} alignItems="center">
          <Text mb={6} color="custom.black4">
            I am
          </Text>
          <FormRadioGroup name="gender">
            <FormCustomRadio value={GENDER_OPTIONS.Male}>Male</FormCustomRadio>
            <FormCustomRadio value={GENDER_OPTIONS.Female}>
              Female
            </FormCustomRadio>
            <FormCustomRadio value={GENDER_OPTIONS.Other}>
              Other
            </FormCustomRadio>
          </FormRadioGroup>
        </Stack>
        <FormDatePicker name="dateOfBirth" placeholder="Date of Birth" />
        <Stack isInline spacing={6}>
          <Box flex={3}>
            <Controller
              as={BareSelect}
              name="residence.country"
              control={control}
              placeholder="Country of Residence"
              options={countryOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
            />
          </Box>
          <Box flex={2}>
            <Controller
              as={BareSelect}
              name="residence.state"
              control={control}
              placeholder="State"
              options={stateOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
            />
          </Box>
          <Box flex={2}>
            <Controller
              as={BareSelect}
              name="residence.city"
              control={control}
              placeholder="City"
              options={cityOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
            />
          </Box>
        </Stack>
        <Controller
          as={BareSelect}
          name="citizenship.country"
          control={control}
          placeholder="Country of Citizenship"
          options={countryOptions}
          getOptionValue={(option) => option['id']}
          getOptionLabel={(option) => option['name']}
        />
        <FormButton px={6} icon="arrow-forward">
          Complete
        </FormButton>
      </Form>
    </Box>
  );
}

export default CompleteProfileStep;
