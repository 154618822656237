export const FETCH_COUNTRY_LIST_RESOLVED = 'FETCH_COUNTRY_LIST_RESOLVED';
export const FETCH_STATE_LIST_RESOLVED = 'FETCH_STATE_LIST_RESOLVED';

export const getCountryList = () => async (dispatch, _, api) => {
  const { data } = await api('get', 'utils/countries');
  dispatch({ type: FETCH_COUNTRY_LIST_RESOLVED, data });
  return data;
};

export const getStatesByCountry = (id) => async (dispatch, _, api) => {
  const { data } = await api('get', `utils/states/${id}`);
  dispatch({ type: FETCH_STATE_LIST_RESOLVED, data, countryId: id });
  return data;
};
