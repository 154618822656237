import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/core';
import { useDispatch } from 'react-redux';
import { editQuestion } from '../../../../../store/modules/listening/questionSlice';
import AddEditForm from './Form';
import { useParams } from 'react-router-dom';
import TitleBar from '../../../../../components/Question/TitleBar';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { handleApiError } from '../../../../../helpers/handleApiError';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import useFetchQuestion from '../hooks/useFetchQuestion';
import { LoaderWrapper } from '../../../../../components/Loader/Loader';
import { paths } from './utils/paths';
import { listening } from '../../../../../constants/titles';
import { objectToFormData } from 'object-to-formdata';

const currentPaths = [
  ...paths,
  {
    name: 'Edit Question',
  },
];

function EditPage() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const { isFetching, question } = useFetchQuestion(id);

  const submit = useCallback(
    async ({
      description,
      labels,
      priority,
      advice,
      duration,
      audio,
      options,
      isAnswer,
    }) => {
      try {
        const answer = options[parseInt(isAnswer)];

        const data = {
          answer,
          options: options.filter((option) => option),
          advice,
          duration,
        };

        const body = objectToFormData({
          audio,
          description,
          labels,
          priority,
          data,
        });

        await dispatch(editQuestion(id, body));

        success({ title: 'Edited Successfully' });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, id, toast, success]
  );

  return (
    <LoaderWrapper loading={isFetching}>
      <QuestionMetabar paths={currentPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title={`Edit ${listening.mcqSingle}`} />
        <AddEditForm onSubmit={submit} data={question} />
      </Box>
    </LoaderWrapper>
  );
}

export default EditPage;
