import { changeToCamelCase } from '../../helpers/changeCase';

export const UPDATE_SIGNUP_DATA = 'UPDATE_SIGNUP_DATA';
export const COMPLETE_SIGNUP = 'COMPLETE_SIGNUP';

export const updateSignupData = (data, step) => (dispatch) => {
  return dispatch({ type: UPDATE_SIGNUP_DATA, data, step });
};

export const signup = (user) => async (dispatch, __, api) => {
  const { data } = await api('post', 'auth/signup', user);
  dispatch(updateSignupData(data.user, 'user'));
  return data;
};

export const analyseReport = (values, config) => async (dispatch, __, api) => {
  const { data } = await api('post', 'users/reportAnalysis', values, config);
  dispatch(updateSignupData(changeToCamelCase(data), 'assessment'));
  return changeToCamelCase(data);
};

export const completeSignup = (values) => async (dispatch, __, api) => {
  const { data } = await api('post', `users/${values.get('user_id')}`, values);
  dispatch({ type: COMPLETE_SIGNUP });
  return data;
};
