import React, { useMemo, useCallback } from 'react';
import Form from '../../../../../components/Form/Form';
import { useForm } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import FormInput from '../../../../../components/Form/Input';
import FormSelect from '../../../../../components/Form/Select';
import FormVisualEditor from '../../../../../components/Form/VisualEditor';
import FormMultiSelect from '../../../../../components/Form/MutliSelect';
import * as Yup from 'yup';
import { priority } from '../../../../../constants/priority';
import { map, get } from 'lodash-es';
import { useLabels } from '../../../../../store/label/hooks';
import { stripHtml } from '../../../../../helpers/stripHtml';
import FormTextarea from '../../../../../components/Form/Textarea';
import { FormLabel, Text } from '@chakra-ui/core';

function AddEditForm({ data, onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      labels: get(data, 'labels', []).map((label) => label.id),
      description: get(data, 'data.answer', ''),
      additional: get(data, 'data.additional', ''),
      priority: get(data, 'priority', ''),
      duration: get(data, 'data.duration', ''),
      advice: get(data, 'data.advice', ''),
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        description: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
        additional: Yup.string().required('Required.'),
        labels: Yup.array().required('Required.'),
        duration: Yup.string().required('Required.'),
        priority: Yup.string().required('Required.'),
        advice: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const { reset } = form;

  const { byId, allIds } = useLabels();

  const priorityOptions = useMemo(
    () => map(priority, (item) => ({ label: item, value: item })),
    []
  );

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const formOnSubmit = useCallback(
    async (values) => {
      await onSubmit(values, { reset });
    },
    [onSubmit, reset]
  );

  return (
    <Form form={form} onSubmit={formOnSubmit} mt={6}>
      <FormLabel mb={1}>
        Question of the FIB - use 3rd bracket to select the answer.
      </FormLabel>
      <FormTextarea name="description" minHeight="200px" />
      <FormLabel mb={1}>Use semicolon (;) to separate each word(s).</FormLabel>
      <FormInput placeholder="Additional options" name="additional" />
      <FormMultiSelect
        placeholder="Labels"
        name="labels"
        options={labelOptions}
      />
      <FormSelect
        name="priority"
        placeholder="Priority"
        options={priorityOptions}
      />
      <FormInput
        type="number"
        placeholder="Duration (Target Time) (In Seconds)"
        name="duration"
      />
      <Text fontWeight={600} color="custom.gray5" mb={2}>
        Examiner's Advice
      </Text>
      <FormVisualEditor name="advice" />
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

export default AddEditForm;
