import { routesMapper } from '../../../../../../Routes/routesMapper';
import { mcqMultiple } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Reading',
  },
  {
    name: mcqMultiple,
    path: routesMapper.reading.MultipleChoiceMultiple.PRACTISE,
  },
];
