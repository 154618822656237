import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { getRelativeTime } from '../../helpers/getDate';
import { UserAvatar } from '../Avatar/Avatar';
import { useCurrentUser } from '../../store/user/hooks';
import { roles } from '../../constants/roles';
import Logo from '../../assets/images/logo-aiwas.png';

function NotificationItem({ notification, createdAt }) {
  const user = useCurrentUser();

  if (user.role === roles.student) {
    return (
      <Flex align="center" px="15px" py="5px">
        <UserAvatar size="38px" src={Logo} name="AIWAS" />
        <Box ml={3}>
          <Text>
            AIWAS Specialist {get(notification, 'action')}{' '}
            {get(notification, 'action') === 'added' ||
            get(notification, 'action') === 'posted'
              ? 'a new'
              : 'in a'}{' '}
            {get(notification, 'service')} on{' '}
            {get(get(notification, 'details'), 'section')} in{' '}
            {get(notification, 'moduleName')}.
          </Text>
          <Text fontSize="xs">{getRelativeTime(createdAt)}</Text>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex align="center" px="15px" py="5px">
      <UserAvatar
        size="38px"
        src={get(notification, 'user.avatar')}
        name={get(notification, 'user.firstName')}
      />
      <Box ml={3}>
        <Text>
          {get(notification, 'user.firstName')} {get(notification, 'action')}{' '}
          {get(notification, 'action') === 'added' ||
          get(notification, 'action') === 'posted'
            ? 'a new'
            : 'in a'}{' '}
          {get(notification, 'service')} on{' '}
          {get(get(notification, 'details'), 'section')} in{' '}
          {get(notification, 'moduleName')}.
        </Text>
        <Text fontSize="xs">{getRelativeTime(createdAt)}</Text>
      </Box>
    </Flex>
  );
}

export default NotificationItem;
