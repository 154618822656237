import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Text } from '@chakra-ui/core';
import { get } from 'lodash-es';

export function ErrorMessageDummy({ message, ...props }) {
  return (
    <Box {...props}>
      <Text fontSize="sm" color="custom.red" padding="5px 0 0 5px">
        {message}
      </Text>
    </Box>
  );
}

function ErrorMessage({ name, ...props }) {
  const { errors } = useFormContext();
  return <ErrorMessageDummy message={get(errors, name)?.message} {...props} />;
}

export default ErrorMessage;
