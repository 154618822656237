import React, { memo } from 'react';
import { Box, Flex, Text, Badge } from '@chakra-ui/core';
import { sum } from 'lodash-es';
import { getModuleStatsData } from '../../helpers/stats';
import CustomProgressBar from '../../../../../components/ProgressBar/ProgressBar';

const DashboardCurrentPTELevel = memo(({ data, overallGoal }) => {
  return (
    <Box bg="white" p={6} boxShadow="custom.tertiary">
      <Flex align="center">
        <Text
          mr={2}
          color="custom.blue"
          textTransform="uppercase"
          fontWeight={600}
        >
          Current PTE Level
        </Text>
        <Badge borderRadius="5px" variant="outline" variantColor="redVariant">
          AI
        </Badge>
      </Flex>
      <Text color="custom.gray2">
        Based on current activity and submitted score cards
      </Text>

      <Flex mt={4} align="center">
        <Text color="custom.blue" fontSize="3xl">
          OVERALL{' '}
          {parseInt(
            sum([
              getModuleStatsData(data.speaking).average,
              getModuleStatsData(data.writing).average,
              getModuleStatsData(data.reading).average,
              getModuleStatsData(data.listening).average,
            ]) / 4
          )}
        </Text>
        <Text ml={2}>(YOUR PTE GOAL OVERALL {overallGoal}+)</Text>
      </Flex>

      <Box mt={4}>
        <CustomProgressBar
          value={getModuleStatsData(data.speaking).averageIn100}
          label={`Speaking (${getModuleStatsData(data.speaking).average})`}
          labelColor="custom.gray2"
          labelSize="sm"
        />
        <CustomProgressBar
          value={getModuleStatsData(data.reading).averageIn100}
          label={`Reading (${getModuleStatsData(data.reading).average})`}
          labelColor="custom.gray2"
          progressColor="yellow"
          labelSize="sm"
        />
        <CustomProgressBar
          value={getModuleStatsData(data.writing).averageIn100}
          label={`Writing (${getModuleStatsData(data.writing).average})`}
          labelColor="custom.gray2"
          progressColor="redVariant"
          labelSize="sm"
        />
        <CustomProgressBar
          value={getModuleStatsData(data.listening).averageIn100}
          label={`Listening (${getModuleStatsData(data.listening).average})`}
          labelColor="custom.gray2"
          progressColor="greenVariant"
          labelSize="sm"
        />
      </Box>
    </Box>
  );
});

export default DashboardCurrentPTELevel;
