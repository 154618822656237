import React from 'react';
import Container, { AuthForm } from '../../../components/Layout/Container';
import { Image, Heading } from '@chakra-ui/core';
import logo from '../../../assets/images/pte-aiwas-logo.svg';
import backgroundImage from '../../../assets/images/signupbg.svg';

function AuthLayout({ children, heading }) {
  return (
    <Container
      backgroundImage={`url(${backgroundImage})`}
      backgroundPosition="bottom"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      minHeight="100vh"
    >
      <AuthForm>
        <Image maxW={200} src={logo} mb={8} />
        <Heading
          mb={6}
          size="md"
          textTransform="uppercase"
          color="custom.black3"
        >
          {heading}
        </Heading>
        {children}
      </AuthForm>
    </Container>
  );
}

export default AuthLayout;
