import React from 'react';
import { Text } from '@chakra-ui/core';

function CustomBadge({ color, children, ...props }) {
  return (
    <Text
      px={2}
      py={1}
      borderWidth="1px"
      borderColor={color}
      borderRadius="4px"
      fontWeight={600}
      fontSize="12px"
      color={color}
      {...props}
    >
      {children}
    </Text>
  );
}

export default CustomBadge;
