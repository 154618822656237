import React, { useState, useEffect } from 'react';
import { Box, Stack, SimpleGrid, Text, Flex } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { getDate } from '../../../helpers/getDate';
import CustomAvatar from '../../Avatar/Avatar';
import { AnchorLink } from '../../Navigation/Link';
import { MdEdit } from 'react-icons/md';
import VerifyPhone from '../../ApplicationModals/VerifyPhone';
import ToggleAccountStatus from '../../ApplicationModals/ToggleAccountStatus';
import { GoVerified } from 'react-icons/go';
import { selectUserById } from '../../../store/users/usersSlice';
import { useRouteMatch } from 'react-router-dom';
import Authorize from '../../Authorize/Authorize';

const retrySeconds = 40;

function Card({ label, value, children, ...props }) {
  return (
    <Box mb={6} {...props}>
      <Text color="custom.black3" textTransform="uppercase" fontWeight={600}>
        {label}
      </Text>
      {children ? children : <Text color="custom.black4">{value}</Text>}
    </Box>
  );
}

function Profile({ userId, isSelf = true }) {
  const [timeOut, setTimeOut] = useState(0);

  const { url } = useRouteMatch();

  useEffect(() => {
    if (!timeOut) return;

    const timer = setInterval(() => setTimeOut(timeOut - 1), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [timeOut]);

  const user = useSelector((state) => selectUserById(state, userId));

  const { phoneVerifiedAt } = user;

  const {
    firstName,
    lastName,
    countryCode,
    phone,
    dob,
    gender,
    address,
    avatar,
  } = user.details ?? {};

  return (
    <Box>
      <Flex justify="space-between">
        <Box mb={6}>
          <CustomAvatar src={avatar} />
        </Box>
        <Authorize permissions={!isSelf ? 'users.canEditProfile' : undefined}>
          <AnchorLink
            to={`${url}/edit`}
            label="Edit Profile"
            variant="link"
            leftIcon={MdEdit}
          />
        </Authorize>
      </Flex>
      <SimpleGrid columns={2}>
        <Card label="First Name" value={firstName ?? 'N/A'} />
        <Card label="Last Name" value={lastName ?? 'N/A'} />
        <Authorize
          permissions={!isSelf ? 'users.canViewProfilePrivateData' : undefined}
        >
          <Card label="Phone Number">
            <Stack isInline spacing={phone ? 2 : 0}>
              <Text color="custom.black4">
                {countryCode}
                {phone}
              </Text>
              <Text color={phoneVerifiedAt ? 'custom.blue' : 'custom.red'}>
                {phoneVerifiedAt ? (
                  <Text mt={1} as={GoVerified} />
                ) : (
                  'Not Verified'
                )}
              </Text>
            </Stack>
            {phone && !phoneVerifiedAt && (
              <VerifyPhone
                userId={userId}
                phone={`${countryCode}${phone}`}
                setTimeOut={setTimeOut}
                timeOut={timeOut}
                retrySeconds={retrySeconds}
              />
            )}
          </Card>
        </Authorize>
        <Card label="Date of Birth" value={getDate(dob) || 'N/A'} />
      </SimpleGrid>
      <Box>
        <Card label="Gender" value={gender || 'N/A'} />
        <Stack isInline spacing={6}>
          <Card
            flex={3}
            label="Country of Residence"
            value={get(address, 'residence.country.name', 'N/A')}
          />
          <Card
            flex={2}
            label="State"
            value={get(address, 'residence.state.name', 'N/A')}
          />
          <Card
            flex={2}
            label="City"
            value={get(address, 'residence.city.name', 'N/A')}
          />
        </Stack>
        <Card
          label="Country of Citizenship"
          value={get(address, 'citizenship.country.name', 'N/A')}
        />
      </Box>
      <Authorize permissions={!isSelf ? 'users.canDeactivate' : undefined}>
        <ToggleAccountStatus userId={userId} status={user.isActive} />
      </Authorize>
    </Box>
  );
}

export default Profile;
