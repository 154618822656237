import React, { useMemo } from 'react';
import { Box, Flex, Text, Stack, Button } from '@chakra-ui/core';
import { IoMdPeople } from 'react-icons/io';
import CustomBadge from '../../../components/Badge/Badge';
import { Link } from 'react-router-dom';
import { getRouteByModule } from '../../../helpers/getRoutes';
import TinyBadge from '../../../components/Badge/TinyBadge';
import PredictionMCQView from './Views/MCQ';
import { modules } from '../../../constants/modules';
import PredictionReorderView from './Views/Reorder';
import PredictionFillBlanksView from './Views/FillBlanks';
import PredictionDicationView from './Views/Dictation';
import PredictionEssaySummaryView from './Views/EssaySummary';
import PredictionSpeakingView from './Views/Speaking';

function ViewByModule(props) {
  const { section, module: moduleName } = props;

  const viewByModule = useMemo(() => {
    switch (section) {
      case 'reading':
        switch (moduleName) {
          case modules.reading.MultipleChoiceSingle:
          case modules.reading.MultipleChoiceMultiple:
            return <PredictionMCQView />;
          case modules.reading.ReorderParagraph:
            return <PredictionReorderView />;
          case modules.reading.FillInTheBlanksDropDown:
            return <PredictionFillBlanksView />;
          case modules.reading.FillInTheBlanksDrag:
            return <PredictionFillBlanksView />;
          default:
            return null;
        }
      case 'listening':
        switch (moduleName) {
          case modules.listening.HighlightIncorrectWords:
          case modules.listening.FillInTheBlanks:
            return <PredictionFillBlanksView />;
          case modules.listening.MultipleChoiceMultiple:
          case modules.listening.HighlightCorrectSummary:
          case modules.listening.SelectMissingWords:
          case modules.listening.MultipleChoiceSingle:
            return <PredictionMCQView />;
          case modules.listening.WriteFromDictation:
            return <PredictionDicationView />;
          case modules.listening.SummarySpokenText:
            return <PredictionEssaySummaryView />;
          default:
            return null;
        }
      case 'writing':
        switch (moduleName) {
          case modules.writing.Essay:
          case modules.writing.SummaryWrittenText:
            return <PredictionEssaySummaryView />;
          default:
            return null;
        }
      case 'speaking':
        switch (moduleName) {
          case modules.speaking.ReadAloud:
            return <PredictionSpeakingView />;
          case modules.speaking.RepeatSentence:
            return <PredictionSpeakingView />;
          case modules.speaking.DescribeImage:
            return <PredictionSpeakingView />;
          case modules.speaking.RetellLecture:
            return <PredictionSpeakingView />;
          case modules.speaking.AnswerShortQuestion:
            return <PredictionSpeakingView />;
          default:
            return null;
        }
      default:
        return null;
    }
  }, [section, moduleName]);

  return viewByModule && React.cloneElement(viewByModule, props);
}

function PredictionItem(props) {
  const {
    id,
    serial = id,
    isPracticed,
    priority,
    weight,
    labels,
    module: moduleName,
    section,
  } = props;

  return (
    <Box mb={6} pb={6} borderBottomWidth="1px" color="custom.black4">
      <Stack isInline spacing={4}>
        {serial && <TinyBadge label={`ID #${serial}`} />}
        {isPracticed && <TinyBadge label="Practised" />}
        {!!weight && (
          <TinyBadge>
            <Flex align="center" color="custom.blue">
              <Box as={IoMdPeople} mr={2} />{' '}
              <Text as="strong" mr={1}>
                {weight} People
              </Text>{' '}
              mark this as{' '}
              <Text as="strong" ml={1}>
                PTE Exam Question
              </Text>
            </Flex>
          </TinyBadge>
        )}
      </Stack>
      <Flex>
        <Box flex={1}>
          <ViewByModule {...props} />
        </Box>
        <Box w="180px" borderLeftWidth="1px" pl={6} ml={6}>
          <Text fontSize="lg" color="custom.blue">
            Priority {priority}
          </Text>
          <Flex mt={2} py={2} wrap="wrap">
            {labels.map(({ name, color }, index) => (
              <CustomBadge key={index} color={color} mr={2} mb={2}>
                {name}
              </CustomBadge>
            ))}
          </Flex>
          <Button
            as={Link}
            to={getRouteByModule(id, { section, module: moduleName })}
            size="sm"
            px={6}
            mt={2}
            variantColor="blueVariant"
          >
            Practice {isPracticed ? 'Again' : ''}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
}

export default PredictionItem;
