import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/core';
import CustomProgress from '../ProgressBar/CustomProgress';
import { useCallback } from 'react';
import { GiPlainArrow } from 'react-icons/gi';
import { useInterval } from '../../hooks/useTimer';

function AudioPlayerBare({
  url,
  onLoadedCallback = () => null,
  onEndCallback = () => null,
  beginningDuration = 0,
  isPaused = false,
  status: recorderStatus,
}) {
  const [audio] = useState(new Audio(url));
  const [currentTime, setCurrentTime] = useState(0.01);
  const [delay, setDelay] = useState(1000);

  const [status, setStatus] = useState();
  const [volume, setVolume] = useState(0.5);

  const [, /* state */ setState] = useState();

  const [audioBeginningDuration, setAudioBeginningDuration] = useState(
    beginningDuration
  );

  useEffect(() => {
    if (recorderStatus === 'recording') {
      audio.pause();
      setDelay(null);
      setAudioBeginningDuration(-1);
    }
  }, [recorderStatus, status, audio]);

  const playAudio = useCallback(async () => {
    try {
      setStatus('playing');
      await audio.play();
    } catch (error) {
      // console.log(error);
      if (error.name === 'NotAllowedError') {
        setState(() => {
          throw error;
        });
      }
    }
  }, [audio]);

  useInterval(
    () => {
      if (audioBeginningDuration <= 0) {
        playAudio();
      }

      setAudioBeginningDuration((state) => state - 1);
    },
    audioBeginningDuration >= 0 && !isPaused ? delay : null
  );

  useEffect(() => {
    if (audio) {
      audio.volume = volume;
    }
  }, [volume, audio]);

  useEffect(() => {
    return () => audio.pause();
  }, [audio]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(audio.currentTime);
  }, [audio]);

  const onEnd = useCallback(() => {
    setStatus('completed');
    onEndCallback();
  }, [onEndCallback]);

  const onLoad = useCallback(() => {
    onLoadedCallback(audio.duration);
  }, [onLoadedCallback, audio]);

  useEffect(() => {
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('ended', onEnd);
    audio.addEventListener('canplaythrough', onLoad);
    return () => {
      audio.removeEventListener('timeupdate', onTimeUpdate);
      audio.removeEventListener('ended', onEnd);
      audio.removeEventListener('canplaythrough', onLoad);
    };
  }, [onTimeUpdate, audio, onEnd, onLoad]);

  return (
    <Box>
      <Box
        borderWidth="1px"
        borderColor="custom.white3"
        mt={4}
        bg="custom.white2"
        p="40px"
        w="480px"
        borderRadius="8px"
      >
        <Flex mb={6}>
          <Text mr={6}>Status:</Text>
          <Text fontWeight={600} textTransform="capitalize">
            {isPaused
              ? ''
              : audioBeginningDuration > 0
              ? `Beginning in ${audioBeginningDuration} seconds`
              : status}
          </Text>
        </Flex>

        <Flex align="center">
          <Text mr={6}>Volume:</Text>
          <Slider
            onChange={(value) => setVolume(value)}
            value={volume}
            min={0}
            max={1}
            step={0.1}
          >
            <SliderTrack />
            <SliderFilledTrack bg="custom.gray" />
            <SliderThumb size={6} borderWidth="1px" borderColor="custom.gray">
              <Box color="gray" as={GiPlainArrow} />
            </SliderThumb>
          </Slider>
        </Flex>

        <CustomProgress duration={audio.duration} current={currentTime} />
      </Box>
    </Box>
  );
}

export default AudioPlayerBare;
