import { mockVersions } from './mockTypes';
import { reading, listening, writing, speaking } from './titles';

export const modules = {
  speaking: {
    ReadAloud: 'ReadAloud',
    RepeatSentence: 'RepeatSentence',
    DescribeImage: 'DescribeImage',
    RetellLecture: 'RetellLecture',
    AnswerShortQuestion: 'AnswerShortQuestion',
  },
  writing: {
    Essay: 'Essay',
    SummaryWrittenText: 'SummaryWrittenText',
  },
  reading: {
    FillInTheBlanksDropDown: 'FillInTheBlanksDropDown',
    MultipleChoiceMultiple: 'MultipleChoiceMultiple',
    ReorderParagraph: 'ReorderParagraph',
    FillInTheBlanksDrag: 'FillInTheBlanksDrag',
    MultipleChoiceSingle: 'MultipleChoiceSingle',
  },
  listening: {
    FillInTheBlanks: 'FillInTheBlanks',
    MultipleChoiceSingle: 'MultipleChoiceSingle',
    SelectMissingWords: 'SelectMissingWords',
    HighlightCorrectSummary: 'HighlightCorrectSummary',
    MultipleChoiceMultiple: 'MultipleChoiceMultiple',
    WriteFromDictation: 'WriteFromDictation',
    HighlightIncorrectWords: 'HighlightIncorrectWords',
    SummarySpokenText: 'SummarySpokenText',
  },
};

export const titlesByModule = {
  reading: {
    [modules.reading.FillInTheBlanksDropDown]: reading.fillBlanksDropdown,
    [modules.reading.MultipleChoiceMultiple]: reading.mcqMultiple,
    [modules.reading.ReorderParagraph]: reading.reorder,
    [modules.reading.FillInTheBlanksDrag]: reading.fillBlanksDrag,
    [modules.reading.MultipleChoiceSingle]: reading.mcqSingle,
  },
  listening: {
    [modules.listening.FillInTheBlanks]: listening.fillBlanks,
    [modules.listening.MultipleChoiceSingle]: listening.mcqSingle,
    [modules.listening.SelectMissingWords]: listening.missingWords,
    [modules.listening.HighlightCorrectSummary]: listening.highlightCorrect,
    [modules.listening.MultipleChoiceMultiple]: listening.mcqMultiple,
    [modules.listening.WriteFromDictation]: listening.dictation,
    [modules.listening.HighlightIncorrectWords]: listening.highlightIncorrect,
    [modules.listening.SummarySpokenText]: listening.summary,
  },
  writing: {
    [modules.writing.Essay]: writing.essay,
    [modules.writing.SummaryWrittenText]: writing.summary,
  },
  speaking: {
    [modules.speaking.ReadAloud]: speaking.readAloud,
    [modules.speaking.RepeatSentence]: speaking.repeatSentence,
    [modules.speaking.DescribeImage]: speaking.describeImage,
    [modules.speaking.RetellLecture]: speaking.retellLecture,
    [modules.speaking.AnswerShortQuestion]: speaking.answerShortQuestion,
  },
};

export const limitsByModuleV1 = {
  reading: {
    [modules.reading.MultipleChoiceSingle]: 'min. 2 to max. 3',
    [modules.reading.MultipleChoiceMultiple]: 'min. 2 to max. 3',
    [modules.reading.ReorderParagraph]: 'min. 2 to max. 3',
    [modules.reading.FillInTheBlanksDrag]: 'min. 4 to max. 5',
    [modules.reading.FillInTheBlanksDropDown]: 'min. 5 to max. 6',
  },
  listening: {
    [modules.listening.FillInTheBlanks]: 'min. 2 to max. 3',
    [modules.listening.MultipleChoiceSingle]: 'min. 2 to max. 3',
    [modules.listening.SelectMissingWords]: 'min. 2 to max. 3',
    [modules.listening.HighlightCorrectSummary]: 'min. 2 to max. 3',
    [modules.listening.MultipleChoiceMultiple]: 'min. 2 to max. 3',
    [modules.listening.WriteFromDictation]: 'min. 3 to max. 4',
    [modules.listening.HighlightIncorrectWords]: 'min. 2 to max. 3',
    [modules.listening.SummarySpokenText]: 'min. 2 to max. 3',
  },
  writing: {
    [modules.writing.Essay]: 'min. 1 to max. 2',
    [modules.writing.SummaryWrittenText]: 'min. 2 to max. 3',
  },
  speaking: {
    [modules.speaking.ReadAloud]: 'min. 6 to max. 7',
    [modules.speaking.RepeatSentence]: 'min. 10 to max. 12',
    [modules.speaking.DescribeImage]: 'min. 6 to max. 7',
    [modules.speaking.RetellLecture]: 'min. 3 to max. 4',
    [modules.speaking.AnswerShortQuestion]: 'min. 10 to max. 12',
  },
};

export const limitsByModuleV2 = {
  reading: {
    [modules.reading.FillInTheBlanksDropDown]: 'min. 5 to max. 6',
    [modules.reading.MultipleChoiceMultiple]: 'min. 1 to max. 2',
    [modules.reading.ReorderParagraph]: 'min. 2 to max. 3',
    [modules.reading.FillInTheBlanksDrag]: 'min. 4 to max. 5',
    [modules.reading.MultipleChoiceSingle]: 'min. 1 to max. 2',
  },
  listening: {
    [modules.listening.FillInTheBlanks]: 'min. 2 to max. 3',
    [modules.listening.MultipleChoiceSingle]: 'min. 1 to max. 2',
    [modules.listening.SelectMissingWords]: 'min. 1 to max. 2',
    [modules.listening.HighlightCorrectSummary]: 'min. 1 to max. 2',
    [modules.listening.MultipleChoiceMultiple]: 'min. 1 to max. 2',
    [modules.listening.WriteFromDictation]: 'min. 3 to max. 4',
    [modules.listening.HighlightIncorrectWords]: 'min. 2 to max. 3',
    [modules.listening.SummarySpokenText]: 'min. 1 to max. 2',
  },
  writing: {
    [modules.writing.Essay]: 'min. 1 to max. 2',
    [modules.writing.SummaryWrittenText]: 'min. 1 to max. 2',
  },
  speaking: {
    [modules.speaking.ReadAloud]: 'min. 6 to max. 7',
    [modules.speaking.RepeatSentence]: 'min. 10 to max. 12',
    [modules.speaking.DescribeImage]: 'min. 3 to max. 4',
    [modules.speaking.RetellLecture]: 'min. 1 to max. 2',
    [modules.speaking.AnswerShortQuestion]: 'min. 5 to max. 6',
  },
};

export const limitConfigByVersion = {
  [mockVersions.v1]: limitsByModuleV1,
  [mockVersions.v2]: limitsByModuleV2,
};
