import React, { useMemo, useCallback } from 'react';
import { Box, useToast } from '@chakra-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from '../../Form/Form';
import { useForm } from 'react-hook-form';
import FormInput from '../../Form/Input';
import FormButton from '../../Form/Button';
import { handleApiError } from '../../../helpers/handleApiError';
import FormSwitch from '../../Form/Switch';
import {
  selectUserById,
  updateUserSecurity,
} from '../../../store/users/usersSlice';

function Security({ userId }) {
  const user = useSelector((state) => selectUserById(state, userId));

  const dispatch = useDispatch();

  const toast = useToast();

  const { email, is2Fa } = user;

  const defaultValues = useMemo(
    () => ({
      email,
      is2Fa,
    }),
    [email, is2Fa]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        is2Fa: Yup.bool().required('Required.'),
        oldPassword: Yup.string().required('Current password is required.'),
        password: Yup.string().test('min8', 'Minimum 8 characters.', (value) =>
          value.trim().length > 0 ? value.length >= 8 : true
        ),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          "Ops! Password doesn't match"
        ),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const submit = useCallback(
    async ({ is2Fa, oldPassword, password, confirmPassword }) => {
      try {
        await dispatch(
          updateUserSecurity(userId, {
            old_password: oldPassword,
            password: password || undefined,
            confirm_password: confirmPassword || undefined,
            is_2fa: is2Fa,
          })
        );

        toast({
          position: 'top',
          title: 'Your security settings have been updated.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, userId]
  );

  return (
    <Box>
      <Form form={form} onSubmit={submit} maxWidth="500px">
        <Box mb={6}>
          <FormSwitch
            name="is2Fa"
            label="Turn on/off two factor authentication"
            containerProps={{ mb: 2 }}
            color="blueVariant"
          />
          <Box
            bg="custom.blue3"
            borderRadius="5px"
            py={2}
            px={4}
            color="custom.black4"
          >
            A link will be sent to your email everytime you login.
          </Box>
        </Box>
        <FormInput name="email" label="Email address" disabled />
        <FormInput
          name="oldPassword"
          label="Current Password"
          type="password"
        />
        <FormInput name="password" label="New Password" type="password" />
        <FormInput
          name="confirmPassword"
          label="Confirm New Password"
          type="password"
        />
        <FormButton>UPDATE</FormButton>
      </Form>
    </Box>
  );
}

export default Security;
