import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

import ReduxThunk from 'redux-thunk';
import rootReducer from './reducer';
import { apiRequest } from '../api/api';

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['signup']
// }

const middlewares = [ReduxThunk.withExtraArgument(apiRequest)];

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

// export const persistor = persistStore(store);
