import React, { useMemo, useCallback } from 'react';
import { Box, Button, Heading, Icon, Stack } from '@chakra-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import NotificationItem from '../../../components/Notification/NotificationItem';
import { useHistory } from 'react-router-dom';
import { markNotificationAsSeenRequest } from '../../../store/nofitication/notificationSlice';
import { getRouteByService } from '../../../helpers/getRoutes';
import { useState } from 'react';
import { take } from 'lodash-es';
import PrimaryButton from '../../../components/Button/PrimaryButton';

function NotificationButton({ id, notification, createdAt, seenAt }) {
  const { push } = useHistory();

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(markNotificationAsSeenRequest(id));
    const link = getRouteByService(notification);
    push(link);
  }, [push, notification, id, dispatch]);

  return (
    <Stack isInline alignItems="top">
      <Button
        variant="unstyled"
        onClick={onClick}
        fontWeight="400"
        _hover={{
          backgroundColor: 'none',
          color: 'custom.orange2',
        }}
      >
        <NotificationItem notification={notification} createdAt={createdAt} />
      </Button>
      <Icon
        color={!seenAt ? 'custom.blue' : 'custom.white4'}
        size="16px"
        name="info"
        isInline
        mt="12px"
      />
    </Stack>
  );
}

const currentLimit = 10;

function NotificationPage() {
  const [limit, setLimit] = useState(currentLimit);

  const notification = useSelector((state) => state.notification);

  const notifications = useMemo(
    () =>
      take(
        notification.allIds.map((id) => notification.byId[id]),
        limit
      ),
    [notification, limit]
  );

  const setNotifificationLimit = useCallback(() => {
    setLimit((state) => state + currentLimit);
  }, [setLimit]);

  return (
    <Box maxWidth="900px" margin="30px auto 0">
      <Heading size="lg" color="custom.black3" mb={6}>
        NOTIFICATIONS
      </Heading>
      <Box
        bg="white"
        py={6}
        px={4}
        boxShadow="custom.primary"
        borderRadius="6px"
        mb={8}
      >
        {notifications.map((notification) => (
          <Box
            key={notification.id}
            color="custom.gray5"
            borderBottom="1px dashed"
            borderColor="custom.white4"
            mb={3}
            pb={6}
          >
            <Stack isInline alignItems="top">
              <NotificationButton {...notification} />
            </Stack>
          </Box>
        ))}
        {limit < notification.allIds.length && (
          <PrimaryButton onClick={setNotifificationLimit} variant="link">
            View older notifications
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
}

export default NotificationPage;
