import { memo, useCallback, useMemo, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Heading, Select } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

function DynamicSelect({ options = [] }) {
  const optionsMapped = options.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ));

  return (
    <Select
      name="answer"
      rootProps={{ width: 'auto', display: 'inline-flex', borderRadius: 0 }}
      height="28px"
      mx="5px"
      width="100px"
      borderRadius={0}
      borderColor="#333"
    >
      <option value="Not Answered"></option>
      {optionsMapped}
    </Select>
  );
}

const DynamicForm = memo(({ body, onSubmit, isLoading, ...props }) => {
  return (
    <Box as="form" id="dropdown-form" {...props}>
      <Box lineHeight="2">{body}</Box>
    </Box>
  );
});

const rx = /\[\.{4}\d+\.{4}\]/g;

function MockQuestionReadingFillBlanksDropdownView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const paragraph = useMemo(() => {
    const text = get(data, 'description', '');

    const splitText = text.split(rx);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = text.match(rx);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <DynamicSelect
                key={index}
                options={get(data, `dropdown.${index}`)}
                index={index}
              />,
            ]
          : [...arr, element],
      []
    );
  }, [data]);

  const { error } = useCustomToast();
  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setTimerDelay(null);

      const formData = new FormData(document.getElementById('dropdown-form'));

      await apiRequest('post', 'mocks/autoSave', {
        answer: formData.getAll('answer'),
        type: mock.type,
        section: sections.reading,
        question_id: data.id,
        module: data.module,
        index,
      });

      setTimerDelay(1000);

      onAfterSubmit();
    } catch {
      error({
        title: 'Looks like something went wrong!',
        description: 'Please check your network connection and try reloading.',
      });
      setIsSubmitting(false);
      setTimerDelay(1000);
    }
  }, [onAfterSubmit, error, mock, index, data, setTimerDelay]);

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box px={6} py={10}>
            <Box>
              <Heading size="sm" mb={8}>
                Below is a text with blanks. Click on each blank, a list of
                choices will appear. Select the appropriate answer choice for
                each blank.
              </Heading>

              <DynamicForm body={paragraph} mt={4} onSubmit={onSubmit} />
            </Box>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={onSubmit}
              isSubmitting={isSubmitting}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionReadingFillBlanksDropdownView;
