import React, { useState, useCallback } from 'react';
import { Box, Text, Button, Flex, Heading, useToast } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { STRIPE_PUBLISH_KEY } from '../../../config/metaData';
import CheckoutDetails from './CheckoutDetails';
import { apiRequest } from '../../../api/api';
import { useCurrentUser } from '../../../store/user/hooks';
import { saveAs } from 'file-saver';
import { getDate } from '../../../helpers/getDate';
import PaymentForm from '../../../components/Payment/PaymentForm';
import { handleApiError } from '../../../helpers/handleApiError';

const promise = loadStripe(STRIPE_PUBLISH_KEY);

// paymentFlow: true|false true will open payment for stripe flow
function CheckoutForm({ data: checkoutData, paymentFlow = false }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [pdf, setPdf] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const user = useCurrentUser();
  const toast = useToast();

  const handleServerResponse = useCallback(
    async (response) => {
      if (response.error) {
        setError(response.error);
      } else if (response.requires_action) {
        const {
          error: errorAction,
          paymentIntent,
        } = await stripe.handleCardAction(
          response.payment_intent_client_secret
        );

        if (errorAction) {
          setError(errorAction.message);
        } else {
          try {
            const { data } = await apiRequest('post', 'payment/stripe', {
              type: 'coupon',
              payment_intent_id: paymentIntent.id,
              data: {
                amount: get(checkoutData, 'amount'),
                package_id: get(checkoutData, 'package.id'),
                package_extension_id: get(checkoutData, 'extension.id'),
                package_validity: get(checkoutData, 'featureValidity'),
                speaking_validity: get(checkoutData, 'speakingValidity'),
                institute_id: get(checkoutData, 'institute.id'),
                units: get(checkoutData, 'units'),
                discount: get(checkoutData, 'discount'),
              },
            });

            handleServerResponse(data);
          } catch (error) {
            handleApiError(error, { toast });
          }
        }
      } else {
        if (response.pdf_base64) {
          setPdf(response.pdf_base64);
        }
        setSucceeded(true);
      }
      setProcessing(false);
    },
    [stripe, checkoutData, toast]
  );

  const onSubmitWithoutPayment = useCallback(async () => {
    try {
      setProcessing(true);

      const { data } = await apiRequest('post', 'payment/stripe', {
        type: 'coupon',
        data: {
          amount: get(checkoutData, 'amount'),
          package_id: get(checkoutData, 'package.id'),
          package_extension_id: get(checkoutData, 'extension.id'),
          package_validity: get(checkoutData, 'featureValidity'),
          speaking_validity: get(checkoutData, 'speakingValidity'),
          institute_id: get(checkoutData, 'institute.id'),
          units: get(checkoutData, 'units'),
          discount: get(checkoutData, 'discount'),
        },
      });
      if (data.pdf_base64) {
        setPdf(data.pdf_base64);
      }
      setSucceeded(true);
    } catch (error) {
      handleApiError(error, { toast });
    }
    setProcessing(false);
  }, [checkoutData, toast]);

  const stripePaymentMethodHandler = useCallback(
    async (result) => {
      if (result.error) {
        setError(result.error.message);
        setProcessing(false);
      } else {
        try {
          const { data } = await apiRequest('post', 'payment/stripe', {
            type: 'coupon',
            payment_method_id: result.paymentMethod.id,
            data: {
              amount: get(checkoutData, 'amount'),
              package_id: get(checkoutData, 'package.id'),
              package_extension_id: get(checkoutData, 'extension.id'),
              package_validity: get(checkoutData, 'featureValidity'),
              speaking_validity: get(checkoutData, 'speakingValidity'),
              institute_id: get(checkoutData, 'institute.id'),
              units: get(checkoutData, 'units'),
              discount: get(checkoutData, 'discount'),
            },
          });

          handleServerResponse(data);
        } catch (error) {
          handleApiError(error, { toast });
          setProcessing(false);
        }
      }
    },
    [handleServerResponse, checkoutData, toast]
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      if (error) {
        elements.getElement('card').focus();
        return;
      }

      setProcessing(true);
      setError(null);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${get(user, 'details.firstName')} ${get(
            user,
            'details.lastName'
          )}`,
          email: get(user, 'email'),
          phone: get(user, 'details.phoneNumber'),
        },
      });

      stripePaymentMethodHandler(result);
    },
    [error, elements, stripe, stripePaymentMethodHandler, user]
  );

  const onChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  if (succeeded) {
    return (
      <Box
        boxShadow="custom.tertiary"
        borderWidth="1px"
        borderRadius="5px"
        mt={6}
        bg="#F7FAFC"
        p={10}
      >
        <Text color="custom.green" fontSize="xl" mb={2}>
          You have successfully generated {get(checkoutData, 'units')} coupons.
        </Text>
        <Text>An email has been sent with the details.</Text>
        <Button
          onClick={() => saveAs(pdf, `coupons-${getDate(new Date())}.pdf`)}
          variantColor="blueVariant"
          mt={4}
        >
          DOWNLOAD PDF
        </Button>
      </Box>
    );
  }

  if (!paymentFlow) {
    return (
      <Flex
        boxShadow="custom.tertiary"
        borderWidth="1px"
        borderRadius="5px"
        mt={6}
        bg="#F7FAFC"
        p={10}
        justify="center"
        align="center"
      >
        <Button
          onClick={onSubmitWithoutPayment}
          variantColor="blueVariant"
          isLoading={processing}
          isDisabled={processing}
          mt={4}
        >
          GENERATE NOW
        </Button>
      </Flex>
    );
  }

  return (
    <PaymentForm
      onSubmit={onSubmit}
      onChange={onChange}
      processing={processing}
      disabled={disabled}
      amount={get(checkoutData, 'amount')}
      error={error}
    />
  );
}

function CouponCheckout({ data, setToggleCheckout }) {
  return (
    <Box>
      <Button
        onClick={() => setToggleCheckout(false)}
        alignSelf="flex-start"
        variant="link"
        variantColor="blueVariant"
        leftIcon="arrow-back"
      >
        GO BACK
      </Button>
      <Box py={10}>
        <Heading mb={6} size="md" color="custom.red">
          REVIEW CHECKOUT
        </Heading>
        <CheckoutDetails data={data} />
        <Flex align="center" flexDirection="column">
          <Box w="50%">
            <Elements stripe={promise}>
              <CheckoutForm data={data} />
            </Elements>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default CouponCheckout;
