import { setItem, clearItems } from '../../helpers/localStorage';
import { userAdded } from '../users/usersSlice';
import { changeToCamelCase } from '../../helpers/changeCase';
import { batch } from 'react-redux';

export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_PHONE_VERIFIED = 'USER_PHONE_VERIFIED';

export const USER_META_UPDATE = 'USER_META_UPDATE';
export const USER_GOAL_UPDATE = 'USER_GOAL_UPDATE';

export const USER_SECURITY_UPDATE = 'USER_SECURITY_UPDATE';

export const login = (token) => async (dispatch, __, api) => {
  setItem('token', token);
  await dispatch(getUser());
};

export const forgetPassword = (email) => async (_, __, api) => {
  const { data } = await api('post', 'auth/forgot', email);
  return data;
};

export const resetPassword = (values) => async (_, __, api) => {
  const { data } = await api('post', 'auth/reset', values);
  return data;
};

export const getUser = () => async (dispatch, __, api) => {
  try {
    const { data } = await api('get', 'auth/user');
    batch(() => {
      dispatch(userAdded(changeToCamelCase(data)));
      dispatch({ type: SET_USER, data });
    });
    return data;
  } catch {
    dispatch({ type: REMOVE_USER });
  }
};

export const logout = () => async (dispatch) => {
  clearItems();
  dispatch({ type: USER_LOGOUT });
};
