import { routesMapper } from '../../../../../../Routes/routesMapper';
import { fillBlanksDrag } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Reading',
  },
  {
    name: fillBlanksDrag,
    path: routesMapper.reading.FillInTheBlanksDrag.PRACTISE,
  },
];
