import React, { memo } from 'react';
import { Spinner, Flex } from '@chakra-ui/core';

function Loader({ color = 'custom.blue' }) {
  return (
    <Flex p={4} justify="center" align="center">
      <Spinner color={color} />
    </Flex>
  );
}

export const LoaderWrapper = memo(({ loading, children }) => {
  if (loading) return <Loader />;
  return children;
});

export default Loader;
