import React, { useMemo } from 'react';
import { Box, Flex, Text, Stack } from '@chakra-ui/core';
import { get, map } from 'lodash-es';
import {
  ScoreHeader,
  ScoreFooter,
  ScoreDetails,
  MockScoreHeader,
} from '../Common/CommonView';
import { mapWordsToGrammarPopover } from '../Common/WordPopover';
import AudioPlayerControl from '../../../../AudioPlayer/AudioPlayerControl';
import { MdCancel } from 'react-icons/md';

function ListeningSummaryScore({ score, practiseAgain, isMockReport }) {
  const enablingScores = useMemo(
    () => [
      get(score, 'details.content'),
      get(score, 'details.form'),
      get(score, 'details.grammar'),
      get(score, 'details.vocabularies'),
      get(score, 'details.spelling'),
    ],
    [score]
  ).filter(Boolean);

  const textBlocks = useMemo(
    () =>
      mapWordsToGrammarPopover(
        get(score, 'details.givenAnswer'),
        get(score, 'details.grammarSpelling')
      ),
    [score]
  );

  return (
    <Box mt={2} mb={2} overflowY="hidden">
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          QUESTION
        </Text>
        <AudioPlayerControl audioUrl={get(score, 'details.audio')} />
        <Flex justify="flex-end" mt={4} fontSize="10px">
          <Stack isInline spacing={2}>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.red" mr={1} />
              <Text color="custom.black3">Grammar/Spelling Mistake(s)</Text>
            </Flex>
          </Stack>
        </Flex>
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          YOUR ANSWER
        </Text>
        {get(score, 'details.givenAnswer')?.trim?.() ? (
          <Box whiteSpace="pre-line" color="custom.black">
            {textBlocks}
          </Box>
        ) : (
          <Flex align="start" color="custom.red">
            <Box>
              <Box as={MdCancel} mr={2} />
            </Box>
            <Text mt="-5px">Not Answered</Text>
          </Flex>
        )}
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          ENABLING SCORES AND OTHER TRAITS
        </Text>
        <Box as="table" width="100%" mt={2}>
          <Box as="thead" borderBottom="1px solid #eee">
            <Box as="tr" color="custom.black5" fontWeight={600}>
              <Box as="td">{''}</Box>
              <Box as="td" fontSize="14px" px={2}>
                Score Allocation
              </Box>
              <Box as="td" fontSize="14px" px={2}>
                Comment(s)
              </Box>
              <Box
                as="td"
                fontSize="14px"
                textAlign="center"
                minW="80px"
                px={2}
              >
                Your Score
              </Box>
            </Box>
          </Box>
          <Box as="tbody">
            {enablingScores.map(
              ({ label, score, comments, scoreAllocation }, index) => (
                <Box as="tr" key={index} borderBottom="1px solid #eee">
                  <Box
                    as="td"
                    py={4}
                    px={2}
                    color="custom.blue"
                    fontWeight={600}
                  >
                    {label}
                  </Box>
                  <Box
                    as="td"
                    py={4}
                    px={2}
                    color="custom.black4"
                    fontSize="13px"
                  >
                    {map(scoreAllocation, ({ score, requirement }) => (
                      <Flex key={score} mb={1}>
                        <Text mr={1} fontWeight={600}>
                          {score}
                        </Text>
                        <Text>{requirement}</Text>
                      </Flex>
                    ))}
                  </Box>
                  <Box
                    as="td"
                    py={4}
                    px={2}
                    color="custom.black4"
                    fontSize="13px"
                  >
                    {comments}
                  </Box>
                  <Box
                    as="td"
                    py={4}
                    textAlign="center"
                    color="custom.blue"
                    fontWeight={600}
                  >
                    {score}
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        isMockReport={isMockReport}
      />
      {!isMockReport && (
        <>
          {/* <Box mt={6}>
            <Text color="custom.black3" fontWeight={600} mb={1}>
              SAMPLE ANSWER
            </Text>
            <Text color="custom.black" whiteSpace="pre-line">
              {get(score, 'details.sampleAnswer')}
            </Text>
          </Box> */}
          <ScoreFooter score={score} practiseAgain={practiseAgain} />
        </>
      )}
    </Box>
  );
}

export default ListeningSummaryScore;
