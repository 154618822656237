import { routesMapper } from '../../../../../../Routes/routesMapper';
import { speaking } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Speaking',
  },
  {
    name: speaking.describeImage,
    path: routesMapper.speaking.DescribeImage.PRACTISE,
  },
];
