import React, { memo, useMemo, useState } from 'react';
import { Flex, Text } from '@chakra-ui/core';
import CustomBadge from '../Badge/Badge';
import { NavLink } from 'react-router-dom';
import { customColors } from '../../theme/theme';

const titleHandler = (title) => {
  let words = title.split(' ');

  if (words[3] !== undefined) {
    words[3] = '...';
  }
  if (words[5] !== undefined) {
    words[5] = '...';
  }
  if (words[7] !== undefined) {
    words[7] = '...';
  }
  if (words[9] !== undefined) {
    words[9] = '...';
  }
  if (words[11] !== undefined) {
    words[11] = '...';
  }
  if (words[13] !== undefined) {
    words[13] = '...';
  }
  if (words[15] !== undefined) {
    words[15] = '...';
  }

  return words.join(' ');
};

function BarLink({
  title,
  to,
  labels,
  isFree,
  isPracticed = true,
  hasStatus = false,
  titleFormat = true,
  hideTitleOnActive = false,
}) {
  const styles = useMemo(
    () => ({
      display: 'block',
      marginTop: '15px',
      paddingBottom: '18px',
      color: customColors.gray5,
      borderBottomWidth: '1px',
      borderBottomColor: customColors.white4,
    }),
    []
  );

  const [isActive, setIsActive] = useState(false);

  return (
    <NavLink
      to={to}
      style={styles}
      activeStyle={{ color: customColors.blue }}
      isActive={(match) => {
        if (!match) {
          setIsActive(false);
          return false;
        }
        setIsActive(true);
        return true;
      }}
    >
      <Text
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        fontWeight={isActive ? '600' : '400'}
        backgroundColor={
          hideTitleOnActive && isActive ? 'custom.blue' : 'white'
        }
        transition="all .3s"
        lineHeight={1.1}
      >
        {titleFormat ? titleHandler(title) : title}
      </Text>
      <Flex mt={3} justify="space-between">
        <Flex>
          {labels.map(({ name, color }, index) => (
            <CustomBadge
              key={index}
              opacity={isActive ? 1 : 0.5}
              color={color}
              mr={2}
            >
              {name}
            </CustomBadge>
          ))}
        </Flex>
        <Flex>
          {!hasStatus && (
            <CustomBadge
              opacity={isActive ? 1 : 0.5}
              backgroundColor="custom.red"
              color="custom.white5"
              mr={2}
            >
              Draft
            </CustomBadge>
          )}
          {isPracticed && (
            <CustomBadge
              opacity={isActive ? 1 : 0.5}
              borderColor="custom.white5"
              color="custom.white5"
            >
              Practiced
            </CustomBadge>
          )}
          {!isFree && (
            <CustomBadge
              opacity={isActive ? 1 : 0.5}
              borderColor="custom.green"
              color="custom.green"
              ml={2}
            >
              Premium
            </CustomBadge>
          )}
        </Flex>
      </Flex>
    </NavLink>
  );
}

export default memo(BarLink);
