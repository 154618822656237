import React, { useMemo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Form from '../../components/Form/Form';
import FormInput from '../../components/Form/Input';
import FormButton from '../../components/Form/Button';
import { Text, useToast, Flex, Image } from '@chakra-ui/core';
import { changeToSnakeCase } from '../../helpers/changeCase';
import { useDispatch } from 'react-redux';
import { login } from '../../store/user/action';
import { handleApiError } from '../../helpers/handleApiError';
import { useHistory, useLocation } from 'react-router-dom';
import { AnchorLink } from '../../components/Navigation/Link';
import AuthLayout from './AuthLayout/AuthLayout';
import { apiRequest } from '../../api/api';
import logo from '../../assets/images/aiwas-plus.svg';

function Login() {
  const dispatch = useDispatch();

  const toast = useToast();

  const { push } = useHistory();

  const defaultValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email('Enter valid email address.')
          .required('Enter your email address.'),
        password: Yup.string().required('Enter your password.'),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const { state } = useLocation();

  const [is2fa, setIs2fa] = useState(false);

  const submit = useCallback(
    async (values) => {
      try {
        const valuesSnakeCased = changeToSnakeCase(values);

        const { data } = await apiRequest(
          'post',
          'auth/login',
          valuesSnakeCased
        );

        if (data.is_2fa) {
          setIs2fa(true);
          return;
        }

        await dispatch(login(data.access_token));

        if (state?.from) {
          push(state.from);
        } else {
          push('/dashboard');
        }
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, push, state]
  );

  return is2fa ? (
    <AuthLayout heading="Login from your email">
      <Text>An email has been sent with an instruction to login.</Text>
    </AuthLayout>
  ) : (
    <>
      <AuthLayout
        heading={
          <Text>
            Sign In To{' '}
            <Image maxW="60px" d="inline-block" mb="5px" src={logo} />
          </Text>
        }
      >
        <Form form={form} onSubmit={submit}>
          <FormInput name="email" placeholder="Email Address" />
          <AnchorLink
            to="/forget-password"
            variant="link"
            label="Forget Password?"
            size="sm"
            float="right"
            mb={2}
          />
          <FormInput name="password" type="password" placeholder="Password" />
          <FormButton px={10} my={2}>
            LOGIN
          </FormButton>
        </Form>
        <Flex mt={4} align="center">
          <Text mr={2} fontSize="sm" color="custom.black3">
            Don’t have an account yet?
          </Text>
          <AnchorLink
            to="/signup"
            label="Sign up for FREE"
            variant="link"
            size="sm"
          />
        </Flex>
      </AuthLayout>
    </>
  );
}

export default Login;
