import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/core';
import { useDispatch } from 'react-redux';
import { editQuestion } from '../../../../../store/modules/speaking/questionSlice';
import AddEditForm from './Form';
import { useParams } from 'react-router-dom';
import TitleBar from '../../../../../components/Question/TitleBar';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { handleApiError } from '../../../../../helpers/handleApiError';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import useFetchQuestion from '../hooks/useFetchQuestion';
import { LoaderWrapper } from '../../../../../components/Loader/Loader';
import { paths } from './utils/paths';
import { speaking } from '../../../../../constants/titles';
import { objectToFormData } from 'object-to-formdata';
import { speakingDuration } from '../../../../../constants/speakingDuration';

const currentPaths = [
  ...paths,
  {
    name: 'Edit Question',
  },
];

function Edit() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const { isFetching, question } = useFetchQuestion(id);

  const submit = useCallback(
    async ({
      description,
      labels,
      priority,
      advice,
      phraseMatchCheck,
      reference,
      sample,
      audioQuestion,
      answer,
    }) => {
      try {
        const data = {
          phrase_match_check: phraseMatchCheck,
          advice,
          reference,
          answer,
          question: description,
          duration: speakingDuration.answerShortQuestion.duration,
        };

        const body = objectToFormData({
          audio_question: audioQuestion,
          description,
          labels,
          priority,
          data,
          audio_male: sample.audioMale,
          audio_female: sample.audioFemale,
        });

        await dispatch(editQuestion(id, body));

        success({ title: 'Edited Successfully' });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, id, toast, success]
  );

  return (
    <LoaderWrapper loading={isFetching}>
      <QuestionMetabar paths={currentPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title={`Edit ${speaking.answerShortQuestion}`} />
        <AddEditForm onSubmit={submit} data={question} />
      </Box>
    </LoaderWrapper>
  );
}

export default Edit;
