import React, { memo, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { Flex, Box } from '@chakra-ui/core';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

function DragNDropSide({ items, setItems, selected, setSelected }) {
  const id2List = {
    droppable: items,
    droppable2: selected,
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        id2List[source.droppableId],
        source.index,
        destination.index
      );

      if (source.droppableId === 'droppable2') {
        setSelected(items);
      } else {
        setItems(items);
      }
    } else {
      const result = move(
        id2List[source.droppableId],
        id2List[destination.droppableId],
        source,
        destination
      );

      setItems(result.droppable);
      setSelected(result.droppable2);
    }
  };

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <Flex justify="space-between" ref={ref} minHeight={height}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box ref={provided.innerRef} flex={1} pr={4}>
              {items.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      boxShadow="custom.tertiary"
                      p={4}
                      borderRadius="5px"
                      mb={items.length === index + 1 ? 0 : 4}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Droppable droppableId="droppable2">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              bg="custom.gray"
              flex={1}
              p={4}
              borderRadius="5px"
            >
              {selected.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      boxShadow="custom.tertiary"
                      p={4}
                      borderRadius="5px"
                      bg="white"
                      mb={selected.length === index + 1 ? 0 : 4}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Flex>
  );
}

export default memo(DragNDropSide);
