import React, { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Form from '../../../components/Form/Form';
import FormInput from '../../../components/Form/Input';
import FormButton from '../../../components/Form/Button';
import { Text, useToast } from '@chakra-ui/core';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import { useDispatch } from 'react-redux';
import { handleApiError } from '../../../helpers/handleApiError';
import { useHistory, useParams } from 'react-router-dom';
import { resetPassword } from '../../../store/user/action';
import AuthLayout from '../AuthLayout/AuthLayout';

function ResetPassword() {
  const dispatch = useDispatch();

  const { token } = useParams();

  const toast = useToast();

  const { push } = useHistory();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(8, 'Minimum 8 characters.')
          .required('Required.'),
        confirmPassword: Yup.string()
          .required('Required.')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      }),
    []
  );

  const form = useForm({ validationSchema });

  const submit = useCallback(
    async (values) => {
      try {
        const valuesSnakeCased = changeToSnakeCase({
          ...values,
          token,
          location: window.location.origin,
        });
        await dispatch(resetPassword(valuesSnakeCased));
        toast({
          position: 'top',
          title: 'Your password has been reset successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        push('/login');
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, push, token]
  );

  return (
    <AuthLayout heading="Reset Password">
      <Text mb={4}>Enter a new password to reset</Text>
      <Form form={form} onSubmit={submit}>
        <FormInput name="password" type="password" placeholder="Password" />
        <FormInput
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
        />
        <FormButton px={10}>SUBMIT</FormButton>
      </Form>
    </AuthLayout>
  );
}

export default ResetPassword;
