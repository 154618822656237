import React from 'react';
import { Box } from '@chakra-ui/core';

function Table({ ...props }) {
  return <Box as="table" {...props} />;
}

export function Thead({ ...props }) {
  return <Box as="thead" {...props} />;
}

export function Tbody({ ...props }) {
  return <Box as="tbody" {...props} />;
}

export function Th({ ...props }) {
  return <Box as="th" {...props} />;
}

export function Td({ ...props }) {
  return <Box as="td" {...props} />;
}

export function Tr({ ...props }) {
  return <Box as="tr" {...props} />;
}

export default Table;
