import React, { useState, useCallback, useMemo } from 'react';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useToast,
} from '@chakra-ui/core';
import * as Yup from 'yup';
import Loader from '../Loader/Loader';
import { apiRequest } from '../../api/api';
import { useForm } from 'react-hook-form';
import Form from '../Form/Form';
import FormInput from '../Form/Input';
import FormButton from '../Form/Button';
import { handleApiError } from '../../helpers/handleApiError';
import { useDispatch } from 'react-redux';
import { verifyUserPhone } from '../../store/users/usersSlice';

function VerifyPhone({ userId, phone, setTimeOut, timeOut, retrySeconds }) {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required('Required.'),
      }),
    []
  );

  const form = useForm({ validationSchema });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClickOpen = useCallback(async () => {
    onOpen();
    try {
      await apiRequest('post', 'users/phone/send', { phone });
      setTimeOut(retrySeconds);
      setLoading(false);
      toast({
        position: 'top',
        title: 'We have sent a code to your phone.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      handleApiError(error, { toast });
    }
  }, [setTimeOut, onOpen, phone, toast, retrySeconds]);

  const submit = useCallback(
    async ({ code }) => {
      try {
        await dispatch(verifyUserPhone({ code, phone }, userId));
        toast({
          position: 'top',
          title: 'Your phone number has been verified successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, phone, onClose, userId]
  );

  return (
    <>
      {timeOut === 0 ? (
        <Button variant="link" onClick={onClickOpen} color="custom.blue">
          Verify Now
        </Button>
      ) : (
        <Text fontSize="sm" color="custom.blue" mt={1}>
          Wait for {timeOut} seconds, to try again
        </Text>
      )}
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Phone Verification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <>
                <Text>We are sending a code to your phone number</Text>
                <Loader />
              </>
            ) : (
              <Form form={form} py={4} onSubmit={submit}>
                <FormInput name="code" placeholder="Enter code" />
                <FormButton>Submit</FormButton>
              </Form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VerifyPhone;
