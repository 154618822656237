import React, { useMemo, useCallback } from 'react';
import Form from '../../../../../components/Form/Form';
import { useForm, useFieldArray } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import FormInput from '../../../../../components/Form/Input';
import FormSelect from '../../../../../components/Form/Select';
import FormVisualEditor from '../../../../../components/Form/VisualEditor';
import FormMultiSelect from '../../../../../components/Form/MutliSelect';
import * as Yup from 'yup';
import { priority } from '../../../../../constants/priority';
import { map, get, take } from 'lodash-es';
import { useLabels } from '../../../../../store/label/hooks';
import { stripHtml } from '../../../../../helpers/stripHtml';
import FileUpload from '../../../../../components/Form/FileUpload';
import { Stack, Text, Grid, FormLabel } from '@chakra-ui/core';
import { IoIosMusicalNotes } from 'react-icons/io';
import ErrorMessage from '../../../../../components/Form/ErrorMessage';
import FormRadio from '../../../../../components/Form/Radio';
import { accept } from '../../../../../constants/accept';

function OptionGrid({ children }) {
  return (
    <Grid
      columns={2}
      gridTemplateColumns="1fr auto"
      gridColumnGap={4}
      alignItems="center"
    >
      {children}
    </Grid>
  );
}

function AddEditForm({ data, onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      options: take(
        [...get(data, 'data.options', []), ...Array(6).fill('')],
        6
      ),
      isAnswer: get(data, 'data.options', [])
        .indexOf(get(data, 'data.answer'))
        .toString(),
      labels: get(data, 'labels', []).map((label) => label.id),
      description: get(data, 'description', ''),
      priority: get(data, 'priority', ''),
      duration: get(data, 'data.duration', ''),
      advice: get(data, 'data.advice', ''),
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        options: Yup.array()
          .test(
            'is-minimum',
            'Minimum 4 options.',
            (value) => value.filter((item) => item).length >= 4
          )
          .test('is-unique', 'Must contain unique values.', (value) => {
            const valuesWithValue = value.filter((item) => item);
            return valuesWithValue.length === new Set(valuesWithValue).size;
          }),
        isAnswer: Yup.string().test('is-valid-answer', 'Required.', function (
          value
        ) {
          return this.parent.options[parseInt(value)];
        }),
        description: Yup.string().required('Required.'),
        audio: data ? Yup.mixed() : Yup.mixed().required('Required.'),
        labels: Yup.array().required('Required.'),
        duration: Yup.string().required('Required.'),
        priority: Yup.string().required('Required.'),
        advice: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
      }),
    [data]
  );

  const form = useForm({ defaultValues, validationSchema });

  const { control, reset } = form;

  const { fields } = useFieldArray({
    control,
    name: 'options',
  });

  const { byId, allIds } = useLabels();

  const priorityOptions = useMemo(
    () => map(priority, (item) => ({ label: item, value: item })),
    []
  );

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const formOnSubmit = useCallback(
    async (values) => {
      await onSubmit(values, { reset });
    },
    [onSubmit, reset]
  );

  const audio = form.watch('audio');

  const audioName = useMemo(() => audio?.name, [audio]);

  return (
    <Form form={form} onSubmit={formOnSubmit} mt={6}>
      <FormInput name="description" placeholder="Question" />
      <FormLabel mb={1}>
        Audio format (.wav - 16 bit, 16000 hz rate, mono channel. Try this{' '}
        <strong>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://audio.online-convert.com/convert-to-wav"
          >
            converter
          </a>
        </strong>
        )
      </FormLabel>
      <Stack
        alignItems="center"
        borderRadius="8px"
        mb={4}
        isInline
        spacing={4}
        p={2}
        borderWidth="1px"
        borderColor="custom.gray"
      >
        <FileUpload
          name="audio"
          icon={IoIosMusicalNotes}
          label="Upload Audio"
          accept={accept.audio}
        />
        <Text>{audioName}</Text>
        <ErrorMessage name="audio" />
      </Stack>
      {fields.map((field, index) => (
        <OptionGrid key={field.id}>
          <FormInput placeholder="Write option" name={`options[${index}]`} />
          <FormRadio
            label="Mark as answer"
            id={index}
            name="isAnswer"
            value={parseInt(index)}
            containerProps={{ mb: 8 }}
          />
        </OptionGrid>
      ))}
      <ErrorMessage name="options" mt={-8} mb={8} />
      <FormMultiSelect
        placeholder="Labels"
        name="labels"
        options={labelOptions}
      />
      <FormSelect
        name="priority"
        placeholder="Priority"
        options={priorityOptions}
      />
      <FormInput
        type="number"
        placeholder="Duration (Target Time) (In Seconds)"
        name="duration"
      />
      <Text fontWeight={600} color="custom.gray5" mb={2}>
        Examiner's Advice
      </Text>
      <FormVisualEditor name="advice" />
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

export default AddEditForm;
