import React, { memo } from 'react';
import { Flex, Stack } from '@chakra-ui/core';
import CustomBreadcrumb from '../Breadcrumb/Breadcrumb';
import { AnchorLink } from '../Navigation/Link';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { MdFilterNone } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
// import Delete from '../ApplicationModals/Question/Delete';
import Share from '../Community/Share/Share';
import { getRouteFromOrigin } from '../../helpers/getRoutes';
import Authorize from '../Authorize/Authorize';
import DraftPublish from '../ApplicationModals/Question/DraftPublish';

function QuestionMetaBar({
  id,
  rootPath,
  paths = [],
  showAdd = false,
  showEdit = false,
  // showDelete = false,
  showDraftPublish = false,
  showShare = false,
  showOptions = false,
  optionLabel = 'Templates & Conjunctions',
  // onConfirmDelete,
  onConfirmDraftPublish,
  status,
}) {
  return (
    <Flex mb={4} justify="space-between" align="center">
      <CustomBreadcrumb paths={paths} />
      <Stack isInline spacing={4} alignItems="center">
        <Authorize permissions="questions.canEditOptions">
          {showOptions && (
            <AnchorLink
              to={`${rootPath}/options`}
              variant="link"
              label={optionLabel}
              leftIcon={MdFilterNone}
            />
          )}
        </Authorize>
        <Authorize permissions="questions.canAdd">
          {showAdd && (
            <AnchorLink
              to={`${rootPath}/add`}
              variant="link"
              label="Add Question"
              leftIcon={AiOutlinePlusSquare}
            />
          )}
        </Authorize>
        <Authorize permissions="questions.canEdit">
          {showEdit && (
            <AnchorLink
              to={`${rootPath}/edit/${id}`}
              variant="link"
              label="Edit"
              leftIcon={FiEdit2}
            />
          )}
        </Authorize>
        {/* <Authorize permissions="questions.canDelete">
          {showDelete && (
            <Delete
              id={id}
              title="Confirm Delete"
              body="Are you sure to confirm this action?"
              onConfirm={onConfirmDelete}
            />
          )}
        </Authorize> */}
        <Authorize permissions="questions.canDelete">
          {showDraftPublish && (
            <DraftPublish
              id={id}
              onConfirm={onConfirmDraftPublish}
              status={status}
            />
          )}
        </Authorize>
        {showShare && (
          <Share ml={4} shareLink={getRouteFromOrigin(`${rootPath}/${id}`)} />
        )}
      </Stack>
    </Flex>
  );
}

export default memo(QuestionMetaBar);
