import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tooltip,
  IconButton,
  AlertDialogCloseButton,
} from '@chakra-ui/core';
import React, { useRef } from 'react';
import Histories from '../../../../components/Community/Histories/Histories';
import { changeToCamelCase } from '../../../../helpers/changeCase';

function MockHistory({ history, title }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <>
      <Tooltip hasArrow label="View History" placement="top" bg="custom.blue">
        <IconButton
          size="sm"
          variantColor="blueVariant"
          variant="outline"
          icon="calendar"
          onClick={onOpen}
          mr={2}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="lg"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p={4}>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            color="custom.black2"
          >
            View History ({title})
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody>
            <Histories histories={changeToCamelCase(history)} />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default MockHistory;
