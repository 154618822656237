import React, { useMemo, useCallback, useEffect } from 'react';
import {
  Box,
  Stack,
  SimpleGrid,
  Text,
  Flex,
  FormLabel,
  useToast,
} from '@chakra-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { map, get, isEmpty } from 'lodash-es';
import * as Yup from 'yup';
import { objectToFormData } from 'object-to-formdata';
import Form from '../../Form/Form';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../Form/Input';
import FormSelect, { BareSelect } from '../../Form/Select';
import FormDatePicker from '../../Form/DatePicker';
import { FormRadioGroup, FormCustomRadio } from '../../Form/Radio';
import { GENDER_OPTIONS } from '../../../constants/gender';
import FormButton from '../../Form/Button';
import FileUpload from '../../Form/FileUpload';
import { handleApiError } from '../../../helpers/handleApiError';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import CustomAvatar from '../../Avatar/Avatar';
import {
  useStatesByCountryId,
  useCitiesByStateId,
} from '../../../store/global/hooks';
import {
  selectUserById,
  updateUserMeta,
} from '../../../store/users/usersSlice';

function EditProfile({ userId }) {
  const user = useSelector((state) => selectUserById(state, userId));

  const dispatch = useDispatch();

  const toast = useToast();

  const defaultValues = useMemo(
    () => ({
      firstName: get(user, 'details.firstName'),
      lastName: get(user, 'details.lastName'),
      countryCode: get(user, 'details.countryCode'),
      phone: get(user, 'details.phone'),
      dob: get(user, 'details.dob'),
      gender: get(user, 'details.gender'),
      residence: get(user, 'details.address.residence'),
      citizenship: get(user, 'details.address.citizenship'),
      avatar: get(user, 'details.avatar'),
    }),
    [user]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        countryCode: Yup.string().required('Country code is required.'),
        phone: Yup.string().required('Phone number is required.'),
        gender: Yup.string().required('Gender is required.'),
        dob: Yup.string().nullable().required('Date of Birth is required.'),
        residence: Yup.object({
          country: Yup.object().nullable().required('Required.'),
          state: Yup.object().nullable().required('Required.'),
        }),
        citizenship: Yup.object({
          country: Yup.object().nullable().required('Required.'),
        }),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const { watch, setValue, formState, control } = form;

  const avatarValue = watch('avatar');

  const { countries } = useSelector((state) => state.global);

  const countryOptions = useMemo(
    () => map(countries.byId, ({ name, id }) => ({ name, id })),
    [countries]
  );

  const countryCodeOptions = useMemo(
    () =>
      map(countries.byId, ({ sortname, phonecode }) => ({
        label: `${sortname}(+${phonecode})`,
        value: `+${phonecode}`,
      })),
    [countries]
  );

  const residenceCountryId = get(watch('residence.country'), 'id');
  const stateId = get(watch('residence.state'), 'id');

  const statesById = useStatesByCountryId(residenceCountryId);

  const stateOptions = useMemo(
    () =>
      map(statesById[residenceCountryId], ({ name, id }) => ({
        name,
        id,
      })),
    [residenceCountryId, statesById]
  );

  const cities = useCitiesByStateId(stateId);

  const cityOptions = useMemo(
    () =>
      map(cities, ({ name, id }) => ({
        name,
        id,
      })),
    [cities]
  );

  const { touched } = formState;

  useEffect(() => {
    if (!isEmpty(touched)) {
      setValue('residence.state', null);
      setValue('residence.city', null);
    }
  }, [setValue, touched, residenceCountryId]);

  useEffect(() => {
    if (!isEmpty(touched)) {
      setValue('residence.city', null);
    }
  }, [setValue, touched, stateId]);

  const submit = useCallback(
    async ({
      firstName,
      lastName,
      countryCode,
      phone,
      dob,
      gender,
      residence,
      citizenship,
      avatar,
    }) => {
      try {
        const formData = objectToFormData(
          changeToSnakeCase({
            firstName,
            lastName,
            countryCode,
            phone,
            dob,
            gender,
            address: {
              residence,
              citizenship,
            },
          })
        );

        if (avatar) {
          formData.append('avatar', avatar);
        }

        await dispatch(updateUserMeta(formData, userId));
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, userId]
  );

  return (
    <Box>
      <Form form={form} onSubmit={submit}>
        <Flex justify="space-between">
          <Box mb={6}>
            <Stack isInline alignItems="center">
              {avatarValue ? (
                <CustomAvatar
                  src={avatarValue?.preview ? avatarValue.preview : avatarValue}
                />
              ) : (
                <Box
                  width="60px"
                  height="60px"
                  borderRadius="30px"
                  borderWidth="1px"
                  borderColor="custom.gray"
                  borderStyle="dashed"
                />
              )}
              <FileUpload ml={4} label="Upload your image" name="avatar" />
            </Stack>
          </Box>
          <FormButton>SAVE CHANGES</FormButton>
        </Flex>
        <SimpleGrid columns={2} gridColumnGap={10}>
          <FormInput name="firstName" label="FIRST NAME" />
          <FormInput name="lastName" label="LAST NAME" />
          <Box>
            <FormLabel>PHONE NUMBER</FormLabel>
            <Flex width="100%">
              <FormSelect
                placeholder="Code"
                name="countryCode"
                options={countryCodeOptions}
                width="150px"
                mr={4}
              />
              <FormInput placeholder="Phone Number" name="phone" />
            </Flex>
          </Box>
          <FormDatePicker label="DATE OF BIRTH" name="dob" />
        </SimpleGrid>
        <Flex width="50%">
          <Box>
            <FormLabel my={2}>GENDER</FormLabel>
            <Stack isInline spacing={4} alignItems="center">
              <Text mb={6} color="custom.black4">
                I am
              </Text>
              <FormRadioGroup name="gender">
                <FormCustomRadio
                  value={GENDER_OPTIONS.Male}
                  isDisabled={get(user, 'details.gender')}
                >
                  Male
                </FormCustomRadio>
                <FormCustomRadio
                  value={GENDER_OPTIONS.Female}
                  isDisabled={get(user, 'details.gender')}
                >
                  Female
                </FormCustomRadio>
                <FormCustomRadio
                  value={GENDER_OPTIONS.Other}
                  isDisabled={get(user, 'details.gender')}
                >
                  Other
                </FormCustomRadio>
              </FormRadioGroup>
            </Stack>
          </Box>
        </Flex>
        <Stack isInline spacing={6}>
          <Box flex={3}>
            <Controller
              label="COUNTRY OF RESIDENCE"
              as={BareSelect}
              name="residence.country"
              control={control}
              placeholder="Country of Residence"
              options={countryOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
              menuPlacement="auto"
            />
          </Box>
          <Box flex={2}>
            <Controller
              label="STATE"
              as={BareSelect}
              name="residence.state"
              control={control}
              placeholder="State"
              options={stateOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
              menuPlacement="auto"
            />
          </Box>
          <Box flex={2}>
            <Controller
              label="CITY"
              as={BareSelect}
              name="residence.city"
              control={control}
              placeholder="City"
              options={cityOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
              menuPlacement="auto"
            />
          </Box>
        </Stack>
        <Flex width="50%">
          <Box flex={1}>
            <Controller
              label="COUNTRY OF CITIZENSHIP"
              as={BareSelect}
              name="citizenship.country"
              control={control}
              placeholder="Country of Citizenship"
              options={countryOptions}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
              menuPlacement="auto"
            />
          </Box>
        </Flex>
      </Form>
    </Box>
  );
}

export default EditProfile;
