import { Box, Flex, useDisclosure } from '@chakra-ui/core';
import React, { useCallback, useState } from 'react';
import { useEventListener } from '../../../../hooks/useEventListener';
import { useCurrentUser } from '../../../../store/user/hooks';
import { ActionButtons, MockTopbar, NextTips } from '../Components/Layout';
import AttendFinalSteps from './AttendFinalSteps';
import HeadsetCheckStep from './InitalSteps/HeadsetCheck';
import KeyboardCheckStep from './InitalSteps/KeyboardCheck';
import LegalStep from './InitalSteps/Legal';
import MicCheckStep from './InitalSteps/MicCheck';
import TestOverviewStep from './InitalSteps/TestOverview';
import TestInformation from './InitalSteps/TestInformation';

const initialSteps = {
  legal: 'legal',
  headsetCheck: 'headsetCheck',
  micCheck: 'micCheck',
  keyboardCheck: 'keyboardCheck',
  testInformation: 'testInformation',
  testOverview: 'testOverview',
};

const stateTransition = {
  [initialSteps.legal]: {
    prev: null,
    next: initialSteps.headsetCheck,
  },
  [initialSteps.headsetCheck]: {
    prev: initialSteps.legal,
    next: initialSteps.micCheck,
  },
  [initialSteps.micCheck]: {
    prev: initialSteps.headsetCheck,
    next: initialSteps.keyboardCheck,
  },
  [initialSteps.keyboardCheck]: {
    prev: initialSteps.micCheck,
    next: initialSteps.testInformation,
  },
  [initialSteps.testInformation]: {
    prev: initialSteps.keyboardCheck,
    next: initialSteps.testOverview,
  },
  [initialSteps.testOverview]: {
    prev: initialSteps.testInformation,
    next: null,
  },
};

function renderSteps({ step, mockVersion }) {
  console.log(mockVersion);
  switch (step) {
    case initialSteps.legal:
      return <LegalStep />;
    case initialSteps.headsetCheck:
      return <HeadsetCheckStep />;
    case initialSteps.micCheck:
      return <MicCheckStep />;
    case initialSteps.keyboardCheck:
      return <KeyboardCheckStep />;
    case initialSteps.testInformation:
      return <TestInformation mockVersion={mockVersion} />;
    case initialSteps.testOverview:
      return <TestOverviewStep mockVersion={mockVersion} />;
    default:
      return null;
  }
}

const generateNextButtonTexts = (step) => {
  if (step === initialSteps.testOverview) {
    return {
      label: 'Confirm',
      title: 'Confirm Start Mock',
      description: 'Are you sure that you want to confirm starting the mock?',
    };
  }

  return {
    description: 'Are you sure that you want to perform this action?',
  };
};

function MockSteps({ mockData, ...props }) {
  const [isFinalSteps, setIsFinalSteps] = useState(!!mockData?.existingMockId);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = useCurrentUser();

  const [currentStep, setCurrentStep] = useState(initialSteps.legal);

  const handleNextStep = useCallback(
    (callBackFunction = () => null) => {
      callBackFunction();
      if (stateTransition[currentStep].next) {
        setCurrentStep(stateTransition[currentStep].next);
      } else {
        setIsFinalSteps(true);
      }
    },
    [currentStep]
  );

  const handlePrevStep = useCallback(
    (callBackFunction = () => null) => {
      // console.log('prev', stateTransition[currentStep]);
      setCurrentStep(stateTransition[currentStep].prev);
      callBackFunction();
    },
    [currentStep]
  );

  useEventListener('keydown', ({ key }) => {
    if (key === 'n' || key === 'N') {
      onOpen();
    }
  });

  if (isFinalSteps) {
    return <AttendFinalSteps user={user} mockData={mockData} {...props} />;
  }

  return (
    <Flex h="100vh" direction="column" justify="space-between">
      <Box>
        <MockTopbar
          fullName={user.details.firstName + ' ' + user.details.lastName}
        />
        <Box my={10} px={6}>
          {renderSteps({
            step: currentStep,
            mockVersion: mockData.mockVersion,
          })}
        </Box>
      </Box>
      <Box>
        {currentStep !== initialSteps.testOverview && <NextTips />}
        <ActionButtons
          onConfirmNext={handleNextStep}
          onConfirmPrev={handlePrevStep}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          hasPrevStep={stateTransition[currentStep].prev}
          nextButtonTexts={generateNextButtonTexts(currentStep)}
        />
      </Box>
    </Flex>
  );
}

export default MockSteps;
