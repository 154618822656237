import { useEffect } from 'react';

function useScrollToHash(hash, prefix = '') {
  useEffect(() => {
    const scrollToLocation = () => {
      if (hash !== '') {
        let retries = 0;
        const id = hash.replace('#', '');
        const scroll = () => {
          retries += 0;
          if (retries > 50) return;
          const element = document.getElementById(`${prefix}${id}`);
          if (element) {
            setTimeout(() => element.scrollIntoView(), 0);
          } else {
            setTimeout(scroll, 100);
          }
        };
        scroll();
      }
    };
    scrollToLocation();
  }, [hash, prefix]);
}

export default useScrollToHash;
