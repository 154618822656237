import React, { memo } from 'react';
import { Text } from '@chakra-ui/core';
import MegaMenuLink from './MegaMenuLink';

function MegaMenuList({ heading, list }) {
  return (
    <>
      <Text color="custom.black" fontSize="18px" mb="20px">
        {heading}
      </Text>
      {list.map((subLink) => (
        <MegaMenuLink
          key={subLink.label}
          link={subLink.path}
          label={subLink.label}
          exact={subLink.exact}
        />
      ))}
    </>
  );
}

export default memo(MegaMenuList);
