import { useCallback, useState } from 'react';
import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import Recorder from '../../../../../../components/Recorder/Recorder';
import MockRecorderView from '../../../Components/Audio/Recorder';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { virtualSectionKeys } from '../../../Utils/prepareMockQuestionsSteps';

function MockQuestionSpeakingIntroductionView({
  onAfterSubmit,
  mock,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error } = useCustomToast();
  const onSubmit = useCallback(
    async (blob) => {
      try {
        setIsSubmitting(true);
        setTimerDelay(null);
        const formData = new FormData();

        formData.append('audio', blob);
        formData.append('mock', JSON.stringify(mock));
        formData.append('type', mock.type);
        formData.append('section', virtualSectionKeys.introduction);

        await apiRequest('post', 'mocks/autoSave', formData);
        setTimerDelay(1000);

        onAfterSubmit();
      } catch {
        error({
          title: 'Looks like something went wrong!',
          description:
            'Please check your network connection and try reloading.',
        });
        setIsSubmitting(false);
        setTimerDelay(1000);
      }
    },
    [onAfterSubmit, mock, error, setTimerDelay]
  );

  return (
    <StepLayout>
      {({ isOpen, onOpen, onClose }) => (
        <Recorder duration={30} initialBeginningDuration={25} hasBeep>
          {({
            duration,
            isDenied,
            status,
            beginningDuration,
            reverseRecordingDuration,
            stopRecording,
            isGettingBlob,
          }) => (
            <>
              <Box py={10} px={6}>
                <Heading size="sm" mb={8}>
                  Read the prompt below. In 25 seconds, you must reply in your
                  own words, as naturally and clearly as possible. You have 30
                  seconds to record your response. Your response will be sent
                  together with your score report to the institutions selected
                  by you.
                </Heading>
                <Box mt={6}>
                  <Box my={6}>
                    <Text mb={2}>
                      Please introduce yourself. For example, you could talk
                      about one or more of the following:
                    </Text>
                    <Box ml={10}>
                      <Text>- Your interests</Text>
                      <Text>- Your plans for future study</Text>
                      <Text>- Why you want to study abroad</Text>
                      <Text>- Why you need to learn English</Text>
                      <Text>- Why you chose this test</Text>
                    </Box>
                  </Box>
                  <Flex justify="center">
                    <MockRecorderView
                      isDenied={isDenied}
                      status={
                        status || `beginning in ${beginningDuration} seconds.`
                      }
                      duration={duration}
                      reverseRecordingDuration={reverseRecordingDuration}
                    />
                  </Flex>
                </Box>
              </Box>
              <Box>
                <ActionButtons
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                  onConfirmNext={() => stopRecording(onSubmit)}
                  isSubmitting={isSubmitting || isGettingBlob}
                  isNextButtonDisabled={!status}
                />
              </Box>
            </>
          )}
        </Recorder>
      )}
    </StepLayout>
  );
}

export default MockQuestionSpeakingIntroductionView;
