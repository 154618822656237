import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/core';

export function AnchorLink({ to, label, ...props }) {
  return (
    <Link to={to}>
      <Button color="custom.blue" {...props}>
        {label}
      </Button>
    </Link>
  );
}
