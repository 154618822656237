import React, { useCallback, useMemo, useEffect } from 'react';
import { FormControl, FormLabel, Box } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import ErrorMessage from './ErrorMessage';

const getCustomStyles = (isInvalid) => ({
  control: (provided) => ({
    ...provided,
    padding: 2,
    fontSize: '14px',
    borderColor: isInvalid ? '#EB5555' : '#DCDDE1',
  }),
});

export function BareSelect({ name, label, ...props }) {
  const styles = useMemo(() => getCustomStyles(), []);

  return (
    <Box mb={6}>
      {label && (
        <FormLabel {...(props.labelProps && { ...props.labelProps })}>
          {label}
        </FormLabel>
      )}
      <ReactSelect styles={styles} {...props} />
      <ErrorMessage name={name} />
    </Box>
  );
}

function Select({ id, name, options, value, setValue, isInvalid, ...props }) {
  const styles = useMemo(() => getCustomStyles(isInvalid), [isInvalid]);

  const onChange = useCallback(
    (value) => {
      setValue(name, value ? value.value : null);
    },
    [name, setValue]
  );

  const formattedValue = useMemo(
    () => (value ? options.find((option) => option.value === value) : null),
    [options, value]
  );

  return (
    <ReactSelect
      id={id}
      name={name}
      value={formattedValue}
      options={options}
      onChange={onChange}
      styles={styles}
      {...props}
    />
  );
}

function FormSelect({
  name,
  id = name,
  label,
  options,
  required,
  validate,
  ...props
}) {
  const { register, errors, watch, setValue } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    register({ name });
  }, [name, register]);

  return (
    <FormControl isRequired={required} isInvalid={Boolean(get(errors, name))}>
      {label && (
        <FormLabel
          htmlFor={id}
          {...(props.labelProps && { ...props.labelProps })}
        >
          {label}
        </FormLabel>
      )}

      <Box {...props} mb={6}>
        <Select
          id={id}
          name={name}
          options={options}
          value={value}
          setValue={setValue}
          isInvalid={!!get(errors, name)}
          {...props}
        />
        <ErrorMessage name={name} />
      </Box>
    </FormControl>
  );
}

export default FormSelect;
