import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Image,
} from '@chakra-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiRequest } from '../../../../api/api';
import Loader from '../../../../components/Loader/Loader';
import { mockAttendTypes, mockTypes } from '../../../../constants/mockTypes';
import { getError } from '../../../../helpers/handleApiError';
import { useQuery } from '../../../../hooks/useQuery';
import MockLimitBox from '../Components/MockLimitBox';
import ResetMock from '../Components/ResetMock';
import { prepareMockQuestionsSteps } from '../Utils/prepareMockQuestionsSteps';
import MockAttendStart from './Start';
import pteAIWASLogo from '../../../../assets/images/pte-aiwas-logo.svg';
import * as FullStory from '@fullstory/browser';
import metaData from '../../../../config/metaData';
import { useCurrentUser } from '../../../../store/user/hooks';

function DeniedView() {
  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        ATTEND MOCK
      </Heading>
      <Flex
        justify="center"
        borderRadius="6px"
        bg="white"
        p={10}
        boxShadow="custom.primary"
      >
        <Alert status="error">
          <AlertIcon />
          Please allow your microphone to record audio before proceeding.
        </Alert>
      </Flex>
    </Box>
  );
}

async function getSavedData(type) {
  return await apiRequest('post', 'mocks/checkSave', {
    type,
  });
}

async function getMockData({ type, id, mockType, version }) {
  if (type === mockAttendTypes.auto) {
    return await apiRequest('get', `mocks/attendAuto/${mockType}/${version}`);
  }

  if (type === mockAttendTypes.editor) {
    return await apiRequest('get', `mocks/attend/${id}`);
  }
}

function MockAttend({ mockData, fetchMockData }) {
  const [isDenied, setIsDenied] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });

      setIsStarted(true);
    } catch (error) {
      setIsDenied(true);
    }
  }, []);

  if (isStarted) {
    return (
      <Box bg="#F2F2F2">
        <Box maxWidth="1140px" margin="0 auto" bg="white">
          <MockAttendStart mockData={mockData} />
        </Box>
      </Box>
    );
  }

  if (isDenied) {
    return <DeniedView />;
  }

  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        ATTEND MOCK
      </Heading>
      <Flex
        justify="center"
        borderRadius="6px"
        bg="white"
        p={10}
        boxShadow="custom.primary"
      >
        {mockData?.existingMockId ? (
          <Box textAlign="center" color="custom.black3">
            <Icon name="warning-2" color="custom.red" fontSize={64} mb={6} />
            <Text fontSize={20}>
              You have already an existing{' '}
              <Text
                color="custom.blue"
                as="span"
                fontWeight={600}
                textTransform="uppercase"
              >
                {mockData?.type}
              </Text>{' '}
              mock ongoing!
            </Text>
            <Text fontSize={20} mb={6}>
              You can either resume that mock or click reset to start a new
              mock.
            </Text>
            <Text as="i" color="custom.red" fontWeight={600}>
              Remember, resetting the mock will cost a limit from your overall
              mock limit.
            </Text>
            <Stack justify="center" isInline spacing={4} mt={6}>
              <ResetMock
                id={mockData.existingMockId}
                onConfirm={fetchMockData}
              />
              <Button onClick={handleClick} variantColor="blueVariant" px={6}>
                RESUME
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box textAlign="center" color="custom.black3">
            <Image
              src={pteAIWASLogo}
              maxW="250px"
              display="inline-block"
              mr="auto"
              width="100%"
              height="auto"
              mb={6}
            />
            <Text fontSize={20}>You are attempting to start a new mock.</Text>
            <Text fontSize={20} mb={6}>
              Please click on the "<strong>Start</strong>" button to start your
              mock.
            </Text>
            <Stack justify="center" isInline spacing={4}>
              <Button onClick={handleClick} variantColor="blueVariant" px={6}>
                START
              </Button>
            </Stack>
          </Box>
        )}
      </Flex>
    </Box>
  );
}

function MockAttendFetchWrapper({ id, type, mockType, version }) {
  const [isFetching, setIsFetching] = useState(true);
  const [mockData, setMockData] = useState();
  const [error, setError] = useState();

  const fetchMockData = useCallback(async () => {
    try {
      setIsFetching(true);
      const { data } = await getMockData({ id, type, mockType, version });

      const { data: savedData } = await getSavedData(data.type);

      const stepData = prepareMockQuestionsSteps({
        questions: savedData.mock?.data || data.data,
        pointer: savedData?.pointer,
        mockType: data.type,
        mockVersion: +(data?.version || 1),
      });

      setMockData({
        ...(savedData.mock || data),
        mockVersion: +((savedData.mock || data)?.version || 1),
        stepData,
        existingMockId: savedData.id,
      });
    } catch (error) {
      setError({
        error: getError(error),
        status: error?.response?.status,
      });
    }
    setIsFetching(false);
  }, [type, id, mockType, version]);

  useEffect(() => {
    fetchMockData();
  }, [fetchMockData]);

  if (!isFetching && !error) {
    return <MockAttend mockData={mockData} fetchMockData={fetchMockData} />;
  }

  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        ATTEND MOCK
      </Heading>
      <Flex
        justify="center"
        borderRadius="6px"
        bg="white"
        p={10}
        boxShadow="custom.primary"
        direction="column"
      >
        {isFetching && <Loader />}
        {!isFetching && error && error?.status === 403 && (
          <Box mb={8}>
            <MockLimitBox />
          </Box>
        )}
        {!isFetching && error && (
          <Flex align="center" justify="center">
            <Alert status="error">
              <AlertIcon />
              {error.error}
            </Alert>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

function MockAttendPage() {
  const { type } = useParams();
  const query = useQuery();
  const user = useCurrentUser();

  useEffect(() => {
    window.zE('webWidget', 'hide');
    return () => window.zE('webWidget', 'show');
  }, []);

  useEffect(() => {
    FullStory.init({
      orgId: metaData.FULL_STORY_ORG_ID,
      devMode: process.env.REACT_APP_ENV !== 'production',
    });
    FullStory.identify(user.id, {
      displayName: `${user?.details?.firstName} ${user?.details?.lastName}`,
      email: user?.email,
    });
    return () => FullStory.shutdown();
  }, [user]);

  const mockVersion = useMemo(() => {
    if (['1', '2'].includes(query.get('mockVersion'))) {
      return +query.get('mockVersion');
    }
    return 1;
  }, [query]);

  if (
    (type === mockAttendTypes.auto && [
      Object.values(mockTypes).includes(query.get('mockType')),
    ]) ||
    (type === mockAttendTypes.editor && query.get('id'))
  ) {
    return (
      <MockAttendFetchWrapper
        type={type}
        id={query.get('id')}
        mockType={query.get('mockType')}
        version={mockVersion}
      />
    );
  }

  return null;
}

export default MockAttendPage;
