import React, { useState, useMemo } from 'react';
import { Text, Stack, Box, Flex, Icon } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import Container, { LayoutBox } from '../../../components/Layout/Container';
import UserStep from './User';
import CompleteProfileStep from './CompleteProfile';
import AssessmentStep from './Assessment';
import { SignupContext, useSignupContext } from './SignupContext';

function StepItem({ serial = '', label, name, description }) {
  const { step } = useSignupContext();

  const signupState = useSelector((state) => state.signup);

  const isCurrentStep = useMemo(() => step === name, [step, name]);

  const isComplete = useMemo(() => signupState[name]?.isComplete, [
    name,
    signupState,
  ]);

  const backgroundColor = isCurrentStep
    ? 'custom.black2'
    : isComplete
    ? 'custom.blue5'
    : '';

  const color = isCurrentStep
    ? 'white'
    : isComplete
    ? 'white'
    : 'custom.black2';

  const borderColor = isComplete ? 'custom.blue5' : 'custom.black2';

  const textColor = isCurrentStep
    ? 'custom.black2'
    : isComplete
    ? 'custom.blue5'
    : 'gray.500';

  const subTextColor = isCurrentStep
    ? 'custom.black2'
    : isComplete
    ? 'custom.blue5'
    : 'gray.500';

  return (
    <Box mb={10} position="relative">
      <Flex>
        <Flex
          backgroundColor={backgroundColor}
          color={color}
          borderColor={borderColor}
          borderWidth="2px"
          borderRadius="50%"
          width="40px"
          height="40px"
          justify="center"
          align="center"
          fontWeight={700}
        >
          {signupState[name]?.isComplete ? (
            <Icon name="check" size="18px" />
          ) : (
            serial
          )}
        </Flex>
        <Box flex={1} ml={4}>
          <Text
            fontWeight={700}
            color={textColor}
            textTransform="uppercase"
            fontSize="lg"
          >
            {label}
          </Text>
          <Text mt={2} color={subTextColor}>
            {description}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

function renderSteps(step) {
  switch (step) {
    case 'assessment':
      return <AssessmentStep />;
    case 'completeProfile':
      return <CompleteProfileStep />;
    case 'user':
    default:
      return <UserStep />;
  }
}

function Signup() {
  const [step, setStep] = useState('user');

  const value = useMemo(() => ({ step, setStep }), [step]);

  return (
    <SignupContext.Provider value={value}>
      <Container>
        <LayoutBox
          backgroundColor="white"
          minHeight="100vh"
          align="center"
          boxShadow="0px 7px 64px rgba(0, 0, 0, 0.07)"
          paddingBottom="30px"
        >
          {renderSteps(step)}
        </LayoutBox>
        <LayoutBox>
          <Stack p={10}>
            <StepItem
              serial="1"
              name="user"
              label="Signup For Free!"
              description="Your basic info is secured and protected from spammers"
            />
            <StepItem
              serial="2"
              name="assessment"
              label="PTE Level/Goal"
              description="AIWAS Plus wants to know what is your current PTE level/goal"
            />
            <StepItem
              serial="3"
              name="completeProfile"
              label="Complete your profile"
              description="AIWAS Plus will guide you to achieve your PTE goal"
            />
          </Stack>
        </LayoutBox>
      </Container>
    </SignupContext.Provider>
  );
}

export default Signup;
