import { UPDATE_SIGNUP_DATA, COMPLETE_SIGNUP } from './action';

const initialState = {
  user: {
    isComplete: false,
  },
  completeProfile: {
    isComplete: false,
  },
  goal: {
    isComplete: false,
  },
  assessment: {
    isComplete: false,
  },
};

const signupReducer = (state = initialState, { type, data, step }) => {
  switch (type) {
    case UPDATE_SIGNUP_DATA:
      return {
        ...state,
        [step]: { ...state[step], ...data, isComplete: true },
      };
    case COMPLETE_SIGNUP:
      return initialState;
    default:
      return state;
  }
};

export default signupReducer;
