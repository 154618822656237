import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { formatNumber } from '../../../helpers/number';

function CheckoutDetails({ data }) {
  return (
    <Box>
      <Stack
        spacing="45px"
        isInline
        py={4}
        mb={10}
        borderTopWidth="1px"
        borderBottomWidth="1px"
        fontSize="md"
      >
        <Box>
          <Text
            fontWeight={600}
            mb={1}
            color="custom.blue"
            textTransform="uppercase"
          >
            {get(data, 'type')} Name
          </Text>
          <Text color="custom.black3">
            {get(data, 'type') === 'package'
              ? get(data, 'package.name')
              : get(data, 'extension.name')}
          </Text>
        </Box>
        <Box>
          <Text fontWeight={600} mb={1} color="custom.blue">
            UNITS
          </Text>
          <Text color="custom.black3">{get(data, 'units')}</Text>
        </Box>
        <Box>
          <Text fontWeight={600} mb={1} color="custom.blue">
            INSTITUTE
          </Text>
          <Text color="custom.black3">{get(data, 'institute.name')}</Text>
        </Box>
        <Box>
          <Text
            fontWeight={600}
            mb={1}
            color="custom.blue"
            textTransform="uppercase"
          >
            {get(data, 'type')} Validity
          </Text>
          <Text color="custom.black3">{get(data, 'featureValidity')} Days</Text>
        </Box>
        {!!get(data, 'speakingValidity') && (
          <Box>
            <Text fontWeight={600} mb={1} color="custom.blue">
              SPEAKING VALIDITY
            </Text>
            <Text color="custom.black3">
              {get(data, 'speakingValidity')} Days
            </Text>
          </Box>
        )}
        <Box>
          <Text fontWeight={600} mb={1} color="custom.blue">
            TOTAL
          </Text>
          <Text fontWeight={600} color="custom.green">
            {formatNumber(get(data, 'amount'))}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default CheckoutDetails;
