import React from 'react';
import { Text } from '@chakra-ui/core';
import FormCheckbox from '../../../components/Form/Checkbox';

function MCQMultipleFormField({ label, name, options = [], idPrefix = '' }) {
  return (
    <>
      <Text dangerouslySetInnerHTML={{ __html: label }} pb={2} />
      {options.map((option, index) => (
        <FormCheckbox
          key={index}
          id={`${idPrefix}${index}`}
          name={`${name}[${index}]`}
          value={option}
          label={option}
        />
      ))}
    </>
  );
}

export default MCQMultipleFormField;
