import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
} from '@chakra-ui/core';
import { useInterval } from '../../hooks/useTimer';
import WaveSurfer from 'wavesurfer.js';
import { customColors } from '../../theme/theme';

function AudioPlayer({
  audioUrl,
  startingTime = 2,
  onEventCallback = () => null,
}) {
  const waveSurfer = useRef();
  const containerRef = useRef();

  const [status, setStatus] = useState('loading');

  const [count, setCount] = useState(startingTime);
  const [delay, setDelay] = useState(null);

  const [isReady, setIsReady] = useState(false);

  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const onReady = useCallback(() => {
    if (isMounted.current) {
      setIsReady(true);
      setStatus('loaded');
    }
  }, []);

  const onFinish = useCallback(() => {
    setStatus('ended');
  }, []);

  useEffect(() => {
    if (audioUrl) {
      setCount(startingTime);
      setDelay(null);
      setStatus('loading');
      if (waveSurfer.current) {
        waveSurfer.current.load(audioUrl);
        return;
      }

      if (containerRef.current && !waveSurfer.current) {
        waveSurfer.current = WaveSurfer.create({
          container: containerRef.current,
          waveColor: 'white',
          barWidth: 4,
          barGap: 2,
          barRadius: 2,
          progressColor: customColors.blue,
          cursorColor: customColors.blue,
          interact: false,
        });

        waveSurfer.current.on('ready', onReady);

        waveSurfer.current.on('finish', onFinish);

        waveSurfer.current.load(audioUrl);
      }
    }

    return () => waveSurfer.current?.empty();
  }, [startingTime, audioUrl, onReady, onFinish]);

  useInterval(() => {
    if (count >= 0) {
      setCount(count - 1);
      setStatus(`Starting in ${count}`);
    }

    if (count <= 0) {
      setDelay(null);
      setStatus('playing');
    }
  }, delay);

  useEffect(() => {
    if (status === 'loading') {
      onEventCallback({ status });
    }
    if (status === 'playing') {
      onEventCallback({ status });
      waveSurfer.current.play();
    }
    if (status === 'ended') {
      onEventCallback({ status });
    }
  }, [status, count, onEventCallback]);

  return (
    <>
      <Box bg="custom.blue3" p={4} borderRadius="5px" mt={6}>
        <Flex justify="space-between">
          <Text color="custom.gray5">
            Current Status:
            <Text
              as="strong"
              color="custom.red"
              ml={1}
              textTransform="capitalize"
            >
              {status}
            </Text>
          </Text>
          <Box minW="200px">
            <Flex align="center">
              <Text mr={3} fontSize="sm" color="custom.gray5" fontWeight={600}>
                Volume
              </Text>
              <Slider
                onChange={(value) => waveSurfer.current?.setVolume(value)}
                color="blueVariant"
                value={waveSurfer.current?.getVolume()}
                defaultValue={1}
                min={0}
                max={1}
                step={0.1}
                mr={2}
              >
                <SliderTrack />
                <SliderFilledTrack />
                <SliderThumb />
              </Slider>
            </Flex>
          </Box>
        </Flex>
        <Box m={4} position="relative" ref={containerRef} />
      </Box>
      {['loading', 'loaded'].includes(status) && !delay && (
        <Button
          _hover={{
            backgroundColor: 'custom.blue8',
          }}
          mt={6}
          variantColor="blueVariant"
          onClick={() => setDelay(1000)}
          isLoading={!isReady}
        >
          Start
        </Button>
      )}
    </>
  );
}

export default AudioPlayer;
