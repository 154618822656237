import React from 'react';
import { Box, Flex, Text, Badge, Image, Icon, Stack } from '@chakra-ui/core';
// import { useSelector } from 'react-redux';
// import { selectUserById } from '../../store/users/usersSlice';
import { get } from 'lodash-es';
import { getDate, DATE_TIME_FULL } from '../../helpers/getDate';
import logoTextual from '../../assets/images/logo-textual.svg';
import PrimaryButton from '../Button/PrimaryButton';
import { Link } from 'react-router-dom';

function TargetScore({ score, label }) {
  return (
    <Flex align="center" justify="center">
      <Flex
        align="center"
        justify="center"
        h={50}
        w={50}
        borderRadius="50%"
        borderWidth={6}
        borderColor="custom.yellow"
        fontWeight={600}
        fontSize="lg"
      >
        {score}
      </Flex>
      <Text ml={2} color="custom.blue">
        {label}
      </Text>
    </Flex>
  );
}

function Suggestion({ label, items, iconColor, ...props }) {
  return (
    <Box {...props}>
      {label && (
        <Text mb={4} color="custom.black4">
          {label}
        </Text>
      )}
      <Stack spacing={2}>
        {items.map((item) => (
          <Flex
            align="flex-start"
            key={item}
            fontSize="sm"
            color="custom.black2"
          >
            <Icon mt={1} name="info" color={iconColor} />
            <Text ml={2} dangerouslySetInnerHTML={{ __html: item }} />
          </Flex>
        ))}
      </Stack>
    </Box>
  );
}

function AnalysedReport({
  data,
  isProfile,
  isMockReport,
  isPreview,
  ...props
}) {
  return (
    <Box py={4} {...props}>
      {!isProfile && !isMockReport && (
        <Flex
          align="center"
          justify="space-between"
          borderBottomWidth="1px"
          pb={4}
          mb={6}
        >
          <Box>
            <Flex align="center">
              <Text
                mr={2}
                color="custom.blue"
                textTransform="uppercase"
                fontWeight={600}
                fontSize="xl"
              >
                PTE Score Analyzer
              </Text>
              <Badge
                borderRadius="5px"
                variant="outline"
                variantColor="redVariant"
              >
                AI
              </Badge>
            </Flex>
            <Text color="custom.black4">
              {!isPreview &&
                `${get(data, 'firstName')} ${get(data, 'lastName')}`}
              <Text color="custom.gray2" ml={isPreview ? 0 : 2} as="span">
                {getDate(new Date(), DATE_TIME_FULL)}
              </Text>
            </Text>
          </Box>
          <Image src={logoTextual} />
        </Flex>
      )}
      {!isMockReport && (
        <Box mb={6}>
          <Text fontSize="lg">Target Scores:</Text>
          <Flex mt={4} justify="space-between">
            <TargetScore
              label="Speaking"
              score={get(data, 'analysis.target')}
            />
            <TargetScore label="Writing" score={get(data, 'analysis.target')} />
            <TargetScore label="Reading" score={get(data, 'analysis.target')} />
            <TargetScore
              label="Listening"
              score={get(data, 'analysis.target')}
            />
          </Flex>
        </Box>
      )}
      <Box>
        {!isMockReport && (
          <Text fontSize="lg" mb={4}>
            Suggestions:
          </Text>
        )}
        {get(data, 'analysis.message') ? (
          <Suggestion
            iconColor="custom.blue"
            items={[get(data, 'analysis.message')]}
          />
        ) : (
          <Stack spacing={4}>
            <Suggestion
              iconColor="custom.red"
              label={get(data, 'analysis.highlyLikelyText')}
              items={
                isPreview
                  ? get(data, 'analysis.highlyLikely', []).length > 2
                    ? get(data, 'analysis.highlyLikely').slice(0, 2)
                    : get(data, 'analysis.highlyLikely')
                  : get(data, 'analysis.highlyLikely')
              }
            />
            {!isPreview && (
              <>
                <Suggestion
                  iconColor="custom.yellow"
                  label={get(data, 'analysis.lessLikelyText')}
                  items={get(data, 'analysis.lessLikely')}
                />
                <Suggestion
                  iconColor="custom.blue"
                  label={get(data, 'analysis.mayHaveText')}
                  items={get(data, 'analysis.mayHave')}
                  mt={4}
                />
              </>
            )}
          </Stack>
        )}

        {isPreview && (
          <Flex justify="center" align="center" mt={8}>
            <PrimaryButton variantColor="blueVariant" as={Link} to="/signup">
              SIGNUP TO SEE FULL REPORT
            </PrimaryButton>
          </Flex>
        )}
      </Box>
    </Box>
  );
}

export default AnalysedReport;
