import { modules } from '../../../../../../constants/modules';

export const defaultQueryObject = {
  module: modules.speaking.RetellLecture,
};

export const defaultQueryWithSection = {
  module: modules.speaking.RetellLecture,
  section: 'speaking',
};
