import React from 'react';
import { Flex, Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

const CustomBox = styled(Box)`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

function DashboardLayout({ children, sidebar }) {
  return (
    <Flex color="custom.black" height="calc(100vh - 76px)" overflow="hidden">
      <CustomBox p={10} overflowY="auto" width="100%">
        {children}
      </CustomBox>
      <Box>{sidebar}</Box>
    </Flex>
  );
}

export default DashboardLayout;
