import React, { memo, useState, useMemo } from 'react';
import {
  Tooltip,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { Box, Text, Flex } from '@chakra-ui/core';
import { customColors } from '../../../../theme/theme';
import { get, take, map } from 'lodash-es';
import DateIntervalSelect from './DateIntervalSelect';

const CustomizeTooltip = memo(({ active, payload }) => {
  if (active) {
    return (
      <Box
        color="white"
        fontSize="sm"
        bg="#0F3154"
        py={2}
        px={4}
        borderRadius="10px"
      >
        <Flex mb={2} align="center">
          <Text mr={2} textTransform="uppercase">
            Speaking
          </Text>
          <Text fontSize="xs" color="custom.gray2">
            avg: {get(payload, '0.payload.old.y1.overall', 0)} count:{' '}
            {get(payload, '0.payload.old.y1.count', 0)}
          </Text>
        </Flex>
        <Flex mb={2} align="center">
          <Text mr={2} textTransform="uppercase">
            Writing
          </Text>
          <Text fontSize="xs" color="custom.gray2">
            avg: {get(payload, '0.payload.old.y2.overall', 0)} count:{' '}
            {get(payload, '0.payload.old.y2.count', 0)}
          </Text>
        </Flex>
        <Flex mb={2} align="center">
          <Text mr={2} textTransform="uppercase">
            Reading
          </Text>
          <Text fontSize="xs" color="custom.gray2">
            avg: {get(payload, '0.payload.old.y3.overall', 0)} count:{' '}
            {get(payload, '0.payload.old.y3.count', 0)}
          </Text>
        </Flex>
        <Flex align="center">
          <Text mr={2} textTransform="uppercase">
            Listening
          </Text>
          <Text fontSize="xs" color="custom.gray2">
            avg: {get(payload, '0.payload.old.y4.overall', 0)} count:{' '}
            {get(payload, '0.payload.old.y4.count', 0)}
          </Text>
        </Flex>
      </Box>
    );
  }
  return null;
});

function StatsChart({ data }) {
  return (
    <ResponsiveContainer width="100%" aspect={6.0 / 2.0}>
      <LineChart
        data={data}
        margin={{ top: 10, bottom: 30, left: 30, right: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tick={false} axisLine={false} tickLine={false} height={15} />
        <XAxis
          tick={{
            fill: '#666',
            fontSize: '10px',
            angle: -45,
            textAnchor: 'middle',
          }}
          dataKey="x"
          tickLine={false}
          axisLine={false}
          interval={0}
          xAxisId={1}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          ticks={[30, 45, 60, 75, 90]}
          domain={[0, 'dataMax']}
          width={25}
        />
        <Legend
          iconSize={10}
          height={40}
          verticalAlign="top"
          align="right"
          iconType="square"
        />
        <Tooltip content={<CustomizeTooltip />} />
        <Line
          type="monotone"
          connectNulls
          dataKey="speaking"
          stroke={customColors.blue}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          connectNulls
          dataKey="writing"
          stroke={customColors.yellow}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          connectNulls
          dataKey="reading"
          stroke={customColors.green}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          connectNulls
          dataKey="listening"
          stroke={customColors.red}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

const days7 = 7;
const days30 = 30;
const days60 = 60;

const dateIntervalOptions = [
  { label: '7 Days', value: days7 },
  { label: '30 Days', value: days30 },
  { label: '60 Days', value: days60 },
];

function prepareData(data) {
  const mappedItems = map(data, (item) => ({
    x: item.x,
    speaking: item.y1.overall || 0,
    writing: item.y2.overall || 0,
    reading: item.y3.overall || 0,
    listening: item.y4.overall || 0,
    old: item,
  }));
  const dataByDateInterval = {
    [days7]: take(mappedItems, 7),
    [days30]: take(mappedItems, 30),
    [days60]: take(mappedItems, 60),
  };

  return dataByDateInterval;
}

function PractiseChart({ data }) {
  const [dateInterval, setDateInterval] = useState(dateIntervalOptions[0]);

  const dataByDateInterval = useMemo(() => prepareData(data), [data]);

  return (
    <Box mt={8} bg="white" p={6} boxShadow="custom.tertiary">
      <Flex justify="space-between" align="center">
        <Text
          mr={2}
          color="custom.blue"
          textTransform="uppercase"
          fontWeight={600}
          mb={4}
        >
          Activity
        </Text>
        <DateIntervalSelect
          value={dateInterval}
          options={dateIntervalOptions}
          onChange={setDateInterval}
        />
      </Flex>
      <StatsChart data={dataByDateInterval[dateInterval.value]} />
    </Box>
  );
}

export default memo(PractiseChart);
