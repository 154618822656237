import { get } from 'lodash-es';
import {
  getDayDifference,
  getDayDifferenceFromDate,
  getDate,
} from '../../../../helpers/getDate';

export function getModuleStatsData(moduleValue) {
  return {
    ratio: {
      label: `${get(moduleValue, 'practicedQuestions')}/${get(
        moduleValue,
        'totalQuestions'
      )}`,
      value: get(moduleValue, 'completePercentage'),
    },
    average: get(moduleValue, 'averageScore'),
    averageIn100: parseInt((get(moduleValue, 'averageScore') * 100) / 90),
  };
}

export function getPackageStatsData({ startedAt, endsOn }) {
  return {
    label: `${getDate(startedAt)} - ${getDate(endsOn)}`,
    value: parseInt(
      getDayDifferenceFromDate(endsOn, startedAt) / getDayDifference(endsOn)
    ),
  };
}
