import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/core';

class AutoplayErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex minH="200px" justify="center" align="center" direction="column">
          <Text>Please proceed with this button click to resume.</Text>
          <Button
            mt={6}
            _hover={{
              backgroundColor: 'custom.blue8',
            }}
            variantColor="blueVariant"
            onClick={() => this.setState({ hasError: false })}
          >
            RESUME
          </Button>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default AutoplayErrorBoundary;
