import React, { useMemo } from 'react';
import Form from '../../../../../components/Form/Form';
import { FormLabel, Grid, Text } from '@chakra-ui/core';
import { useForm, useFieldArray } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import FormInput from '../../../../../components/Form/Input';
import FormSelect from '../../../../../components/Form/Select';
import FormVisualEditor from '../../../../../components/Form/VisualEditor';
import FormMultiSelect from '../../../../../components/Form/MutliSelect';
import * as Yup from 'yup';
import { priority } from '../../../../../constants/priority';
import { map, get, take } from 'lodash-es';
import { useLabels } from '../../../../../store/label/hooks';
import ErrorMessage from '../../../../../components/Form/ErrorMessage';
import { stripHtml } from '../../../../../helpers/stripHtml';
import FormCheckbox from '../../../../../components/Form/Checkbox';
import { useCallback } from 'react';

function OptionGrid({ children }) {
  return (
    <Grid
      columns={2}
      gridTemplateColumns="1fr auto"
      gridColumnGap={4}
      alignItems="center"
    >
      {children}
    </Grid>
  );
}

function generateDefaultValues(options = [], answer = []) {
  const generatedOptions = [];

  for (let option of options) {
    const generatedOption = {};
    generatedOption.item = option;
    generatedOption.isAnswer = false;
    if (answer.includes(option)) {
      generatedOption.isAnswer = true;
    }

    generatedOptions.push(generatedOption);
  }

  return generatedOptions;
}

function AddEditForm({ data, onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      options: take(
        [
          ...generateDefaultValues(
            get(data, 'data.options'),
            get(data, 'data.answer')
          ),
          ...Array(6).fill(''),
        ],
        6
      ),
      labels: get(data, 'labels', []).map((label) => label.id),
      description: get(data, 'description', ''),
      priority: get(data, 'priority', ''),
      duration: get(data, 'data.duration', ''),
      advice: get(data, 'data.advice', ''),
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        description: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
        options: Yup.array()
          .test(
            'is-minimum',
            'Minimum 4 options.',
            (value) => value.filter((item) => item.item).length >= 4
          )
          .test('is-unique', 'Must contain unique values.', (value) => {
            const valuesWithValue = value
              .filter((item) => item.item)
              .map((item) => item.item);
            return valuesWithValue.length === new Set(valuesWithValue).size;
          })
          .test(
            'has-answer',
            'Atleast one valid answer.',
            (value) => value.find((item) => item.isAnswer)?.item
          ),
        labels: Yup.array().required('Required.'),
        duration: Yup.string().required('Required.'),
        priority: Yup.string().required('Required.'),
        advice: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const { control, reset } = form;

  const { fields } = useFieldArray({
    control,
    name: 'options',
  });

  const { byId, allIds } = useLabels();

  const priorityOptions = useMemo(
    () => map(priority, (item) => ({ label: item, value: item })),
    []
  );

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const formOnSubmit = useCallback(
    async (values) => {
      await onSubmit(values, { reset });
    },
    [onSubmit, reset]
  );

  return (
    <Form form={form} onSubmit={formOnSubmit} mt={6}>
      <FormLabel mb={1}>Question of the MCQ</FormLabel>
      <FormVisualEditor name="description" />
      {fields.map((field, index) => (
        <OptionGrid key={field.id}>
          <FormInput
            placeholder="Write option"
            name={`options[${index}].item`}
          />
          <FormCheckbox
            label="Mark as answer"
            name={`options[${index}].isAnswer`}
            containerProps={{ mb: 8 }}
          />
        </OptionGrid>
      ))}
      <ErrorMessage name="options" mt={-8} mb={8} />
      <FormMultiSelect
        placeholder="Labels"
        name="labels"
        options={labelOptions}
      />
      <FormSelect
        name="priority"
        placeholder="Priority"
        options={priorityOptions}
      />
      <FormInput
        type="number"
        placeholder="Duration (Target Time) (In Seconds)"
        name="duration"
      />
      <Text fontWeight={600} color="custom.gray5" mb={2}>
        Examiner's Advice
      </Text>
      <FormVisualEditor name="advice" />
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

export default AddEditForm;
