import React, { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/core';
import { get } from 'lodash-es';
import AudioPlayerControl from '../../../../components/AudioPlayer/AudioPlayerControl';

const rx = /\[.*?\]/g;

function PredictionFillBlanksView({ data, section, audio }) {
  const paragraph = useMemo(() => {
    const text = get(data, 'answer');

    const splitText = text.split(rx);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = text.match(rx);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <Text
                key={index}
                as="span"
                color="white"
                px={0.5}
                bg="custom.yellow"
              >
                {matches[index].replace(/\[|\]/g, '').split(';')[0]}
              </Text>,
            ]
          : [...arr, element],
      []
    );
  }, [data]);

  return (
    <Box>
      {section === 'listening' && (
        <Box mb={10}>
          <AudioPlayerControl audioUrl={audio} />
        </Box>
      )}
      <Box>{paragraph}</Box>
    </Box>
  );
}

export default PredictionFillBlanksView;
