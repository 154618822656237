import { useState, useEffect } from 'react';
import { apiRequest } from '../../../../api/api';
import { changeToCamelCase } from '../../../../helpers/changeCase';

function useScore({ section, module, id }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const { data } = await apiRequest(
          'get',
          `share/score/${id}?section=${section}`
        );

        setData(changeToCamelCase(data));

        setLoading(false);
      } catch {}
    }

    fetchData();
  }, [section, module, id]);

  return { data, loading };
}

export default useScore;
