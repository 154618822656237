import React, { useMemo } from 'react';
import Container from '../../../components/Layout/Container';
import {
  Heading,
  Box,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/core';
import {
  useRouteMatch,
  Switch,
  Route,
  NavLink,
  Redirect,
} from 'react-router-dom';
import { customColors } from '../../../theme/theme';
import Profile from '../../../components/User/Profile/Profile';
import EditProfile from '../../../components/User/Profile/EditProfile';
import Security from '../../../components/User/Security/Security';
import Goal from '../../../components/User/Goal/Goal';
import Subscription from '../../../components/User/Subscription/Subscription';
import {
  useCurrentUser,
  useHasCompletedProfile,
} from '../../../store/user/hooks';
import Billing from '../../../components/User/Billing/Billing';

function Sidebar() {
  const { path } = useRouteMatch();

  const routes = useMemo(
    () => getSettingsRoutes().filter((route) => route.label),
    []
  );

  return (
    <Stack spacing={6} m={10}>
      {routes.map((route) => (
        <NavLink
          to={`${path}${route.path}`}
          key={route.path}
          style={{
            fontSize: '24px',
            marginBottom: '40px',
            color: customColors.blue5,
          }}
          exact
          activeStyle={{ color: customColors.black2 }}
        >
          {route.label}
        </NavLink>
      ))}
    </Stack>
  );
}

const getSettingsRoutes = () => [
  {
    label: 'Profile',
    path: '/profile',
    exact: true,
    main: ({ ...props }) => <Profile {...props} />,
  },
  {
    path: '/profile/edit',
    exact: true,
    main: ({ ...props }) => <EditProfile {...props} />,
  },
  {
    label: 'Security',
    path: '/security',
    main: ({ ...props }) => <Security {...props} />,
  },
  {
    label: 'Goal/Target',
    path: '/goal',
    main: ({ ...props }) => <Goal {...props} />,
  },
  {
    label: 'Billing',
    path: '/billing',
    main: ({ ...props }) => <Billing {...props} />,
  },
  {
    label: 'Subscription',
    path: '/subscription',
    main: ({ ...props }) => <Subscription {...props} />,
  },
];

function Settings() {
  const hasCompletedProfile = useHasCompletedProfile();

  const { path } = useRouteMatch();

  const user = useCurrentUser();

  const routes = useMemo(() => getSettingsRoutes(), []);

  return (
    <Box maxWidth="1140px" margin="30px auto">
      {!hasCompletedProfile && (
        <Alert mb={6} status="warning" variant="solid" borderRadius="8px">
          <AlertIcon />
          <AlertTitle mr={2}>UPDATE PROFILE!</AlertTitle>
          You need to complete your profile before you can proceed.
        </Alert>
      )}

      <Heading size="lg" color="custom.black3" mb={6}>
        PROFILE & SETTINGS
      </Heading>
      <Container columns="1fr 3fr">
        <Box boxShadow="custom.primary" bg="white" borderRadius="6px 0 0 6px">
          <Sidebar />
        </Box>
        <Box
          bg="white"
          boxShadow="custom.primary"
          p={10}
          borderRadius="0 6px 6px 0"
        >
          <Box>
            <Switch>
              x
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={`${path}${route.path}`}
                  exact={route.exact}
                >
                  <route.main userId={user.id} />
                </Route>
              ))}
              <Redirect to={`${path}/profile`} />
            </Switch>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Settings;
