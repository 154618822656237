import React, { lazy, Suspense, useMemo } from 'react';
import { useCurrentUser } from '../../../store/user/hooks';
import { roles } from '../../../constants/roles';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDashboardData } from './hooks/hooks';
import Loader from '../../../components/Loader/Loader';
import { get } from 'lodash-es';
// import SuperAdminDashboard from './SuperAdminDashboard';
import { Box, Icon } from '@chakra-ui/core';
import { FiAlertCircle } from 'react-icons/fi';
import { isChrome, isMobile } from 'react-device-detect';

const StudentDashboard = lazy(() => import('./StudentDashboard'));
const InstructorDashboard = lazy(() => import('./InstructorDashboard'));
// const AdminDashboard = lazy(() => import('./AdminDashboard'));

export function handleDashboardRoutes(userData, isSelf) {
  const { user } = userData;

  switch (get(user, 'role')) {
    case roles.student:
      return <StudentDashboard userData={userData} isSelf={isSelf} />;
    case roles.instructor:
    case roles.admin:
    case roles.super_admin:
      return <InstructorDashboard userData={userData} isSelf={isSelf} />;
    default:
      return null;
  }
}

function MainDashboard() {
  const { search } = useLocation();

  const query = queryString.parse(search);

  const currentUser = useCurrentUser();

  const userId = useMemo(() => (query.id ? query.id : currentUser.id), [
    currentUser,
    query,
  ]);

  const [userData, fetching] = useDashboardData(userId);

  if (fetching) {
    return <Loader />;
  }

  return (
    <Suspense fallback={null}>
      {(isMobile || !isChrome) && (
        <Box
          backgroundColor="custom.red"
          px={10}
          py={3}
          textAlign="center"
          color="white"
          fontSize="md"
          fontWeight={400}
        >
          <Icon as={FiAlertCircle} size="24px" mb="2px" mr={2} />
          <strong>
            Your Browser/Device is Not Fully AIWAS Plus compatible!
          </strong>{' '}
          If you want to get the best possible outcome from AIWAS Plus, please{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/chrome/"
          >
            <strong>Download/Use Chrome</strong>
          </a>{' '}
          from your PC/Laptop.
        </Box>
      )}
      {handleDashboardRoutes(userData, parseInt(userId) === currentUser.id)}
    </Suspense>
  );
}

export default MainDashboard;
