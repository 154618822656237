import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/core';
import { get } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { extensionIds } from '../../../../constants/packages';
import { useCurrentUser } from '../../../../store/user/hooks';

function MockLimitBox() {
  const user = useCurrentUser();

  return (
    <Flex align="center" justify="center">
      <Box backgroundColor="custom.white1" px={8} py={4} borderRadius={4}>
        <Heading size="md" mb={1} textAlign="center" color="custom.black2">
          YOUR MOCK LIMITS
        </Heading>
        <Stack isInline spacing={6} color="custom.blue8" px={1}>
          <Text fontSize="sm">
            FULL:{' '}
            <strong>{get(user, 'currentPackage.fullMocks', 'N/A')}</strong>
          </Text>
          <Text fontSize="sm">
            CUSTOM:{' '}
            <strong>{get(user, 'currentPackage.customMocks', 'N/A')}</strong>
          </Text>
        </Stack>
        <PrimaryButton
          minW="160px"
          fontSize="sm"
          mt={2}
          as={Link}
          leftIcon={AiOutlinePlusCircle}
          to={`/dashboard/checkout?id=${extensionIds.mock}&type=extension`}
          // onClick={() => setView(views.auto)}
          py={4}
          variantColor="orangeVariant"
          variant="solid"
        >
          Increase Mock Limits
        </PrimaryButton>
      </Box>
    </Flex>
  );
}

export default MockLimitBox;
