import { Box, Button, Flex, Stack } from '@chakra-ui/core';
import React from 'react';

function DatatablePagination({
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageIndex,
  ...props
}) {
  return (
    <Flex
      justify="space-between"
      align="center"
      borderTopWidth={1}
      pt={4}
      mt={4}
      {...props}
    >
      <Stack isInline>
        <Button
          disabled={!canPreviousPage}
          mr={6}
          onClick={() => previousPage()}
          leftIcon="arrow-back"
          variant="link"
        >
          Prev
        </Button>
        <Button
          disabled={!canNextPage}
          onClick={() => nextPage()}
          rightIcon="arrow-forward"
          variant="link"
        >
          Next
        </Button>
      </Stack>
      <Box color="custom.black4">
        Page{' '}
        <em>
          {pageIndex + 1} of {pageOptions.length}
        </em>
      </Box>
    </Flex>
  );
}

export default DatatablePagination;
