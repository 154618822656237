import React from 'react';
import { Box } from '@chakra-ui/core';
import AudioPlayerControl from '../../../../components/AudioPlayer/AudioPlayerControl';

function PredictionDicationView({ description, audio }) {
  return (
    <Box>
      <Box mb={10}>
        <AudioPlayerControl audioUrl={audio} />
      </Box>
      <Box mt={6}>{description}</Box>
    </Box>
  );
}

export default PredictionDicationView;
