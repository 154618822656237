import React, { useMemo } from 'react';
import { Box, Flex, Text, Stack, SimpleGrid } from '@chakra-ui/core';
import {
  ScoreHeader,
  ScoreDetails,
  ScoreFooter,
  MockScoreHeader,
} from '../Common/CommonView';
import { get, map } from 'lodash-es';
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import VocabularyWord from '../Common/VocabularyWord';

function FillBlanksDragScore({ score, practiseAgain, isMockReport }) {
  const givenAnswers = useMemo(() => score.details.givenAnswer, [
    score.details.givenAnswer,
  ]);

  const correctAnswers = useMemo(() => score.details.actualAnswer, [
    score.details.actualAnswer,
  ]);

  const wrongAnswers = useMemo(() => score.details.wrongAnswer, [
    score.details.wrongAnswer,
  ]);

  return (
    <Box mt={2} mb={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          QUESTION
        </Text>
        <Text
          color="custom.black"
          dangerouslySetInnerHTML={{
            __html: get(score, 'details.description'),
          }}
        />
      </Box>
      <SimpleGrid columns={2} mt={6}>
        <Box>
          <Text color="custom.black3" fontWeight={600}>
            CORRECT ANSWER
          </Text>
          {correctAnswers.map((answer, index) => (
            <Flex key={index} align="center">
              <Text color="custom.black3" fontWeight="700" mr={2}>
                [{index + 1}]
              </Text>
              <Flex align="center" color="custom.blue">
                <Box as={MdCheckCircle} mr={1} mb="-2px" />
                <Text>{answer}</Text>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Box>
          <Text color="custom.black3" fontWeight={600}>
            INCORRECT ANSWER
          </Text>
          {wrongAnswers.map((answer, index) => (
            <Flex key={index} align="center">
              <Flex align="center" color="custom.red">
                <Box as={MdCancel} mr={1} mb="-2px" />
                <Text>{answer}</Text>
              </Flex>
            </Flex>
          ))}
        </Box>
      </SimpleGrid>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          YOUR ANSWER
        </Text>
        <Stack isInline spacing={2} flexWrap="wrap">
          {givenAnswers.map(({ answer, isCorrect }, index) => (
            <Flex
              key={index}
              align="center"
              color={isCorrect ? 'custom.blue' : 'custom.red'}
            >
              <Text color="custom.black3" fontWeight="700" mr={2}>
                [{index + 1}]
              </Text>
              <Box as={isCorrect ? MdCheckCircle : MdCancel} mr={1} mb="-2px" />
              <Text
                dangerouslySetInnerHTML={{
                  __html: answer,
                }}
              />
            </Flex>
          ))}
        </Stack>
      </Box>
      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        isMockReport={isMockReport}
      />
      {!isMockReport && (
        <>
          <Box
            mt={6}
            pt={6}
            mb={8}
            borderTop="1px solid"
            borderTopColor="custom.white4"
          >
            <Text
              mb={2}
              color="custom.black3"
              fontWeight={600}
              textTransform="uppercase"
            >
              Learn Vocabulary and Pronunciation
              <Text fontSize="12px" fontStyle="italic" color="custom.gray5">
                (Click on the word to learn)
              </Text>
            </Text>
            <Flex ml={1} mt={2} flexWrap="wrap">
              {map(get(score, 'details.vocabulary'), (vocabulary, index) => (
                <VocabularyWord key={index} vocabulary={vocabulary} />
              ))}
            </Flex>
          </Box>
          <ScoreFooter score={score} practiseAgain={practiseAgain} />
        </>
      )}
    </Box>
  );
}

export default FillBlanksDragScore;
