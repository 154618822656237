import { combineReducers } from 'redux';
import user from './user/reducer';
import signup from './signup/reducer';
import global from './global/reducer';
import { USER_LOGOUT } from './user/action';
import label from './label/ducks';
import reading from './modules/reading/reducer';
import listening from './modules/listening/reducer';
import writing from './modules/writing/reducer';
import speaking from './modules/speaking/reducer';
import notification from './nofitication/notificationSlice';
import users from './users/usersSlice';
import mocks from './mocks/mocksSlice';

const reducer = combineReducers({
  user,
  signup,
  global,
  label,
  reading,
  listening,
  writing,
  speaking,
  notification,
  users,
  mocks,
});

export default (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = { global: state.global };
  }

  return reducer(state, action);
};
