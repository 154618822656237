import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/core';
import { useDispatch } from 'react-redux';
import { addQuestion } from '../../../../../store/modules/speaking/questionSlice';
import AddEditForm from './Form';
import TitleBar from '../../../../../components/Question/TitleBar';
import { handleApiError } from '../../../../../helpers/handleApiError';
import useCustomToast from '../../../../../hooks/useCustomToast';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import { modules } from '../../../../../constants/modules';
import { paths } from './utils/paths';
import { speaking } from '../../../../../constants/titles';
import { objectToFormData } from 'object-to-formdata';
import { speakingDuration } from '../../../../../constants/speakingDuration';

const customPaths = [
  ...paths,
  {
    name: 'Add Question',
  },
];

function Add() {
  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const submit = useCallback(
    async (
      {
        audioQuestion,
        description,
        phraseMatchCheck,
        sample,
        labels,
        priority,
        advice,
        reference,
        answer,
      },
      { reset }
    ) => {
      try {
        const data = {
          phrase_match_check: phraseMatchCheck,
          advice,
          reference,
          answer,
          question: description,
          duration: speakingDuration.answerShortQuestion.duration,
        };

        const body = objectToFormData({
          description,
          audio_question: audioQuestion,
          labels,
          priority,
          data,
          audio_male: sample.audioMale,
          audio_female: sample.audioFemale,
          module: modules.speaking.AnswerShortQuestion,
        });

        await dispatch(addQuestion(body));

        success({ title: 'Question is added' });

        reset();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, success]
  );

  return (
    <>
      <QuestionMetabar paths={customPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title={`Add ${speaking.answerShortQuestion}`} />
        <AddEditForm onSubmit={submit} />
      </Box>
    </>
  );
}

export default Add;
