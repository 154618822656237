import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/core';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import ErrorMessage from './ErrorMessage';

export function FormBoxInput({
  type,
  name,
  id = name,
  label,
  placeholder,
  isRequired,
  ...props
}) {
  const { register, errors } = useFormContext();

  return (
    <Box>
      <FormControl isRequired={isRequired} isInvalid={!!get(errors, name)}>
        <Flex flexDirection="column" align="start">
          <FormLabel p={0} htmlFor={name} color="custom.black4">
            {label}
          </FormLabel>
          <Input
            type={type}
            name={name}
            fontSize="32px"
            color="custom.black5"
            id={name}
            placeholder={placeholder}
            ref={register}
            my={2}
            minHeight={20}
            textAlign="center"
            backgroundColor="transparent"
            borderWidth="1px"
            borderColor="custom.gray3"
            borderRadius="6px"
            errorBorderColor="custom.red"
            focusBorderColor="custom.blue2"
            boxShadow="none !important"
            _focus={{ outline: 0 }}
            {...props}
          />
        </Flex>
      </FormControl>
    </Box>
  );
}

function FormInput({
  name,
  id = name,
  label,
  type = 'text',
  placeholder,
  isRequired,
  inputRight,
  boxProps,
  ...props
}) {
  const { register, errors } = useFormContext();

  return (
    <Box mb={6} width="100%" {...boxProps}>
      <FormControl isRequired={isRequired} isInvalid={!!get(errors, name)}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <InputGroup width="100%">
          <Input
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            ref={register}
            fontSize="14px"
            py={5}
            borderRadius="6px"
            borderColor="custom.gray"
            borderWidth="1px"
            errorBorderColor="custom.red"
            focusBorderColor="custom.blue2"
            boxShadow="none !important"
            {...props}
          />
          {inputRight && (
            <InputRightElement>
              <Icon name={inputRight} color="custom.gray" />
            </InputRightElement>
          )}
        </InputGroup>
        <ErrorMessage name={name} />
      </FormControl>
    </Box>
  );
}

export default FormInput;
