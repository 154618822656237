import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Box, Heading } from '@chakra-ui/core';
import Loader from '../../../components/Loader/Loader';
import { useDiagnosticFormData } from './hooks';
import useCustomToast from '../../../hooks/useCustomToast';
import {
  changeToSnakeCase,
  changeToCamelCase,
} from '../../../helpers/changeCase';
import { apiRequest } from '../../../api/api';
import { handleApiError } from '../../../helpers/handleApiError';
import { useCurrentUser } from '../../../store/user/hooks';
import DiagnosticReport from './DiagnosticReport';
import DiagnosticFormStep from './DiagnosticFormStep';
import AppContext from '../../../Routes/AppContext';

const initialStep = 'pteKnowledge';

const nextSteps = {
  [initialStep]: 'grammar',
  grammar: 'vocabulary',
  vocabulary: 'readingComprehension',
  readingComprehension: 'structuralWriting',
};

function DiagnosticPage() {
  const { isPreview } = useContext(AppContext);

  const user = useCurrentUser();

  const [data] = useDiagnosticFormData(isPreview);

  const [submitData, setSubmitData] = useState({});

  const [submittedData, setSubmittedData] = useState();

  const [step, setStep] = useState(initialStep);

  useEffect(() => {
    setSubmitData({});
    setSubmittedData();
    setStep(initialStep);
  }, []);

  const { toast } = useCustomToast();

  const handleSubmitData = useCallback(
    async (value) => {
      if (nextSteps[step]) {
        // save value to use later, setStep
        setSubmitData((state) => ({
          ...state,
          [step]: value,
        }));
        setStep(nextSteps[step]);
        return;
      }

      const dataToSubmit = changeToSnakeCase({
        ...submitData,
        [step]: value,
      });

      try {
        const { data } = await apiRequest(
          'post',
          isPreview ? 'utils/diagnostic' : `users/diagnostic/${user.id}`,
          dataToSubmit
        );

        setSubmittedData(changeToCamelCase(data));
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [step, submitData, toast, user, isPreview]
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <Box maxWidth="1140px" margin="30px  auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        DIAGNOSTIC TEST
      </Heading>
      <Box borderRadius="6px" bg="white" p={10} boxShadow="custom.primary">
        {submittedData ? (
          <DiagnosticReport data={submittedData} />
        ) : (
          <DiagnosticFormStep
            key={step}
            {...data[step]}
            onSubmit={handleSubmitData}
            hasNext={nextSteps[step]}
          />
        )}
      </Box>
    </Box>
  );
}

export default DiagnosticPage;
