import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/core';
import { capitalize, map } from 'lodash-es';
import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  titlesByModule,
  limitConfigByVersion,
} from '../../../../constants/modules';
import MockQuestionsDatatable from '../Components/MockQuestionsDatatable';
import ReactSelect from 'react-select';
import { priority as priorityValues } from '../../../../constants/priority';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import { useLabels } from '../../../../store/label/hooks';
import { mockVersions } from '../../../../constants/mockTypes';

const getCustomStyles = (isInvalid) => ({
  control: (provided) => ({
    ...provided,
    padding: 2,
    fontSize: '14px',
    borderColor: isInvalid ? '#EB5555' : '#DCDDE1',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
      backgroundColor: 'transparent',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: data.color,
    backgroundColor: 'transparent',
  }),
});

function Questions({
  questions,
  onChangeSelection,
  mappers,
  section,
  selectedState,
  limitsByModule,
}) {
  return (
    <Stack py={4} spacing={6}>
      {mappers.map((value) => (
        <Box key={value}>
          <Box mt={8} mb={8} textAlign="center" color="custom.black2">
            <Text fontSize="2xl" textTransform="uppercase">
              {titlesByModule[section][value]}
            </Text>
            <Text color="custom.black4">
              Select from the questions ({limitsByModule[section][value]}) below
              for mock test
            </Text>
          </Box>
          <MockQuestionsDatatable
            questions={questions}
            section={section}
            moduleName={value}
            onChangeSelection={onChangeSelection}
            initialSelectedRowIds={Object.fromEntries(
              (selectedState?.[value] || []).map((item) => [item, true])
            )}
          />
        </Box>
      ))}
    </Stack>
  );
}

// const initialState = {};

const QuestionTabs = memo(
  ({
    dataMappers,
    setSelectedQuestions,
    selectedState,
    limitsByModule,
    ...props
  }) => {
    return (
      <TabPanels py={2}>
        {dataMappers.tabs.map((item) => (
          <TabPanel key={item}>
            <Questions
              onChangeSelection={setSelectedQuestions}
              questions={dataMappers.questions[item]}
              mappers={dataMappers.questionModules[item]}
              section={item}
              limitsByModule={limitsByModule}
              selectedState={selectedState && selectedState[item]}
            />
          </TabPanel>
        ))}
      </TabPanels>
    );
  }
);

const mockVersionOptions = [
  { value: mockVersions.v2, label: 'v2' },
  { value: mockVersions.v1, label: 'v1' },
];

function MockForm({ dataMappers, onSubmit, initialState = {}, selectedState }) {
  const [title, setTitle] = useState(initialState.title || '');
  const [priority, setPriority] = useState(initialState.priority);
  const [labels, setLabels] = useState(initialState.labels || []);
  const [version, setVersion] = useState(
    initialState.version || {
      value: mockVersions.v2,
      label: 'v2',
    }
  );
  const [selectedQuestions, setSelectedQuestions] = useState({});

  const multiSelectStyles = useMemo(() => getCustomStyles(), []);

  const priorityOptions = useMemo(
    () => map(priorityValues, (item) => ({ label: item, value: item })),
    []
  );

  const { byId, allIds } = useLabels();

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      title,
      priority,
      selectedQuestions,
      labels,
      version,
    });
  }, [onSubmit, title, priority, selectedQuestions, labels, version]);

  return (
    <Box>
      <FormControl isRequired>
        <FormLabel color="custom.black5" htmlFor="title">
          Mock Title
        </FormLabel>
        <Input
          id="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          placeholder="Mock Title"
        />
      </FormControl>
      <FormControl isRequired mt={6}>
        <FormLabel color="custom.black5" htmlFor="priority">
          Priority
        </FormLabel>
        <ReactSelect
          options={priorityOptions}
          onChange={setPriority}
          value={priority}
        />
      </FormControl>
      <FormControl isRequired mt={6}>
        <FormLabel color="custom.black5" htmlFor="labels">
          Labels
        </FormLabel>
        <ReactSelect
          options={labelOptions}
          onChange={setLabels}
          value={labels}
          isMulti
          styles={multiSelectStyles}
        />
      </FormControl>
      <FormControl isRequired mt={6}>
        <FormLabel color="custom.black5" htmlFor="labels">
          Mock Version
        </FormLabel>
        <ReactSelect
          options={mockVersionOptions}
          onChange={setVersion}
          value={version}
          isDisabled={initialState.version}
        />
      </FormControl>

      <Tabs mt={6}>
        <TabList>
          {dataMappers.tabs.map((item) => (
            <Tab
              key={item}
              p={0}
              pb={2}
              mr={6}
              color="custom.gray5"
              _selected={{
                color: 'custom.blue2',
                borderColor: 'custom.blue2',
              }}
              fontWeight={600}
              _focus={{ outline: 'none' }}
              _active={{ backgroundColor: 'transparent' }}
            >
              {capitalize(item)}
            </Tab>
          ))}
        </TabList>
        <QuestionTabs
          dataMappers={dataMappers}
          setSelectedQuestions={setSelectedQuestions}
          selectedState={selectedState}
          limitsByModule={limitConfigByVersion[version.value]}
        />
      </Tabs>
      <PrimaryButton
        onClick={handleSubmit}
        px={10}
        mt={6}
        variantColor="blueVariant"
      >
        Submit
      </PrimaryButton>
    </Box>
  );
}

export default MockForm;
