import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import keyboardImage from '../../../../../assets/images/qwerty.jpg';

function KeyboardCheckStep() {
  return (
    <Stack spacing={4}>
      <Flex justify="space-between">
        <Stack spacing={4}>
          <Heading size="md">Keyboard Check</Heading>
          <Text>
            This is an opportunity to check that you have the correct keyboard
          </Text>
          <Stack spacing={1}>
            <Text>1. Look at the top row of letters on the keyboard</Text>
            <Text>2. The letters should appear in this order Q W E R T Y</Text>
            <Text>
              3. If you do not have a QWERTY keyboard, raise your hand to get
              the attention of the Test Administrator
            </Text>
          </Stack>
        </Stack>
      </Flex>
      <Box>
        <Image maxW="768px" w="100%" src={keyboardImage} />
      </Box>
    </Stack>
  );
}

export default KeyboardCheckStep;
