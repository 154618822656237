const maleVoiceNames = ['Daniel', 'Alex', 'Fred', 'Google UK English Male'];
const femaleVoiceNames = ['Google UK English Female', 'Samantha', 'Victoria'];

const getVoicesAsync = () => {
  return new Promise((resolve, reject) => {
    let voices = speechSynthesis.getVoices();
    if (voices.length > 0) {
      resolve(voices);
      return;
    }
    speechSynthesis.onvoiceschanged = () => {
      voices = speechSynthesis.getVoices();
      resolve(voices);
    };
    setTimeout(() => {
      if (voices.length > 0) {
        resolve(voices);
      } else {
        reject(new Error('Failed to load voices.'));
      }
    }, 2000);
  });
};

export const textToSpeech = async (text, gender = 'Male') => {
  let voices = await getVoicesAsync();
  if (text) {
    const voiceNames = gender === 'Male' ? maleVoiceNames : femaleVoiceNames;
    const utter = new SpeechSynthesisUtterance(text);
    console.log(
      'window.speechSynthesis.getVoices(): ',
      window.speechSynthesis.getVoices()
    );
    if (voices) {
      const foundVoice = voices.find((voice) =>
        voiceNames.includes(voice.name)
      );
      if (foundVoice) {
        utter.voice = foundVoice;
      }
    }
    speechSynthesis.cancel();
    speechSynthesis.speak(utter);
  }
};
