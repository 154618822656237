import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/core';
import { map } from 'lodash-es';
import React, { useMemo } from 'react';
import { useAsyncDebounce } from 'react-table';
import { priority } from '../../../../constants/priority';

function EditorPicksTableFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  labels,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter({
      ...globalFilter,
      searchValue: value || undefined,
    });
  }, 200);

  const labelOptions = useMemo(
    () => labels.allIds.map((id) => labels.byId[id]),
    [labels]
  );

  return (
    <Stack spacing={3} isInline>
      <Box>
        <Select
          placeholder="Select Priority"
          onChange={(event) => {
            setGlobalFilter({
              ...globalFilter,
              selectedPriority: event.target.value,
            });
          }}
        >
          {map(priority, (item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </Box>
      <Box>
        <Select
          placeholder="Select Label"
          onChange={(event) => {
            setGlobalFilter({
              ...globalFilter,
              selectedLabel: +event.target.value,
            });
          }}
        >
          {labelOptions.map((label) => (
            <option key={label.id} value={label.id}>
              {label.name}
            </option>
          ))}
        </Select>
      </Box>
      <Box>
        <InputGroup>
          <InputLeftElement>
            <Icon name="search" color="blue.300" />
          </InputLeftElement>
          <Input
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Search ${count} record${count > 1 ? 's' : ''}...`}
            variant="outline"
          />
        </InputGroup>
      </Box>
    </Stack>
  );
}

export default EditorPicksTableFilter;
