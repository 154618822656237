import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useScore from './hooks/useScore';
import { Box } from '@chakra-ui/core';
import Score from '../../../components/Community/Scores/Score';
import { LoaderWrapper } from '../../../components/Loader/Loader';
import queryString from 'query-string';

function ScorePage() {
  const { id } = useParams();

  const { search } = useLocation();

  const { module, section } = queryString.parse(search);

  const { data, loading } = useScore({ id, section });

  return (
    <LoaderWrapper loading={loading}>
      <Box maxWidth="900px" margin="auto" p={10}>
        <Box bg="white" p={10} boxShadow="custom.primary">
          <Score section={section} score={data} module={module} />
        </Box>
      </Box>
    </LoaderWrapper>
  );
}

export default ScorePage;
