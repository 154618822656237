import React from 'react';
import { FormControl, FormLabel, Stack, Switch } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

const FormSwitch = ({
  name,
  id = name,
  label,
  containerProps = {},
  ...props
}) => {
  const { register, errors } = useFormContext();

  return (
    <FormControl
      isDisabled={props.disabled}
      isInvalid={Boolean(get(errors, name))}
    >
      <Stack isInline alignItems="center" mb={6} {...containerProps}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <Switch name={name} id={id} size="lg" ref={register} {...props} />
      </Stack>
    </FormControl>
  );
};

export default FormSwitch;
