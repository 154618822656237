import React, { useMemo, useEffect, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import FillBlanksDragAdd from './Add';
import FillBlanksDragView from './View';
import FillBlanksDragEdit from './Edit';
import QuestionLayout from '../../../../../components/Question/QuestionLayout';
import {
  getQuestions,
  setQueryObject,
  loadQuestions,
} from '../../../../../store/modules/reading/questionSlice';
import { getLabels } from '../../../../../store/label/ducks';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../components/Loader/Loader';
import Stats from '../../../../../components/Stats/Stats';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import { defaultQueryObject } from './utils/defaultQueryObject';
import { paths } from './utils/paths';
import { get, capitalize } from 'lodash-es';
import Helmet from 'react-helmet';
import { fillBlanksDrag, siteTitle } from '../../../../../constants/titles';
import { sections } from '../../../../../constants/sections';
import Authorize from '../../../../../components/Authorize/Authorize';
import { reading } from '../../../../../constants/titles';
import FreeAlert from '../../../../../components/FreeAlert/FreeAlert';

function Main() {
  const { stats } = useSelector((state) => state.reading.questions);

  const { path } = useRouteMatch();

  return (
    <>
      <QuestionMetabar rootPath={path} showAdd paths={paths} />
      <FreeAlert />
      <Stats title={reading.fillBlanksDrag} stats={stats} />
    </>
  );
}

function FillBlanksDragPage() {
  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  const { queryObject } = useSelector((state) => state.reading.questions);

  const finalQueryObject = useMemo(
    () => ({ ...queryObject, ...defaultQueryObject }),
    [queryObject]
  );

  useEffect(() => {
    dispatch(getQuestions(finalQueryObject));
  }, [dispatch, finalQueryObject]);

  useEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  const setQuery = useCallback(
    (query) => {
      dispatch(setQueryObject(query));
    },
    [dispatch]
  );

  const { byId, allIds, stats, loading, page, nextPage } = useSelector(
    (state) => state.reading.questions
  );

  const loadMore = useCallback(() => {
    dispatch(loadQuestions({ page: page + 1, ...defaultQueryObject }));
  }, [dispatch, page]);

  const questions = useMemo(() => allIds.map((id) => byId[id]), [allIds, byId]);

  if (get(loading, finalQueryObject.module, true)) return <Loader />;

  return (
    <QuestionLayout
      questions={questions}
      stats={stats}
      page={page}
      nextPage={nextPage}
      searchPlaceHolder="Search Reading Questions"
      rootPath={path}
      setQuery={setQuery}
      queryObject={queryObject}
      loadMore={loadMore}
    >
      <Helmet>
        <title>
          {fillBlanksDrag} - {capitalize(sections.reading)} | {siteTitle}
        </title>
      </Helmet>
      <Switch>
        <Route exact path={`${path}/add`}>
          <Authorize permissions="questions.canAdd">
            <FillBlanksDragAdd />
          </Authorize>
        </Route>
        <Route exact path={`${path}/edit/:id`}>
          <Authorize permissions="questions.canEdit">
            <FillBlanksDragEdit />
          </Authorize>
        </Route>
        <Route exact path={`${path}/:id/:tab?`}>
          <FillBlanksDragView />
        </Route>
        <Route exact path={`${path}`}>
          <Main />
        </Route>
      </Switch>
    </QuestionLayout>
  );
}

export default FillBlanksDragPage;
