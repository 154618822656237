import { useState, useCallback, useRef, useEffect } from 'react';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';

function useMockRecorder(duration, onStop) {
  const recorderRef = useRef();

  const [recordingTime, setRecordingTime] = useState(duration);
  const [status, setStatus] = useState();

  const stop = useCallback(async () => {
    clearInterval(recorderTimer.current);

    if (recorderRef.current?.recordRTC.getState() !== 'stopped') {
      await recorderRef.current.stopRecording();
      setStatus(recorderRef.current?.recordRTC.getState());
    }

    const blob = await recorderRef.current.getBlob();
    onStop(blob);
    return blob;
  }, [onStop]);

  const recorderTimer = useRef();

  useEffect(() => {
    if (recordingTime === 0) {
      stop();
    }
  }, [stop, recordingTime]);

  const getMediaAndStart = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new RecordRTCPromisesHandler(stream, {
        recorderType: StereoAudioRecorder,
        type: 'audio',
        desiredSampRate: 16000,
        mimeType: 'audio/wav',
        numberOfAudioChannels: 1,
        disableLogs: true,
      });
      recorderRef.current = recorder;
      await recorderRef.current.startRecording();

      recorderTimer.current = setInterval(async () => {
        setRecordingTime((state) => state - 1);
      }, 1000);

      setStatus(recorderRef.current?.recordRTC.getState());
    } catch (error) {}
  }, []);

  const start = useCallback(() => {
    setRecordingTime(duration);
    getMediaAndStart();
  }, [getMediaAndStart, duration]);

  return {
    status,
    start,
    stop,
    recordingTime,
  };
}

export default useMockRecorder;
