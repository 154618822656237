import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/core';
import { mockColors } from '../../../../theme/theme';

function MockButton({
  label,
  onConfirm,
  isOpen,
  onOpen,
  onClose,
  title = 'Confirm Action',
  description = "Are you sure? You can't undo this action afterwards.",
  isSubmitting,
  isDisabled,
}) {
  const {
    isOpen: isOpenSelf,
    onOpen: onOpenSelf,
    onClose: onCloseSelf,
  } = useDisclosure();

  const cancelRef = useRef();

  return (
    <>
      <Button
        onClick={onOpen || onOpenSelf}
        size="xs"
        px={4}
        background={`linear-gradient(to top, ${mockColors.buttonOne}, ${mockColors.buttonTwo})`}
        color="white"
        minW="80px"
        isDisabled={isDisabled}
      >
        {label}
      </Button>

      <AlertDialog
        isOpen={isOpen || isOpenSelf}
        leastDestructiveRef={cancelRef}
        onClose={onClose || onCloseSelf}
        closeOnOverlayClick={!isSubmitting}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              isDisabled={isSubmitting}
              ref={cancelRef}
              onClick={onClose || onCloseSelf}
            >
              Cancel
            </Button>
            <Button
              variantColor="blueVariant"
              onClick={() => onConfirm(onClose || onCloseSelf)}
              ml={3}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default MockButton;
