import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/core';
import { useDispatch } from 'react-redux';
import { addQuestion } from '../../../../../store/modules/reading/questionSlice';
import AddEditForm from './Form';
import TitleBar from '../../../../../components/Question/TitleBar';
import { handleApiError } from '../../../../../helpers/handleApiError';
import useCustomToast from '../../../../../hooks/useCustomToast';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import { modules } from '../../../../../constants/modules';
import { paths } from './utils/paths';
import { reading } from '../../../../../constants/titles';

const customPaths = [
  ...paths,
  {
    name: 'Add Question',
  },
];

function FillBlanksDropdownAdd() {
  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const submit = useCallback(
    async (
      { description, additional, labels, priority, advice, duration },
      { reset }
    ) => {
      try {
        const data = {
          answer: description,
          additional,
          advice,
          duration,
        };

        await dispatch(
          addQuestion({
            description,
            labels,
            priority,
            data,
            module: modules.reading.FillInTheBlanksDrag,
          })
        );

        success({ title: 'Question is added' });
        reset();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, success]
  );

  return (
    <>
      <QuestionMetabar paths={customPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title={`New ${reading.fillBlanksDrag}`} />
        <AddEditForm onSubmit={submit} />
      </Box>
    </>
  );
}

export default FillBlanksDropdownAdd;
