import { useEffect, useState } from 'react';
import useQuestion from './useQuestion';
import { useDispatch } from 'react-redux';
import { getQuestion } from '../../../../../store/modules/speaking/questionSlice';

function useFetchQuestion(id, reFetch = true) {
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState();

  const { question } = useQuestion(id);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchQuestionIfNeeded() {
      setIsFetching(true);
      setError();
      try {
        await dispatch(getQuestion(id));
      } catch (error) {
        setError(error);
      }
      setIsFetching(false);
    }

    if (reFetch) {
      fetchQuestionIfNeeded();
    }
  }, [dispatch, id, reFetch]);

  return {
    isFetching,
    question,
    isNotEligible:
      error?.response?.status === 403 && error?.response?.data?.message,
  };
}

export default useFetchQuestion;
