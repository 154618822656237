import React, { useCallback, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/core';
import { handleApiError } from '../../../../helpers/handleApiError';
import useCustomToast from '../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../api/api';

function ResetMock({ id, onConfirm, ...props }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cancelRef = useRef();

  const { toast, success } = useCustomToast();

  const confirm = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await apiRequest('delete', `mocks/reset/${id}`);
      success({ title: 'Current mock has been reset!' });
      onClose();
      await onConfirm(id);
    } catch (error) {
      handleApiError(error, { toast });
      setIsSubmitting(false);
    }
  }, [onClose, id, onConfirm, toast, success]);

  return (
    <>
      <Button
        variantColor="redVariant"
        px={6}
        variant="outline"
        onClick={onOpen}
        isLoading={isSubmitting}
        {...props}
      >
        RESET
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Reset Mock!
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to reset your current mock? Resetting the mock
            will cost a limit from your overall mock limit.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variantColor="red"
              onClick={confirm}
              ml={3}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default ResetMock;
