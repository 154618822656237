import React from 'react';
import { Box, Alert, AlertIcon, Text } from '@chakra-ui/core';
import CustomProgress from '../ProgressBar/CustomProgress';

function RecorderView({
  isDenied,
  status,
  duration,
  reverseRecordingDuration,
}) {
  return (
    <Box>
      {isDenied && (
        <Alert status="error">
          <AlertIcon />
          Please allow your microphone to record audio.
        </Alert>
      )}
      <Box
        borderWidth="1px"
        borderColor="custom.blue6"
        mt={4}
        bg="custom.blue3"
        p="40px"
        w="480px"
        borderRadius="8px"
      >
        <Text textAlign="center" fontWeight={600} mb={2} fontSize="lg">
          Recorded Answer
        </Text>

        <Text>Current Status</Text>
        <Text fontWeight={600} textTransform="capitalize">
          {status}
        </Text>

        <CustomProgress
          duration={duration}
          current={reverseRecordingDuration}
        />
      </Box>
    </Box>
  );
}

export default RecorderView;
