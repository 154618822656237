import React from 'react';
import { get } from 'lodash-es';
import { UserAvatar } from '../Avatar/Avatar';
import {
  Box,
  Text,
  Divider,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Icon,
} from '@chakra-ui/core';
import { BsShieldLock } from 'react-icons/bs';
import { getDate, DATE_TIME_FULL } from '../../helpers/getDate';
import { Link } from 'react-router-dom';
import Authorize from '../Authorize/Authorize';

function CustomTooltip({ trigger, children }) {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent zIndex={4} width="auto">
        <PopoverArrow />
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function PackageDetails({ details }) {
  return (
    <Box textAlign="center">
      <Text>Package: {get(details, 'package.name')}</Text>
      <Text color="custom.gray5" fontSize={12}>
        Package Validity
      </Text>
      <Text color="custom.blue" fontSize={12}>
        {getDate(get(details, 'packageStartedAt'))} -{' '}
        {getDate(get(details, 'packageEndsOn'))}
      </Text>
      <Divider />
      <Text color="custom.gray5" fontSize={12}>
        Speaking Validity
      </Text>
      <Text color="custom.blue" fontSize={12}>
        {getDate(get(details, 'speakingStartedAt'))} -{' '}
        {getDate(get(details, 'speakingEndsOn'))}
      </Text>
    </Box>
  );
}

function UserCard({ user }) {
  return (
    <Box bg="white" boxShadow="custom.tertiary" overflow="hidden">
      <Flex justify="space-between" p={2}>
        <Flex align="center">
          <CustomTooltip
            trigger={
              <Box
                mr={1}
                cursor="pointer"
                w="12px"
                h="12px"
                borderRadius="50%"
                bg={get(user, 'isActive') ? 'custom.blue' : 'custom.red'}
              />
            }
          >
            {get(user, 'isActive') ? (
              <Text color="custom.gray5" fontSize={12}>
                This account is active
              </Text>
            ) : (
              <>
                <Text color="custom.gray5" fontSize={12} textAlign="center">
                  This account is deactivated{' '}
                  {getDate(get(user, 'deactivatedAt')) && 'since'}
                </Text>
                <Text color="custom.blue" fontSize={12} textAlign="center">
                  {getDate(get(user, 'deactivatedAt'), DATE_TIME_FULL)}
                </Text>
              </>
            )}
          </CustomTooltip>
          {!!get(user, 'is2Fa') && (
            <CustomTooltip
              trigger={
                <Box cursor="pointer" color="custom.blue">
                  <Box as={BsShieldLock} />
                </Box>
              }
            >
              <Text color="custom.gray5" fontSize={12}>
                This account has 2-FA enabled.
              </Text>
            </CustomTooltip>
          )}
        </Flex>
        <Box>
          <CustomTooltip
            trigger={
              <Flex
                cursor="pointer"
                fontSize={12}
                color="custom.blue"
                align="center"
              >
                <Icon name="info" mr={1} />
                <Text>Package</Text>
              </Flex>
            }
          >
            <PackageDetails details={get(user, 'currentPackage')} />
          </CustomTooltip>
        </Box>
      </Flex>
      <Flex px={2} pb={4} pt={2} direction="column" align="center">
        <UserAvatar size="64px" src={get(user, 'details.avatar')} />
        <Box textAlign="center" px={4} mt={4}>
          <Text>
            {get(user, 'details.firstName', 'N/A')}{' '}
            <Text color="custom.gray5" as="span" fontSize={12}>
              {get(user, 'details.gender') &&
                `(${get(user, 'details.gender', 'N/A')})`}
            </Text>
          </Text>
          <Text color="custom.gray5" fontSize={14}>
            Members since{' '}
            <Text as="strong">{getDate(get(user, 'createdAt'))}</Text>
          </Text>
          <Authorize permissions="users.canViewProfilePrivateData">
            <Divider />
            <Text color="custom.gray5" fontSize={14}>
              {get(user, 'email')}
            </Text>
            <Text color="custom.gray5" fontSize={14}>
              {get(user, 'details.countryCode', 'N/A')}
              {get(user, 'details.phone')}
            </Text>
          </Authorize>
          <Divider />
          <Text color="custom.gray5" fontSize={14}>
            {get(user, 'details.address.residence.country.name', 'N/A')}
          </Text>
          <Text color="custom.gray5" fontSize={14}>
            {get(user, 'details.address.residence.state.name', 'N/A')}
          </Text>
        </Box>
        <Button
          as={Link}
          to={`/dashboard/users/${get(user, 'id')}`}
          mt={6}
          size="xs"
          variant="outline"
          variantColor="blueVariant"
        >
          VIEW DETAILS
        </Button>
      </Flex>
    </Box>
  );
}

export default UserCard;
