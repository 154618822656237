import React, { memo } from 'react';
import { MenuItem } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

function MegaMenuLink({ link, label, exact }) {
  return (
    <MenuItem
      fontWeight="600"
      lineHeight="1.5"
      color="custom.blue"
      transition="all .3s"
      _focus={{
        backgroundColor: 'none',
        color: 'custom.orange2',
      }}
      _active={{
        backgroundColor: 'none',
        color: 'custom.orange2',
      }}
      fontSize="16px"
      p={0}
      mb="5px"
      as={Link}
      to={link}
      exact={exact}
    >
      {label}
    </MenuItem>
  );
}

export default memo(MegaMenuLink);
