import { useState, useEffect } from 'react';
import { apiRequest } from '../../api/api';
import { changeToCamelCase } from '../../helpers/changeCase';

export function usePackages() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await apiRequest('get', 'packages');

      setData(changeToCamelCase(data));
    }

    fetchData();
  }, []);

  return data;
}

export function usePackage(packageId) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { data } = await apiRequest('get', `packages/${packageId}`);

      setData(changeToCamelCase(data));
    }

    if (packageId) {
      fetchData();
    }
  }, [packageId]);

  return data;
}

export function useSubscriptionHistory(userId) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await apiRequest(
        'get',
        `packages/history/user/${userId}`
      );

      setData(changeToCamelCase(data));
    }

    fetchData();
  }, [userId]);

  return data;
}
