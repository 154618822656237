import { modules } from '../../../../../../constants/modules';

export const defaultQueryObject = {
  module: modules.writing.SummaryWrittenText,
};

export const defaultQueryWithSection = {
  module: modules.writing.SummaryWrittenText,
  section: 'writing',
};
