import React, { useCallback, useMemo } from 'react';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/core';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Form from '../../Form/Form';
import FormButton from '../../Form/Button';
import { handleApiError } from '../../../helpers/handleApiError';
import FormInput from '../../Form/Input';
import { useDispatch } from 'react-redux';
import { applyCoupon } from '../../../store/users/usersSlice';

function ApplyCoupon({ userId }) {
  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        coupon: Yup.string().required('Required.'),
      }),
    []
  );

  const form = useForm({ validationSchema });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const submit = useCallback(
    async ({ coupon }) => {
      try {
        const data = await dispatch(applyCoupon(userId, { coupon }));

        toast({
          position: 'top',
          title: data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        onClose();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [toast, onClose, dispatch, userId]
  );

  return (
    <>
      <Button variantColor="blueVariant" onClick={onOpen}>
        APPLY SUBSCRIPTION COUPON
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Apply Coupon</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form form={form} py={4} onSubmit={submit}>
              <FormInput name="coupon" placeholder="Coupon" />
              <FormButton>Submit</FormButton>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ApplyCoupon;
