import React, { useMemo } from 'react';
import { Box, Flex, Text, Stack, SimpleGrid } from '@chakra-ui/core';
import { get } from 'lodash-es';
import {
  ScoreHeader,
  ScoreFooter,
  ScoreDetails,
  ScoreBox,
  MockScoreHeader,
} from '../Common/CommonView';
import AudioPlayerControl from '../../../../AudioPlayer/AudioPlayerControl';
import PitchChart from '../Common/PitchChart';
import SpeechWords from '../Common/SpeechWords';

function RepeatSentenceScore({ score, practiseAgain, isMockReport }) {
  const enablingScores = useMemo(
    () => [
      get(score, 'details.fluencyScore'),
      get(score, 'details.pronunciationScore'),
      get(score, 'details.contentScore'),
      get(score, 'details.contentRecognitionScore'),
    ],
    [score]
  ).filter(Boolean);

  return (
    <Box mt={2} mb={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Flex
        mt={6}
        bg="custom.white6"
        py={3}
        px={4}
        borderRadius="5px"
        justify="space-between"
        fontWeight={600}
      >
        <ScoreBox
          score={get(score, 'details.fluencyScore.score')}
          label="Fluency"
        />
        <ScoreBox
          score={get(score, 'details.pronunciationScore.score')}
          label="Pronunciation"
        />
        <ScoreBox
          score={get(score, 'details.contentScore.score')}
          label="Content"
        />
        <ScoreBox
          score={get(score, 'details.contentRecognitionScore.score')}
          label="Content Recog."
        />
      </Flex>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={2}>
          QUESTION
        </Text>
        <AudioPlayerControl
          audioUrl={get(score, 'details.questionAudio')}
          waveHeight={64}
          volumeContainerProps={{ minW: '100px' }}
        />
      </Box>

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={2}>
          YOUR ANSWER: RECORDING
        </Text>

        <AudioPlayerControl
          audioUrl={get(score, 'details.recording')}
          waveHeight={64}
          volumeContainerProps={{ minW: '100px' }}
        />
      </Box>

      {!get(score, 'details.hasFailed') ? (
        <>
          <Box mt={10} id="answer-analysis">
            <Flex justify="space-between" align="center">
              <Text color="custom.black3" fontWeight={600} mb={2}>
                YOUR ANSWER - ANALYSIS
              </Text>
              <Flex justify="flex-end" fontSize="10px">
                <Stack isInline spacing={2}>
                  <Flex align="center">
                    <Box width="10px" height="10px" bg="custom.blue" mr={1} />
                    <Text color="custom.black3">Excellent/Native-like</Text>
                  </Flex>
                  <Flex align="center">
                    <Box width="10px" height="10px" bg="custom.yellow" mr={1} />
                    <Text color="custom.black3">Good/Intelligible</Text>
                  </Flex>
                  <Flex align="center">
                    <Box width="10px" height="10px" bg="custom.red" mr={1} />
                    <Text color="custom.black3">Fair/Poor</Text>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <SpeechWords
              words={get(score, 'details.wordsAnalysis')}
              audioUrl={get(score, 'details.recording')}
            />
          </Box>
          {!isMockReport && (
            <Box mt={6}>
              <Flex justify="space-between" align="center">
                <Text color="custom.black3" fontWeight={600}>
                  YOUR VOICE - ANALYSIS
                </Text>
                <Flex justify="flex-end" fontSize="10px">
                  <Stack isInline spacing={2}>
                    <Flex align="center">
                      <Text color="custom.black3">P. = Pause</Text>
                    </Flex>
                    <Flex align="center">
                      <Box
                        width="10px"
                        height="10px"
                        bg="custom.yellow"
                        mr={1}
                      />
                      <Text color="custom.black3">Flat Range</Text>
                    </Flex>
                    <Flex align="center">
                      <Box width="10px" height="10px" bg="custom.blue" mr={1} />
                      <Text color="custom.black3">
                        Suggested High/Low Range
                      </Text>
                    </Flex>
                    <Flex align="center">
                      <Box width="10px" height="10px" bg="custom.red" mr={1} />
                      <Text color="custom.black3">Your High/Low Range</Text>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
              <Text color="custom.black">
                {get(score, 'details.givenAnswer')}
              </Text>
              <Box pt={4}>
                <PitchChart pitch={get(score, 'details.pitch')} />
              </Box>
            </Box>
          )}
          <Box mt={8}>
            <Text color="custom.black3" fontWeight={600} mb={1}>
              SPEAKING SCORING TRAITS
            </Text>
            <Box as="table" width="100%" mt={2}>
              <Box as="thead" borderBottom="1px solid #eee">
                <Box as="tr" color="custom.black5" fontWeight={600}>
                  <Box as="td">{''}</Box>
                  <Box as="td" fontSize="14px" px={2}>
                    PTE Scoring
                  </Box>
                  <Box as="td" fontSize="14px" px={2}>
                    Feedback/Comment(s)
                  </Box>
                  <Box
                    as="td"
                    fontSize="14px"
                    textAlign="center"
                    minW="80px"
                    px={2}
                  >
                    Your Score
                  </Box>
                </Box>
              </Box>
              <Box as="tbody">
                {enablingScores.map(
                  ({ label, score, feedback, pteScoring }, index) => (
                    <Box as="tr" key={index} borderBottom="1px solid #eee">
                      <Box
                        as="td"
                        py={4}
                        px={2}
                        color="custom.blue"
                        fontWeight={600}
                      >
                        {label}
                      </Box>
                      <Box
                        as="td"
                        py={4}
                        px={2}
                        color="custom.black4"
                        fontSize="13px"
                      >
                        {pteScoring}
                      </Box>
                      <Box
                        as="td"
                        py={4}
                        px={2}
                        color="custom.black4"
                        fontSize="13px"
                      >
                        <Text dangerouslySetInnerHTML={{ __html: feedback }} />
                      </Box>
                      <Box
                        as="td"
                        py={4}
                        textAlign="center"
                        color="custom.blue"
                        fontWeight={600}
                      >
                        {score}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box mt={10} id="answer-analysis">
          <Text color="custom.black3" fontWeight={600} mb={2}>
            YOUR ANSWER - ANALYSIS
          </Text>
          <Text
            backgroundColor="custom.red"
            color="white"
            px={5}
            py={2}
            fontWeight={600}
            mb={2}
          >
            Your Content Recognition has failed.
          </Text>
        </Box>
      )}

      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        mt={8}
        isMockReport={isMockReport}
      />

      {!isMockReport && (
        <>
          {get(score, 'details.samples.audio') && (
            <Box mt={8}>
              <Text color="custom.black3" fontWeight={600} mb={1}>
                MODEL ANSWER (STUDENT)
              </Text>
              <SimpleGrid columns={1} spacing={2}>
                <Box mb={10}>
                  <Text color="custom.gray5">
                    {get(score, 'details.samples.gender')} - Recording
                  </Text>
                  <AudioPlayerControl
                    audioUrl={get(score, 'details.samples.audio')}
                    waveHeight={64}
                    volumeContainerProps={{ minW: '100px' }}
                  />
                </Box>
                <Box>
                  <PitchChart pitch={get(score, 'details.samples.pitch')} />
                </Box>
              </SimpleGrid>
            </Box>
          )}
          <ScoreFooter score={score} practiseAgain={practiseAgain} />
          <Flex justify="flex-end">
            <Text fontSize="xs" color="custom.gray5">
              {get(score, 'details.reference', '')}
            </Text>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default RepeatSentenceScore;
