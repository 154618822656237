import React, { useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Input, PseudoBox } from '@chakra-ui/core';
import { FiImage } from 'react-icons/fi';

function FileUpload({
  name,
  label,
  accept = 'image/*',
  icon = FiImage,
  ...props
}) {
  let fileUpload = null;

  const { register, unregister, setValue } = useFormContext();

  const onChange = useCallback(
    (event) => {
      if (!event.target.files.length) return;

      const file = [...event.target.files][0];
      file.preview = URL.createObjectURL(file);

      setValue(name, file);
    },
    [name, setValue]
  );

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  return (
    <Box {...props}>
      <PseudoBox
        as="button"
        bg="custom.blue3"
        py={2}
        px={4}
        rounded="md"
        fontWeight="regular"
        color="custom.blue4"
        borderRadius="30px"
        onClick={() => fileUpload.click()}
        type="button"
      >
        <Box as={icon} display="inline" mr={2} verticalAlign="middle" />
        {label}
      </PseudoBox>
      <Input
        type="file"
        accept={accept}
        onChange={onChange}
        ref={(ref) => (fileUpload = ref)}
        hidden
      />
    </Box>
  );
}

export default FileUpload;
