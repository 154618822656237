import React from 'react';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, Textarea, Box } from '@chakra-ui/core';
import ErrorMessage from './ErrorMessage';

const FormTextarea = ({
  name,
  id = name,
  placeholder,
  required = false,
  validate,
  label,
  resize = 'vertical',
  boxProps,
  ...props
}) => {
  const { register, errors } = useFormContext();

  return (
    <Box mb={6} width="100%" {...boxProps}>
      <FormControl
        isRequired={required}
        isDisabled={props.disabled}
        isInvalid={!!get(errors, name)}
      >
        <FormLabel
          htmlFor={id}
          display={label ? 'block' : 'none'}
          {...(props.labelProps && { ...props.labelProps })}
        >
          {label}
        </FormLabel>

        <Textarea
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          resize={resize}
          fontSize="14px"
          py={5}
          borderRadius="6px"
          borderColor="custom.gray"
          borderWidth="1px"
          errorBorderColor="custom.red"
          focusBorderColor="custom.blue2"
          boxShadow="none !important"
          ref={register({ required, validate })}
          data-gramm_editor="false"
          {...props}
        />

        <ErrorMessage name={name} />
      </FormControl>
    </Box>
  );
};

export default FormTextarea;
