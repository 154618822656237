import React from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/core';
import { UserAvatar } from '../../Avatar/Avatar';
import { get } from 'lodash-es';
import { getDate, DATE_TIME_FULL } from '../../../helpers/getDate';
import Score from './Score';
import Share from '../Share/Share';
import { getShareRoute } from '../../../helpers/getRoutes';

function ViewReport({ score, section, module }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        ml={6}
        variantColor="blueVariant"
        _hover={{
          borderColor: 'custom.blue',
          backgroundColor: 'custom.blue',
          color: 'custom.white1',
        }}
        variant="outline"
        size="sm"
        onClick={onOpen}
      >
        View Report
      </Button>
      <Modal
        size="3xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box p={4}>
              <Score score={score} section={section} module={module} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function ScoreItem({ score, module, section }) {
  const { details, user, createdAt } = score;

  return (
    <Flex
      bg="white"
      py={4}
      px={6}
      boxShadow="custom.primary"
      justify="space-between"
      mb={4}
      borderRadius={4}
    >
      <Flex align="center">
        <UserAvatar
          size="42px"
          src={get(user, 'avatar')}
          name={get(user, 'firstName')}
        />
        <Box ml={4}>
          <Text color="custom.black">{get(user, 'firstName')}</Text>
          <Text color="custom.gray5" fontSize="xs">
            {getDate(createdAt, DATE_TIME_FULL)}
          </Text>
        </Box>
      </Flex>
      <Flex align="center">
        <Flex align="center">
          <Text color="custom.gray5" fontWeight="600" mr={2}>
            OVERALL
          </Text>
          <Box
            borderRadius="4px"
            minWidth="42px"
            textAlign="center"
            fontSize="2xl"
            justify="center"
            align="center"
            py={1}
            px={2}
            bg="custom.blue"
            color="white"
          >
            {get(details, 'overallScore')}
          </Box>
        </Flex>
        <Share
          ml={6}
          shareLink={getShareRoute(section, module, get(score, 'id'))}
        />
        <ViewReport score={score} section={section} module={module} />
      </Flex>
    </Flex>
  );
}

function Scores({ scores, module, section }) {
  if (!scores.length) {
    return (
      <Text color="custom.gray5">
        No score yet. Submit your answer above to generate score.
      </Text>
    );
  }
  return scores.map((score) => (
    <ScoreItem key={score.id} score={score} section={section} module={module} />
  ));
}

export default Scores;
