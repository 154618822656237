import { apiRequest } from '../../../../api/api';
import { changeToCamelCase } from '../../../../helpers/changeCase';
import useSWR from 'swr';

const fetcher = (url, id) =>
  apiRequest('get', url + '/' + id).then((r) => changeToCamelCase(r.data));

// experimental
export function useDashboardData(id) {
  const { data } = useSWR(['users/settings/dashboard', id], fetcher);

  return [data, !data];
}
