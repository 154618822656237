import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthedUser } from '../store/user/hooks';

function PublicRoute({ children, ...rest }) {
  const { isAuthenticated } = useAuthedUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? children : <Redirect to="/dashboard" />
      }
    />
  );
}

export default PublicRoute;
