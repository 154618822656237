import React, { useMemo } from 'react';
import { Box, Flex, Text, Stack } from '@chakra-ui/core';
import {
  ScoreHeader,
  ScoreDetails,
  ScoreFooter,
  MockScoreHeader,
} from '../Common/CommonView';
import { get, map } from 'lodash-es';
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import VocabularyWord from '../Common/VocabularyWord';
import AudioPlayerControl from '../../../../AudioPlayer/AudioPlayerControl';

function HighlightIncorrectWords({ score, practiseAgain, isMockReport }) {
  const givenAnswers = useMemo(() => {
    if (score.details.givenAnswer.length) {
      return score.details.givenAnswer;
    }
    return [
      {
        answer: 'Not Answered',
      },
    ];
  }, [score.details.givenAnswer]);

  const correctAnswers = useMemo(() => score.details.actualAnswer, [
    score.details.actualAnswer,
  ]);

  return (
    <Box mt={2} mb={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          QUESTION
        </Text>

        <AudioPlayerControl audioUrl={get(score, 'details.audio')} />
        <Flex justify="flex-end" mt={4} fontSize="10px">
          <Stack isInline spacing={2}>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.blue" mr={1} />
              <Text color="custom.black3">Correct Words(s)</Text>
            </Flex>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.yellow" mr={1} />
              <Text color="custom.black3">Unselected Word(s)</Text>
            </Flex>
            <Flex align="center">
              <Box width="10px" height="10px" bg="custom.red" mr={1} />
              <Text color="custom.black3">Incorrect Words(s)</Text>
            </Flex>
          </Stack>
        </Flex>

        <Text
          mt={6}
          color="custom.black"
          dangerouslySetInnerHTML={{
            __html: get(score, 'details.description'),
          }}
        />
      </Box>

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          CORRECT ANSWER
        </Text>
        <Stack isInline spacing={2} flexWrap="wrap">
          {correctAnswers.map((answer, index) => (
            <Flex key={index} align="center" color="custom.blue">
              <Box as={MdCheckCircle} mr={1} mb="-2px" />
              <Text>{answer}</Text>
            </Flex>
          ))}
        </Stack>
      </Box>

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          YOUR ANSWER
        </Text>
        <Stack isInline spacing={2} flexWrap="wrap">
          {givenAnswers.map(({ answer, isCorrect }, index) => (
            <Flex
              key={index}
              align="center"
              color={isCorrect ? 'custom.blue' : 'custom.red'}
            >
              <Box as={isCorrect ? MdCheckCircle : MdCancel} mr={1} mb="-2px" />
              <Text
                dangerouslySetInnerHTML={{
                  __html: answer,
                }}
              />
            </Flex>
          ))}
        </Stack>
      </Box>

      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          SCORING
          <Text fontSize="12px" fontStyle="italic" color="custom.gray5">
            (Minimum score: 0)
          </Text>
        </Text>
        <Text
          backgroundColor="custom.white2"
          color="custom.black3"
          border="1px solid"
          borderColor="custom.white4"
          borderRadius="10px"
          py={3}
          px={4}
          mt={2}
        >
          Total Score:{' '}
          <Text as="strong">{get(score, 'details.actualScore')}</Text>; Correct
          Score: <Text as="strong">{get(score, 'details.correctScore')}</Text>;
          Incorrect Score:{' '}
          <Text as="strong">{get(score, 'details.incorrectScore')}</Text>; Your
          Score: <Text as="strong">{get(score, 'details.score')}</Text>;
        </Text>
      </Box>
      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        isMockReport={isMockReport}
      />
      {!isMockReport && (
        <>
          <Box
            mt={6}
            pt={6}
            mb={8}
            borderTop="1px solid"
            borderTopColor="custom.white4"
          >
            <Text
              mb={2}
              color="custom.black3"
              fontWeight={600}
              textTransform="uppercase"
            >
              Learn Vocabulary and Pronunciation
              <Text fontSize="12px" fontStyle="italic" color="custom.gray5">
                (Click on the word to learn)
              </Text>
            </Text>
            <Flex ml={1} mt={2} flexWrap="wrap">
              {map(get(score, 'details.vocabulary'), (vocabulary, index) => (
                <VocabularyWord key={index} vocabulary={vocabulary} />
              ))}
            </Flex>
          </Box>
          <ScoreFooter score={score} practiseAgain={practiseAgain} />
        </>
      )}
    </Box>
  );
}

export default HighlightIncorrectWords;
