import React, { useEffect, useMemo, useCallback } from 'react';
import { Box, SimpleGrid, Flex, Button, Stack } from '@chakra-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsers,
  setFilter,
  setPage,
  selectPaginatedUsers,
} from '../../../store/users/usersSlice';
import UserCard from '../../../components/User/UserCard';
import CustomBreadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import UserAdd from './UserAdd';
import UsersFilter from './UsersFilter';
import SortButton from './SortButton';
import { defaults, size, debounce } from 'lodash-es';
import SearchBar from '../../../components/Search/SearchBar';

const paths = [
  {
    name: 'Users',
  },
  {
    name: 'All Users',
  },
];

const defaultValues = {
  order: 'desc',
};

function UserList() {
  const dispatch = useDispatch();

  const { hasNextPage, pages, filterValues } = useSelector(
    (state) => state.users
  );

  const queryObject = useMemo(
    () => defaults({ ...filterValues }, defaultValues),
    [filterValues]
  );

  const setFilterValues = useCallback(
    (values) => {
      dispatch(setFilter(values));
    },
    [dispatch]
  );

  const setPagination = useCallback(
    (page) => {
      dispatch(setPage(page));
    },
    [dispatch]
  );

  const onSearch = debounce((value) => {
    setFilterValues({ search: value });
  }, 500);

  useEffect(() => {
    dispatch(fetchUsers(queryObject));
  }, [dispatch, queryObject]);

  const users = useSelector(selectPaginatedUsers);

  return (
    <Box maxWidth="1140px" margin="30px auto 0">
      <Flex mb={6} justify="space-between" align="center">
        <CustomBreadcrumb paths={paths} />
        <UserAdd />
      </Flex>
      <Flex mb={6} align="center">
        <Stack isInline spacing={4}>
          <UsersFilter values={filterValues} onSubmit={setFilterValues} />
          <SortButton
            state={queryObject.order}
            onClick={() =>
              setFilterValues({
                order: queryObject.order === 'desc' ? 'asc' : 'desc',
              })
            }
          />
        </Stack>
        <Box ml={4} flex={1}>
          <SearchBar
            m={0}
            placeholder="Search by name, email, phone number"
            onChange={(event) => onSearch(event.target.value)}
          />
        </Box>
      </Flex>

      <SimpleGrid columns={4} spacing={8}>
        {users.map((user) => (
          <UserCard key={user.id} user={user} />
        ))}
      </SimpleGrid>

      <Flex align="center" justify="center" mt={6} pb={6}>
        {hasNextPage && (
          <Button
            onClick={() => setPagination(size(pages) + 1)}
            px={10}
            variantColor="blueVariant"
            variant="outline"
          >
            LOAD MORE USERS
          </Button>
        )}
      </Flex>
    </Box>
  );
}

export default UserList;
