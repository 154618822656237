export const siteTitle = 'AIWAS Plus';

export const mcqSingle = 'Multiple Choice, Choose Single Answer';
export const mcqMultiple = 'Multiple Choice, Choose Multiple Answers';
export const reorder = 'Re-order Paragraphs';
export const fillBlanksDropdown = 'Fill in The Blanks (Drop-down)';
export const fillBlanksDrag = 'Fill in The Blanks (Drag & Drop)';

export const reading = {
  mcqSingle: 'Multiple Choice, Choose Single Answer',
  mcqMultiple: 'Multiple Choice, Choose Multiple Answers',
  reorder: 'Re-order Paragraphs',
  fillBlanksDropdown: 'Fill in The Blanks (Drop-down)',
  fillBlanksDrag: 'Fill in The Blanks (Drag & Drop)',
};

export const listening = {
  fillBlanks: 'Fill in The Blanks',
  mcqSingle: 'Multiple Choice, Choose Single Answer',
  missingWords: 'Select Missing Word',
  highlightCorrect: 'Highlight Correct Summary',
  mcqMultiple: 'Multiple Choice, Choose Multiple Answers',
  dictation: 'Write From Dictation',
  highlightIncorrect: 'Highlight Incorrect Words',
  summary: 'Summarize Spoken Text',
};

export const writing = {
  essay: 'Essay',
  summary: 'Summarize Written Text',
};

export const speaking = {
  readAloud: 'Read Aloud',
  repeatSentence: 'Repeat Sentence',
  describeImage: 'Describe Image',
  retellLecture: 'Re-tell Lecture',
  answerShortQuestion: 'Answer Short Question',
};
