import { routesMapper } from '../../../../../../Routes/routesMapper';
import { reorder } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Reading',
  },
  {
    name: reorder,
    path: routesMapper.reading.ReorderParagraph.PRACTISE,
  },
];
