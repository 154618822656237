import { Box, Heading, Image, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import { ActionButtons, NextTips, StepLayout } from '../../Components/Layout';

function OptionalBreakStep({ onAfterSubmit }) {
  return (
    <StepLayout nextShortcutEnabled>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Stack spacing={4} my={10} px={6} overflowY="auto">
            <Heading size="md">Optional Break</Heading>
            <Text>
              You may now take a break of up to 10 minutes. If you want to take
              a break you{' '}
              <Text as="strong" color="#FF0000">
                must
              </Text>{' '}
              leave the testing room.{' '}
              <Text as="strong" color="#FF0000">
                You should allow up to 5 minutes to sign back and be seated.
              </Text>{' '}
              If you spend more than 10 minutes in total the break, that time is
              taken from the next part.
            </Text>

            <Box as="ul" ml={8} my={4}>
              <li>
                If you want to take a break raise your hand to get the attention
                of the Test Administrator
              </li>
              <li>
                If you do{' '}
                <Text as="strong" color="#FF0000">
                  not
                </Text>{' '}
                want to take a break click on the Next (N) button below to
                continue the test
              </li>
            </Box>
            <Image
              mt={8}
              width={300}
              src="http://via.placeholder.com/300x200"
            />
          </Stack>
          <Box>
            <NextTips />
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              canEndExam={false}
              onConfirmNext={(onCallback) => {
                onCallback();
                onAfterSubmit();
              }}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default OptionalBreakStep;
