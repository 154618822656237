import { Box, Heading } from '@chakra-ui/core';
import { capitalize, get } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { mockTypes } from '../../../../constants/mockTypes';
import { titlesByModule } from '../../../../constants/modules';
import { handleApiError } from '../../../../helpers/handleApiError';
import useCustomToast from '../../../../hooks/useCustomToast';
import { getLabels } from '../../../../store/label/ducks';
import {
  getMockQuestions,
  submitMockAdd,
} from '../../../../store/mocks/mocksSlice';
import { getDataMappersByType } from '../Utils/getMockDataMappers';
import MockForm from './MockForm';

function MocksAddByTypePage() {
  const [resetKey, setResetKey] = useState(1);

  const { type } = useParams();

  const dispatch = useDispatch();

  const mocks = useSelector((state) => state.mocks);

  const dataMappers = useMemo(
    () => getDataMappersByType({ type, questions: mocks.mockQuestions }),
    [type, mocks]
  );

  const { error } = useCustomToast();

  const { success, toast } = useCustomToast();
  const handleSubmit = useCallback(
    async ({ title, priority, selectedQuestions, labels, version }) => {
      if (!title?.trim()) {
        error({ title: 'Please Select Title.' });
        return;
      }
      if (!priority) {
        error({ title: 'Please Select Priority.' });
        return;
      }

      if (!labels.length) {
        error({ title: 'Please Select Labels.' });
        return;
      }

      let emptyModule;

      parentLoop: for (let key in selectedQuestions) {
        for (let keyByModule in selectedQuestions[key]) {
          if (!selectedQuestions[key][keyByModule].length) {
            emptyModule = [key, keyByModule];
            break parentLoop;
          }
        }
      }

      if (emptyModule) {
        const [section] = emptyModule;
        error({
          title: 'You have to select questions from all required modules.',
          description: `Hint: You have missed ${capitalize(section)}: ${get(
            titlesByModule,
            emptyModule.join('.')
          )}`,
        });
        return;
      }

      try {
        await dispatch(
          submitMockAdd({
            title,
            type,
            labels: labels.map((label) => label.value),
            priority: priority.value,
            questions: selectedQuestions,
            version: version.value,
          })
        );

        success({ title: 'Mock added successfully.' });

        setResetKey((state) => state + 1);
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [error, toast, dispatch, success, type]
  );

  useEffect(() => {
    if (!mocks.mockQuestions.loaded) {
      dispatch(getMockQuestions());
    }
    dispatch(getLabels());
  }, [dispatch, mocks]);

  if (!mockTypes[type]) return null;

  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        ADD {mockTypes[type].toUpperCase()} MOCK
      </Heading>
      <Box borderRadius="6px" bg="white" p={10} boxShadow="custom.primary">
        {mocks.mockQuestions.isFetching ? (
          <Loader />
        ) : (
          <MockForm
            key={resetKey}
            type={type}
            dataMappers={dataMappers}
            onSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
}

export default MocksAddByTypePage;
