import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import Form from '../../../../../../components/Form/Form';
import MockAudioPlayerBare from '../../../Components/Audio/Player';
import FormRadio from '../../../../../../components/Form/Radio';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

function MockQuestionListeningHighlightCorrectView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAudioPlayingEnded, setIsAudioPlayingEnded] = useState(false);

  const form = useForm();

  const { error } = useCustomToast();
  const onSubmit = useCallback(
    async ({ answer }) => {
      try {
        setIsSubmitting(true);
        setTimerDelay(null);

        await apiRequest('post', 'mocks/autoSave', {
          answer,
          type: mock.type,
          section: sections.listening,
          question_id: data.id,
          module: data.module,
          index,
        });

        setTimerDelay(1000);

        onAfterSubmit();
      } catch {
        error({
          title: 'Looks like something went wrong!',
          description:
            'Please check your network connection and try reloading.',
        });
        setIsSubmitting(false);
        setTimerDelay(1000);
      }
    },
    [onAfterSubmit, error, mock, index, data, setTimerDelay]
  );

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box py={10} px={6}>
            <Heading size="sm" mb={8}>
              You will hear a recording. Click on the paragraph that best
              relates to the recording.
            </Heading>
            <Flex justify="center" my={6}>
              <MockAudioPlayerBare
                beginningDuration={10}
                url={get(data, 'audio')}
                onEndCallback={() => setIsAudioPlayingEnded(true)}
              />
            </Flex>
            <Form form={form}>
              {get(data, 'multiple_choice', []).map((option, index) => (
                <Flex
                  align="center"
                  py={2}
                  px={1}
                  mb={2}
                  key={index}
                  bg={form.watch('answer') === option ? '#FAFFD2' : ''}
                >
                  <FormRadio
                    key={index}
                    id={index}
                    value={option}
                    name="answer"
                    label={option}
                  />
                </Flex>
              ))}
            </Form>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={() => form.handleSubmit(onSubmit)()}
              isSubmitting={isSubmitting}
              isNextButtonDisabled={!isAudioPlayingEnded}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionListeningHighlightCorrectView;
