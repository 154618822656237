import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { formatNumber } from '../../../helpers/number';
import { addDate } from '../../../helpers/getDate';

function Details({ data }) {
  return (
    <Box>
      <Stack
        spacing="45px"
        isInline
        py={4}
        mb={10}
        borderTopWidth="1px"
        borderBottomWidth="1px"
        fontSize="md"
      >
        <Box>
          <Text
            mb={1}
            color="custom.blue"
            fontWeight={600}
            textTransform="uppercase"
          >
            Name
          </Text>
          <Text color="custom.black3">{get(data, 'name')}</Text>
        </Box>
        {get(data, 'validity') && (
          <Box>
            <Text
              mb={1}
              color="custom.blue"
              fontWeight={600}
              textTransform="uppercase"
            >
              Validity
            </Text>
            <Text color="custom.black3">{addDate(get(data, 'validity'))}</Text>
          </Box>
        )}
        {get(data, 'speaking.validity') && (
          <Box>
            <Text
              mb={1}
              color="custom.blue"
              fontWeight={600}
              textTransform="uppercase"
            >
              Speaking Validity
            </Text>
            <Text color="custom.black3">
              {addDate(get(data, 'speaking.validity'))}
            </Text>
          </Box>
        )}
        {get(data, 'prediction.validity') && (
          <Box>
            <Text
              mb={1}
              color="custom.blue"
              fontWeight={600}
              textTransform="uppercase"
            >
              Prediction Validity
            </Text>
            <Text color="custom.black3">
              {addDate(get(data, 'prediction.validity'))}
            </Text>
          </Box>
        )}
        {get(data, 'fullMocks') && (
          <Box>
            <Text mb={1} color="custom.blue" fontWeight={600}>
              FULL MOCKS
            </Text>
            <Text color="custom.black3">{get(data, 'fullMocks')}</Text>
          </Box>
        )}
        {get(data, 'customMocks') && (
          <Box>
            <Text mb={1} color="custom.blue" fontWeight={600}>
              CUSTOM MOCKS
            </Text>
            <Text color="custom.black3">{get(data, 'customMocks')}</Text>
          </Box>
        )}
        <Box>
          <Text mb={1} color="custom.blue" fontWeight={600}>
            TOTAL
          </Text>
          {get(data, 'discountDetails.amount') ? (
            <Text color="custom.green">
              {formatNumber(get(data, 'discountDetails.amount'))}
            </Text>
          ) : get(data, 'targetPrice') ? (
            <Stack isInline>
              <Text color="custom.green">
                {formatNumber(get(data, 'sellingPrice'))}
              </Text>
              <Text color="custom.black4" textDecoration="line-through">
                {formatNumber(get(data, 'targetPrice'))}
              </Text>
            </Stack>
          ) : (
            <Text color="custom.green">
              {formatNumber(get(data, 'sellingPrice'))}
            </Text>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export default Details;
