import { theme } from '@chakra-ui/core';

export const customColors = {
  yellow: '#F4BB4E',
  yellow2: '#C29B50',
  yellow3: '#FFF2D9',
  blue: '#3680A1',
  blue2: '#497F9E',
  blue3: '#DCE7EC',
  blue4: '#437490',
  blue5: '#87BFD8',
  blue6: '#F2F6F8',
  blue7: '#085988',
  blue8: '#0F3154',
  gray: '#DCDDE1',
  gray2: '#A0A4A8',
  gray3: '#BAD0DB',
  gray4: '#A7AFB3',
  gray5: '#9BA6AA',
  red: '#EB5555',
  red2: '#FB4E4E',
  orange: '#E16943',
  orange2: '#FFA26B',
  black: '#6a6a6a',
  black2: '#276682',
  black3: '#4E4F51',
  black4: '#80838C',
  black5: '#646568',
  black6: '#626B6F',
  white1: '#F8FAFB',
  white2: '#FDFEFF',
  white3: '#E5E5E5',
  white4: '#E1E6E9',
  white5: '#dddddd',
  white6: '#F3F5F6',
  green: '#00C48C',
  green2: '#EBF372',
};

export const mockColors = {
  gray: '#C6C3C4',
  buttonOne: '#143A51',
  buttonTwo: '#3896CB',
  blue: '#EAF1FB',
  yellow: '#F6DC28',
};

export const customShadows = {
  primary: '0px -2px 28px rgba(0, 0, 0, 0.07)',
  secondary: '0px 2px 36px rgba(0, 0, 0, 0.04)',
  tertiary: '2px 2px 12px rgba(0, 0, 0, 0.1) !important',
};

const customTheme = {
  ...theme,
  shadows: {
    ...theme.shadows,
    custom: {
      ...customShadows,
    },
  },
  colors: {
    ...theme.colors,
    custom: {
      ...customColors,
    },
    mock: {
      ...mockColors,
    },
    blueVariant: {
      ...theme.colors.blue,
      500: customColors.blue,
    },
    greenVariant: {
      ...theme.colors.green,
      500: customColors.green,
    },
    orangeVariant: {
      ...theme.colors.orange2,
      500: customColors.orange2,
    },
    redVariant: {
      ...theme.colors.red,
      500: customColors.red,
    },
    yellow: {
      50: '#fffff0',
      100: '#fefcbf',
      200: '#faf089',
      300: '#f6e05e',
      400: '#ecc94b',
      500: customColors.yellow,
      600: '#b7791f',
      700: '#975a16',
      800: '#744210',
      900: '#5F370E',
    },
  },
};

export default customTheme;
