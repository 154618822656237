import React, { useMemo } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Box,
  Flex,
  Text,
  Icon,
  Stack,
} from '@chakra-ui/core';
import { get, find, take } from 'lodash-es';
import { FiVolume2 } from 'react-icons/fi';
import useAudio from '../../../../../hooks/useAudio';

function PlayAudio({ url, label }) {
  const [toggle] = useAudio(url);

  return (
    <Stack
      ml={6}
      isInline
      spacing={1}
      cursor="pointer"
      onClick={toggle}
      color="custom.black4"
      alignItems="center"
    >
      <Box as={FiVolume2} />
      <Text>{label}</Text>
    </Stack>
  );
}

function VocabularyDetails({ vocabulary }) {
  const usPronounciationWithAudioFile = useMemo(
    () => find(get(vocabulary, 'pronunciations.enUs', []), 'audioFile'),
    [vocabulary]
  );

  const gbPronounciationWithAudioFile = useMemo(
    () => find(get(vocabulary, 'pronunciations.enGb', []), 'audioFile'),
    [vocabulary]
  );

  return (
    <Flex p={6} direction="column">
      <Flex align="baseline">
        <Box>
          <Text fontSize="32px" lineHeight={1} mr={2} color="custom.black3">
            {get(vocabulary, 'words')}
          </Text>
          <Text fontStyle="italic" color="custom.black4" fontSize="xs">
            {get(vocabulary, 'pronunciations.enGb.0.phoneticSpelling')}
          </Text>
        </Box>
        <Text fontStyle="italic" color="custom.black4">
          {get(vocabulary, 'definitions.enGb.0.lexicalCategory.text')}
        </Text>
        <PlayAudio
          label="British"
          url={gbPronounciationWithAudioFile.audioFile}
        />
        <PlayAudio
          label="American"
          url={usPronounciationWithAudioFile.audioFile}
        />
      </Flex>
      <Box mt={4} ml={2} fontSize="sm">
        {take(get(vocabulary, 'synonyms', []), 4).map(
          ({ example, synonyms, definition }, index) => (
            <Flex key={index} align="flex-start" mb={2}>
              <Icon name="arrow-forward" mr={4} mt={1} />
              <Box>
                <Text mb={1} color="custom.black3">
                  {definition}
                </Text>
                <Text color="custom.black4">
                  {Array.isArray(example)
                    ? get(example, '0')
                    : 'Example(s): ' + example}
                </Text>
                {!Array.isArray(synonyms) && (
                  <Text color="custom.black4" fontSize="14px">
                    Synonym(s): {synonyms}
                  </Text>
                )}
              </Box>
            </Flex>
          )
        )}
      </Box>
    </Flex>
  );
}

function VocabularyWord({ vocabulary }) {
  return (
    <>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Button
            variantColor="blueVariant"
            variant="link"
            leftIcon="info"
            mr={4}
            mb={2}
          >
            <Text mt="-2px">{get(vocabulary, 'words')}</Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          maxWidth="600px"
          boxShadow="custom.primary"
          borderRadius="8px"
        >
          <VocabularyDetails vocabulary={vocabulary} />
        </PopoverContent>
      </Popover>
    </>
  );
}

export default VocabularyWord;
