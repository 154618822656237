import { useState, useEffect, useCallback, useMemo } from 'react';
import useRecorder from '../../hooks/useRecorder';
import { useInterval } from '../../hooks/useTimer';
import useAudio from '../../hooks/useAudio';
import beep from '../../assets/beep.mp3';

function Recorder({
  duration,
  initialBeginningDuration = duration,
  children,
  toggleBeginning = true,
  hasBeep = false,
}) {
  const {
    status: recorderStatus,
    isDenied,
    start,
    stop,
    isStoppedDueToSilence,
    recordingTime,
    isGettingBlob,
  } = useRecorder(duration);

  const [beginningDuration, setBeginningDuration] = useState(
    initialBeginningDuration
  );

  const [isRunningBeginning, setIsRunningBeginning] = useState(toggleBeginning);

  useEffect(() => {
    setIsRunningBeginning(toggleBeginning);
    setBeginningDuration(initialBeginningDuration);
  }, [toggleBeginning, initialBeginningDuration]);

  const [playBeep] = useAudio(beep);

  const startRecording = useCallback(() => {
    setIsRunningBeginning(false);
    if (hasBeep) {
      playBeep();
    }
    start();
  }, [start, hasBeep, playBeep]);

  const reverseRecordingDuration = useMemo(() => duration - recordingTime, [
    duration,
    recordingTime,
  ]);

  const stopRecording = useCallback(
    async (callBack = () => null) => {
      console.log('Recorder log: calling stop recorder');
      let blob = await stop();

      console.log('Recorder log: blob', blob);
      callBack(blob, reverseRecordingDuration);
    },
    [stop, reverseRecordingDuration]
  );

  useInterval(
    () => {
      if (beginningDuration <= 0) {
        startRecording();
        return;
      }

      setBeginningDuration(beginningDuration - 1);
    },
    isRunningBeginning && toggleBeginning ? 1000 : null
  );

  const getStatus = useCallback(() => {
    if (isStoppedDueToSilence && recorderStatus === 'stopped') {
      return 'stopped due to inactivity';
    }

    if (recorderStatus === 'recording') {
      return 'recording';
    }

    if (recorderStatus === 'stopped') {
      return 'completed';
    }
  }, [recorderStatus, isStoppedDueToSilence]);

  return children({
    isDenied,
    status: getStatus(),
    reverseRecordingDuration,
    beginningDuration,
    duration,
    startRecording,
    stopRecording,
    isGettingBlob,
    recorderStatus,
  });
}

export default Recorder;
