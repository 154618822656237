import React from 'react';
import { Box, Flex, Text, Badge, Stack } from '@chakra-ui/core';

const mapStatusColors = {
  'In Progress': 'orangeVariant',
  Completed: 'greenVariant',
  Optional: 'blueVariant',
};

function StudyGuideItem({ step, text, status, practiced, total, ...props }) {
  return (
    <Box {...props} opacity={status === 'Completed' ? 0.8 : 1}>
      <Flex align="center" mb={1}>
        <Text fontSize="lg" mr={2} color="custom.gray2" fontWeight={600}>
          {step}
        </Text>
        <Badge
          variantColor={mapStatusColors[status]}
          variant="outline"
          borderRadius="5px"
        >
          {status}
        </Badge>
      </Flex>
      <Text
        mb={1}
        color="custom.gray2"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {practiced !== undefined && total !== undefined && (
        <>
          <Text mb={2} color="custom.gray2" fontWeight={600}>
            {practiced}/{total} Completed
          </Text>
        </>
      )}
    </Box>
  );
}

function StudyGuide({ studyGuide = [] }) {
  return (
    <Box>
      <Flex align="center">
        <Text
          mr={2}
          color="custom.blue"
          textTransform="uppercase"
          fontWeight={600}
        >
          AI Study Guide
        </Text>
        <Badge borderRadius="5px" variant="outline" variantColor="redVariant">
          AI
        </Badge>
      </Flex>
      <Text color="custom.gray2">
        Generated based on level and time left for the next exam. Complete all
        steps one by one.
      </Text>
      <Stack spacing={4} mt={6}>
        {studyGuide.map((item) => (
          <StudyGuideItem key={item.step} {...item} />
        ))}
      </Stack>
    </Box>
  );
}

export default StudyGuide;
