import { combineReducers } from 'redux';
import questions from './questionSlice';
import comments from './commentSlice';

const speakingReducer = combineReducers({
  questions,
  comments,
});

export default speakingReducer;
