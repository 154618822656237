import React from 'react';
import { Avatar } from '@chakra-ui/core';
import { useCurrentUser } from '../../store/user/hooks';

export function UserAvatar({ name = 'AIWA', size, src }) {
  return (
    <Avatar
      bg="blue.200"
      width={size}
      height={size}
      name={name}
      {...(src && { src })}
    />
  );
}

function CustomAvatar({ src, size = '60px' }) {
  const user = useCurrentUser();
  return (
    <UserAvatar
      bg="blue.200"
      size={size}
      name={user?.email || 'A'}
      {...(src && { src })}
    />
  );
}

export default CustomAvatar;
