import { useCallback, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import Recorder from '../../../../../../components/Recorder/Recorder';
import MockRecorderView from '../../../Components/Audio/Recorder';
import { sum } from 'lodash-es';
import { speakingDuration } from '../../../../../../constants/speakingDuration';
import MockAudioPlayerBare from '../../../Components/Audio/Player';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { sections } from '../../../../../../constants/sections';
import { apiRequest } from '../../../../../../api/api';

function MockQuestionSpeakingAnswerShortView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error } = useCustomToast();
  const onSubmit = useCallback(
    async (blob) => {
      try {
        setIsSubmitting(true);
        setTimerDelay(null);
        const formData = new FormData();

        formData.append('answer', blob);
        formData.append('type', mock.type);
        formData.append('section', sections.speaking);
        formData.append('question_id', data.id);
        formData.append('module', data.module);
        formData.append('index', index);

        await apiRequest('post', 'mocks/autoSave', formData);

        setTimerDelay(1000);

        onAfterSubmit();
      } catch {
        error({
          title: 'Looks like something went wrong!',
          description:
            'Please check your network connection and try reloading.',
        });
        setIsSubmitting(false);
        setTimerDelay(1000);
      }
    },
    [onAfterSubmit, error, mock, index, data, setTimerDelay]
  );

  const [toggleBeginning, setToggleBeginning] = useState(false);
  const [initialBeginningDuration, setInitialBeginningDuration] = useState();

  const onLoadedCallback = useCallback((audioDuration) => {
    setToggleBeginning(true);
    setInitialBeginningDuration(
      Math.ceil(
        audioDuration +
          sum(Object.values(speakingDuration.answerShortQuestion.beginning))
      )
    );
  }, []);

  return (
    <StepLayout>
      {({ isOpen, onOpen, onClose }) => (
        <Recorder
          initialBeginningDuration={initialBeginningDuration}
          duration={get(data, 'duration')}
          toggleBeginning={toggleBeginning}
        >
          {({
            duration,
            isDenied,
            status,
            beginningDuration,
            reverseRecordingDuration,
            stopRecording,
            isGettingBlob,
          }) => (
            <>
              <Box py={10} px={6}>
                <Heading size="sm" mb={8}>
                  You will hear a sentence. Please give a simple and short
                  answer. Often just one or a few words is enough.
                </Heading>
                <Box mt={6} px={20}>
                  <Flex justify="center" my={6}>
                    <MockAudioPlayerBare
                      url={get(data, 'audio_question')}
                      onLoadedCallback={onLoadedCallback}
                      beginningDuration={
                        speakingDuration.answerShortQuestion.beginning.before
                      }
                    />
                  </Flex>
                  <Flex justify="center">
                    <MockRecorderView
                      isDenied={isDenied}
                      status={
                        status ||
                        (!toggleBeginning && 'loading') ||
                        `beginning in ${beginningDuration} seconds.`
                      }
                      duration={duration}
                      reverseRecordingDuration={reverseRecordingDuration}
                    />
                  </Flex>
                </Box>
              </Box>
              <Box>
                <ActionButtons
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                  onConfirmNext={() => stopRecording(onSubmit)}
                  isSubmitting={isSubmitting || isGettingBlob}
                  isNextButtonDisabled={!status}
                />
              </Box>
            </>
          )}
        </Recorder>
      )}
    </StepLayout>
  );
}

export default MockQuestionSpeakingAnswerShortView;
