import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import Login from '../pages/auth/Login';
import Signup from '../pages/auth/Signup/Signup';
import Dashboard from '../pages/Dashboard/Dashboard';
import PrivateRoute from '../Routes/PrivateRoute';
import ForgetPassword from '../pages/auth/ForgetPassword/ForgetPassword';
import ResetPassword from '../pages/auth/ForgetPassword/ResetPassword';
import { useDispatch } from 'react-redux';
import { getCountryList } from '../store/global/action';
import { getUser } from '../store/user/action';
import FullLoader from '../components/Loader/FullLoader';
import { getItem } from '../helpers/localStorage';
import ActivateAccount from '../pages/auth/ActivateAccount';
import { fetchNofications } from '../store/nofitication/notificationSlice';
import { useAuthedUser } from '../store/user/hooks';
import { initPusher } from '../services/pusher';
import AppContext from './AppContext';
import MfaPage from '../pages/auth/Mfa';
import PublicRoute from './PublicRoute';
import Preview from '../pages/Preview/Preview';

function Routes() {
  const [isPreview, setIsPreview] = useState(false);

  const [isTopBarShown, setIsTopBarShown] = useState(true);

  const [loading, setLoading] = useState(true);

  const [channels, setChannels] = useState();

  const dispatch = useDispatch();

  const { isAuthenticated, data } = useAuthedUser();

  useEffect(() => {
    async function fetchInitialData() {
      const toFetch = [dispatch(getCountryList())];

      if (getItem('token')) {
        toFetch.push(dispatch(getUser()));
      }

      await Promise.all(toFetch);
      setLoading(false);
    }

    fetchInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchNofications());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const channels = initPusher(data.id);
      setChannels(channels);
    }
  }, [isAuthenticated, data]);

  if (loading) {
    return <FullLoader />;
  }

  return (
    <AppContext.Provider
      value={{
        ...channels,
        setIsTopBarShown,
        isTopBarShown,
        setIsPreview,
        isPreview,
      }}
    >
      <Router>
        <Switch>
          <PublicRoute path="/login">
            <Login />
          </PublicRoute>
          <PublicRoute path="/signup">
            <Signup />
          </PublicRoute>
          <PublicRoute path="/forget-password">
            <ForgetPassword />
          </PublicRoute>
          <PublicRoute path="/reset-password/:token">
            <ResetPassword />
          </PublicRoute>
          <PublicRoute path="/activate/:token">
            <ActivateAccount />
          </PublicRoute>
          <PublicRoute path="/mfa/:token">
            <MfaPage />
          </PublicRoute>
          <PublicRoute path="/preview">
            <Preview />
          </PublicRoute>
          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <Redirect to="/login" />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default Routes;
