import { useState, useEffect, useRef } from 'react';

export function useTimer(id, id2) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(0);
  }, [id, id2]);

  useEffect(() => {
    const timer = setInterval(() => setCounter((counter) => counter + 1), 1000);
    return () => clearInterval(timer);
  }, [id, id2]);

  return { counter };
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
