import { useState, useEffect } from 'react';
import { apiRequest } from '../../api/api';

export function useInstitutions() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await apiRequest('get', 'institutions');
        setData(data);
      } catch {}
    }

    fetchData();
  }, []);

  return data;
}
