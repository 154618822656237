import React, { memo, useState, useEffect } from 'react';

import {
  Tabs,
  Badge,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from '@chakra-ui/core';
import Comments from './Comments/Comments';
import Scores from './Scores/Scores';
import Histories from './Histories/Histories';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { useAuthorization } from '../../hooks/useAuthorization';

function Community({
  question,
  comments,
  module,
  section,
  tabIndex = 0,
  addQuestionComment,
  editQuestionComment,
  deleteQuestionComment,
}) {
  const [index, setIndex] = useState(tabIndex);

  useEffect(() => {
    setIndex(tabIndex);
  }, [tabIndex]);

  const handleTabsChange = (index) => {
    setIndex(index);
  };

  const histories = useMemo(() => get(question, 'history', []), [question]);
  const scores = useMemo(() => get(question, 'scores', []), [question]);
  const questionId = useMemo(() => get(question, 'id'), [question]);

  const canViewHistory = useAuthorization({
    permissions: 'questions.canViewHistory',
  });

  return (
    <Box mt={10}>
      <Tabs mt={2} size="md" index={index} onChange={handleTabsChange}>
        <TabList>
          <Tab
            p={0}
            pb={2}
            mr={4}
            color="custom.gray5"
            _selected={{
              color: 'custom.blue2',
              borderColor: 'custom.blue2',
            }}
            fontWeight={600}
            _focus={{ outline: 'none' }}
            _active={{ backgroundColor: 'transparent' }}
          >
            My Score
          </Tab>
          <Tab
            p={0}
            pb={2}
            mr={4}
            color="custom.gray5"
            _selected={{
              color: 'custom.blue2',
              borderColor: 'custom.blue2',
            }}
            fontWeight={600}
            _focus={{ outline: 'none' }}
            _active={{ backgroundColor: 'transparent' }}
          >
            Comments
          </Tab>
          {canViewHistory && (
            <Tab
              p={0}
              pb={2}
              mr={4}
              color="custom.gray5"
              _selected={{
                color: 'custom.blue2',
                borderColor: 'custom.blue2',
              }}
              fontWeight={600}
              _focus={{ outline: 'none' }}
              _active={{ backgroundColor: 'transparent' }}
            >
              History
            </Tab>
          )}
        </TabList>

        <TabPanels py={6}>
          <TabPanel>
            <Badge
              mb={3}
              bg="custom.white3"
              px={2}
              fontSize="xs"
              color="custom.gray2"
              borderRadius={2}
            >
              Please note, you will only be able to see the last 90 days reports
              here. Keep a backup if needed
            </Badge>

            <Scores scores={scores} module={module} section={section} />
          </TabPanel>
          <TabPanel>
            <Comments
              questionId={questionId}
              comments={comments}
              addQuestionComment={addQuestionComment}
              editQuestionComment={editQuestionComment}
              deleteQuestionComment={deleteQuestionComment}
            />
          </TabPanel>
          <TabPanel>
            <Histories histories={histories} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default memo(Community);
