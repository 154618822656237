import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

function BlurWrapper({ blur = 4, children, ...rest }) {
  return (
    <PseudoBox
      style={{ filter: `blur(${blur}px)` }}
      width="100%"
      pointerEvents="none"
      {...rest}
    >
      {children}
    </PseudoBox>
  );
}

export default BlurWrapper;
