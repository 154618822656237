import { useSelector } from 'react-redux';

function useQuestion(id) {
  const { byId } = useSelector((state) => state.reading.questions);

  return {
    question: byId[id],
  };
}

export default useQuestion;
