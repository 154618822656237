import { modules } from '../../../../../../constants/modules';

export const defaultQueryObject = {
  module: modules.listening.SummarySpokenText,
};

export const defaultQueryWithSection = {
  module: modules.listening.SummarySpokenText,
  section: 'listening',
};
