import { customColors } from '../../../../theme/theme';

export const getCustomSelectStyles = () => ({
  control: (provided) => ({
    ...provided,
    padding: 2,
    fontSize: '14px',
    borderColor: customColors.gray,
  }),
});

export const getCustomSelectTheme = (theme) => ({
  ...theme,
  borderRadius: 4,
  spacing: {
    baseUnit: 1.5,
    controlHeight: 1,
    menuGutter: 1,
  },
  colors: {
    ...theme.colors,
  },
});
