import React, { useCallback, useState } from 'react';
import { FaHashtag } from 'react-icons/fa';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Text,
  useToast,
} from '@chakra-ui/core';
import { handleApiError } from '../../helpers/handleApiError';
import { useDispatch } from 'react-redux';
import { toggleUserStatus } from '../../store/users/usersSlice';

function ToggleAccountStatus({ userId, status }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = React.useRef();

  const deactive = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(toggleUserStatus(userId, !status));
      onClose();
    } catch (error) {
      handleApiError(error, { toast });
    }
    setLoading(false);
  }, [dispatch, toast, userId, onClose, status]);

  return (
    <>
      <Button
        leftIcon={FaHashtag}
        variant="link"
        color="custom.red"
        onClick={onOpen}
      >
        {status ? 'Deactive' : 'Activate'} Account
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {status ? 'Deactive' : 'Activate'} Account
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, you want to {status ? 'deactivate' : 'activate'} this
            account?
            {!!status && (
              <Text color="custom.red">
                You will be logged out immediately after confirmation.
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={loading}
              variantColor="red"
              onClick={deactive}
              ml={3}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default ToggleAccountStatus;
