import { Box, Flex, Text, useDisclosure } from '@chakra-ui/core';
import React, { useMemo, useState } from 'react';
import MockButton from './MockButton';
import { GoClock } from 'react-icons/go';
import { getTime } from '../../../../helpers/getTime';
import { useEventListener } from '../../../../hooks/useEventListener';
import { BsViewList } from 'react-icons/bs';

export function MockTopbar({ fullName, timer, moduleCounter, total }) {
  return (
    <Box>
      <Flex
        bg="mock.gray"
        px={5}
        mb={0.5}
        align="center"
        justify="space-between"
        minH="54px"
      >
        <Text fontSize="xl" fontWeight={600}>
          PTE ACADEMIC - {fullName}
        </Text>
        <Box>
          <Box>{!!timer && timer > 0 && <TimerDetails timer={timer} />}</Box>
          {total && !!timer && timer > 0 ? (
            <QuestionCounter counter={moduleCounter + 1} total={total} />
          ) : (
            <Box minH={5} />
          )}
        </Box>
      </Flex>
      <Box h="30px" bg="custom.blue" />
    </Box>
  );
}

function QuestionCounter({ counter, total }) {
  return (
    <Flex align="center" justify="flex-end">
      <Box color="white" as={BsViewList} />
      <Text ml={2}>
        {counter} of {total}
      </Text>
    </Flex>
  );
}

export function NextTips() {
  return (
    <Flex bg="custom.blue" mx={5} py={1} px={5} color="white" mb={10}>
      <Text>
        Click on the <strong>Next (N)</strong> button to continue.
      </Text>
    </Flex>
  );
}

export function TimerDetails({ timer }) {
  const [isOpen, setIsOpen] = useState(true);

  const { seconds, minutes, hours } = getTime(timer);

  const isBelowFiveMinutes = useMemo(() => {
    return timer < 60 * 5;
  }, [timer]);

  return (
    <Flex
      align="center"
      onClick={() => setIsOpen((state) => !state)}
      cursor="pointer"
      minH="28px"
    >
      <Box color="white" as={GoClock} />
      {isOpen && (
        <Text ml={2} color={isBelowFiveMinutes ? 'custom.red' : ''}>
          Time Remaining {hours.toString()?.padStart(2, '0')}:
          {minutes.toString()?.padStart(2, '0')}:
          {seconds.toString()?.padStart(2, '0')}
        </Text>
      )}
    </Flex>
  );
}

export function ActionButtons({
  onConfirmNext,
  onConfirmPrev,
  isOpen,
  onOpen,
  onClose,
  hasPrevStep,
  nextButtonTexts = {},
  canEndExam,
  isSubmitting,
  isNextButtonDisabled,
}) {
  const { label, title, description } = nextButtonTexts;

  return (
    <Flex>
      {canEndExam && (
        <Flex bg="mock.gray" p={2}>
          <MockButton label="End Exam" />
        </Flex>
      )}
      <Box bg="mock.gray" flex={1} mr={1} ml={canEndExam ? 1 : 0} />
      {hasPrevStep && (
        <Flex bg="mock.gray" p={2} mr={1}>
          <MockButton
            label="Previous"
            onConfirm={onConfirmPrev}
            description="Are you sure that you want to perform this action?"
            // isOpen={isOpen}
            // onOpen={onOpen}
            // onClose={onClose}
          />
        </Flex>
      )}
      <Flex bg="mock.gray" p={2}>
        <MockButton
          label={label || 'Next'}
          onConfirm={onConfirmNext}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          title={title}
          description={description}
          isSubmitting={isSubmitting}
          isDisabled={isNextButtonDisabled}
        />
      </Flex>
    </Flex>
  );
}

export function StepLayout({ children, nextShortcutEnabled = false }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEventListener('keydown', ({ key }) => {
    if (!nextShortcutEnabled) {
      return;
    }
    if (key === 'n' || key === 'N') {
      onOpen();
    }
  });

  return (
    <Flex direction="column" h="calc(100vh - 84.5px)" justify="space-between">
      {children({ isOpen, onOpen, onClose })}
    </Flex>
  );
}
