import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { getPackageStatsData } from '../../helpers/stats';
import CustomProgressBar from '../../../../../components/ProgressBar/ProgressBar';

function DashboardCurrentPackage({ currentPackage }) {
  return (
    <Box bg="white" p={6} boxShadow="custom.tertiary">
      <Text
        mr={2}
        color="custom.blue"
        textTransform="uppercase"
        fontWeight={600}
        mb={4}
      >
        Current package
      </Text>
      <Box mb={6}>
        <Text fontSize="2xl" fontWeight={600}>
          {get(currentPackage, 'package.name')}
        </Text>
      </Box>
      <CustomProgressBar
        value={
          getPackageStatsData({
            startedAt: get(currentPackage, 'packageStartedAt'),
            endsOn: get(currentPackage, 'packageEndsOn'),
          }).value
        }
        label={
          'Package Validity: ' +
          getPackageStatsData({
            startedAt: get(currentPackage, 'packageStartedAt'),
            endsOn: get(currentPackage, 'packageEndsOn'),
          }).label
        }
        labelColor="custom.gray2"
        labelSize="sm"
      />
      <CustomProgressBar
        value={
          getPackageStatsData({
            startedAt: get(currentPackage, 'speakingStartedAt'),
            endsOn: get(currentPackage, 'speakingEndsOn'),
          }).value
        }
        label={
          'Speaking Validity: ' +
          getPackageStatsData({
            startedAt: get(currentPackage, 'speakingStartedAt'),
            endsOn: get(currentPackage, 'speakingEndsOn'),
          }).label
        }
        labelColor="custom.gray2"
        labelSize="sm"
        progressColor="greenVariant"
      />
      <CustomProgressBar
        value={
          getPackageStatsData({
            startedAt: get(currentPackage, 'predictionStartedAt'),
            endsOn: get(currentPackage, 'predictionEndsOn'),
          }).value
        }
        label={
          'Prediction Validity: ' +
          getPackageStatsData({
            startedAt: get(currentPackage, 'predictionStartedAt'),
            endsOn: get(currentPackage, 'predictionEndsOn'),
          }).label
        }
        labelColor="custom.gray2"
        labelSize="sm"
        progressColor="yellow"
      />
      <Stack isInline spacing={6}>
        <Box>
          <Text fontSize="xl" fontWeight={600}>
            FULL MOCKS
          </Text>
          <Text color="custom.blue">{get(currentPackage, 'fullMocks')}</Text>
        </Box>
        <Box>
          <Text fontSize="xl" fontWeight={600}>
            CUSTOM MOCKS
          </Text>
          <Text color="custom.green">{get(currentPackage, 'customMocks')}</Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default DashboardCurrentPackage;
