import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import Table, {
  Tbody,
  Td,
  Thead,
  Tr,
} from '../../../../../components/Table/Table';
import { mockVersions } from '../../../../../constants/mockTypes';
import { ActionButtons, NextTips, StepLayout } from '../../Components/Layout';
import { getConfigByVersion } from '../../Utils/getConfigsByVersion';

function PartThreeOverviewStep({ onAfterSubmit, mockVersion }) {
  const { listeningOnly } = getConfigByVersion(mockVersion);
  return (
    <StepLayout nextShortcutEnabled>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Stack spacing={4} my={10} px={6} overflowY="auto">
            <Heading size="md">Part 3: Listening</Heading>
            <Text>
              In Part 3 you are tested mainly on your listening skills.
            </Text>
            <Text>
              This part consists of questions that are based on audio or video
              clips which begin to play automatically. You hear each audio or
              video clip only once so listen carefully. You are allowed to take
              notes.
            </Text>
            <Text>
              For each question you have the opportunity to adjust the volume.
              While the audio clip is playing, move the control bar to the right
              to increase the volume or to the left to decrease the volume.
            </Text>
            <Box>
              <Table minW="500px" fontWeight={600}>
                <Thead>
                  <Tr borderWidth={1} bg="mock.blue">
                    <Td borderWidth={1} p={1} colSpan={3}>
                      Part 3: Listening
                    </Td>
                  </Tr>
                  <Tr borderWidth={1} bg="mock.blue">
                    <Td borderWidth={1} p={1}>
                      Section
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Item Type
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Time allowed
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Section 1
                    </Td>
                    <Td borderWidth={1} p={1}>
                      {mockVersion === mockVersions.v1 ? '2 or 3' : '1 or 2'}{' '}
                      Summarize spoken text
                    </Td>
                    <Td borderWidth={1} p={1}>
                      {mockVersion === mockVersions.v1
                        ? '20 or 30'
                        : '10 or 20'}{' '}
                      minutes
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1} rowSpan={7}>
                      Section 2
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Multiple-choice, choose multiple answers
                    </Td>
                    <Td borderWidth={1} p={1} rowSpan={7}>
                      {listeningOnly} minutes
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Fill in the blanks
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Highlight correct summary
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Multiple-choice, choose single answer
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Selet missing word
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Highlight incorrect words
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Write from dictation
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Stack>
          <Box>
            <NextTips />
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              canEndExam={false}
              onConfirmNext={(onCallback) => {
                onCallback();
                onAfterSubmit();
              }}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default PartThreeOverviewStep;
