import React, { useState } from 'react';
import { Box, Text, Button } from '@chakra-ui/core';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import './PaymentForm.css';
import { formatNumber } from '../../helpers/number';
import { useLocation } from 'react-router-dom';

function PaymentForm({ amount }) {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { pathname } = useLocation();

  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const returnUrl = `${window.location.origin}${pathname}/payment-status`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setIsLoading(false);

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Box
      boxShadow="custom.tertiary"
      borderWidth="1px"
      borderRadius="5px"
      mt={6}
      as="form"
      id="payment-form"
      onSubmit={onSubmit}
      bg="#F7FAFC"
      p={10}
      height="auto"
    >
      <Text mb={6} fontWeight={600}>
        ENTER PAYMENT DETAILS
      </Text>
      <PaymentElement />
      {errorMessage && <Box color="custom.red">{errorMessage}</Box>}
      <Button
        type="submit"
        id="submit"
        variantColor="blueVariant"
        mt={6}
        px={10}
        isLoading={isLoading}
        disabled={!stripe || !elements || isLoading}
      >
        PAY ({formatNumber(amount)})
      </Button>
    </Box>
  );
}

export default PaymentForm;
