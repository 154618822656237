import React, { useMemo, useState, useEffect } from 'react';
import { Flex, Box, Text, Stack } from '@chakra-ui/core';
import { modules, titlesByModule } from '../../../constants/modules';
import { reduce, capitalize, map } from 'lodash-es';
import { NavLink, useParams } from 'react-router-dom';
import PredictionFilter from './PredictionFilter';
import { usePrediction } from '../../../store/prediction/hooks';
import queryString from 'query-string';
import PredictionItem from './PredictionItem';
import { customColors } from '../../../theme/theme';
import { toISODate } from '../../../helpers/getDate';
import NextPrevButtons from '../../../components/Navigation/NextPrev';

import CustomBreadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import styled from '@emotion/styled';
import PredictionValidityExpire from './PredictionValidityExpire';
import { LoaderWrapper } from '../../../components/Loader/Loader';

const CustomBox = styled(Box)`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const getPaths = () => {
  const paths = reduce(
    modules,
    (result, value, key) => [
      ...result,
      {
        label: capitalize(key),
        items: map(value, (item) => ({
          label: titlesByModule[key][item],
          path: `/dashboard/prediction/${key}/${item}`,
        })),
      },
    ],
    []
  );

  return paths;
};

const styles = {
  display: 'block',
  marginBottom: '15px',
  color: customColors.gray5,
};

function Sidebar() {
  const paths = useMemo(() => getPaths(), []);
  return (
    <CustomBox overflowY="scroll">
      {paths.map((path) => (
        <Stack key={path.label} spacing={4} mb={6}>
          <Text
            textTransform="capitalize"
            fontWeight={600}
            mb={2}
            borderBottomWidth="1px"
            pb={2}
          >
            {path.label}
          </Text>
          {path.items.map((item) => (
            <NavLink
              key={`${path.label}${item.label}`}
              style={styles}
              to={item.path}
              activeStyle={{ color: customColors.blue }}
              exact={false}
            >
              {item.label}
            </NavLink>
          ))}
        </Stack>
      ))}
    </CustomBox>
  );
}

const defaultQuery = {
  order: 'desc',
  page: 1,
};

const getBreadcumbPaths = (section, moduleName) => [
  {
    name: 'AI Exam Prediction',
  },
  {
    name: capitalize(section),
  },
  {
    name: titlesByModule[section]?.[moduleName],
  },
];

function PredictionPage() {
  const { section, moduleName } = useParams();

  const [page, setPage] = useState(defaultQuery.page);

  const [queryValues, setQueryValues] = useState(defaultQuery);

  useEffect(() => {
    setPage(defaultQuery.page);
  }, [queryValues, section, moduleName]);

  const queryObject = useMemo(
    () => ({
      section,
      module: moduleName,
      search: queryValues.search || undefined,
      order: queryValues.order,
      practiced: queryValues.isPracticed,
      priority: queryValues.priority,
      date_from: toISODate(queryValues.dateFrom),
      date_to: toISODate(queryValues.dateTo),
      page,
    }),
    [section, moduleName, queryValues, page]
  );

  const [prediction, loading, error] = usePrediction(
    queryString.stringify(queryObject)
  );

  const paths = useMemo(() => getBreadcumbPaths(section, moduleName), [
    section,
    moduleName,
  ]);

  if (error?.response?.status === 403) {
    return <PredictionValidityExpire />;
  }

  return (
    <LoaderWrapper loading={loading}>
      <Flex color="custom.black" height="calc(100vh - 76px)" overflow="hidden">
        <Box>
          <Flex
            p={6}
            bg="custom.white2"
            borderWidth="1px"
            w={350}
            borderColor="custom.white4"
            borderTopWidth={0}
            height="calc(100vh - 70px)"
            direction="column"
          >
            <PredictionFilter
              queryValues={queryValues}
              setQueryValues={setQueryValues}
            />
            <Sidebar />
          </Flex>
        </Box>
        <Box p={10} pb={20} overflowY="auto" width="100%">
          <Flex align="center" justify="space-between">
            <CustomBreadcrumb paths={paths} />
            <Text fontWeight={600}>
              {prediction?.to} out of {prediction?.total}
            </Text>
          </Flex>
          <Box bg="white" p={8} mt={6}>
            {map(prediction?.data || [], (item) => (
              <PredictionItem
                key={item.id}
                module={moduleName}
                section={section}
                {...item}
              />
            ))}
          </Box>
          <NextPrevButtons
            hasNextPage={prediction?.nextPageUrl}
            hasPrevPage={prediction?.prevPageUrl}
            setPage={setPage}
          />
        </Box>
      </Flex>
    </LoaderWrapper>
  );
}

// export function PredictionPage() {
//   return (
//     <PredictionValidityCheck>
//       <Prediction />
//     </PredictionValidityCheck>
//   )
// }

export default PredictionPage;
