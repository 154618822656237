import { useState, useEffect } from 'react';
import { apiRequest } from '../../../api/api';
import { changeToCamelCase } from '../../../helpers/changeCase';

export function useDiagnosticFormData(isPreview) {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await apiRequest(
          'get',
          isPreview ? 'utils/diagnostic/form' : 'users/diagnostic/form'
        );
        setData(changeToCamelCase(data));
      } catch {}
    }

    fetchData();
  }, [isPreview]);

  return [data, !data];
}
