import { useSelector } from 'react-redux';
import { selectUserById } from '../users/usersSlice';
import { get } from 'lodash-es';
import { useState, useEffect } from 'react';
import { apiRequest } from '../../api/api';
import { changeToCamelCase } from '../../helpers/changeCase';

// export function useCurrentUser() {
//   const user = useSelector((state) => state.user);
//   return user;
// }

export function useCurrentUser() {
  const authedUser = useSelector((state) => state.user);

  const user = useSelector((state) =>
    selectUserById(state, authedUser.data.id)
  );

  return user;
}

export function useHasCompletedProfile() {
  const user = useCurrentUser();

  return get(user, 'details.phone') && get(user, 'details.gender');
}

export function usePaymentStatus() {
  const user = useCurrentUser();

  return user.paymentStatus;
}

export function useAuthedUser() {
  const user = useSelector((state) => state.user);

  return user;
}

export function useUserValidity({ questionId, moduleName } = {}) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setFetching(true);

        const { data } = await apiRequest('post', 'auth/user/validity', {
          module: moduleName,
          question_id: questionId,
        });

        setData(changeToCamelCase(data));
      } catch {}
      setFetching(false);
    }
    fetchData();
  }, [questionId, moduleName]);

  return [data, fetching];
}
