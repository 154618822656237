import { routesMapper } from '../../../../../../Routes/routesMapper';
import { listening } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Listening',
  },
  {
    name: listening.mcqMultiple,
    path: routesMapper.listening.MultipleChoiceMultiple.PRACTISE,
  },
];
