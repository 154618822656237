import React, { useCallback, useMemo } from 'react';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/core';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Form from '../../../components/Form/Form';
import FormButton from '../../../components/Form/Button';
import { handleApiError } from '../../../helpers/handleApiError';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import FormInput from '../../../components/Form/Input';
import { useInstitutions } from '../../../store/institutions/hooks';
import FormSelect from '../../../components/Form/Select';
import { roles } from '../../../constants/roles';
import { useCurrentUser } from '../../../store/user/hooks';
import { addUser } from '../../../store/users/usersSlice';
import { useDispatch } from 'react-redux';

let roleOptions = [
  {
    label: 'Student',
    value: 'student',
  },
  {
    label: 'Instructor',
    value: 'instructor',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];

function UserAdd() {
  const toast = useToast();

  const user = useCurrentUser();

  if (user.role === 'instructor') {
    roleOptions = [
      {
        label: 'Student',
        value: 'student',
      },
    ];
  }

  const defaultValues = useMemo(
    () => ({
      role: roles.student,
      instituteId: user.instituteId,
    }),
    [user.instituteId]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email address.')
          .required('Required.'),
        role: Yup.string().required('Required.'),
        instituteId: Yup.string().nullable().required('Required.'),
      }),
    []
  );

  const form = useForm({ validationSchema, defaultValues });

  const institutions = useInstitutions();

  const institutionOptions = useMemo(
    () => institutions.map(({ id, name }) => ({ label: name, value: id })),
    [institutions]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const submit = useCallback(
    async ({ email, role, instituteId }) => {
      try {
        await dispatch(addUser({ email, role, institute_id: instituteId }));

        toast({
          position: 'top',
          title: 'A user has been added.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        onClose();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [toast, onClose, dispatch]
  );

  return (
    <>
      <Button
        variant="link"
        variantColor="blueVariant"
        onClick={onOpen}
        leftIcon={AiOutlinePlusSquare}
      >
        Add New User
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Add New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form form={form} py={4} onSubmit={submit}>
              <FormInput name="email" placeholder="Email Address" />
              <FormSelect
                name="role"
                options={roleOptions}
                placeholder="Role"
              />
              <FormSelect
                isDisabled={user.instituteId}
                name="instituteId"
                options={institutionOptions}
                placeholder="Institution"
                display={user.instituteId ? 'none' : 'inherit'}
              />
              <FormButton>Submit</FormButton>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UserAdd;
