import { useState, useEffect } from 'react';

export function useWordCount(text) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (text) {
      setCount(text.trim().split(/\s+/).length);
    } else {
      setCount(0);
    }
  }, [text]);

  return count;
}
