export function getSpeakingResultColor(score) {
  let color = 'custom.blue';

  if (score < 80 && score > 69) {
    color = 'custom.yellow';
  }

  if (score < 70) {
    color = 'custom.red';
  }

  return color;
}

const speakingText = {
  excellent: 'Excellent/Native',
  veryGood: 'Very Good',
  good: 'Good',
  fair: 'Fair',
  poor: 'Poor',
};

export function getSpeakingResultText(score) {
  let text = speakingText.poor;

  if (score > 89) {
    text = speakingText.good;
  }

  if (score < 90 && score > 79) {
    text = speakingText.veryGood;
  }

  if (score < 80 && score > 69) {
    text = speakingText.good;
  }

  if (score < 70 && score > 59) {
    text = speakingText.fair;
  }

  return { text, color: getSpeakingResultColor(score) };
}
