import React from 'react';
import { Button } from '@chakra-ui/core';
import { FaSortAmountUp, FaSortAmountDown } from 'react-icons/fa';

function SortButton({ state, onClick, ...props }) {
  return (
    <Button
      leftIcon={state === 'desc' ? FaSortAmountDown : FaSortAmountUp}
      onClick={onClick}
      variant="link"
      {...props}
    >
      Sort
    </Button>
  );
}

export default SortButton;
