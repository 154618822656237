import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  useToast,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/core';
import Dropzone from '../Dropzone/Dropzone';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty, some } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../api/api';
import { handleApiError } from '../../helpers/handleApiError';
import Form from '../Form/Form';
import { scores, scoresSub } from '../../constants/assessment';
import { FormBoxInput } from '../Form/Input';
import ErrorMessage from '../Form/ErrorMessage';
import { FormRadioGroup, FormCustomRadio } from '../Form/Radio';
import { changeToCamelCase } from '../../helpers/changeCase';
import FormButton from '../Form/Button';
import { userMetaUpdated } from '../../store/users/usersSlice';
import AnalysedReport from './AnalysedReport';
import { useCurrentUser } from '../../store/user/hooks';
import AppContext from '../../Routes/AppContext';

function getValidationSchema() {
  return Yup.object().shape({
    target: Yup.number()
      .min(0, 'Minimum 0.')
      .max(79, 'Maximum 79.')
      .required('Required.'),
  });
}

const getUrls = ({ isPreview, userId }) => {
  return {
    submit: isPreview
      ? 'utils/publicReportAnalyzer'
      : `users/${userId}/reportAnalyzer`,
    parser: isPreview ? 'utils/publicReportAnalysis' : 'users/reportAnalysis',
  };
};

function ReportAnalyser({ userId, isProfile }) {
  const { isPreview } = useContext(AppContext);

  const { isOpen, onOpen, onClose } = useDisclosure(isPreview);
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const [submittedData, setSubmittedData] = useState();

  const toast = useToast();

  const dispatch = useDispatch();

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const form = useForm({ validationSchema });

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (isOpen) {
      setSubmittedData(null);
    }
  }, [isOpen]);

  const { setValue, setError, clearError } = form;

  const uploadConfig = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadingProgress(percentCompleted);
    },
  };

  const { submit, parser } = getUrls({ isPreview, userId });

  const uploadPdf = useCallback(
    async (file) => {
      const formData = new FormData();

      formData.append('user_id', userId);
      formData.append('report_pdf_file', file);

      try {
        const { data } = await apiRequest(
          'post',
          parser,
          formData,
          uploadConfig
        );

        const camelCasedData = changeToCamelCase(data);

        setValue(
          'overall',
          get(camelCasedData, 'examReportDetails.overall', '')
        );

        setValue(
          'listening',
          get(
            camelCasedData,
            'examReportDetails.communicativeSkills.listening',
            ''
          )
        );
        setValue(
          'reading',
          get(
            camelCasedData,
            'examReportDetails.communicativeSkills.reading',
            ''
          )
        );
        setValue(
          'speaking',
          get(
            camelCasedData,
            'examReportDetails.communicativeSkills.speaking',
            ''
          )
        );
        setValue(
          'writing',
          get(
            camelCasedData,
            'examReportDetails.communicativeSkills.writing',
            ''
          )
        );

        setValue(
          'grammar',
          get(camelCasedData, 'examReportDetails.enablingSkills.grammar', '')
        );
        setValue(
          'oralFluency',
          get(
            camelCasedData,
            'examReportDetails.enablingSkills.oralFluency',
            ''
          )
        );
        setValue(
          'pronunciation',
          get(
            camelCasedData,
            'examReportDetails.enablingSkills.pronunciation',
            ''
          )
        );
        setValue(
          'spelling',
          get(camelCasedData, 'examReportDetails.enablingSkills.spelling', '')
        );
        setValue(
          'vocabulary',
          get(camelCasedData, 'examReportDetails.enablingSkills.vocabulary', '')
        );
        setValue(
          'writtenDiscourse',
          get(
            camelCasedData,
            'examReportDetails.enablingSkills.writtenDiscourse',
            ''
          )
        );
      } catch (error) {
        setUploadingProgress(0);
        handleApiError(error, { toast });
      }
    },
    [toast, userId, setValue, uploadConfig, parser]
  );

  const handleSubmit = useCallback(
    async ({ target, ...values }) => {
      if (some(values, isEmpty)) {
        setError(
          'scores',
          'notMatch',
          'Please upload scorecard or enter scores manually'
        );
        return;
      }

      if (some(values, (value) => value < 0 || value > 90)) {
        setError('scores', 'rangeError', 'Scores must be within 0 to 90');
        return;
      }

      clearError('scores');

      const { data } = await apiRequest('post', submit, {
        target,
        is_profile: isProfile,
        communicative_skills: {
          listening: values.listening,
          reading: values.reading,
          speaking: values.speaking,
          writing: values.writing,
        },
        enabling_skills: {
          grammar: values.grammar,
          oral_fluency: values.oralFluency,
          pronunciation: values.pronunciation,
          spelling: values.spelling,
          vocabulary: values.vocabulary,
          written_discourse: values.writtenDiscourse,
        },
      });

      if (currentUser?.id === userId && isProfile) {
        dispatch(userMetaUpdated(changeToCamelCase(data)));
      }

      setSubmittedData(changeToCamelCase(data));
    },
    [dispatch, userId, clearError, setError, currentUser, isProfile, submit]
  );

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        variantColor="blueVariant"
        px={10}
      >
        Analyze Your Report
      </Button>
      <Modal
        isOpen={isPreview ? true : isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          {!submittedData && (
            <ModalHeader borderBottomWidth="1px" fontSize="md" p={4}>
              Automated AI Score Analyzer
            </ModalHeader>
          )}
          {!isPreview && <ModalCloseButton />}
          <ModalBody>
            {submittedData ? (
              <AnalysedReport
                isPreview={isPreview}
                data={submittedData}
                mt={2}
              />
            ) : (
              <Form form={form} my={4} onSubmit={handleSubmit}>
                <FormLabel mb={4} fontSize="xl" color="custom.black4">
                  Score I want to achieve
                </FormLabel>
                <FormRadioGroup isInline name="target" mb={6}>
                  <FormCustomRadio value={79}>All 79 (IELTS 8)</FormCustomRadio>
                  <FormCustomRadio value={65}>All 65 (IELTS 7)</FormCustomRadio>
                  <FormCustomRadio value={50}>All 50 (IELTS 6)</FormCustomRadio>
                </FormRadioGroup>

                <FormLabel fontSize="xl" color="custom.black4">
                  Upload your PTE Report card here (.pdf)
                </FormLabel>
                <Box my={4}>
                  <Dropzone
                    onChange={uploadPdf}
                    accept="application/pdf"
                    uploadingProgress={uploadingProgress}
                  />
                  <FormLabel mt={2} fontSize="xl" color="custom.black4">
                    Or You can submit your score here
                  </FormLabel>
                  <SimpleGrid columns={5} gridColumnGap={3} my={4}>
                    {scores.map(({ name, label }) => (
                      <FormBoxInput
                        key={name}
                        name={name}
                        label={label}
                        type="number"
                      />
                    ))}
                  </SimpleGrid>
                  <SimpleGrid columns={6} gridColumnGap={3} my={4}>
                    {scoresSub.map(({ name, label }) => (
                      <FormBoxInput
                        key={name}
                        name={name}
                        label={label}
                        type="number"
                      />
                    ))}
                  </SimpleGrid>
                </Box>
                <ErrorMessage name="scores" mb={4} />
                <FormButton>CHECK NOW</FormButton>
              </Form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ReportAnalyser;
