import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import Table, {
  Tbody,
  Td,
  Thead,
  Tr,
} from '../../../../../components/Table/Table';
import { getConfigByVersion } from '../../Utils/getConfigsByVersion';

function TestOverviewStep({ mockVersion }) {
  const {
    speakingAndWriting,
    reading,
    optionalBreak,
    listening,
  } = getConfigByVersion(mockVersion);

  return (
    <Stack spacing={4}>
      <Heading size="md">Test Overview</Heading>
      <Text>
        PTE Academic is designed to test your English reading, writing,
        listening and speaking skills.
      </Text>
      <Text>
        The test takes approximately two hours to complete. This includes an
        un-timed introduction to the test.
      </Text>
      <Text>
        There are <strong>three timed parts:</strong>
      </Text>
      <Box ml={4}>
        <Text>Speaking and writing</Text>
        <Text>Reading</Text>
        <Text>Listening</Text>
      </Box>
      <Text>
        Each part may contain a number of sections. Each section is individually
        timed.
      </Text>
      <Box>
        <Table minW="500px" fontWeight={600}>
          <Thead>
            <Tr borderWidth={1} bg="mock.blue">
              <Td borderWidth={1} p={1}>
                Part
              </Td>
              <Td borderWidth={1} p={1}>
                Content
              </Td>
              <Td borderWidth={1} p={1}>
                Time allowed
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            <Tr borderWidth={1}>
              <Td borderWidth={1} p={1}>
                Intro
              </Td>
              <Td borderWidth={1} p={1}>
                Introduction
              </Td>
              <Td borderWidth={1} p={1}>
                Not timed
              </Td>
            </Tr>
            <Tr borderWidth={1}>
              <Td borderWidth={1} p={1}>
                Part 1
              </Td>
              <Td borderWidth={1} p={1}>
                Speaking and writing
              </Td>
              <Td borderWidth={1} p={1}>
                {speakingAndWriting} minutes
              </Td>
            </Tr>
            <Tr borderWidth={1}>
              <Td borderWidth={1} p={1}>
                Part 2
              </Td>
              <Td borderWidth={1} p={1}>
                Reading
              </Td>
              <Td borderWidth={1} p={1}>
                {reading} minutes
              </Td>
            </Tr>
            {optionalBreak && (
              <Tr borderWidth={1} bg="mock.blue">
                <Td borderWidth={1} p={1} colSpan={2}>
                  <i>Optional Scheduled Break</i>
                </Td>
                <Td borderWidth={1} p={1}>
                  <i>10 minutes</i>
                </Td>
              </Tr>
            )}
            <Tr borderWidth={1}>
              <Td borderWidth={1} p={1}>
                Part 3
              </Td>
              <Td borderWidth={1} p={1}>
                Listening
              </Td>
              <Td borderWidth={1} p={1}>
                {listening} minutes
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Text>
        Click on the <strong>Confirm</strong> button to start your mock test
      </Text>
    </Stack>
  );
}

export default TestOverviewStep;
