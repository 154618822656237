import { Box, Flex, Heading, Text, Tooltip } from '@chakra-ui/core';
import React, { useState } from 'react';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import AutoGenerated from './Type/AutoGenerated';
import EditorPicks from './Type/EditorPicks';
import Reports from './Report/Reports';
import { FaRobot, FaChalkboardTeacher } from 'react-icons/fa';
import MockLimitBox from './Components/MockLimitBox';

const views = {
  editor: 'editor',
  auto: 'auto',
};

function MocksPage() {
  const [view, setView] = useState('editor');
  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        MOCKS
      </Heading>
      <Box borderRadius="6px" bg="white" p={10} boxShadow="custom.primary">
        <Flex align="center" justify="space-between">
          <Box>
            <Heading size="md" fontWeight={600} mb={4} color="custom.black2">
              <Text as="span" mr={1}>
                Select
              </Text>
              <Tooltip
                hasArrow
                label="Hand Picked Questions by our Expert Mentors"
                placement="top"
                bg="custom.black2"
              >
                <Text as="span" color="custom.blue8">
                  Experts Picks
                </Text>
              </Tooltip>
              <Text as="span" mx={1}>
                or
              </Text>
              <Tooltip
                hasArrow
                label="Auto generated questions set by our AI System"
                placement="top"
                bg="custom.black2"
              >
                <Text as="span" color="custom.blue8">
                  AI Generated
                </Text>
              </Tooltip>
              <Text as="span" ml={1}>
                Mock
              </Text>
            </Heading>
            <Flex align="center" justify="center">
              <PrimaryButton
                minW="180px"
                leftIcon={FaChalkboardTeacher}
                onClick={() => setView(views.editor)}
                mr={6}
                p={6}
                py={8}
                variantColor="blueVariant"
                variant={view === views.editor ? 'solid' : 'outline'}
              >
                Expert Picks
              </PrimaryButton>
              <PrimaryButton
                minW="180px"
                leftIcon={FaRobot}
                onClick={() => setView(views.auto)}
                p={6}
                py={8}
                variantColor="blueVariant"
                variant={view === views.auto ? 'solid' : 'outline'}
              >
                AI Generated
              </PrimaryButton>
            </Flex>
          </Box>

          <MockLimitBox />
        </Flex>

        <Box mt={10}>
          {view === views.editor && <EditorPicks />}
          {view === views.auto && <AutoGenerated />}
        </Box>
      </Box>
      <Heading size="lg" color="custom.black3" mt={10} mb={6}>
        MOCK REPORTS
        <Text fontSize="sm" color="custom.gray2">
          Please note you will only be able to see the last 90 days full reports
          here. Keep a backup if needed.
        </Text>
      </Heading>
      <Reports />
    </Box>
  );
}

export default MocksPage;
