import React, { useMemo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  FormLabel,
  Button,
  useToast,
} from '@chakra-ui/core';
import * as Yup from 'yup';
import Form from '../../../components/Form/Form';
import FormButton from '../../../components/Form/Button';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch, batch } from 'react-redux';
import { updateSignupData, analyseReport } from '../../../store/signup/action';
import {
  FormRadioGroup,
  FormCustomRadio,
} from '../../../components/Form/Radio';
import Dropzone from '../../../components/Dropzone/Dropzone';
import { FormBoxInput } from '../../../components/Form/Input';
import { useSignupContext } from './SignupContext';
import ErrorMessage from '../../../components/Form/ErrorMessage';
import { handleApiError } from '../../../helpers/handleApiError';
import { some, isEmpty, get } from 'lodash-es';
import FormDatePicker from '../../../components/Form/DatePicker';
import { toISODate } from '../../../helpers/getDate';
import { scores, scoresSub } from '../../../constants/assessment';

function AssessmentStep() {
  const dispatch = useDispatch();

  const [uploadingProgress, setUploadingProgress] = useState(0);

  const { setStep } = useSignupContext();

  const { user } = useSelector((state) => state.signup);

  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        examDate: Yup.string().nullable(),
        appeared: Yup.string().required('Required.'),
        goalOverall: Yup.number()
          .min(0, 'Minimum 0.')
          .max(79, 'Maximum 79.')
          .required('Required.'),
      }),
    []
  );

  const scoresData = useMemo(() => scores, []);

  const scoresSubData = useMemo(() => scoresSub, []);

  const form = useForm({ validationSchema });

  const { watch, setValue, setError, clearError } = form;

  const appeared = watch('appeared');

  useEffect(() => {
    clearError('scores');
  }, [clearError, appeared]);

  const setFormStep = useCallback(() => {
    setStep('completeProfile');
  }, [setStep]);

  const uploadConfig = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadingProgress(percentCompleted);
    },
  };

  const uploadPdf = useCallback(
    async (file) => {
      const formData = new FormData();

      formData.append('user_id', user.id || 4);
      formData.append('report_pdf_file', file);

      try {
        const { examReportDetails } = await dispatch(
          analyseReport(formData, uploadConfig)
        );

        setValue('overall', get(examReportDetails, 'overall', ''));

        setValue(
          'listening',
          get(examReportDetails, 'communicativeSkills.listening', '')
        );
        setValue(
          'reading',
          get(examReportDetails, 'communicativeSkills.reading', '')
        );
        setValue(
          'speaking',
          get(examReportDetails, 'communicativeSkills.speaking', '')
        );
        setValue(
          'writing',
          get(examReportDetails, 'communicativeSkills.writing', '')
        );

        setValue(
          'grammar',
          get(examReportDetails, 'enablingSkills.grammar', '')
        );
        setValue(
          'oralFluency',
          get(examReportDetails, 'enablingSkills.oralFluency', '')
        );
        setValue(
          'pronunciation',
          get(examReportDetails, 'enablingSkills.pronunciation', '')
        );
        setValue(
          'spelling',
          get(examReportDetails, 'enablingSkills.spelling', '')
        );
        setValue(
          'vocabulary',
          get(examReportDetails, 'enablingSkills.vocabulary', '')
        );
        setValue(
          'writtenDiscourse',
          get(examReportDetails, 'enablingSkills.writtenDiscourse', '')
        );
      } catch (error) {
        setUploadingProgress(0);
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, user, setValue, uploadConfig]
  );

  const submit = useCallback(
    async ({ appeared, scorecard, examDate, goalOverall, ...values }) => {
      if (appeared === 'no') {
        batch(() => {
          dispatch(updateSignupData({ examReportDetails: {} }, 'assessment'));
          dispatch(
            updateSignupData(
              {
                overall: goalOverall,
                examDate: examDate && toISODate(examDate),
              },
              'goal'
            )
          );
        });
        setFormStep();
        return;
      }

      if (some(values, isEmpty)) {
        setError(
          'scores',
          'notMatch',
          'Please upload scorecard or enter scores manually'
        );
        return;
      }

      clearError('scores');

      if (uploadingProgress !== 100) {
        batch(() => {
          dispatch(
            updateSignupData(
              {
                examReportDetails: {
                  communicativeSkills: {
                    listening: values.listening,
                    reading: values.reading,
                    speaking: values.speaking,
                    writing: values.writing,
                  },
                  enablingSkills: {
                    grammar: values.grammar,
                    oralFluency: values.oralFluency,
                    pronunciation: values.pronunciation,
                    spelling: values.spelling,
                    vocabulary: values.vocabulary,
                    writtenDiscourse: values.writtenDiscourse,
                  },
                },
              },
              'assessment'
            )
          );
          dispatch(
            updateSignupData(
              {
                overall: goalOverall,
                examDate: examDate && toISODate(examDate),
              },
              'goal'
            )
          );
        });
      }

      setFormStep();
    },
    [dispatch, setFormStep, clearError, setError, uploadingProgress]
  );

  const examDateLabelProps = useMemo(() => ({ fontSize: 'xl', mb: 4 }), []);

  return (
    <Box mt={6}>
      <Heading mb={2} color="custom.black3">
        PTE LEVEL/GOAL
      </Heading>
      <Form form={form} onSubmit={submit} width="600px" mt={6}>
        <FormDatePicker
          name="examDate"
          placeholder="Next Exam date"
          label="When you are sitting for the PTE test next?"
          labelProps={examDateLabelProps}
          mb={4}
        />
        <FormLabel mb={4} fontSize="xl" color="custom.black4">
          What score you aim to achieve?
        </FormLabel>
        <FormRadioGroup isInline name="goalOverall" mb={6}>
          <FormCustomRadio value={79}>All 79 (IELTS 8)</FormCustomRadio>
          <FormCustomRadio value={65}>All 65 (IELTS 7)</FormCustomRadio>
          <FormCustomRadio value={50}>All 50 (IELTS 6)</FormCustomRadio>
        </FormRadioGroup>
        <Box>
          <FormLabel mb={4} fontSize="xl" color="custom.black4">
            Have you ever sit for PTE exam before?
          </FormLabel>
          <FormRadioGroup name="appeared">
            <FormCustomRadio value="yes">
              Yes, I have sat for PTE before
            </FormCustomRadio>
            <FormCustomRadio value="no">No</FormCustomRadio>
          </FormRadioGroup>
        </Box>
        {appeared === 'yes' && (
          <Box my={4}>
            <Dropzone
              onChange={uploadPdf}
              accept="application/pdf"
              uploadingProgress={uploadingProgress}
            />
            <FormLabel mt={2} fontSize="xl" color="custom.black4">
              Or You can submit your score here
            </FormLabel>
            <SimpleGrid columns={5} gridColumnGap={3} my={4}>
              {scoresData.map(({ name, label }) => (
                <FormBoxInput
                  key={name}
                  name={name}
                  label={label}
                  type="number"
                />
              ))}
            </SimpleGrid>
            <SimpleGrid columns={6} gridColumnGap={3} my={4}>
              {scoresSubData.map(({ name, label }) => (
                <FormBoxInput
                  key={name}
                  name={name}
                  label={label}
                  type="number"
                />
              ))}
            </SimpleGrid>
          </Box>
        )}
        <ErrorMessage name="scores" mb={4} />
        <FormButton px={6} icon="arrow-forward">
          NEXT
        </FormButton>
      </Form>
      <Button
        size="sm"
        mt={4}
        variant="link"
        onClick={setFormStep}
        color="custom.blue"
      >
        <strong>Skip now</strong>, I will do it later
      </Button>
    </Box>
  );
}

export default AssessmentStep;
