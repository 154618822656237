import { Box, Flex, Heading, Stack } from '@chakra-ui/core';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiRequest } from '../../../../../api/api';
import PrimaryButton from '../../../../../components/Button/PrimaryButton';
import { handleApiError } from '../../../../../helpers/handleApiError';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { MockTopbar, StepLayout } from '../../Components/Layout';

function ExamEndStep({ user, mockData }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const { toast } = useCustomToast();

  const handleClick = useCallback(async () => {
    setIsSubmitting(true);

    try {
      let existingMockId = mockData.existingMockId;

      if (!mockData.existingMockId) {
        const { data: savedData } = await apiRequest(
          'post',
          'mocks/checkSave',
          {
            type: mockData.type,
          }
        );
        existingMockId = savedData.id;
      }

      const { data } = await apiRequest(
        'get',
        `mocks/evaluate/${existingMockId}`
      );
      history.push(`/dashboard/mocks/report/${data.id}`);
    } catch (error) {
      handleApiError(error, { toast });
      setIsSubmitting(false);
    }
  }, [mockData, toast, history]);

  return (
    <>
      <Flex direction="column" minH="100vh">
        <Box>
          <MockTopbar
            fullName={user.details.firstName + ' ' + user.details.lastName}
          />
          <StepLayout nextShortcutEnabled>
            {() => (
              <>
                <Stack spacing={4} my={10} px={6} overflowY="auto">
                  <Heading size="md">Your exam has ended</Heading>
                  <Box as="ul" ml={8} my={4}>
                    <li>
                      You can call adminstrator to state that your exam has
                      ended by raising your hand to confirm your end exam
                    </li>
                    <li>Your detailed report will be sent to your email</li>
                    <li>
                      Collect acknowledgement from administrator before leaving
                      exam hall.
                    </li>
                  </Box>
                  <Box mt={10}>
                    <PrimaryButton
                      onClick={handleClick}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      variantColor="blueVariant"
                      px={10}
                    >
                      End Mock
                    </PrimaryButton>
                  </Box>
                </Stack>
                <Box>
                  <Box bg="mock.gray" flex={1} />
                </Box>
              </>
            )}
          </StepLayout>
        </Box>
      </Flex>
    </>
  );
}

export default ExamEndStep;
