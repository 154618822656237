import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Progress,
} from '@chakra-ui/core';
import { useCallback } from 'react';
import { GiPlainArrow } from 'react-icons/gi';
import { useInterval } from '../../../../../hooks/useTimer';

function MockAudioPlayerBare({
  url,
  onLoadedCallback = () => null,
  onEndCallback = () => null,
  beginningDuration = 0,
  // status: recorderStatus,
}) {
  const [audio] = useState(new Audio(url));
  const [currentTime, setCurrentTime] = useState(0.01);

  const [status, setStatus] = useState();
  const [volume, setVolume] = useState(0.5);

  const [, /* state */ setState] = useState();

  const [audioBeginningDuration, setAudioBeginningDuration] = useState(
    beginningDuration
  );

  // useEffect(() => {
  //   if (recorderStatus === 'recording' && status === 'playing') {
  //     audio.pause();
  //   }
  // }, [recorderStatus, status, audio]);

  const playAudio = useCallback(async () => {
    try {
      setStatus('playing');
      await audio.play();
    } catch (error) {
      // console.log(error);
      if (error.name === 'NotAllowedError') {
        setState(() => {
          throw error;
        });
      }
    }
  }, [audio]);

  useInterval(
    () => {
      if (audioBeginningDuration <= 0) {
        playAudio();
      }

      setAudioBeginningDuration((state) => state - 1);
    },
    audioBeginningDuration >= 0 ? 1000 : null
  );

  useEffect(() => {
    if (audio) {
      audio.volume = volume;
    }
  }, [volume, audio]);

  useEffect(() => {
    return () => audio.pause();
  }, [audio]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(audio.currentTime);
  }, [audio]);

  const onEnd = useCallback(() => {
    setStatus('completed');
    onEndCallback();
  }, [onEndCallback]);

  const onLoad = useCallback(() => {
    onLoadedCallback(audio.duration);
  }, [onLoadedCallback, audio]);

  useEffect(() => {
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('ended', onEnd);
    audio.addEventListener('canplaythrough', onLoad);
    return () => {
      audio.removeEventListener('timeupdate', onTimeUpdate);
      audio.removeEventListener('ended', onEnd);
      audio.removeEventListener('canplaythrough', onLoad);
    };
  }, [onTimeUpdate, audio, onEnd, onLoad]);

  return (
    <Box>
      <Box borderWidth="1px" borderColor="mock.gray" mt={4} p="10px" w="350px">
        <Flex mb={6}>
          <Text mr={6}>Status:</Text>
          <Text textTransform="capitalize">
            {audioBeginningDuration > 0
              ? `Begining in ${audioBeginningDuration} seconds`
              : status}
          </Text>
        </Flex>

        <Flex align="center" mt={3}>
          <Text mr={4}>Volume:</Text>
          <Slider
            onChange={(value) => setVolume(value)}
            value={volume}
            min={0}
            max={1}
            step={0.1}
          >
            <SliderTrack borderWidth={1} />
            <SliderFilledTrack bg="custom.gray" />
            <SliderThumb size={4} borderWidth={0}>
              <Box color="gray" as={GiPlainArrow} />
            </SliderThumb>
          </Slider>
        </Flex>
        <Progress
          borderWidth={1}
          h={4}
          borderColor="mock.gray"
          bg="white"
          my={6}
          value={parseInt((currentTime * 100) / audio.duration) || 0}
          color="blueVariant"
        />
      </Box>
    </Box>
  );
}

export default MockAudioPlayerBare;
