export const speakingDuration = {
  repeatSentence: {
    beginning: {
      before: 2,
      after: 0,
    },
    duration: 15,
  },
  describeImage: {
    beginning: 25,
    duration: 40,
  },
  retellLecture: {
    beginning: {
      before: 3,
      after: 10,
    },
    duration: 40,
  },
  answerShortQuestion: {
    beginning: {
      before: 3,
      after: 0,
    },
    duration: 15,
  },
};
