import React, { memo, useMemo } from 'react';
import { modules } from '../../../constants/modules';

import MCQSingleScore from './ScoreViews/Reading/MCQSingleScore';
import MCQMultipleScore from './ScoreViews/Reading/MCQMultipleScore';
import ReorderScore from './ScoreViews/Reading/ReorderScore';
import FillBlanksDropdownScore from './ScoreViews/Reading/FillBlanksDropdownScore';
import FillBlanksDragScore from './ScoreViews/Reading/FillBlanksDragScore';

import FillBlanksScore from './ScoreViews/Listening/FillBlanksScore';
import ListeningMCQScore from './ScoreViews/Listening/MCQScore';
import ListeningMCQMultipleScore from './ScoreViews/Listening/MCQMultipleScore';
import ListeningDictationScore from './ScoreViews/Listening/DictationScore';
import HighlightIncorrectWords from './ScoreViews/Listening/HighlightIncorrectWords';
import WritingEssayScore from './ScoreViews/Writing/EssayScore';
import WritingSummaryScore from './ScoreViews/Writing/SummaryScore';
import ListeningSummaryScore from './ScoreViews/Listening/SummaryScore';
import ReadAloudScore from './ScoreViews/Speaking/ReadAloudScore';
import RepeatSentenceScore from './ScoreViews/Speaking/RepeatSentenceScore';
import DescribeImageScore from './ScoreViews/Speaking/DescribeImageScore';
import RetellLectureScore from './ScoreViews/Speaking/RetellLectureScore';
import AnswerShortQuestionScore from './ScoreViews/Speaking/AnswerShortQuestionScore';

function Score({ score, section, module, practiseAgain, isMockReport }) {
  const scoreByModule = useMemo(() => {
    switch (section) {
      case 'reading':
        switch (module) {
          case modules.reading.MultipleChoiceSingle:
            return <MCQSingleScore />;
          case modules.reading.MultipleChoiceMultiple:
            return <MCQMultipleScore />;
          case modules.reading.ReorderParagraph:
            return <ReorderScore />;
          case modules.reading.FillInTheBlanksDropDown:
            return <FillBlanksDropdownScore />;
          case modules.reading.FillInTheBlanksDrag:
            return <FillBlanksDragScore />;
          default:
            return null;
        }
      case 'listening':
        switch (module) {
          case modules.listening.FillInTheBlanks:
            return <FillBlanksScore />;
          case modules.listening.MultipleChoiceMultiple:
            return <ListeningMCQMultipleScore />;
          case modules.listening.HighlightCorrectSummary:
          case modules.listening.SelectMissingWords:
          case modules.listening.MultipleChoiceSingle:
            return <ListeningMCQScore />;
          case modules.listening.WriteFromDictation:
            return <ListeningDictationScore />;
          case modules.listening.HighlightIncorrectWords:
            return <HighlightIncorrectWords />;
          case modules.listening.SummarySpokenText:
            return <ListeningSummaryScore />;
          default:
            return null;
        }
      case 'writing':
        switch (module) {
          case modules.writing.Essay:
            return <WritingEssayScore />;
          case modules.writing.SummaryWrittenText:
            return <WritingSummaryScore />;
          default:
            return null;
        }
      case 'speaking':
        switch (module) {
          case modules.speaking.ReadAloud:
            return <ReadAloudScore />;
          case modules.speaking.RepeatSentence:
            return <RepeatSentenceScore />;
          case modules.speaking.DescribeImage:
            return <DescribeImageScore />;
          case modules.speaking.RetellLecture:
            return <RetellLectureScore />;
          case modules.speaking.AnswerShortQuestion:
            return <AnswerShortQuestionScore />;
          default:
            return null;
        }
      default:
        return null;
    }
  }, [module, section]);

  return (
    scoreByModule &&
    React.cloneElement(scoreByModule, { score, practiseAgain, isMockReport })
  );
}

export default memo(Score);
