import React, { useMemo, useCallback, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import { useForm, useFieldArray } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import {
  Box,
  Flex,
  FormLabel,
  Button,
  IconButton,
  Text,
  Input,
  Textarea,
} from '@chakra-ui/core';
import FormTextarea from '../../../../../components/Form/Textarea';
import ErrorMessage from '../../../../../components/Form/ErrorMessage';
import { paths } from './utils/paths';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import TitleBar from '../../../../../components/Question/TitleBar';
import { apiRequest } from '../../../../../api/api';
import { handleApiError } from '../../../../../helpers/handleApiError';
import { sections } from '../../../../../constants/sections';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { useQuestionOptions } from '../../../../../hooks/useQuestionOptions';
import { LoaderWrapper } from '../../../../../components/Loader/Loader';
import { FiEdit2 } from 'react-icons/fi';

const customPaths = [
  ...paths,
  {
    name: 'Templates & Conjunctions',
  },
];

const getDefaultValues = (data) => ({
  conjunctions: get(data, 'options.conjunctions', ''),
  templates: get(data, 'options.templates') || [],
});

function OptionsForm({ data }) {
  const defaultValues = useMemo(() => getDefaultValues(data), [data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        conjunctions: Yup.string().trim().required('Required.'),
        templates: Yup.array()
          .of(Yup.string().trim().required('Required.'))
          .required('Atleast one item.'),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const { control, register, setValue } = form;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'templates',
  });

  const { success, toast } = useCustomToast();

  const onSubmit = useCallback(
    async ({ templates, conjunctions }) => {
      try {
        await apiRequest('post', 'options/question', {
          options: { templates, conjunctions },
          feature: sections.writing,
        });
        success({ title: 'Updated successfully.' });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [toast, success]
  );

  const [template, setTemplate] = useState({});

  const onTemplateSubmit = useCallback(() => {
    if (template.id) {
      setValue(`templates[${template.id}]`, template.value);
    } else {
      append(template.value);
    }
    setTemplate({ value: '' });
  }, [setValue, template, append]);

  return (
    <Form form={form} onSubmit={onSubmit} mt={6}>
      <FormLabel mb={2}>
        Add conjunctions of the essay separated by semicolon (;)
      </FormLabel>
      <FormTextarea
        name="conjunctions"
        placeholder="Conjunctions"
        minHeight="120px"
      />
      <Box mb={6}>
        <FormLabel mb={2}>
          Templates <em>(Add multiple templates of the Essay for Structure)</em>
        </FormLabel>
        {fields.map((field, index) => (
          <Flex
            key={field.id}
            justify="space-between"
            align="center"
            borderWidth={1}
            borderColor="custom.gray"
            borderRadius="5px"
            boxShadow="custom.primary"
            py={1}
            px={4}
            mb={3}
          >
            <Text mr={1} color="custom.blue">
              {index + 1}#
            </Text>
            <Input
              color="custom.gray5"
              defaultValue={field.value}
              name={`templates[${index}]`}
              ref={register()}
              flex={1}
              isReadOnly
              border={0}
              p={0}
            />
            <Box>
              <IconButton
                size="sm"
                ml={3}
                icon={FiEdit2}
                variant="ghost"
                variantColor="blueVariant"
                onClick={() => setTemplate({ id: index, value: field.value })}
              />
              <IconButton
                size="sm"
                icon="close"
                variant="ghost"
                variantColor="redVariant"
                onClick={() => remove(index)}
              />
            </Box>
          </Flex>
        ))}
        <Textarea
          mb={4}
          mt={4}
          value={template?.value}
          onChange={(event) => {
            const value = event.target.value;
            const newValue = { ...(value && { ...template }), value };
            setTemplate(newValue);
          }}
        />
        <Button
          disabled={!template?.value?.trim?.()}
          size="sm"
          variantColor="blueVariant"
          type="button"
          onClick={onTemplateSubmit}
        >
          Save {template.id ? `(#${template.id + 1})` : `(New)`}
        </Button>
        <ErrorMessage name="templates" mt={2} />
      </Box>
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

function Options() {
  const { data, loading } = useQuestionOptions({ feature: sections.writing });

  return (
    <LoaderWrapper loading={loading}>
      <QuestionMetabar paths={customPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title="Templates & Conjunctions" />
        <OptionsForm data={data} />
      </Box>
    </LoaderWrapper>
  );
}

export default Options;
