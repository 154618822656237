import {
  Badge,
  Box,
  Flex,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Authorize from '../../../../components/Authorize/Authorize';
import CustomBadge from '../../../../components/Badge/Badge';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import Datatable from '../../../../components/Datatable/Datatable';
import DatatablePagination from '../../../../components/Datatable/DatatablePagination';
import { getDate } from '../../../../helpers/getDate';
import { useAuthorization } from '../../../../hooks/useAuthorization';
import { getMocks } from '../../../../store/mocks/mocksSlice';
import MockHistory from '../Components/MockHistory';
import MockStatusUpdate from '../Components/MockStatusUpdate';
import { AiOutlineFileAdd, AiFillPlayCircle } from 'react-icons/ai';
import { mockAttendTypes } from '../../../../constants/mockTypes';
import EditorPicksTableFilter from './EditorPicksTableFilter';
import { useLabels } from '../../../../store/label/hooks';
import { getLabels } from '../../../../store/label/ducks';
import { pickBy } from 'lodash-es';

const getColumns = () => [
  { Header: 'Title', accessor: 'title' },
  {
    Header: 'Type',
    accessor: ({ type }) => <Text textTransform="capitalize">{type}</Text>,
  },
  { Header: 'Added On', accessor: ({ created_at }) => getDate(created_at) },
  {
    Header: 'Labels',
    accessor: ({ labels, is_practiced, is_free }) => (
      <Flex align="center" justify="space-between" pr={4}>
        <Stack isInline spacing={3}>
          {labels.map(({ name, color }, index) => (
            <CustomBadge key={index} color={color} mr={2}>
              {name}
            </CustomBadge>
          ))}
        </Stack>
        <Stack isInline spacing={3}>
          {is_practiced && (
            <CustomBadge borderColor="custom.white5" color="custom.white5">
              Practiced
            </CustomBadge>
          )}
          {/*{is_free && (*/}
          {/*  <CustomBadge borderColor="custom.green" color="custom.green">*/}
          {/*    Free*/}
          {/*  </CustomBadge>*/}
          {/*)}*/}
        </Stack>
      </Flex>
    ),
  },
  {
    Header: 'Status',
    accessor: ({ status }) =>
      status ? (
        <Badge variant="solid" variantColor="greenVariant" borderRadius={4}>
          Active
        </Badge>
      ) : (
        <Badge variant="solid" variantColor="redVariant" borderRadius={4}>
          Inactive
        </Badge>
      ),
  },
  {
    id: 'actions',
    accessor: ({ id, status, history, title }) => (
      <Stack isInline justifyContent="flex-end">
        <Authorize permissions="mocks.canViewHistory">
          <MockHistory history={history} title={title} />
        </Authorize>
        <Authorize permissions="mocks.canEdit">
          <Tooltip hasArrow label="Edit Mock" placement="top" bg="custom.blue">
            <IconButton
              as={Link}
              mr={2}
              title="Edit"
              size="sm"
              variantColor="blueVariant"
              variant="outline"
              icon="edit"
              to={`/dashboard/mocks/edit/${id}`}
            />
          </Tooltip>
        </Authorize>
        <Tooltip hasArrow label="Attend Mock" placement="top" bg="custom.blue">
          <IconButton
            mr={2}
            size="sm"
            as={Link}
            variantColor="blueVariant"
            variant="outline"
            icon={AiFillPlayCircle}
            to={`/dashboard/mocks/attend/${mockAttendTypes.editor}?id=${id}`}
          />
        </Tooltip>
        <Authorize permissions="mocks.canEdit">
          <MockStatusUpdate id={id} status={status} />
        </Authorize>
      </Stack>
    ),
  },
];

const handleGlobalFilter = (rows, _, value) => {
  const template = pickBy(
    {
      title: value.searchValue,
      labelIds: value.selectedLabel,
      priority: value.selectedPriority,
    },
    Boolean
  );

  return rows.filter((obj) => {
    return Object.keys(template).every((propertyName) => {
      const lowerCased =
        typeof obj.original[propertyName] === 'string'
          ? obj.original[propertyName]?.toLowerCase()
          : obj.original[propertyName];

      return lowerCased?.includes(
        template[propertyName].toLowerCase?.() || template[propertyName]
      );
    });
  });
};

function EditorPicks() {
  const mocks = useSelector((state) => state.mocks);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMocks());
  }, [dispatch]);

  const data = useMemo(
    () =>
      mocks.allIds.map((id) => ({
        ...mocks.byId[id],
        labelIds: mocks.byId[id]?.labels?.map((label) => label.id),
      })),
    [mocks]
  );
  const columns = useMemo(() => getColumns(), []);

  const table = useTable(
    {
      data,
      columns,
      globalFilter: handleGlobalFilter,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setGlobalFilter,
    preGlobalFilteredRows,
    state: { pageIndex, globalFilter },
  } = table;

  const labels = useLabels();
  useEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  const canAddMock = useAuthorization({ permissions: 'mocks.canAdd' });

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        {canAddMock ? (
          <PrimaryButton
            as={Link}
            leftIcon={AiOutlineFileAdd}
            to="/dashboard/mocks/add"
            variantColor="blueVariant"
            variant="outline"
            px={6}
          >
            Add Mock
          </PrimaryButton>
        ) : (
          <Box />
        )}
        <EditorPicksTableFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          labels={labels}
        />
      </Flex>
      <Datatable {...table} />
      {table.flatRows.length === 0 && (
        <Flex justify="center" align="center" py={2} color="custom.gray3">
          No Data Found
        </Flex>
      )}
      <DatatablePagination
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageIndex={pageIndex}
      />
    </Box>
  );
}

export default EditorPicks;
