import { routesMapper } from '../../../../../../Routes/routesMapper';
import { writing } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Writing',
  },
  {
    name: writing.summary,
    path: routesMapper.writing.SummaryWrittenText.PRACTISE,
  },
];
