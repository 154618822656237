import { Box, Button, Flex, Heading, Icon, Text } from '@chakra-ui/core';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { STRIPE_PUBLISH_KEY } from '../../../config/metaData';
import { formatNumber } from '../../../helpers/number';
import Loader from '../../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { MdCancel, MdCheckCircle, MdInfo } from 'react-icons/md';

const promise = loadStripe(STRIPE_PUBLISH_KEY);

const statusTypes = {
  success: 'success',
  failed: 'failed',
  processing: 'processing',
  invalid: 'invalid',
};

function PaymentStatus() {
  const stripe = useStripe();
  const [statusType, setStatusType] = useState(null);
  const [stripeData, setStripeData] = useState(null);
  const { push } = useHistory();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      setStatusType(statusTypes.invalid);
      return;
    }
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .catch(() => {
        setStatusType(statusTypes.invalid);
      })
      .then(({ paymentIntent, ...rest }) => {
        setStripeData(paymentIntent);
        switch (paymentIntent.status) {
          case 'succeeded':
            setStatusType(statusTypes.success);
            break;

          case 'processing':
            setStatusType(statusTypes.processing);
            break;

          default:
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setStatusType(statusTypes.failed);
            break;
        }
      });
  }, [stripe, push]);

  if (!statusType) {
    return <Loader />;
  }

  if (statusType === statusTypes.success) {
    return (
      <>
        <Flex direction="row" align="center">
          <Icon
            as={MdCheckCircle}
            color="custom.green"
            mr={4}
            size="32px"
            mb={2}
          />
          <Text color="custom.green" fontSize="2xl" mb={2}>
            You have successfully completed your payment.
          </Text>
        </Flex>
        <Text mb={2} color="custom.black">
          Amount Charged: <b>{formatNumber(stripeData.amount / 100)}</b>
        </Text>
        <Button
          variantColor="blueVariant"
          mt={6}
          px={10}
          onClick={() => push('/dashboard')}
        >
          Go to Dashboard
        </Button>
      </>
    );
  }
  if (statusType === statusTypes.processing) {
    return (
      <>
        <Flex direction="row" align="center">
          <Icon as={MdInfo} color="custom.blue" mr={4} size="32px" mb={2} />
          <Text color="custom.blue" fontSize="2xl" mb={2}>
            Your payment is processing.
          </Text>
        </Flex>
        <Text color="custom.black" mb={2}>
          You will be notified shortly when processing completes.
        </Text>
        <Button
          variantColor="blueVariant"
          mt={6}
          px={10}
          onClick={() => push('/dashboard')}
        >
          Go to Dashboard
        </Button>
      </>
    );
  }

  if (statusType === statusTypes.invalid) {
    return (
      <>
        <Flex direction="row" align="center">
          <Icon as={MdCancel} color="custom.red" mr={4} size="32px" mb={2} />
          <Text color="custom.red" fontSize="2xl" mb={2}>
            Invalid payment details.
          </Text>
        </Flex>
        <Text color="custom.black">Please try again after sometime.</Text>
      </>
    );
  }

  return (
    <>
      <Flex direction="row" align="center">
        <Icon as={MdCancel} color="custom.red" mr={4} size="32px" mb={2} />
        <Text color="custom.red" fontSize="2xl" mb={2}>
          Your payment failed.
        </Text>
      </Flex>
      <Text color="custom.black">
        Please try again later or try a different payment method.
      </Text>
      <Button
        variantColor="blueVariant"
        mt={6}
        px={10}
        onClick={() => push('/subscription')}
      >
        Go to Subscription
      </Button>
    </>
  );
}

function PaymentStatusPage() {
  return (
    <Elements stripe={promise}>
      <Box maxWidth="1140px" margin="30px auto 0">
        <Heading size="lg" color="custom.black3" mb={6}>
          Payment Status
        </Heading>
        <Box bg="white" borderRadius="6px" boxShadow="custom.primary" p={10}>
          <PaymentStatus />
        </Box>
      </Box>
    </Elements>
  );
}

export default PaymentStatusPage;
