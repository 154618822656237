import React, { useEffect, useCallback, useMemo } from 'react';
import { Box } from '@chakra-ui/core';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './VisualEditor.css';

function FormVisualEditor({ name }) {
  const { register, unregister, setValue, watch } = useFormContext();

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  const onChange = useCallback(
    (value) => {
      setValue(name, value);
    },
    [setValue, name]
  );

  const value = watch(name);

  const modules = useMemo(
    () => ({
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ['clean'],
      ],
    }),
    []
  );

  return (
    <Box mb={6}>
      <ReactQuill
        value={value}
        onChange={onChange}
        theme="snow"
        modules={modules}
      />
      <ErrorMessage name={name} />
    </Box>
  );
}

export default FormVisualEditor;
