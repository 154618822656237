import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/core';
import { get, map, sortBy } from 'lodash-es';

export function mapWordsToGrammarPopover(text, words) {
  if (text === null || text === undefined) text = '';

  // check new api
  text =
    words?.grammar?.[0]?.description || words?.spelling?.[0]?.description
      ? text
      : text.replace(/"/g, '&quot;');

  const detectedWords = [];

  for (let item of get(words, 'grammar', [])) {
    const word = text.substr(item.offset, item.length);
    detectedWords.push({ ...item, type: 'grammar', word });
  }

  for (let item of get(words, 'spelling', [])) {
    const word = text.substr(item.offset, item.length);
    detectedWords.push({ ...item, type: 'spelling', word });
  }

  const sortedDetectedWords = sortBy(detectedWords, (item) => item.offset);

  const splitByCharacters = text.split('');

  let offsetOffseted = 0;

  const elementByIndex = [];

  for (let word of sortedDetectedWords) {
    const index = word.offset - offsetOffseted;

    splitByCharacters.splice(index, word.length, ' __PLACEHOLDER__ ');
    elementByIndex.push(
      <PopoverWord key={word.offset} label={word.word} data={word} />
    );
    offsetOffseted += word.length - 1;
  }

  let unescapedCharacters = splitByCharacters
    .join('')
    .split(' ')
    .map((word) => {
      return word.replace(/&quot;/g, '"');
    });

  for (let index in unescapedCharacters) {
    if (unescapedCharacters[index].includes('__PLACEHOLDER__')) {
      unescapedCharacters[index] = elementByIndex[0];
      elementByIndex.shift();
    } else {
      unescapedCharacters[index] =
        (index !== 0 &&
        ![',', '.', '!', '"', '"'].includes(unescapedCharacters[index])
          ? ' '
          : '') +
        unescapedCharacters[index] +
        ' ';
    }
  }

  return unescapedCharacters;
}

const PopoverWord = memo(({ label, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  useEffect(() => {
    open();
  }, []);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={open}
      onClose={close}
      closeOnBlur={false}
      placement="top-start"
    >
      <PopoverTrigger>
        <Box as="span" bg="custom.red" color="white" cursor="pointer">
          {label}
        </Box>
      </PopoverTrigger>
      <PopoverContent zIndex={4} borderRadius="10px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p={4}>
          <Flex mb={4}>
            <Box
              width="10px"
              height="10px"
              borderRadius="5px"
              bg="custom.red"
              mr={2}
            />
            <Box mt="-3px" flex={1}>
              <Text
                textTransform="capitalize"
                lineHeight={1}
                mb={1}
                fontSize="14px"
                color="custom.black"
              >
                {get(data, 'type')}
              </Text>
              <Text fontSize="11px" color="custom.gray5">
                {get(data, 'message') || get(data, 'description')?.en}
              </Text>
            </Box>
          </Flex>
          {(data.better?.length || !!data.replacements?.length) && (
            <Flex>
              <Box
                width="10px"
                height="10px"
                borderRadius="5px"
                bg="custom.yellow"
                mr={2}
              />
              <Box mt="-3px">
                <>
                  <Text
                    lineHeight={1}
                    mb={1}
                    fontSize="14px"
                    color="custom.black"
                  >
                    Possible Correction(s)
                  </Text>
                  <Text fontSize="11px" color="custom.gray5">
                    {data.better && data.better.join(', ')}
                    {map(get(data, 'replacements', []), 'value').join(', ')}
                  </Text>
                </>
              </Box>
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
