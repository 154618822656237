import { useState, useEffect, useCallback } from 'react';

function useAudio(url, currentTime, duration) {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    if (currentTime) {
      audio.currentTime = currentTime;
    }
  }, [currentTime, audio, playing]);

  const onTimeUpdate = useCallback(() => {
    if (duration && currentTime) {
      if (audio.currentTime - currentTime >= duration - 0.1) {
        setPlaying(false);
        audio.pause();
      }
    }
  }, [audio, duration, currentTime]);

  useEffect(() => {
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('timeupdate', onTimeUpdate);
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio, onTimeUpdate]);

  return [toggle, playing];
}

export default useAudio;
