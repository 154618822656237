import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import DummyPlayer from '../../Components/Audio/DummyPlayer';
import headsetImage from '../../../../../assets/images/headset.jpg';

function HeadsetCheckStep() {
  return (
    <Stack spacing={4}>
      <Flex justify="space-between">
        <Stack spacing={4}>
          <Heading size="md">Headset Check</Heading>
          <Text>
            This is an opportunity to check that your headset is working
            correctly.
          </Text>
          <Stack spacing={1}>
            <Text>
              1. Put your headset on and adjust it so that it fits comfortably
              over your ears
            </Text>
            <Text>
              2. When you are ready, click on the{' '}
              <Text as="strong" color="custom.blue">
                Play
              </Text>{' '}
              button. You will hear a short recording
            </Text>
            <Text>
              3. if you do not hear anything in your headphones whilte the
              status reads{' '}
              <Text as="strong" color="custom.blue">
                Playing
              </Text>
              , raise your hand to get the attention of the Test Administrator
            </Text>
          </Stack>
        </Stack>
        <Image src={headsetImage} />
      </Flex>

      <DummyPlayer
        audio={
          new Audio('https://i3.cdn.aiwas.io/assets/audio/headphone-test.wav')
        }
      />

      <Box as="ul" ml={4} mt={10}>
        <li>
          During the test you will not have{' '}
          <Text as="strong" color="custom.blue">
            Play
          </Text>{' '}
          and{' '}
          <Text as="strong" color="custom.blue">
            Stop
          </Text>{' '}
          buttons. The audio recording will start playing automatically
        </li>
        <li>
          Please do not remove your headset. You should wear it throughout the
          test
        </li>
      </Box>
    </Stack>
  );
}

export default HeadsetCheckStep;
