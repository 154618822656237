import { useToast } from '@chakra-ui/core';

function useCustomToast() {
  const toast = useToast();

  const success = ({ title, message }) => {
    toast({
      position: 'top',
      title,
      message,
      status: 'success',
      duration: 6000,
    });
  };

  const error = ({ title, description }) => {
    toast({
      position: 'top',
      title,
      description,
      status: 'error',
      duration: 6000,
    });
  };

  return {
    toast,
    error,
    success,
  };
}

export default useCustomToast;
