import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/core';
import { truncate } from 'lodash-es';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import CustomBadge from '../../../../components/Badge/Badge';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import Datatable from '../../../../components/Datatable/Datatable';
import DatatablePagination from '../../../../components/Datatable/DatatablePagination';
import { stripHtml } from '../../../../helpers/stripHtml';
import { routesMapper } from '../../../../Routes/routesMapper';
import { customColors } from '../../../../theme/theme';

const getColumns = ({ moduleName, section }) => [
  {
    Header: 'Name',
    accessor: ({ description, status }) => (
      <Flex>
        {description && truncate(stripHtml(description), { length: 50 })}
        {!status && (
          <CustomBadge
            backgroundColor="custom.red"
            color="custom.white5"
            mx={2}
          >
            Draft
          </CustomBadge>
        )}
      </Flex>
    ),
  },
  { Header: 'Priority', accessor: 'priority' },
  {
    Header: 'Label',
    accessor: ({ labels }) => (
      <Stack isInline spacing={3}>
        {labels.map(({ name, color }, index) => (
          <CustomBadge key={index} color={color} mr={2}>
            {name}
          </CustomBadge>
        ))}
      </Stack>
    ),
  },
  {
    id: 'actions',
    accessor: ({ id }) => (
      <Button
        as={Link}
        target="_blank"
        to={`${routesMapper[section][moduleName].PRACTISE}/${id}`}
        size="sm"
        variant="outline"
        variantColor="blueVariant"
      >
        View
      </Button>
    ),
  },
];

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox size="lg" ref={resolvedRef} isChecked={checked} {...rest} />
      </>
    );
  }
);

function QuestionsDatatableFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const [isShowSelected, setIsShowSelected] = useState(false);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter({
      searchValue: value || undefined,
      isShowSelected,
    });
  }, 200);

  return (
    <Flex>
      <PrimaryButton
        onClick={() => {
          setIsShowSelected(!isShowSelected);
          setGlobalFilter({
            searchValue: value || undefined,
            isShowSelected: !isShowSelected,
          });
        }}
        variant={isShowSelected ? 'solid' : 'outline'}
        variantColor="blueVariant"
        mr={4}
      >
        Show Selected
      </PrimaryButton>
      <InputGroup>
        <InputLeftElement>
          <Icon name="search" color="blue.300" />
        </InputLeftElement>
        <Input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search ${count} records...`}
          variant="outline"
        />
      </InputGroup>
    </Flex>
  );
}

const handleGlobalFilter = (rows, _, value) => {
  return rows.filter((row) => {
    if (!value?.searchValue) {
      return !value?.isShowSelected || row.isSelected === value?.isShowSelected;
    }
    return (
      row.original?.description
        ?.toLowerCase()
        .includes(value?.searchValue?.toLowerCase()) &&
      (!value?.isShowSelected || row.isSelected === value?.isShowSelected)
    );
  });
};

const getRowId = (row) => row.id;

function MockQuestionsDatatable({
  questions,
  initialSelectedRowIds = {},
  section,
  moduleName,
  onChangeSelection,
}) {
  const data = useMemo(() => questions[moduleName], [questions, moduleName]);
  const columns = useMemo(() => getColumns({ moduleName, section }), [
    moduleName,
    section,
  ]);
  const table = useTable(
    {
      data,
      columns,
      initialState: { selectedRowIds: initialSelectedRowIds },
      globalFilter: handleGlobalFilter,
      getRowId,
    },
    useRowSelect,
    useGlobalFilter,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Cell: ({ row }) => (
            <Box ml={2}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </Box>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setGlobalFilter,
    preGlobalFilteredRows,
    state: { pageIndex, globalFilter, selectedRowIds },
  } = table;

  useEffect(() => {
    onChangeSelection((state) => ({
      ...state,
      [section]: {
        ...state[section],
        [moduleName]: Object.keys(selectedRowIds),
      },
    }));
  }, [onChangeSelection, moduleName, selectedRowIds, section]);

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={4}>
        <Tag rounded="full" variant="solid" size="sm">
          <TagLabel mr={1}>Selected</TagLabel>{' '}
          <strong>{Object.keys(selectedRowIds)?.length}</strong>
        </Tag>
        <QuestionsDatatableFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
      <Datatable
        {...table}
        getRowProps={(row) => ({
          style: {
            background: row.isSelected ? customColors.blue6 : 'white',
          },
        })}
      />
      {table.flatRows.length === 0 && (
        <Flex justify="center" align="center" py={2} color="custom.gray3">
          No Data Found
        </Flex>
      )}
      <DatatablePagination
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageIndex={pageIndex}
      />
    </Box>
  );
}

export default memo(MockQuestionsDatatable);
