import React, { useMemo, memo } from 'react';
import {
  ReferenceLine,
  Dot,
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Label,
  Tooltip,
  ReferenceArea,
} from 'recharts';
import { get, min, capitalize, map } from 'lodash-es';
import { Text, Box } from '@chakra-ui/core';

const CustomizeTick = memo(({ payload, pitch, x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={12}
        y={-4}
        dy={0}
        textAnchor="end"
        fill="#999"
        fontSize="14px"
        transform="rotate(-90)"
      >
        {get(payload, 'value') === get(pitch, 'max')
          ? 'High'
          : get(payload, 'value') === get(pitch, 'flat')
          ? 'Flat'
          : 'Low'}
      </text>
    </g>
  );
});

const CustomizedDot = (props) => {
  const { cx, cy } = props;

  return <Box as={Dot} cx={cx} cy={cy} r={4} fill="#999" />;
};

const CustomizeTooltip = memo(({ active, payload }) => {
  if (active) {
    return (
      <Box
        color="white"
        fontSize="md"
        bg="#0F3154"
        py={2}
        px={4}
        borderRadius="10px"
      >
        <Text>{capitalize(get(payload, '0.payload.label'))}</Text>
      </Box>
    );
  }
  return null;
});

function PitchChart({ pitch }) {
  const pauses = useMemo(
    () =>
      get(pitch, 'pauses', []).reduce(
        (arr, current) => [...arr, current[0]],
        []
      ),
    [pitch]
  );

  const data = useMemo(() => {
    const pauseXObj = {};

    const mapCordinateX = get(pitch, 'data', []).map((item, index) => {
      let pauseX;

      if (!item.intonation) {
        // if(!) true then new api
        const extent0 = get(item, 'extent.0') * 1000;
        const extent1 = get(item, 'extent.1') * 1000;

        pauseX = extent0;

        for (let pause of pauses) {
          if (extent1 >= pause && extent0 <= pause) {
            pauseX = pause;
            continue;
          }
        }
      } else {
        pauseX = pauses.includes(get(item, 'extent.1'))
          ? get(item, 'extent.1')
          : get(item, 'extent.0');
      }

      const preparedItem = { ...item };

      if (!pauseXObj[pauseX]) {
        pauseXObj[pauseX] = true;
        preparedItem.pauseX = pauseX;
      } else {
        preparedItem.pauseX = index;
      }
      return preparedItem;
    });

    return mapCordinateX;
  }, [pitch, pauses]);

  const pausesToRender = useMemo(() => {
    return map(pauses, (item) => (
      <ReferenceLine
        key={item}
        x={item}
        stroke="#9BA6AA"
        strokeDasharray="10 5"
        label={
          <Label
            fill="#9BA6AA"
            fontSize="12px"
            value="P."
            offset={6}
            position="top"
          />
        }
      />
    ));
  }, [pauses]);

  return (
    <ResponsiveContainer width="100%" aspect={6.0 / 2.0}>
      <LineChart
        margin={{ top: 20, bottom: 10, left: 10, right: 10 }}
        data={data}
      >
        <ReferenceLine fill="red" y={get(pitch, 'flat')} stroke="#F4BB4E" />

        <ReferenceLine
          y={get(pitch, 'flatMax')}
          stroke="#F4BB4E"
          strokeDasharray="5 3"
        />
        <ReferenceLine
          y={get(pitch, 'flatMin')}
          stroke="#F4BB4E"
          strokeDasharray="5 3"
        />
        <ReferenceLine
          y={get(pitch, 'highest')}
          stroke="#FB4E4E"
          strokeDasharray="5 3"
        />
        <ReferenceLine y={get(pitch, 'max')} stroke="#3680A1" />
        <ReferenceLine
          y={get(pitch, 'lowest')}
          stroke="#FB4E4E"
          strokeDasharray="5 3"
        />
        <ReferenceLine y={get(pitch, 'min')} stroke="#3680A1" />

        {pausesToRender}

        <ReferenceArea
          y1={get(pitch, 'flatMax')}
          y2={get(pitch, 'flatMin')}
          fill="rgba(255, 199, 0, 0.2)"
        />

        <ReferenceArea
          y1={get(pitch, 'max')}
          y2={get(pitch, 'flatMax')}
          fill="rgba(0, 255, 117, 0.1)"
        />

        <ReferenceArea
          y1={get(pitch, 'min')}
          y2={get(pitch, 'flatMin')}
          fill="rgba(0, 255, 117, 0.1)"
        />

        <XAxis
          tick={{
            fill: '#666',
            fontSize: '10px',
            angle: -45,
            textAnchor: 'middle',
          }}
          xAxisId={1}
          tickLine={false}
          dataKey="label"
          axisLine={false}
          interval={0}
        />

        <XAxis
          tick={false}
          axisLine={false}
          tickLine={false}
          dataKey="pauseX"
          domain={[0, 'dataMax']}
          height={20}
        />

        <YAxis
          tick={<CustomizeTick pitch={pitch} />}
          ticks={[
            min([get(pitch, 'min'), get(pitch, 'lowest')]),
            get(pitch, 'flat'),
            get(pitch, 'max'),
          ]}
          domain={[
            min([get(pitch, 'min'), get(pitch, 'lowest')]) - 10,
            'dataMax',
          ]}
          interval="preserveStartEnd"
          axisLine={false}
          tickLine={false}
          width={20}
        />
        <Tooltip content={<CustomizeTooltip />} />
        <Line
          dot={<CustomizedDot />}
          type="monotone"
          dataKey="high"
          stroke="#717A7D"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default memo(PitchChart);
