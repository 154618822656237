import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStatesByCountry } from './action';
import { apiRequest } from '../../api/api';

export function useStatesByCountryId(id) {
  const { statesByCountryId } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && !statesByCountryId[id]) {
      dispatch(getStatesByCountry(id));
    }
  }, [id, dispatch, statesByCountryId]);

  return statesByCountryId;
}

export function useCitiesByStateId(id) {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setData([]);

    async function fetchData() {
      try {
        const { data } = await apiRequest('get', `utils/cities/${id}`);
        setData(data);
      } catch {}
    }

    if (id) {
      fetchData();
    }
  }, [dispatch, id]);

  return data;
}
