import React from 'react';
import { Box, Text, Button, Stack } from '@chakra-ui/core';
import { GoAlert } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { extensionIds } from '../../../constants/packages';

function PredictionValidityExpire() {
  return (
    <Box maxWidth="900px" margin="auto" p={10}>
      <Box bg="white" p={10} boxShadow="custom.primary" borderRadius="6px">
        <Stack spacing={6} alignItems="center">
          <Box as={GoAlert} color="custom.red" fontSize="5xl" />
          <Text fontSize="lg" color="custom.red">
            You dont have sufficient validity to access prediction list.
          </Text>
          <Button
            to={`/dashboard/checkout?id=${extensionIds.prediction}&type=extension`}
            as={Link}
            variantColor="blueVariant"
            px={10}
          >
            UPGRADE PREDICTION VALIDITY
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default PredictionValidityExpire;
