import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Box, Flex, Button } from '@chakra-ui/core';
import TitleBar from '../../../../../components/Question/TitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash-es';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { handleApiError } from '../../../../../helpers/handleApiError';
import {
  draftPublishQuestion,
  evaluate,
} from '../../../../../store/modules/reading/questionSlice';
import {
  getComments,
  addComment,
  deleteComment,
  editComment,
} from '../../../../../store/modules/reading/commentSlice';
import Community from '../../../../../components/Community/Community';
import Score from '../../../../../components/Community/Scores/Score';
import { useTimer } from '../../../../../hooks/useTimer';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import ReportBug from '../../../../../components/ApplicationModals/ReportBug';
import AppContext from '../../../../../Routes/AppContext';
import { LoaderWrapper } from '../../../../../components/Loader/Loader';
import useFetchQuestion from '../hooks/useFetchQuestion';
import { modules } from '../../../../../constants/modules';
import { defaultQueryWithSection } from './utils/defaultQueryObject';
import { routesMapper } from '../../../../../Routes/routesMapper';
import { paths } from './utils/paths';
import { sections } from '../../../../../constants/sections';
import { useRef } from 'react';
import SeenQuestion from '../../../../../components/SeenQuestion/SeenQuestion';
import QuestionValidityExpire from '../../QuestionValidityExpire';

const rx = /\[\.{4}\d+\.{4}\]/g;

function DragItem({ id, label }) {
  return (
    <Box
      data-type="drag"
      draggable="true"
      as="span"
      id={id}
      bg="white"
      px={2}
      py={1}
      mx={2}
      my={1}
      onDragStart={(event) => {
        event.dataTransfer.setData('id', event.target.id);
      }}
      boxShadow="custom.tertiary"
      borderRadius="5px"
    >
      {label}
    </Box>
  );
}

function DropItem({ refToOther }) {
  return (
    <Box
      data-type="drop"
      display="inline-block"
      minWidth="84px"
      minHeight="30px"
      onDragOver={(event) => event.preventDefault()}
      bg="custom.gray"
      mx={2}
      px={1}
      verticalAlign="middle"
      borderRadius="5px"
      onDrop={(event) => {
        event.preventDefault();
        const data = event.dataTransfer.getData('id');
        if (!event.target.hasChildNodes()) {
          event.target.appendChild(document.getElementById(data));
          return;
        }
        refToOther.appendChild(event.target.childNodes[0]); // set to other box
        event.target.appendChild(document.getElementById(data));
      }}
    />
  );
}

function DragForm({ question, isLoading, onSubmit }) {
  const ref = useRef();

  const paragraphRef = useRef();

  const paragraph = useMemo(() => {
    const text = get(question, 'description', '');

    const splitText = text.split(rx);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = text.match(rx);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [...arr, element, <DropItem refToOther={ref.current} key={index} />]
          : [...arr, element],
      []
    );
  }, [question]);

  const onClick = useCallback(() => {
    const answer = [];
    Array.from(paragraphRef.current.children).forEach((child, index) => {
      if (child.getAttribute('data-type') === 'drop') {
        if (!child.children.length) {
          answer.push('Not Answered');
          return;
        }
        answer.push(child.children[0].innerText);
      }
    });

    onSubmit(answer);
  }, [onSubmit]);

  return (
    <>
      <Box mt={6} lineHeight="2" ref={paragraphRef}>
        {paragraph}
      </Box>
      <Flex
        mt={6}
        borderRadius="5px"
        minHeight="50px"
        bg="custom.gray"
        p={4}
        flexWrap="wrap"
        ref={ref}
        onDragOver={(event) => event.preventDefault()}
        onDrop={(event) => {
          event.preventDefault();
          if (!event.target.id) {
            const data = event.dataTransfer.getData('id');
            event.target.appendChild(document.getElementById(data));
          }
        }}
      >
        {get(question, 'drag', []).map((item, index) => (
          <DragItem id={`drag-${index}`} label={item} key={index} />
        ))}
      </Flex>
      <Button
        mt={4}
        mb={1}
        _hover={{
          backgroundColor: 'custom.blue8',
        }}
        variantColor="blueVariant"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={onClick}
      >
        Submit Answer
      </Button>
    </>
  );
}

function AnswerForm({ question, counter, setScore }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (answer) => {
      setLoading(true);
      const data = await dispatch(
        evaluate(get(question, 'id'), {
          answer,
          duration: counter,
        })
      );
      setLoading(false);
      setScore(data);
    },
    [question, dispatch, counter, setScore]
  );

  return (
    <>
      <TitleBar
        serial={`ID # ${get(question, 'id')}`}
        title="Read the text and drag correct responses."
        targetTime={get(question, 'data.duration')}
        yourTime={counter}
      />
      <DragForm question={question} isLoading={loading} onSubmit={onSubmit} />
    </>
  );
}

function FillBlanksDragView() {
  const [score, setScore] = useState();

  const { id, tab } = useParams();

  const tabIndex = useMemo(() => (tab === 'comments' ? 1 : 0), [tab]);

  const { counter } = useTimer(id, score);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const { isFetching, question, isNotEligible } = useFetchQuestion(id, !score);

  const { byId } = useSelector((state) => state.reading.comments);

  const comments = useMemo(
    () => ({
      allIds: get(question, 'comments'),
      byId,
    }),
    [byId, question]
  );

  // const onDelete = useCallback(
  //   async (id) => {
  //     try {
  //       await dispatch(deleteQuestion(id));
  //       success({ title: 'Deleted successfully.' });
  //     } catch (error) {
  //       handleApiError(error, { toast });
  //     }
  //   },
  //   [dispatch, toast, success]
  // );

  const onDraftPublish = useCallback(
    async (id) => {
      try {
        const data = await dispatch(draftPublishQuestion(id));
        success({
          title: `Question status has been changed to ${
            data.status ? '"Published"' : '"Draft"'
          }!`,
        });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, success]
  );

  useEffect(() => {
    if (!question && !isFetching) {
      push(routesMapper.reading.FillInTheBlanksDrag.PRACTISE);
    }
  }, [question, push, isFetching]);

  const fetchComments = useCallback(() => {
    dispatch(getComments(id));
  }, [dispatch, id]);

  const addQuestionComment = useCallback(
    async (id, values) => {
      await dispatch(addComment(id, { ...values, ...defaultQueryWithSection }));
    },
    [dispatch]
  );

  const editQuestionComment = useCallback(
    async (id, values) => {
      await dispatch(
        editComment(id, { ...values, ...defaultQueryWithSection })
      );
    },
    [dispatch]
  );

  const deleteQuestionComment = useCallback(
    async (id, parentId, questionId) => {
      await dispatch(
        deleteComment(id, parentId, questionId, { ...defaultQueryWithSection })
      );
    },
    [dispatch]
  );

  const { commentsChannel } = useContext(AppContext);

  useEffect(() => {
    commentsChannel.bind('comments.posted', function (data) {
      if (parseInt(get(data, 'data.question_id')) === parseInt(id)) {
        fetchComments();
      }
    });
  }, [id, fetchComments, commentsChannel]);

  useEffect(() => {
    fetchComments();
    setScore(null);
  }, [id, fetchComments]);

  if (isNotEligible) {
    return <QuestionValidityExpire message={isNotEligible} />;
  }

  return (
    <LoaderWrapper loading={isFetching}>
      <QuestionMetabar
        paths={paths}
        rootPath={routesMapper.reading.FillInTheBlanksDrag.PRACTISE}
        id={id}
        showAdd
        showEdit
        showShare
        showDraftPublish
        status={question?.status}
        onConfirmDraftPublish={onDraftPublish}
      />
      <>
        <Box bg="white" p={6} boxShadow="custom.secondary">
          {score ? (
            <Box position="relative">
              <Score
                score={score}
                module={modules.reading.FillInTheBlanksDrag}
                practiseAgain={setScore}
                section={sections.reading}
              />
              <SeenQuestion
                section={sections.reading}
                id={get(question, 'id')}
              />
            </Box>
          ) : (
            <AnswerForm
              question={question}
              counter={counter}
              setScore={setScore}
            />
          )}
        </Box>
        <Flex mt={6} justify="flex-end">
          <ReportBug details={question} />
        </Flex>
        <Community
          question={question}
          comments={comments}
          tabIndex={tabIndex}
          section={sections.reading}
          module={modules.reading.FillInTheBlanksDrag}
          addQuestionComment={addQuestionComment}
          editQuestionComment={editQuestionComment}
          deleteQuestionComment={deleteQuestionComment}
        />
      </>
    </LoaderWrapper>
  );
}

export default FillBlanksDragView;
