import { useState, useEffect } from 'react';
import { apiRequest } from '../api/api';

export function useQuestionOptions({ feature }) {
  const [data, setData] = useState();
  const [loading, setLoadng] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoadng(true);
        const { data } = await apiRequest(
          'get',
          `options/question?feature=${feature}`
        );
        setData(data);
        setLoadng(false);
      } catch {}
    }

    fetchData();
  }, [feature]);

  return { data, loading };
}
