import React from 'react';
import { Box, Flex, Text, SimpleGrid } from '@chakra-ui/core';
import { get } from 'lodash-es';
import {
  FcSpeaker,
  FcReading,
  FcHeadset,
  FcPortraitMode,
} from 'react-icons/fc';
import { getDayDifference, getTimeFromNow } from '../../helpers/getDate';
import StatBox from '../Dashboard/Main/Components/StatBox';
import DashboardLayout from '../Dashboard/Main/Components/DashboardLayout';
import { getModuleStatsData } from '../Dashboard/Main/helpers/stats';
import PractiseChart from '../Dashboard/Main/Components/PractiseChart';
import DashboardCurrentPTELevel from '../Dashboard/Main/Components/Student/DashboardCurrentPTELevel';
import DashboardCurrentPackage from '../Dashboard/Main/Components/Student/DashboardCurrentPackage';
import StudentDashboardSidebar from '../Dashboard/Main/Components/Student/StudentDashboardSidebar';

const previewUserData = {
  user: {
    id: 2852,
    email: 'AIWAS+',
    role: 'student',
    details: {
      id: 1837,
      userId: 2852,
      goals: {
        overall: '80',
        exam_date: '2021-07-29T00:00:00.000+09:30',
      },
    },
    currentPackage: {
      id: 3592,
      userId: 2852,
      packageId: 3,
      package: {
        id: 3,
        name: 'Ultimate',
        reading: {
          limit: 3000,
          validity: 30,
        },
        writing: {
          limit: 3000,
          validity: 30,
        },
        speaking: {
          limit: 3000,
          validity: 30,
        },
        validity: 30,
        community: 1,
        listening: {
          limit: 3000,
          validity: 30,
        },
        remainder: 1,
        costPrice: 10,
        createdAt: '2021-03-24T04:04:00.000000Z',
        fullMocks: 1,
        prediction: {
          validity: 30,
        },
        updatedAt: '2021-03-24T04:04:00.000000Z',
        customMocks: 2,
        targetPrice: 99.99,
        sellingPrice: 49.99,
      },
      fullMocks: 1,
      customMocks: 2,
      speakingStartedAt: '2021-07-09 09:09:37',
      speakingEndsOn: '2021-08-08 09:09:37',
      packageStartedAt: '2021-07-09 09:09:37',
      packageEndsOn: '2021-08-08 09:09:37',
      predictionStartedAt: '2021-07-09 09:09:37',
      predictionEndsOn: '2021-08-08 09:09:37',
      createdAt: '2021-07-09 09:09:37',
    },
  },
  speaking: {
    totalQuestions: 1355,
    practicedQuestions: 600,
    remainingQuestions: 1355,
    completePercentage: 49,
    averageScore: 60,
  },
  writing: {
    totalQuestions: 384,
    practicedQuestions: 102,
    remainingQuestions: 384,
    completePercentage: 37,
    averageScore: 80,
  },
  reading: {
    totalQuestions: 1197,
    practicedQuestions: 90,
    remainingQuestions: 1197,
    completePercentage: 9,
    averageScore: 82,
  },
  listening: {
    totalQuestions: 797,
    practicedQuestions: 493,
    remainingQuestions: 797,
    completePercentage: 0,
    averageScore: 76,
  },
  stats: [
    {
      x: '08-07-2021',
      y1: [],
      y2: [],
      y3: [],
      y4: [],
    },
    {
      x: '09-07-2021',
      y1: {
        date: '09-07-2021',
        section: 'Speaking',
        count: 140,
        overall: 62,
      },
      y2: [],
      y3: [],
      y4: {
        date: '09-07-2021',
        section: 'Listening',
        count: 2,
        overall: 76,
      },
    },
  ],
  studyGuide: [
    {
      step: 'Step - One:',
      text: 'Attend diagnostic test',
      status: 'In Progress',
    },
    {
      step: 'Step - Two:',
      text: 'Analyze your PTE Score report using AIWAS',
      status: 'Optional',
    },
    {
      step: 'Step - Three:',
      text: 'Practice all <strong>Priority Level 1</strong> questions',
      status: 'In Progress',
      practiced: 0,
      total: 860,
    },
    {
      step: 'Step - Four:',
      text: 'Practice remaining <strong>AI Exam Prediction</strong> questions',
      status: 'In Progress',
      practiced: 0,
      total: 954,
    },
    {
      step: 'Step - Five:',
      text: 'Practice remaining <strong>Priority Level 2</strong> questions',
      status: 'In Progress',
      practiced: 0,
      total: 1771,
    },
    {
      step: 'Step - Six:',
      text: 'Practice remaining <strong>Priority Level 3</strong> questions',
      status: 'Optional',
      practiced: 0,
      total: 1180,
    },
  ],
};

function PreviewDashboard({ userData = previewUserData, isSelf }) {
  const { user, ...dashboardData } = userData;

  return (
    <DashboardLayout
      sidebar={
        <StudentDashboardSidebar
          userId={user.id}
          studyGuide={dashboardData.studyGuide}
        />
      }
    >
      <Flex justify="space-between" align="center">
        <Box>
          {isSelf ? (
            <Text fontSize="xl" color="black">
              Howdy
              {get(user, 'details.firstName')
                ? `, ${get(user, 'details.firstName')}`
                : ''}
              !
            </Text>
          ) : (
            <Text fontSize="xl" color="black">
              {get(user, 'details.firstName') || get(user, 'email')}'s Dashboard
            </Text>
          )}
          <Text fontSize="sm">
            Last logged in {getTimeFromNow(get(user, 'lastLoggedIn'))}
          </Text>
        </Box>
        <Box>
          <Text>
            Exam Time Left{' '}
            <Text as="strong" color="custom.blue" ml={2}>
              {get(user, 'details.goals.examDate')
                ? `${getDayDifference(
                    get(user, 'details.goals.examDate')
                  )} days`
                : 'N/A'}
            </Text>
          </Text>
        </Box>
      </Flex>
      <SimpleGrid mt={8} columns={4} gridColumnGap={6}>
        <StatBox
          icon={FcSpeaker}
          label="Speaking"
          progress={{
            label: getModuleStatsData(dashboardData.speaking).ratio.label,
            value: getModuleStatsData(dashboardData.speaking).ratio.value,
            color: 'blueVariant',
          }}
        />
        <StatBox
          icon={FcReading}
          label="Reading"
          progress={{
            label: getModuleStatsData(dashboardData.reading).ratio.label,
            value: getModuleStatsData(dashboardData.reading).ratio.value,
            color: 'greenVariant',
          }}
        />
        <StatBox
          icon={FcHeadset}
          label="Listening"
          progress={{
            label: getModuleStatsData(dashboardData.listening).ratio.label,
            value: getModuleStatsData(dashboardData.listening).ratio.value,
            color: 'redVariant',
          }}
        />
        <StatBox
          icon={FcPortraitMode}
          label="Writing"
          progress={{
            label: getModuleStatsData(dashboardData.writing).ratio.label,
            value: getModuleStatsData(dashboardData.writing).ratio.value,
            color: 'yellow',
          }}
        />
      </SimpleGrid>
      <PractiseChart data={get(dashboardData, 'stats')} />

      <SimpleGrid mt={8} columns={2} gridColumnGap={6}>
        <DashboardCurrentPTELevel
          data={dashboardData}
          overallGoal={get(user, 'details.goals.overall')}
        />
        <DashboardCurrentPackage currentPackage={get(user, 'currentPackage')} />
      </SimpleGrid>
    </DashboardLayout>
  );
}

export default PreviewDashboard;
