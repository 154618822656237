import { get } from 'lodash-es';

export const getError = (error) => {
  const errorObject = get(error, 'response.data.errors');
  let errorArray;
  if (errorObject) {
    errorArray = Object.values(errorObject);
  } else {
    errorArray = [get(error, 'response.data.message', 'An error occurred.')];
  }

  return errorArray[0];
};

export const handleApiError = (error, { toast }) => {
  toast({
    position: 'top',
    title: getError(error),
    status: 'error',
    duration: 2000,
    isClosable: true,
  });
};
