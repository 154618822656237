import React, { useMemo } from 'react';
import { Box, Text, Stack, Flex } from '@chakra-ui/core';
import { isArray, get, map, filter } from 'lodash-es';
import AudioPlayerControl from '../../../../components/AudioPlayer/AudioPlayerControl';

function PredictionMCQView({ description, data, section, audio }) {
  const answers = useMemo(
    () => (isArray(data.answer) ? data.answer : [data.answer]),
    [data]
  );

  const optionsMapped = useMemo(
    () =>
      get(data, 'options', []).reduce(
        (obj, current, index) => ({
          ...obj,
          [current]: String.fromCharCode(index + 97),
        }),
        {}
      ),
    [data]
  );

  return (
    <Box>
      {section === 'listening' ? (
        <Box mb={10}>
          <AudioPlayerControl audioUrl={audio} />
        </Box>
      ) : (
        <Text dangerouslySetInnerHTML={{ __html: description }} />
      )}
      <Stack mt={6} spacing={4}>
        {map(optionsMapped, (key, option) => (
          <Flex key={key} align="flex-start">
            <Text mr={1} textTransform="uppercase" fontWeight={600}>
              {key})
            </Text>
            <Text>{option}</Text>
          </Flex>
        ))}
      </Stack>

      <Text textTransform="uppercase" mt={4} fontWeight={600}>
        <Text color="custom.blue" as="span" mr={1}>
          Answer:
        </Text>
        {filter(optionsMapped, (item, key) => answers.includes(key)).join(', ')}
      </Text>
    </Box>
  );
}

export default PredictionMCQView;
