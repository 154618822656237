import React, { memo, useMemo } from 'react';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Button,
  Text,
} from '@chakra-ui/core';
import { FiPlay } from 'react-icons/fi';
import { get } from 'lodash-es';
import { getSpeakingResultText } from '../../../../../constants/speakingColor';
import useAudio from '../../../../../hooks/useAudio';
import { textToSpeech } from '../../../../../helpers/textToSpeech';
import { useCurrentUser } from '../../../../../store/user/hooks';

function PlayButton({ label, onClick, bg = 'custom.blue' }) {
  return (
    <Flex direction="column" justify="center" align="center">
      <Button
        variant="ghost"
        boxShadow="custom.tertiary"
        backgroundColor={bg}
        color="white"
        width="50px"
        height="50px"
        borderRadius="25px"
        onClick={onClick}
        _active={{
          backgroundColor: bg,
          color: 'white',
          opacity: 0.8,
        }}
        _hover={{
          backgroundColor: bg,
          color: 'white',
          opacity: 0.8,
        }}
      >
        <Box as={FiPlay} />
      </Button>
      <Text
        textTransform="uppercase"
        color="custom.blue"
        fontWeight={600}
        mt={1}
      >
        {label}
      </Text>
    </Flex>
  );
}

function PopoverSpeechWord({ label, labelBg, data, audioUrl }) {
  const user = useCurrentUser();

  const fluency = useMemo(() => getSpeakingResultText(data.fluencyScore), [
    data.fluencyScore,
  ]);
  const pronounciation = useMemo(
    () => getSpeakingResultText(data.pronunciationScore),
    [data.pronunciationScore]
  );

  const time = useMemo(() => {
    if (data?.syllables) {
      // if true then old api
      const syllables = get(data, 'syllables', []);
      if (syllables.length) {
        const firstSyllableStartTime = get(syllables, '0.syllable.extent.0');
        const lastSyllableEndTime = get(
          syllables,
          `${syllables.length - 1}.syllable.extent.1`
        );
        return {
          start: firstSyllableStartTime / 100,
          duration: (lastSyllableEndTime - firstSyllableStartTime) / 100,
        };
      }
    }
    return {
      start: data?.extent?.[0],
      duration: data?.extent?.[1] - data?.extent?.[0],
    };
  }, [data]);

  const [toggle] = useAudio(
    audioUrl,
    get(time, 'start'),
    get(time, 'duration')
  );

  return (
    <Popover placement="top-start">
      <PopoverTrigger>
        <Box
          display="inline-block"
          mr="6px"
          mb="4px"
          py={0.5}
          px={1}
          bg={labelBg}
          color="white"
          cursor="pointer"
        >
          {label}
        </Box>
      </PopoverTrigger>
      <PopoverContent zIndex={4} borderRadius="10px" minW="440px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p={4}>
          <Box>
            <Flex justify="space-around" width="100%">
              <PlayButton
                label="Your Voice"
                onClick={toggle}
                bg="custom.yellow"
              />
              <PlayButton
                label="Native Voice"
                onClick={() => textToSpeech(label, get(user, 'details.gender'))}
              />
            </Flex>
            <Box bg="custom.white6" borderRadius="5px" px={4} py={2} mt={4}>
              <Text color="custom.black">
                Fluency is{' '}
                <Text as="span" color={fluency.color} fontWeight={600}>
                  {fluency.text}
                </Text>
              </Text>
              <Text color="custom.black">
                Pronounciation is{' '}
                <Text as="span" color={pronounciation.color} fontWeight={600}>
                  {pronounciation.text}
                </Text>
              </Text>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default memo(PopoverSpeechWord);
