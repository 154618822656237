import React, { useMemo } from 'react';
import { Flex, Box, Grid } from '@chakra-ui/core';

export function LayoutBox({ children, boxProps, ...props }) {
  return (
    <Flex direction="column" {...props}>
      <Box p={4} {...boxProps}>
        {children}
      </Box>
    </Flex>
  );
}

export function AuthForm({ children }) {
  const boxProps = useMemo(
    () => ({
      backgroundColor: '#fff',
      boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
      p: '40px',
      width: '500px',
      borderRadius: '4px',
    }),
    []
  );

  return (
    <LayoutBox align="center" boxProps={boxProps}>
      {children}
    </LayoutBox>
  );
}

export function ContainerBox({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

function Container({ children, columns = '2fr 1fr', ...props }) {
  return (
    <Grid templateColumns={columns} {...props}>
      {children}
    </Grid>
  );
}

export default Container;
