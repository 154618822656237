import { useCallback, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading, Image, SimpleGrid } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import Recorder from '../../../../../../components/Recorder/Recorder';
import MockRecorderView from '../../../Components/Audio/Recorder';
import { speakingDuration } from '../../../../../../constants/speakingDuration';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { sections } from '../../../../../../constants/sections';
import { apiRequest } from '../../../../../../api/api';

function MockQuestionSpeakingDescribeImageView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error } = useCustomToast();
  const onSubmit = useCallback(
    async (blob) => {
      try {
        setIsSubmitting(true);
        setTimerDelay(null);
        const formData = new FormData();

        formData.append('answer', blob);
        formData.append('type', mock.type);
        formData.append('section', sections.speaking);
        formData.append('question_id', data.id);
        formData.append('module', data.module);
        formData.append('index', index);

        await apiRequest('post', 'mocks/autoSave', formData);
        setTimerDelay(1000);

        onAfterSubmit();
      } catch {
        error({
          title: 'Looks like something went wrong!',
          description:
            'Please check your network connection and try reloading.',
        });
        setIsSubmitting(false);
        setTimerDelay(1000);
      }
    },
    [onAfterSubmit, error, mock, index, data, setTimerDelay]
  );

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <Recorder
          initialBeginningDuration={speakingDuration.describeImage.beginning}
          duration={get(data, 'duration')}
          hasBeep
        >
          {({
            duration,
            isDenied,
            status,
            beginningDuration,
            reverseRecordingDuration,
            stopRecording,
            isGettingBlob,
          }) => (
            <>
              <Box py={10} px={6}>
                <Heading size="sm" mb={8}>
                  Look at the image below. In{' '}
                  {speakingDuration.describeImage.beginning} seconds, please
                  speak into the microphone and describe in detail what the
                  image is showing. You will have {data.duration} seconds to
                  give your response.
                </Heading>
                <SimpleGrid mt={6} px={20} columns={2} spacing={3}>
                  <Flex justify="center">
                    <Image
                      margin="0 auto"
                      minW={400}
                      maxW={500}
                      w="100%"
                      src={get(data, 'image')}
                    />
                  </Flex>
                  <Flex justify="center" align="center">
                    <MockRecorderView
                      isDenied={isDenied}
                      status={
                        status || `beginning in ${beginningDuration} seconds.`
                      }
                      duration={duration}
                      reverseRecordingDuration={reverseRecordingDuration}
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
              <Box>
                <ActionButtons
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                  onConfirmNext={() => stopRecording(onSubmit)}
                  isSubmitting={isSubmitting || isGettingBlob}
                  isNextButtonDisabled={!status}
                />
              </Box>
            </>
          )}
        </Recorder>
      )}
    </StepLayout>
  );
}

export default MockQuestionSpeakingDescribeImageView;
