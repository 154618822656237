import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading, Input } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import MockAudioPlayerBare from '../../../Components/Audio/Player';
import { replaceRegEx } from '../../../../../../helpers/regex';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

function MockQuestionListeningFillBlanksView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAudioPlayingEnded, setIsAudioPlayingEnded] = useState(false);

  const { error } = useCustomToast();
  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setTimerDelay(null);

      const formData = new FormData(document.getElementById('fillblanks-form'));

      const answer = formData
        .getAll('answer')
        .map((item) => item || 'Not Answered');

      await apiRequest('post', 'mocks/autoSave', {
        answer,
        type: mock.type,
        section: sections.listening,
        question_id: data.id,
        module: data.module,
        index,
      });

      setTimerDelay(1000);

      onAfterSubmit();
    } catch {
      error({
        title: 'Looks like something went wrong!',
        description: 'Please check your network connection and try reloading.',
      });
      setIsSubmitting(false);
      setTimerDelay(1000);
    }
  }, [onAfterSubmit, error, mock, index, data, setTimerDelay]);

  const formRef = useRef();

  useEffect(() => {
    if (formRef.current) {
      formRef.current.reset();
    }
  }, [data.id]);

  const paragraph = useMemo(() => {
    const text = get(data, 'description', '');

    const splitText = text.split(replaceRegEx);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = text.match(replaceRegEx);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <Input
                key={index}
                display="inline-flex"
                width="100%"
                maxWidth="180px"
                height="26px"
                mx="5px"
                p={2}
                name="answer"
                autoComplete="off"
              />,
            ]
          : [...arr, element],
      []
    );
  }, [data]);

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box py={10} px={6}>
            <Heading size="sm" mb={8}>
              You will hear a recording. Type the missing words in each blank.
            </Heading>
            <Flex justify="center" my={6}>
              <MockAudioPlayerBare
                beginningDuration={7}
                url={get(data, 'audio')}
                onEndCallback={() => setIsAudioPlayingEnded(true)}
              />
            </Flex>

            <Box
              as="form"
              id="fillblanks-form"
              mt={6}
              ref={formRef}
              onSubmit={onSubmit}
            >
              <Box lineHeight={2}>{paragraph}</Box>
            </Box>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={onSubmit}
              isSubmitting={isSubmitting}
              isNextButtonDisabled={!isAudioPlayingEnded}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionListeningFillBlanksView;
