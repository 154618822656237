import { changeToCamelCase } from '../../helpers/changeCase';
import { keyBy, map } from 'lodash-es';

export const FETCH_LABEL_LIST_RESOLVED = 'FETCH_LABEL_LIST_RESOLVED';

export const getLabels = () => async (dispatch, _, api) => {
  const { data } = await api('get', 'labels');
  dispatch({ type: FETCH_LABEL_LIST_RESOLVED, data });
  return data;
};

const initialState = {
  allIds: [],
  byId: {},
  isLoading: true,
};

const labelReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_LABEL_LIST_RESOLVED:
      return {
        ...state,
        allIds: map(data, (item) => item.id),
        byId: keyBy(changeToCamelCase(data), 'id'),
      };
    default:
      return state;
  }
};

export default labelReducer;
