import { useState, useEffect } from 'react';
import { apiRequest } from '../../api/api';
import { changeToCamelCase } from '../../helpers/changeCase';

export function usePaymentsHistory(userId, { page = 1 } = {}) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);

      const { data } = await apiRequest(
        'get',
        `payment/history/user/${userId}?page=${page}`
      );
      setData(changeToCamelCase(data));
      setFetching(false);
    }
    fetchData();
  }, [userId, page]);

  return [data, fetching];
}
