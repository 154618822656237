import React, { memo, useCallback } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  Flex,
  MenuGroup,
  Text,
} from '@chakra-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { take } from 'lodash-es';
import { AiOutlineBell } from 'react-icons/ai';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRouteByService } from '../../helpers/getRoutes';
import {
  markNotificationAsSeenRequest,
  markAllNotificationAsSeenRequest,
} from '../../store/nofitication/notificationSlice';
import NotificationItem from '../Notification/NotificationItem';

function NavItem({ id, notification, createdAt, seenAt }) {
  const { push } = useHistory();

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(markNotificationAsSeenRequest(id));
    const link = getRouteByService(notification);
    push(link);
  }, [push, notification, id, dispatch]);

  return (
    <MenuItem
      lineHeight="1.5"
      color={!seenAt ? 'custom.blue7' : 'custom.gray5'}
      transition="all .3s"
      _focus={{
        backgroundColor: 'none',
        color: !seenAt ? 'custom.blue8' : 'custom.orange2',
      }}
      _active={{
        backgroundColor: 'none',
        color: !seenAt ? 'custom.blue8' : 'custom.orange2',
      }}
      fontSize="14px"
      width="100%"
      onClick={onClick}
      mb={1}
      backgroundColor={!seenAt ? 'custom.blue6' : ''}
    >
      <NotificationItem notification={notification} createdAt={createdAt} />
    </MenuItem>
  );
}

function NotificationPopover() {
  const notification = useSelector((state) => state.notification);

  const notifications = useMemo(
    () =>
      take(
        notification.allIds.map((id) => notification.byId[id]),
        10
      ),
    [notification]
  );

  const dispatch = useDispatch();

  const markAllAsSeen = useCallback(() => {
    dispatch(markAllNotificationAsSeenRequest());
  }, [dispatch]);

  return (
    <Menu autoSelect={false}>
      <MenuButton
        bg="transparent"
        as={Button}
        _hover={{
          backgroundColor: 'none',
          color: 'custom.blue',
        }}
        _active={{
          backgroundColor: 'none',
          color: 'custom.blue',
        }}
      >
        <Box position="relative">
          <Flex
            align="center"
            justify="center"
            height="30px"
            minWidth="30px"
            borderRadius="50%"
            border="2px solid white"
            position="absolute"
            left="10px"
            top={-15}
            p="2px"
            bg="custom.red"
            color="white"
            fontSize="xs"
            fontWeight="700"
          >
            {notification.totalUnseen}
            {!!notification.totalUnseen && '+'}
          </Flex>
          <Box fontSize="2xl" as={AiOutlineBell} color="custom.blue" />
        </Box>
      </MenuButton>
      <MenuList
        borderRadius={0}
        zIndex={2}
        border="none"
        left="unset !important"
        right="0"
        transform="translate(0, 68px) !important"
        boxShadow="0px 9px 28px rgba(0, 0, 0, 0.07) !important"
      >
        <MenuGroup>
          <Flex
            justify="space-between"
            align="center"
            px="30px"
            pt="15px"
            mb="20px"
          >
            <Text fontSize="14px" fontWeight={600} color="custom.gray5" mr={4}>
              {notification.totalUnseen} Unread Notifications
            </Text>
            <Button
              p={0}
              m={0}
              fontWeight={600}
              onClick={markAllAsSeen}
              color="custom.blue"
              variant="link"
              fontSize="14px"
            >
              Mark all as read
            </Button>
          </Flex>
          {notifications.map((notification) => (
            <NavItem key={notification.id} {...notification} />
          ))}
          <MenuItem
            lineHeight="1.5"
            color="custom.blue"
            transition="all .3s"
            _focus={{
              backgroundColor: 'none',
              color: 'custom.orange2',
            }}
            _active={{
              backgroundColor: 'none',
              color: 'custom.orange2',
            }}
            fontSize="14px"
            width="auto"
            as={Link}
            to="/dashboard/notifications"
            ml={3}
            fontWeight={600}
          >
            View All Notifications
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

export default memo(NotificationPopover);
