import React, { useMemo, useCallback, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Icon,
} from '@chakra-ui/core';
import CommentForm from './CommentForm';
import { UserAvatar } from '../../Avatar/Avatar';
import { get, takeRight } from 'lodash-es';
import { getRelativeTime } from '../../../helpers/getDate';
import Form from '../../Form/Form';
import { useForm } from 'react-hook-form';
import FormButton from '../../Form/Button';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import PrimaryButton from '../../Button/PrimaryButton';
import { useCurrentUser } from '../../../store/user/hooks';
import FormTextarea from '../../Form/Textarea';
import { linkify } from '../../../helpers/textReplaces';
import useScrollToHash from '../../../hooks/useScrollToHash';
import { useLocation } from 'react-router-dom';
import { GoVerified } from 'react-icons/go';
import Logo from '../../../assets/images/logo-aiwas.png';

function CommentPopoverForm({ onSubmit, comment, label = 'Submit' }) {
  const defaultValues = useMemo(
    () => ({
      comment,
    }),
    [comment]
  );

  const form = useForm({ defaultValues });

  return (
    <Form form={form} onSubmit={onSubmit} p={2} borderRadius="4px">
      <FormTextarea name="comment" boxProps={{ mb: 0 }} />
      <FormButton size="sm" variant="link" variantColor="blueVariant">
        {label}
      </FormButton>
    </Form>
  );
}

function Delete({
  questionId,
  commentId,
  parentCommentId,
  deleteQuestionComment,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfirm = useCallback(async () => {
    await deleteQuestionComment(commentId, parentCommentId, questionId);
    onClose();
  }, [commentId, deleteQuestionComment, onClose, parentCommentId, questionId]);

  return (
    <>
      <Popover
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
      >
        <PopoverTrigger>
          <Button ml={2} size="sm" variantColor="blueVariant" variant="link">
            Delete
          </Button>
        </PopoverTrigger>
        <PopoverContent zIndex={4}>
          <PopoverHeader fontWeight="semibold">Confirmation</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            Are you sure you want to delete this comment?
          </PopoverBody>
          <PopoverFooter d="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button variantColor="red" onClick={onConfirm}>
                Confirm
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
}

function Edit({ commentId, comment, editQuestionComment }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = useCallback(
    async ({ comment }) => {
      await editQuestionComment(commentId, changeToSnakeCase({ comment }));
      onClose();
    },
    [commentId, editQuestionComment, onClose]
  );

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
    >
      <PopoverTrigger>
        <Button size="sm" variantColor="blueVariant" variant="link">
          Edit
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex={4} p={2}>
        <CommentPopoverForm onSubmit={onSubmit} comment={comment} />
      </PopoverContent>
    </Popover>
  );
}

function Reply({ commentId, questionId, addQuestionComment }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = useCallback(
    async ({ comment }) => {
      await addQuestionComment(
        questionId,
        changeToSnakeCase({ comment, commentId })
      );
      onClose();
    },
    [commentId, questionId, onClose, addQuestionComment]
  );

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
    >
      <PopoverTrigger>
        <Button mr={2} size="sm" variantColor="blueVariant" variant="link">
          Reply
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex={4} p={2}>
        <CommentPopoverForm onSubmit={onSubmit} />
      </PopoverContent>
    </Popover>
  );
}

function highlightComment(commentId) {
  return parseInt(window.location.hash.substr(1)) === commentId
    ? 'custom.yellow3'
    : '';
}

function Comment({
  commentId,
  nestedCommentId,
  isAuthor,
  user,
  comment,
  createdAt,
  questionId,
  addQuestionComment,
  editQuestionComment,
  deleteQuestionComment,
  role,
}) {
  const currentUser = useCurrentUser();

  return (
    <Flex
      id={nestedCommentId ? nestedCommentId : commentId}
      flex={1}
      align="flex-start"
      mb={4}
    >
      {currentUser.role === 'student' && get(user, 'role') !== 'student' ? (
        <UserAvatar size="32px" src={Logo} name="AIWAS" />
      ) : (
        <UserAvatar
          size="32px"
          src={get(user, 'avatar')}
          name={get(user, 'firstName')}
        />
      )}
      <Box ml={4}>
        <Flex align="center">
          {currentUser.role === 'student' && get(user, 'role') !== 'student' ? (
            <Text color="custom.black" fontSize="14px" mr={2}>
              AIWAS Specialist
              <Icon
                color="custom.blue"
                size={5}
                mt={1}
                ml={1}
                as={GoVerified}
                title="AIWAS Plus Team"
              />
            </Text>
          ) : (
            <Text color="custom.black" fontSize="14px" mr={2}>
              {get(user, 'firstName')}
              {get(user, 'role') !== 'student' && (
                <Icon
                  color="custom.blue"
                  size={5}
                  mt={1}
                  ml={1}
                  as={GoVerified}
                  title="AIWAS Plus Team"
                />
              )}
            </Text>
          )}
          <Text fontSize="xs" color="custom.gray5">
            {getRelativeTime(createdAt)}
          </Text>
        </Flex>
        <Text
          color="custom.black"
          fontSize="18px"
          backgroundColor={highlightComment(
            nestedCommentId ? nestedCommentId : commentId
          )}
          dangerouslySetInnerHTML={{ __html: linkify(comment) }}
        />
        <Box mt={2}>
          <Reply
            commentId={commentId}
            questionId={questionId}
            addQuestionComment={addQuestionComment}
          />
          {isAuthor && (
            <>
              <Edit
                commentId={nestedCommentId ? nestedCommentId : commentId}
                comment={comment}
                editQuestionComment={editQuestionComment}
              />
              <Delete
                questionId={questionId}
                commentId={nestedCommentId ? nestedCommentId : commentId}
                parentCommentId={nestedCommentId && commentId}
                deleteQuestionComment={deleteQuestionComment}
              />
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
}

function Comments({
  questionId,
  comments,
  addQuestionComment,
  editQuestionComment,
  deleteQuestionComment,
}) {
  const data = useMemo(
    () => get(comments, 'allIds', []).map((id) => comments.byId[id]),
    [comments]
  );

  const [nestedCommentsLimit, setNestedCommentsLimit] = useState(5);

  const loadNestedComments = useCallback(() => {
    setNestedCommentsLimit((state) => state + 5);
  }, []);

  const user = useCurrentUser();

  const { hash } = useLocation();

  useScrollToHash(hash);

  return (
    <Box>
      <CommentForm
        questionId={questionId}
        addQuestionComment={addQuestionComment}
      />
      <Text
        mt={10}
        mb={8}
        color="custom.black4"
        fontWeight="600"
        borderBottomWidth="2px"
        borderBottomColor="custom.blue"
        py={2}
        display="inline-block"
      >
        Discussions - Community Stream
      </Text>
      {data.map((comment) => (
        <Box
          mb={6}
          key={comment.id}
          borderBottomWidth="1px"
          borderBottomColor="custom.white4"
          pb={4}
        >
          <Comment
            questionId={questionId}
            {...comment}
            commentId={comment.id}
            isAuthor={user.id === comment.userId}
            addQuestionComment={addQuestionComment}
            editQuestionComment={editQuestionComment}
            deleteQuestionComment={deleteQuestionComment}
          />
          <Box ml={10}>
            {comment.nestedComments.length > nestedCommentsLimit && (
              <PrimaryButton
                size="sm"
                variant="link"
                variantColor="blueVariant"
                onClick={loadNestedComments}
                mb={4}
              >
                ...View older comments
              </PrimaryButton>
            )}
            {takeRight(comment.nestedComments, nestedCommentsLimit).map(
              (nestedComment) => (
                <Comment
                  key={nestedComment.id}
                  questionId={questionId}
                  {...nestedComment}
                  commentId={comment.id}
                  nestedCommentId={nestedComment.id}
                  isNested
                  isAuthor={user.id === nestedComment.userId}
                  addQuestionComment={addQuestionComment}
                  editQuestionComment={editQuestionComment}
                  deleteQuestionComment={deleteQuestionComment}
                />
              )
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default Comments;
