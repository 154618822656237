import React, { useMemo, useCallback, useState } from 'react';
import {
  Box,
  useToast,
  FormLabel,
  Text,
  Stack,
  Button,
  Flex,
} from '@chakra-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from '../../Form/Form';
import { useForm } from 'react-hook-form';
import FormButton from '../../Form/Button';
import { handleApiError } from '../../../helpers/handleApiError';
import FormDatePicker from '../../Form/DatePicker';
import { FormCustomRadio, FormRadioGroup } from '../../Form/Radio';
import { get } from 'lodash-es';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import { getDayDifference, getDate, toISODate } from '../../../helpers/getDate';
import {
  selectUserById,
  updateUserGoal,
} from '../../../store/users/usersSlice';
import ReportAnalyser from '../../ReportAnalyser/ReportAnalyser';
import AnalysedReport from '../../ReportAnalyser/AnalysedReport';

function Card({ label, value, children }) {
  return (
    <Box mb={6}>
      <Text color="custom.black3" textTransform="uppercase" fontWeight={600}>
        {label}
      </Text>
      {children ? children : <Text color="custom.black4">{value}</Text>}
    </Box>
  );
}

function Goal({ userId }) {
  const [isDateEditable, setDateEditable] = useState(false);

  const user = useSelector((state) => selectUserById(state, userId));

  const dispatch = useDispatch();

  const toast = useToast();

  const { examDate, overall } = get(user, 'details.goals') || {};

  const defaultValues = useMemo(
    () => ({
      examDate,
      overall: overall && parseInt(overall),
    }),
    [examDate, overall]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        examDate: Yup.string().nullable(),
        overall: Yup.string().required('Required.'),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const submit = useCallback(
    async ({ examDate, overall }) => {
      try {
        const payload = {
          goals: {
            examDate: toISODate(examDate),
            overall,
          },
        };

        await dispatch(updateUserGoal(changeToSnakeCase(payload), userId));

        toast({
          position: 'top',
          title: 'Successfully updated your goals.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });

        setDateEditable(false);
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, userId]
  );

  return (
    <Box>
      <Form form={form} onSubmit={submit}>
        <Flex align="center" justify="space-between">
          <Box>
            <Card label="Next Exam Date">
              <FormDatePicker
                mt={2}
                name="examDate"
                placeholder="Next Exam date"
                display={isDateEditable ? 'block' : 'none'}
              />
              <Stack
                isInline
                spacing={examDate ? 4 : 0}
                mt={1}
                display={!isDateEditable ? 'flex' : 'none'}
              >
                <Text color="custom.black4">{getDate(examDate)}</Text>
                <Button variant="link" onClick={() => setDateEditable(true)}>
                  Edit Date
                </Button>
                <Text color="custom.orange">
                  {getDayDifference(examDate)} {examDate ? 'days left' : ''}
                </Text>
              </Stack>
            </Card>
            <FormLabel mb={4} fontSize="xl" color="custom.black4">
              What score you aim to achieve?
            </FormLabel>
            <FormRadioGroup isInline name="overall">
              <FormCustomRadio value={79}>All 79 (IELTS 8)</FormCustomRadio>
              <FormCustomRadio value={65}>All 65 (IELTS 7)</FormCustomRadio>
              <FormCustomRadio value={50}>All 50 (IELTS 6)</FormCustomRadio>
            </FormRadioGroup>
          </Box>
          <FormButton alignSelf="flex-start">UPDATE</FormButton>
        </Flex>
      </Form>
      <Box borderTopWidth="1px" pt={6}>
        {userId === user.id && <ReportAnalyser isProfile userId={userId} />}
        {get(user, 'details.analysis') && (
          <AnalysedReport isProfile data={get(user, 'details')} />
        )}
      </Box>
    </Box>
  );
}

export default Goal;
