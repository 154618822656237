import { Box, Flex, useDisclosure } from '@chakra-ui/core';
import { omit } from 'lodash-es';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { modules } from '../../../../constants/modules';
import { useInterval } from '../../../../hooks/useTimer';
import { MockTopbar } from '../Components/Layout';
import TimeOutModal from '../Components/TimeOutModal';
import { virtualSectionKeys } from '../Utils/prepareMockQuestionsSteps';
import ExamEndStep from './FinalSteps/ExamEndStep';
import OptionalBreakStep from './FinalSteps/OptionalBreakStep';
import PartOneOverviewStep from './FinalSteps/PartOneOverviewStep';
import PartThreeOverviewStep from './FinalSteps/PartThreeOverviewStep';
import PartTwoOverviewStep from './FinalSteps/PartTwoOverviewStep';
import MockQuestionListeningDictationView from './QuestionViews/Listening/Dictation';
import MockQuestionListeningFillBlanksView from './QuestionViews/Listening/FillBlanks';
import MockQuestionListeningHighlightCorrectView from './QuestionViews/Listening/HIghlightCorrect';
import MockQuestionListeningHighlightIncorrectView from './QuestionViews/Listening/HighlightIncorrect';
import MockQuestionListeningMCQMultipleView from './QuestionViews/Listening/MCQMultiple';
import MockQuestionListeningMCQSingleView from './QuestionViews/Listening/MCQSingle';
import MockQuestionListeningMissingWordView from './QuestionViews/Listening/MissingWord';
import MockQuestionListeningSummaryView from './QuestionViews/Listening/Summary';
import MockQuestionReadingFillBlanksDragView from './QuestionViews/Reading/FillBlanksDrag';
import MockQuestionReadingFillBlanksDropdownView from './QuestionViews/Reading/FillBlanksDropdown';
import MockQuestionReadingMCQMultipleView from './QuestionViews/Reading/MCQMultiple';
import MockQuestionReadingMCQSingleView from './QuestionViews/Reading/MCQSingle';
import MockQuestionReadingReorderView from './QuestionViews/Reading/Reorder';
import MockQuestionSpeakingAnswerShortView from './QuestionViews/Speaking/AnswerShort';
import MockQuestionSpeakingDescribeImageView from './QuestionViews/Speaking/DescribeImage';
import MockQuestionSpeakingIntroductionView from './QuestionViews/Speaking/Introduction';
import MockQuestionSpeakingReadAloudView from './QuestionViews/Speaking/ReadAloud';
import MockQuestionSpeakingRepeatSentenceView from './QuestionViews/Speaking/RepeatSentence';
import MockQuestionSpeakingRetellLectureView from './QuestionViews/Speaking/RetellLecture';
import MockQuestionWrittenEssayView from './QuestionViews/Writing/Essay';
import MockQuestionWrittenSummaryView from './QuestionViews/Writing/Summary';

const TIMER_DELAY = 1000; //ms

const GeneratedStep = ({
  sectionKey,
  data,
  moduleCounter,
  mock,
  onAfterSubmit,
  setTimerDelay,
  isTimerRunning,
  mockVersion,
}) => {
  // console.log(sectionKey, data, moduleCounter, data.questions?.[moduleCounter]);

  const sectionKeySplitted = sectionKey.includes(':')
    ? sectionKey.split(':')[0]
    : sectionKey;

  const foundStep = useMemo(() => {
    switch (sectionKeySplitted) {
      case virtualSectionKeys.partOneOverview:
        return <PartOneOverviewStep mockVersion={mockVersion} />;
      case virtualSectionKeys.partTwoOverview:
        return <PartTwoOverviewStep mockVersion={mockVersion} />;
      case virtualSectionKeys.optionalBreak:
        return <OptionalBreakStep />;
      case virtualSectionKeys.partThreeOverview:
        return <PartThreeOverviewStep mockVersion={mockVersion} />;
      case virtualSectionKeys.introduction:
        return <MockQuestionSpeakingIntroductionView />;

      case virtualSectionKeys.speaking:
        switch (data.questions[moduleCounter].module) {
          case modules.speaking.ReadAloud:
            return (
              <MockQuestionSpeakingReadAloudView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.speaking.RepeatSentence:
            return (
              <MockQuestionSpeakingRepeatSentenceView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.speaking.DescribeImage:
            return (
              <MockQuestionSpeakingDescribeImageView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.speaking.RetellLecture:
            return (
              <MockQuestionSpeakingRetellLectureView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.speaking.AnswerShortQuestion:
            return (
              <MockQuestionSpeakingAnswerShortView
                data={data.questions[moduleCounter]}
              />
            );
          default:
            return null;
        }
      case virtualSectionKeys.writing:
        switch (data.questions[moduleCounter].module) {
          case modules.writing.SummaryWrittenText:
            return (
              <MockQuestionWrittenSummaryView
                data={data.questions[moduleCounter]}
                isTimerRunning={isTimerRunning}
              />
            );
          case modules.writing.Essay:
            return (
              <MockQuestionWrittenEssayView
                data={data.questions[moduleCounter]}
                isTimerRunning={isTimerRunning}
              />
            );
          default:
            return null;
        }
      case virtualSectionKeys.reading:
        switch (data.questions[moduleCounter].module) {
          case modules.reading.MultipleChoiceSingle:
            return (
              <MockQuestionReadingMCQSingleView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.reading.MultipleChoiceMultiple:
            return (
              <MockQuestionReadingMCQMultipleView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.reading.ReorderParagraph:
            return (
              <MockQuestionReadingReorderView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.reading.FillInTheBlanksDrag:
            return (
              <MockQuestionReadingFillBlanksDragView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.reading.FillInTheBlanksDropDown:
            return (
              <MockQuestionReadingFillBlanksDropdownView
                data={data.questions[moduleCounter]}
              />
            );
          default:
            return null;
        }
      case virtualSectionKeys.summarySpoken:
        switch (data.questions[moduleCounter].module) {
          case modules.listening.SummarySpokenText:
            return (
              <MockQuestionListeningSummaryView
                data={data.questions[moduleCounter]}
                isTimerRunning={isTimerRunning}
              />
            );
          default:
            return null;
        }
      case virtualSectionKeys.listening:
        switch (data.questions[moduleCounter].module) {
          case modules.listening.MultipleChoiceMultiple:
            return (
              <MockQuestionListeningMCQMultipleView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.listening.FillInTheBlanks:
            return (
              <MockQuestionListeningFillBlanksView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.listening.HighlightCorrectSummary:
            return (
              <MockQuestionListeningHighlightCorrectView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.listening.MultipleChoiceSingle:
            return (
              <MockQuestionListeningMCQSingleView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.listening.SelectMissingWords:
            return (
              <MockQuestionListeningMissingWordView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.listening.HighlightIncorrectWords:
            return (
              <MockQuestionListeningHighlightIncorrectView
                data={data.questions[moduleCounter]}
              />
            );
          case modules.listening.WriteFromDictation:
            return (
              <MockQuestionListeningDictationView
                data={data.questions[moduleCounter]}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  }, [data, sectionKeySplitted, moduleCounter, isTimerRunning, mockVersion]);

  return (
    foundStep &&
    React.cloneElement(foundStep, {
      onAfterSubmit,
      mock,
      index: moduleCounter,
      setTimerDelay,
      key: data.questions?.[moduleCounter]
        ? data.questions?.[moduleCounter].id
        : undefined,
    })
  );
};

function isEssayOrSummaryOrIntroduction(sectionKey) {
  return (
    sectionKey?.includes?.('writing') ||
    sectionKey?.includes?.('summary') ||
    sectionKey?.includes?.('introduction')
  );
}

function AttendFinalSteps({ user, mockData }) {
  const {
    sectionCounter: savedSectionCounter = 0,
    moduleCounter: savedModuleCounter = 0,
  } = mockData.stepData || {};

  const [isExamEnded, setIsExamEnded] = useState(false);

  const [stepCounters, setStepCounters] = useState({
    sectionCounter: savedSectionCounter,
    moduleCounter: savedModuleCounter,
  });

  // const [sectionCounter, setSectionCounter] = useState(savedSectionCounter);
  // const [moduleCounter, setModuleCounter] = useState(savedModuleCounter);

  const [timerDelay, setTimerDelay] = useState(null);
  const [timer, setTimer] = useState(null);

  // useMemo(() => {
  //   console.log(mockData);
  // }, [mockData]);

  const { isOpen, onOpen: onOpenTimeOutModal, onClose } = useDisclosure();

  useInterval(() => {
    setTimer((timer) => timer - 1);
  }, timerDelay);

  const {
    stepData: { questionsBySteps, stepsOrder },
  } = mockData;

  const { sectionKey, data } = useMemo(() => {
    return {
      sectionKey: stepsOrder[stepCounters.sectionCounter],
      data: questionsBySteps[stepsOrder[stepCounters.sectionCounter]],
    };
  }, [questionsBySteps, stepCounters.sectionCounter, stepsOrder]);

  const startTimer = useCallback(() => {
    setTimerDelay(TIMER_DELAY);
    setTimer(+data?.duration);
    // setTimer(+data?.duration + 60 * 10);
    // setTimer(6);
  }, [data]);

  useEffect(() => {
    startTimer();
  }, [sectionKey, startTimer]);

  useEffect(() => {
    if (timer && timer <= 0) {
      setTimerDelay(null);
      setTimer(null);
      onOpenTimeOutModal();
    }
  }, [timer, onOpenTimeOutModal]);

  const stateTransitioner = useCallback(
    (sectionTimedOut = false) => {
      // console.log(data.questions, data.total, stepCounters.moduleCounter);

      if (sectionTimedOut) {
        setStepCounters((state) => ({
          sectionCounter: state.sectionCounter + 1,
          moduleCounter: 0,
        }));
        // setSectionCounter((state) => state + 1);
        // setModuleCounter(0);
        return;
      }

      if (data.questions && stepCounters.moduleCounter < data.total - 1) {
        setStepCounters((state) => ({
          ...state,
          moduleCounter: stepCounters.moduleCounter + 1,
        }));
        return;
      }

      if (stepsOrder[stepCounters.sectionCounter + 1]) {
        setStepCounters((state) => ({
          sectionCounter: state.sectionCounter + 1,
          moduleCounter: 0,
        }));
        return;
      }

      setIsExamEnded(true);
    },
    [data, stepCounters, stepsOrder]
  );

  const renderStep = useMemo(() => {
    return (
      <GeneratedStep
        sectionKey={sectionKey}
        data={data}
        moduleCounter={stepCounters.moduleCounter}
        onAfterSubmit={stateTransitioner}
        mock={omit(mockData, 'stepData')}
        setTimerDelay={setTimerDelay}
        isTimerRunning={timerDelay}
        mockVersion={mockData.mockVersion}
      />
    );
  }, [
    sectionKey,
    data,
    stateTransitioner,
    stepCounters.moduleCounter,
    mockData,
    timerDelay,
  ]);

  if (isExamEnded || !data) {
    return <ExamEndStep mockData={mockData} user={user} />;
  }

  return (
    <>
      <Flex direction="column" minH={timer <= 0 ? '100vh' : ''}>
        <Box>
          <MockTopbar
            fullName={user.details.firstName + ' ' + user.details.lastName}
            timer={timer}
            moduleCounter={stepCounters.moduleCounter}
            total={data?.total}
          />
          {((!!timer && timer > 0) ||
            isEssayOrSummaryOrIntroduction(sectionKey)) &&
            renderStep}
        </Box>
      </Flex>
      <TimeOutModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={(onClose) => {
          onClose();
          if (!isEssayOrSummaryOrIntroduction(sectionKey)) {
            window.location.reload();
          }
        }}
      />
    </>
  );
}

export default memo(AttendFinalSteps);
