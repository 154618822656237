import { modules } from '../../../../../../constants/modules';

export const defaultQueryObject = {
  module: modules.reading.FillInTheBlanksDropDown,
};

export const defaultQueryWithSection = {
  module: modules.reading.FillInTheBlanksDropDown,
  section: 'reading',
};
