import { useEffect } from 'react';

const handleEvent = (e) => {
  e = e || window.event;
  if (!e.ctrlKey && !e.metaKey) return;
  switch (e.key) {
    case 'x':
      e.preventDefault();
      e.stopPropagation();
      break;
    default:
  }
};

export function useDisabledCut() {
  useEffect(() => {
    document.addEventListener('keydown', handleEvent);
    return () => document.removeEventListener('keydown', handleEvent);
  }, []);
}
