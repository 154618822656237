import React from 'react';
import { Box } from '@chakra-ui/core';
import { FormContext } from 'react-hook-form';

function Form({ form, onSubmit, ...props }) {
  return (
    <FormContext {...form}>
      <Box as="form" onSubmit={form.handleSubmit(onSubmit)} {...props} />
    </FormContext>
  );
}

export default Form;
