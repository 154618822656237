import React, { useMemo } from 'react';
import { useSubscriptionHistory } from '../../../store/package/hooks';
import { useTable } from 'react-table';
import { Box, Text } from '@chakra-ui/core';
import Datatable from '../../Datatable/Datatable';
import { getDate } from '../../../helpers/getDate';
import { capitalize, get } from 'lodash-es';

const getColumns = () => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Type',
    accessor: ({ type = '' }) => capitalize(type),
  },
  {
    Header: 'Activated on',
    accessor: ({ createdAt }) => getDate(createdAt),
  },
  // {
  //   Header: 'Service Period',
  //   accessor: ({ starts, ends, packageExtension }) =>
  //     starts
  //       ? `${getDate(starts)} - ${getDate(ends)}`
  //       : get(packageExtension, 'validity')
  //       ? `${get(packageExtension, 'validity')} Days`
  //       : 'Until package expires',
  // },
];

function prepareSubscriptionHistory({ packages = [], packageExtensions = [] }) {
  const formattedPackages = packages.map((packageData) => ({
    name: packageData.package.name,
    createdAt: packageData.createdAt,
    price: packageData.package.sellingPrice,
    starts: packageData.packageStartedAt,
    ends: packageData.packageEndsOn,
    type: 'package',
  }));

  const formattedPackageExtensions = packageExtensions.map(
    (packageExtensionData) => ({
      name: packageExtensionData.packageExtension.name,
      createdAt: packageExtensionData.createdAt,
      price: packageExtensionData.packageExtension.sellingPrice,
      packageExtension: packageExtensionData.packageExtension,
      type: 'extension',
    })
  );

  return [...formattedPackages, ...formattedPackageExtensions]
    .map((item, index) => ({ ...item, index }))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
}

function SubscriptionHistory({ userId, ...props }) {
  const subscriptionHistory = useSubscriptionHistory(userId);

  const columns = useMemo(() => getColumns(), []);

  const data = useMemo(() => prepareSubscriptionHistory(subscriptionHistory), [
    subscriptionHistory,
  ]);

  const table = useTable({
    data,
    columns,
  });

  return (
    <Box color="custom.black3" {...props}>
      <Box>
        <Text fontSize="lg" mb={4} textTransform="uppercase" fontWeight={600}>
          Subscription History
        </Text>

        <Datatable {...table} />
      </Box>
    </Box>
  );
}

export default SubscriptionHistory;
