import React, { useMemo, useEffect } from 'react';
import Container from '../../../components/Layout/Container';
import { Heading, Box, Stack, Flex, Button } from '@chakra-ui/core';
import {
  useRouteMatch,
  Switch,
  Route,
  NavLink,
  Redirect,
  useParams,
  Link,
} from 'react-router-dom';
import { customColors } from '../../../theme/theme';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserEntities, fetchUser } from '../../../store/users/usersSlice';
import Profile from '../../../components/User/Profile/Profile';
import EditProfile from '../../../components/User/Profile/EditProfile';
import Security from '../../../components/User/Security/Security';
import Goal from '../../../components/User/Goal/Goal';
import Loader from '../../../components/Loader/Loader';
import CustomBreadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Authorize from '../../../components/Authorize/Authorize';
import { get } from 'lodash-es';
import Subscription from '../../../components/User/Subscription/Subscription';
import Billing from '../../../components/User/Billing/Billing';

const paths = [
  {
    name: 'Users',
  },
  {
    name: 'All Users',
    path: '/dashboard/users',
  },
];

function Sidebar() {
  const { url } = useRouteMatch();

  const routes = useMemo(
    () => getSettingsRoutes().filter((route) => route.label),
    []
  );

  return (
    <Stack spacing={6} m={10}>
      {routes.map((route) => (
        <Authorize key={route.path} permissions={route.permissions}>
          <NavLink
            to={`${url}${route.path}`}
            style={{
              fontSize: '24px',
              marginBottom: '40px',
              color: customColors.blue5,
            }}
            exact
            activeStyle={{ color: customColors.black2 }}
          >
            {route.label}
          </NavLink>
        </Authorize>
      ))}
    </Stack>
  );
}

const getSettingsRoutes = () => [
  {
    label: 'Profile',
    path: '/profile',
    exact: true,
    permissions: 'users.canViewProfile',
    main: ({ ...props }) => <Profile {...props} isSelf={false} />,
  },
  {
    path: '/profile/edit',
    exact: true,
    permissions: 'users.canEditProfile',
    main: ({ ...props }) => <EditProfile {...props} isSelf={false} />,
  },
  {
    label: 'Security',
    path: '/security',
    permissions: 'users.canViewSecurity',
    main: ({ ...props }) => <Security {...props} />,
  },
  {
    label: 'Goal/Target',
    path: '/goal',
    permissions: 'users.canViewGoal',
    main: ({ ...props }) => <Goal {...props} />,
  },
  {
    label: 'Billing',
    path: '/billing',
    permissions: 'users.canViewBillings',
    main: ({ ...props }) => <Billing {...props} />,
  },
  {
    label: 'Subscription',
    path: '/subscription',
    permissions: 'users.canViewSubscription',
    main: ({ ...props }) => <Subscription {...props} isSelf={false} />,
  },
];

function UserView() {
  const { id } = useParams();

  const userEntities = useSelector(selectUserEntities);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userEntities[id]) {
      dispatch(fetchUser(id));
    }
  }, [userEntities, id, dispatch]);

  const { url } = useRouteMatch();

  const routes = useMemo(() => getSettingsRoutes(), []);

  if (!userEntities[id]) return <Loader />;

  return (
    <Box maxWidth="1140px" margin="30px auto 0">
      <Flex mb={6} justify="space-between" align="center">
        <CustomBreadcrumb paths={paths} />
        <Button
          as={Link}
          to={`/dashboard?id=${id}`}
          variant="outline"
          size="sm"
          variantColor="blueVariant"
        >
          VIEW DASHBOARD
        </Button>
      </Flex>
      <Heading size="lg" color="custom.black3" mb={6}>
        {get(userEntities[id], 'details.firstName')}{' '}
        {get(userEntities[id], 'details.lastName')}
      </Heading>
      <Container columns="1fr 3fr">
        <Box boxShadow="custom.primary" bg="white" borderRadius="6px 0 0 6px">
          <Sidebar />
        </Box>
        <Box
          bg="white"
          boxShadow="custom.primary"
          p={10}
          borderRadius="0 6px 6px 0"
        >
          <Box>
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={`${url}${route.path}`}
                  exact={route.exact}
                >
                  <Authorize permissions={route.permissions}>
                    <route.main userId={id} />
                  </Authorize>
                </Route>
              ))}
              <Redirect to={`${url}/profile`} />
            </Switch>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default UserView;
