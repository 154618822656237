import React, { memo, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { Flex, Box, Button, Icon } from '@chakra-ui/core';
import { isNil } from 'lodash-es';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

function MockDragNDropSide({ items, setItems, selected, setSelected }) {
  const [clickedItem, setClickedItem] = useState();

  const id2List = {
    droppable: items,
    droppable2: selected,
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        id2List[source.droppableId],
        source.index,
        destination.index
      );

      if (source.droppableId === 'droppable2') {
        setSelected(items);
      } else {
        setItems(items);
      }
    } else {
      const result = move(
        id2List[source.droppableId],
        id2List[destination.droppableId],
        source,
        destination
      );

      setItems(result.droppable);
      setSelected(result.droppable2);
    }
  };

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const handleLeftRightMovement = () => {
    if (!isNil(clickedItem)) {
      const { source, destination } = clickedItem;

      const result = move(
        id2List[source.droppableId],
        id2List[destination.droppableId],
        source,
        destination
      );
      setItems(result.droppable);
      setSelected(result.droppable2);

      setClickedItem(null);
    }
  };

  const handleUpDownMovement = (isUp = true) => {
    if (
      !isNil(clickedItem) &&
      clickedItem?.source?.droppableId === 'droppable2'
    ) {
      const { source } = clickedItem;
      const items = reorder(
        id2List[source.droppableId],
        source.index,
        isUp ? (source.index - 1 < 0 ? 0 : source.index - 1) : source.index + 1
      );

      setSelected(items);
      setClickedItem(null);
    }
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight + 200);
    ref.current.addEventListener('click', (event) => {
      // WARNING: check later
      if (
        event.target.id !== 'draggable-box' &&
        !['BUTTON', 'svg', 'path'].includes(event.target.tagName)
      ) {
        setClickedItem(null);
      }
    });
  }, []);

  return (
    <Flex
      id="drop-container"
      justify="space-between"
      ref={ref}
      minHeight={height}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box ref={provided.innerRef} flex={1} mr={2} borderWidth={2}>
              {items.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <Box
                      id="draggable-box"
                      ref={provided.innerRef}
                      p={4}
                      borderRadius="20px"
                      borderColor={
                        clickedItem?.source?.index === index &&
                        clickedItem?.source?.droppableId === 'droppable'
                          ? 'custom.blue'
                          : 'black'
                      }
                      borderWidth={2}
                      onClick={(event) => {
                        setClickedItem({
                          source: {
                            index,
                            droppableId: 'droppable',
                          },
                          destination: {
                            index: 0,
                            droppableId: 'droppable2',
                          },
                        });
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Flex minW="40px" justify="space-between" mr={2} align="center">
          <Button
            bg="mock.gray"
            onClick={handleLeftRightMovement}
            size="sm"
            p={0}
            borderRadius="50%"
            w={8}
            fontWeight={900}
            h={8}
            mr={1}
            disabled={
              !clickedItem || clickedItem?.source?.droppableId !== 'droppable2'
            }
          >
            <Icon name="arrow-back" />
          </Button>
          <Button
            bg="mock.gray"
            onClick={handleLeftRightMovement}
            size="sm"
            p={0}
            borderRadius="50%"
            w={8}
            fontWeight={900}
            h={8}
            ml={1}
            disabled={
              !clickedItem || clickedItem?.source?.droppableId !== 'droppable'
            }
          >
            <Icon name="arrow-forward" />
          </Button>
        </Flex>
        <Droppable droppableId="droppable2">
          {(provided) => (
            <Box ref={provided.innerRef} flex={1} borderWidth={2}>
              {selected.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <Box
                      id="draggable-box"
                      ref={provided.innerRef}
                      p={4}
                      borderRadius="20px"
                      borderColor={
                        clickedItem?.source?.index === index &&
                        clickedItem?.source?.droppableId === 'droppable2'
                          ? 'custom.blue'
                          : 'black'
                      }
                      borderWidth={2}
                      onClick={() =>
                        setClickedItem({
                          source: {
                            index,
                            droppableId: 'droppable2',
                          },
                          destination: {
                            index: 0,
                            droppableId: 'droppable',
                          },
                        })
                      }
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Flex minW="40px" justify="space-between" ml={2} align="center">
          <Flex direction="column">
            <Button
              bg="mock.gray"
              onClick={handleUpDownMovement}
              size="sm"
              p={0}
              borderRadius="50%"
              w={8}
              fontWeight={900}
              h={8}
              mb={2}
              disabled={
                !clickedItem ||
                clickedItem?.source?.droppableId !== 'droppable2'
              }
            >
              <Icon name="arrow-up" />
            </Button>
            <Button
              bg="mock.gray"
              onClick={() => handleUpDownMovement(false)}
              size="sm"
              p={0}
              borderRadius="50%"
              w={8}
              fontWeight={900}
              h={8}
              disabled={
                !clickedItem ||
                clickedItem?.source?.droppableId !== 'droppable2'
              }
            >
              <Icon name="arrow-down" />
            </Button>
          </Flex>
        </Flex>
      </DragDropContext>
    </Flex>
  );
}

export default memo(MockDragNDropSide);
