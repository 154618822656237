import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import React from 'react';

function LegalStep() {
  return (
    <Stack spacing={4}>
      <Heading size="md">Non-Disclosure Agreement Reminder</Heading>
      <Text>
        This is a reminder of the non-disclosure agreement that you accepted
        when you scheduled this test and when you signed in today
      </Text>
      <Box as="ul" ml={8}>
        <li>
          This test is confidential and you must not disclose it to any third
          party
        </li>
        <li>
          You are not allowed to disclose, publish, reproduce, or transmit this
          test, in whole or in part, verbal or written, electronic or
          mechanical, for any purpose, without prior written permission of
          Pearson
        </li>
      </Box>
    </Stack>
  );
}

export default LegalStep;
