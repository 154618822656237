import React, { memo } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';

function CustomBreadcrumb({ paths = [] }) {
  return (
    <Breadcrumb
      separator={
        <Icon color="custom.gray5" name="chevron-right" fontSize="18px" />
      }
    >
      {paths.map(({ path, name }, index) => {
        const dummyPath = !path;
        return (
          <BreadcrumbItem key={index} isCurrentPage={dummyPath}>
            <BreadcrumbLink
              fontSize="14px"
              as={dummyPath ? 'div' : Link}
              to={path}
              fontWeight={!dummyPath ? '600' : '400'}
              color={!dummyPath ? 'custom.black' : 'custom.gray5'}
            >
              {name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

export default memo(CustomBreadcrumb);
