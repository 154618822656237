import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Form from '../../../components/Form/Form';
import FormSelect, { BareSelect } from '../../../components/Form/Select';
import FormInput from '../../../components/Form/Input';
import FormButton from '../../../components/Form/Button';
import { useCouponFormData } from '../../../store/coupon/hooks';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import FormSwitch from '../../../components/Form/Switch';
import CouponCheckout from './CouponCheckout';
import { useCurrentUser } from '../../../store/user/hooks';

const validityUnit = 30;

const validatityDays = Array(12)
  .fill(validityUnit)
  .map((unit, index) => unit * (index + 1));

const ultimatePackageId = 3;
const exclusivePackageId = 5;

function CouponForm({
  packages = [],
  institutes = [],
  extensions = [],
  onSubmit,
}) {
  const user = useCurrentUser();

  const packageOptions = useMemo(() => {
    return packages.slice(1);
  }, [packages]);

  const extensionOptions = useMemo(() => {
    return extensions.filter((extension) => ![1, 2].includes(extension.id));
  }, [extensions]);

  const instituteOptions = useMemo(() => {
    return institutes;
  }, [institutes]);

  const validatityOptions = useMemo(() => {
    return validatityDays.map((item) => ({
      label: `${item} Days`,
      value: item,
    }));
  }, []);

  const userInstitute = institutes.find(
    (institute) => institute.id === user.instituteId
  );

  const defaultValues = useMemo(
    () => ({
      isPackage: true,
      packageValidity: 30,
      speakingValidity: 30,
      institute: userInstitute,
    }),
    [userInstitute]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        institute: Yup.object().required('Required.'),
        package: Yup.object().when('isPackage', {
          is: true,
          then: Yup.object().required('Required.'),
          otherwise: Yup.object().notRequired(),
        }),
        extension: Yup.object().when('isPackage', {
          is: false,
          then: Yup.object().required('Required.'),
          otherwise: Yup.object().notRequired(),
        }),
        packageValidity: Yup.string().when(['package', 'hasDiscount'], {
          is: (packageData, hasDiscount) =>
            !hasDiscount && get(packageData, 'id') === ultimatePackageId,
          then: Yup.string().required('Required.'),
          otherwise: Yup.string().notRequired(),
        }),
        speakingValidity: Yup.string().when(['package', 'hasDiscount'], {
          is: (packageData, hasDiscount) =>
            !hasDiscount && get(packageData, 'id') === ultimatePackageId,
          then: Yup.string().required('Required.'),
          otherwise: Yup.string().notRequired(),
        }),
        units: Yup.number()
          .typeError('Must be a number.')
          .integer('Must be an integer.')
          .min(1, 'Minimum 1')
          .max(500, 'Maximum 500'),
        discount: Yup.number().when('hasDiscount', {
          is: true,
          then: Yup.number()
            .typeError('Must be a number.')
            // .integer('Must be an integer.')
            .min(1, 'Minimum 1')
            .max(100, 'Maximum 100'),
          otherwise: Yup.number().notRequired(),
        }),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });
  const { control, watch, reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const selectedPackage = watch('package');
  const isPackage = watch('isPackage');
  const hasDiscount = watch('hasDiscount');

  return (
    <Form form={form} width="50%" onSubmit={onSubmit}>
      <Controller
        as={<BareSelect />}
        control={control}
        name="institute"
        label="INSTITUTE"
        isDisabled={user.instituteId}
        options={instituteOptions}
        getOptionValue={(option) => option['id']}
        getOptionLabel={(option) => option['name']}
      />
      {user.role === 'super_admin' && (
        <>
          <FormSwitch label="TOGGLE DISCOUNT" name="hasDiscount" />
          {hasDiscount && <FormInput name="discount" label="DISCOUNT (%)" />}
        </>
      )}
      <FormSwitch label="TOGGLE PACKAGE/EXTENSION" name="isPackage" />
      {isPackage ? (
        <Controller
          key="package"
          as={<BareSelect />}
          control={control}
          name="package"
          label="PACKAGE"
          options={packageOptions}
          getOptionValue={(option) => option['id']}
          getOptionLabel={(option) => option['name']}
        />
      ) : (
        <Controller
          key="extension"
          as={<BareSelect />}
          control={control}
          name="extension"
          label="EXTENSION"
          options={extensionOptions}
          getOptionValue={(option) => option['id']}
          getOptionLabel={(option) => option['name']}
        />
      )}
      <SimpleGrid columns={2} spacing={4}>
        {!hasDiscount &&
          get(selectedPackage, 'id') === ultimatePackageId &&
          isPackage && (
            <>
              <FormSelect
                name="packageValidity"
                label="PACKAGE VALIDITY"
                options={validatityOptions}
              />
              <FormSelect
                name="speakingValidity"
                label="SPEAKING VALIDITY"
                options={validatityOptions}
              />
            </>
          )}
        {!hasDiscount &&
          get(selectedPackage, 'id') === exclusivePackageId &&
          isPackage && (
            <FormSelect
              name="packageValidity"
              label="PACKAGE VALIDITY"
              options={validatityOptions}
            />
          )}
      </SimpleGrid>
      <FormInput name="units" label="UNITS" />
      <FormButton>SUBMIT</FormButton>
    </Form>
  );
}

function CouponPage() {
  const {
    packages = [],
    institutes = [],
    extensions = [],
  } = useCouponFormData();

  const [submitData, setSubmitData] = useState();
  const [toggleCheckout, setToggleCheckout] = useState(false);

  const onSubmit = useCallback(
    ({
      institute,
      package: packageData,
      speakingValidity,
      extension,
      packageValidity,
      units,
      discount,
    }) => {
      let amount;

      if (packageData) {
        const isUltimatePackage =
          !discount && packageData.id === ultimatePackageId;
        const isExclusivePackage =
          !discount && packageData.id === exclusivePackageId;
        if (isUltimatePackage) {
          const bareAmount =
            units * (packageValidity / validityUnit) * packageData.costPrice;
          const speakingBareAmount =
            units *
            (packageValidity / validityUnit) *
            get(extensions, '1.costPrice');
          const speakingAmount =
            units *
            (speakingValidity / validityUnit) *
            get(extensions, '1.costPrice');

          amount = bareAmount - speakingBareAmount + speakingAmount;
        } else if (isExclusivePackage) {
          amount =
            units * (packageValidity / validityUnit) * packageData.costPrice;
        } else {
          if (discount) {
            amount = (
              units *
              (packageData.sellingPrice * (discount / 100))
            ).toFixed(2);
          } else {
            amount = units * packageData.costPrice;
          }
        }
      } else {
        if (discount) {
          amount = (
            units *
            (extension.sellingPrice * (discount / 100))
          ).toFixed(2);
        } else {
          amount = units * extension.costPrice;
        }
      }

      const formattedData = {
        institute,
        type: packageData ? 'package' : 'extension',
        package: packageData,
        extension,
        featureValidity: packageValidity
          ? packageValidity
          : packageData
          ? get(packageData, 'validity')
          : get(extension, 'validity'),
        speakingValidity: speakingValidity,
        units,
        amount,
        discount,
      };

      setSubmitData(formattedData);
      setToggleCheckout(true);
    },
    [extensions]
  );

  return (
    <Box maxWidth="1140px" margin="30px auto 0">
      <Heading size="lg" color="custom.black3" mb={6}>
        GENERATE COUPON
      </Heading>
      <Box bg="white" boxShadow="custom.primary" p={10} borderRadius="6px">
        {toggleCheckout ? (
          <CouponCheckout
            data={submitData}
            setToggleCheckout={setToggleCheckout}
          />
        ) : (
          <CouponForm
            packages={packages}
            extensions={extensions}
            institutes={institutes}
            onSubmit={onSubmit}
          />
        )}
      </Box>
    </Box>
  );
}

export default CouponPage;
