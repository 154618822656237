export const scores = [
  {
    label: 'Overall',
    name: 'overall',
  },
  {
    label: 'Listening',
    name: 'listening',
  },
  {
    label: 'Reading',
    name: 'reading',
  },
  {
    label: 'Speaking',
    name: 'speaking',
  },
  {
    label: 'Writing',
    name: 'writing',
  },
];

export const scoresSub = [
  {
    label: 'Grammar',
    name: 'grammar',
  },
  {
    label: 'Fluency',
    name: 'oralFluency',
  },
  {
    label: 'Pronunciation',
    name: 'pronunciation',
  },
  {
    label: 'Spelling',
    name: 'spelling',
  },
  {
    label: 'Vocabulary',
    name: 'vocabulary',
  },
  {
    label: 'Discourse',
    name: 'writtenDiscourse',
  },
];
