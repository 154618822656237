import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import DummyRecorder from '../../Components/Audio/DummyRecorder';
import microphoneImage from '../../../../../assets/images/microphone.jpg';

function MicCheckStep() {
  return (
    <Stack spacing={4}>
      <Flex justify="space-between">
        <Stack spacing={4}>
          <Heading size="md">Microphone Check</Heading>
          <Text>
            This is an opportunity to check that your microphone is working
            correctly.
          </Text>
          <Stack spacing={1}>
            <Text>
              1. Make sure your headtset is on and the microphone is in the
              downward position near your mouth
            </Text>
            <Text>
              2. When you are ready, click on the{' '}
              <Text as="strong" color="custom.blue">
                Record
              </Text>{' '}
              button and say "Testing, testing, one, two, three" into the
              microphone
            </Text>
            <Text>
              3. After your have spoken, click on the{' '}
              <Text as="strong" color="custom.blue">
                Stop
              </Text>{' '}
              button. Your recording is now complete
            </Text>
            <Text>
              4. Now click on the{' '}
              <Text as="strong" color="custom.blue">
                Playback
              </Text>{' '}
              button. You should clearly hear yourself speaking
            </Text>
            <Text>
              5. If you can not hear your voice clearly, please raise your hand
              to get the attention of the Test Administrator
            </Text>
          </Stack>
        </Stack>
        <Image src={microphoneImage} />
      </Flex>

      <DummyRecorder
        audio={
          new Audio('https://i3.cdn.aiwas.io/assets/audio/headphone-test.wav')
        }
      />

      <Box as="ul" ml={4} mt={10}>
        <li>
          During the test you will not have{' '}
          <Text as="strong" color="custom.blue">
            Record,
          </Text>{' '}
          <Text as="strong" color="custom.blue">
            Playback
          </Text>{' '}
          <Text as="strong" color="custom.blue">
            Stop
          </Text>{' '}
          buttons. The voice recording will start automatically
        </li>
      </Box>
    </Stack>
  );
}

export default MicCheckStep;
