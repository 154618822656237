import { DateTime } from 'luxon';

export const DATE_TIME_FULL = 'dd LLL, yyyy hh:mm a';
export const DATE_FORMAT = 'dd LLL, yyyy';

export const DATE_FORMAT_SHORT = 'dd-MM-yyyy';

export function getDate(date, format = DATE_FORMAT) {
  if (!date) return null;
  return DateTime.fromJSDate(new Date(date)).toFormat(format);
}

export function getDayDifference(date) {
  if (!date) return null;
  const dayDifference =
    DateTime.fromJSDate(new Date(date)).diffNow(['days', 'hours']).toObject()
      .days ?? 0;
  return dayDifference < 0 ? 0 : dayDifference;
}

export function getTimeFromNow(date) {
  if (date !== '') {
    const periods = {
      month: 30 * 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      day: 24 * 60 * 60 * 1000,
      hour: 60 * 60 * 1000,
      minute: 60 * 1000,
    };

    const diff = Date.now() - new Date(date);

    if (diff > periods.month) {
      // it was at least a month ago
      return Math.floor(diff / periods.month) + ' month(s) ago';
    } else if (diff > periods.week) {
      return Math.floor(diff / periods.week) + ' week(s) ago';
    } else if (diff > periods.day) {
      return Math.floor(diff / periods.day) + ' day(s) ago';
    } else if (diff > periods.hour) {
      return Math.floor(diff / periods.hour) + ' hour(s) ago';
    } else if (diff > periods.minute) {
      return Math.floor(diff / periods.minute) + ' minute(s) ago';
    }
    return 'just now';
  }

  return ' - never before';
}

export function getDayDifferenceFromDate(firstDate, secondDate) {
  if (!firstDate || !secondDate) return null;
  const dayDifference =
    DateTime.fromJSDate(new Date(firstDate))
      .diff(DateTime.fromJSDate(new Date(secondDate)), ['days', 'hours'])
      .toObject().days ?? 0;
  return dayDifference < 0 ? 0 : dayDifference;
}

export function addDate(daysToAdd, format = DATE_FORMAT) {
  if (!daysToAdd) return null;

  return DateTime.fromJSDate(new Date())
    .plus({ days: daysToAdd })
    .toFormat(format);
}

export function getRelativeTime(date) {
  if (!date) return null;
  return DateTime.fromJSDate(new Date(date)).toRelative();
}

export function toISODate(date) {
  if (!date) return;

  return DateTime.fromJSDate(new Date(date)).toISO();
}

export function hasExpired(date) {
  return (
    new Date(getDate(new Date().toISOString().slice(0, 10))) > new Date(date)
  );
}

export function getGreaterDate(d1, d2) {
  d1 = new Date(d1);
  d2 = new Date(d2);

  return d1 > d2 ? d1 : d2;
}
