import React, { memo, useCallback, useContext } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/user/action';
import CustomAvatar from '../Avatar/Avatar';
import { get } from 'lodash-es';
import { useCurrentUser } from '../../store/user/hooks';
import AppContext from '../../Routes/AppContext';

function AccountPopover() {
  const { disconnectPusher } = useContext(AppContext);

  const dispatch = useDispatch();

  const logoutUser = useCallback(() => {
    document.title =
      'AIWAS Plus - Artificial Intelligence with Automated Scoring';
    disconnectPusher();
    dispatch(logout());
  }, [dispatch, disconnectPusher]);

  const user = useCurrentUser();

  return (
    <Menu>
      <MenuButton
        bg="transparent"
        as={Button}
        fontWeight="600"
        color="custom.black4"
        _hover={{
          backgroundColor: 'none',
          color: 'custom.blue',
        }}
        _active={{
          backgroundColor: 'none',
          color: 'custom.blue',
        }}
        rightIcon="chevron-down"
        mt="-6px"
        mr="-5px"
      >
        <CustomAvatar src={get(user, 'details.avatar')} size="45px" />
      </MenuButton>
      <MenuList
        borderRadius={0}
        px="30px"
        py="15px"
        zIndex="2"
        border="none"
        left="unset !important"
        right="0"
        transform="translate(0, 68px) !important"
        boxShadow="0px 9px 28px rgba(0, 0, 0, 0.07) !important"
      >
        <MenuItem
          fontWeight="600"
          lineHeight="1.5"
          color="custom.blue"
          transition="all .3s"
          _focus={{
            backgroundColor: 'none',
            color: 'custom.orange2',
          }}
          _active={{
            backgroundColor: 'none',
            color: 'custom.orange2',
          }}
          fontSize="16px"
          p={0}
          mb="5px"
          as={Link}
          to={'/dashboard/settings'}
          excat={true}
        >
          Profile & Settings
        </MenuItem>
        <MenuItem
          fontWeight="600"
          lineHeight="1.5"
          color="custom.blue"
          transition="all .3s"
          _focus={{
            backgroundColor: 'none',
            color: 'custom.orange2',
          }}
          fontSize="16px"
          p={0}
          mb="5px"
          onClick={logoutUser}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default memo(AccountPopover);
