import React from 'react';
import { Box } from '@chakra-ui/core';
import SpeechWordPopover from './SpeechWordPopover';
import { getSpeakingResultColor } from '../../../../../constants/speakingColor';

function mapWordsToPopover(words, audioUrl) {
  return words.map((item, index) => (
    <SpeechWordPopover
      key={index}
      labelBg={getSpeakingResultColor(item.overallScore)}
      label={item.word}
      data={item}
      audioUrl={audioUrl}
    />
  ));
}

function SpeechWords({ words = [], audioUrl }) {
  return <Box>{mapWordsToPopover(words, audioUrl)}</Box>;
}

export default SpeechWords;
