import { modules } from '../../../../../../constants/modules';

export const defaultQueryObject = {
  module: modules.speaking.ReadAloud,
};

export const defaultQueryWithSection = {
  module: modules.speaking.ReadAloud,
  section: 'speaking',
};
