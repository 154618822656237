import { isEmpty } from 'lodash-es';
import { mockTypes } from '../../../../constants/mockTypes';
import { modules } from '../../../../constants/modules';
import { getConfigByVersion } from './getConfigsByVersion';

export const virtualSectionKeys = {
  partOneOverview: 'partOneOverview',
  introduction: 'introduction',
  speaking: 'speaking',
  writing: 'writing',
  partTwoOverview: 'partTwoOverview',
  reading: 'reading',
  optionalBreak: 'optionalBreak',
  partThreeOverview: 'partThreeOverview',
  summarySpoken: 'summarySpoken',
  listening: 'listening',
};

function getSectionAndModuleCounters({ pointer, stepData }) {
  const { section, index, question_id, module: moduleName } = pointer;
  let sectionCounter;
  let moduleCounter = 0;

  const preparedSection =
    section === virtualSectionKeys.writing
      ? `${virtualSectionKeys.writing}:${question_id}`
      : moduleName === modules.listening.SummarySpokenText
      ? `${virtualSectionKeys.summarySpoken}:${question_id}`
      : section;

  const sectionOrderIndex = stepData.stepsOrder.indexOf(preparedSection);
  const sectionKey = stepData.stepsOrder[sectionOrderIndex];
  if (!stepData.questionsBySteps[sectionKey].questions) {
    sectionCounter = sectionOrderIndex + 1;
  } else {
    if (stepData.questionsBySteps[sectionKey].total > parseInt(index) + 1) {
      sectionCounter = sectionOrderIndex;
      moduleCounter = parseInt(index) + 1;
    } else {
      sectionCounter = sectionOrderIndex + 1;
    }
  }

  return {
    sectionCounter,
    moduleCounter,
    sectionKey,
  };
}

function generateQuestionBySteps({ questions, mockType, optionalBreak }) {
  if (mockType === mockTypes.full) {
    return {
      [virtualSectionKeys.partOneOverview]: { duration: 90 },
      [virtualSectionKeys.introduction]: { duration: 60 },
      [virtualSectionKeys.speaking]: {
        ...questions.speaking,
        total: questions.speaking.questions.length,
      },
      // flat writing questions as sections
      ...questions.writing.questions.reduce(
        (obj, item) => ({
          [`${virtualSectionKeys.writing}:${item.id}`]: {
            questions: [item],
            total: 1,
            duration:
              item.module === modules.writing.SummaryWrittenText
                ? questions.writing.unit_summary_duration
                : questions.writing.unit_essay_duration,
          },
          ...obj,
        }),
        {}
      ),
      [virtualSectionKeys.partTwoOverview]: { duration: 90 },
      [virtualSectionKeys.reading]: {
        ...questions.reading,
        total: questions.reading.questions.length,
      },
      ...(optionalBreak
        ? {
            [virtualSectionKeys.optionalBreak]: { duration: 10 * 60 },
          }
        : {}),
      [virtualSectionKeys.partThreeOverview]: { duration: 90 },
      // flat SummarySpokenText questions as sections
      ...questions.SummarySpokenText.questions.reduce(
        (obj, item) => ({
          [`${virtualSectionKeys.summarySpoken}:${item.id}`]: {
            questions: [item],
            total: 1,
            duration: questions.SummarySpokenText.unit_summary_duration,
          },
          ...obj,
        }),
        {}
      ),
      [virtualSectionKeys.listening]: {
        ...questions.listening,
        total: questions.listening.questions.length,
      },
    };
  }

  return {
    [virtualSectionKeys.introduction]: { duration: 60 },
    // speaking
    ...(!isEmpty(questions.speaking)
      ? {
          [virtualSectionKeys.speaking]: {
            ...questions.speaking,
            total: questions.speaking.questions.length,
          },
        }
      : {}),
    // writing
    ...(!isEmpty(questions.writing)
      ? questions.writing.questions.reduce(
          (obj, item) => ({
            [`${virtualSectionKeys.writing}:${item.id}`]: {
              questions: [item],
              total: 1,
              duration:
                item.module === modules.writing.SummaryWrittenText
                  ? questions.writing.unit_summary_duration
                  : questions.writing.unit_essay_duration,
            },
            ...obj,
          }),
          {}
        )
      : {}),
    // reading
    ...(!isEmpty(questions.reading)
      ? {
          [virtualSectionKeys.reading]: {
            ...questions.reading,
            total: questions.reading.questions.length,
          },
        }
      : {}),
    // summary spoken
    ...(!isEmpty(questions.SummarySpokenText)
      ? questions.SummarySpokenText.questions.reduce(
          (obj, item) => ({
            [`${virtualSectionKeys.summarySpoken}:${item.id}`]: {
              questions: [item],
              total: 1,
              duration: questions.SummarySpokenText.unit_summary_duration,
            },
            ...obj,
          }),
          {}
        )
      : {}),
    // listening
    ...(!isEmpty(questions.listening)
      ? {
          [virtualSectionKeys.listening]: {
            ...questions.listening,
            total: questions.listening.questions.length,
          },
        }
      : {}),
  };
}

export function prepareMockQuestionsSteps({
  questions,
  pointer,
  mockType,
  mockVersion,
}) {
  if (!questions) return;

  const { optionalBreak } = getConfigByVersion(mockVersion);

  const questionsBySteps = generateQuestionBySteps({
    questions,
    mockType,
    optionalBreak,
  });

  const stepsOrder = Object.keys(questionsBySteps);

  const stepData = {
    questionsBySteps,
    stepsOrder,
    sectionCounter: 0,
    moduleCounter: 0,
  };

  if (pointer) {
    const {
      sectionCounter,
      moduleCounter,
      sectionKey,
    } = getSectionAndModuleCounters({ pointer, stepData });

    stepData.sectionCounter = sectionCounter;
    stepData.moduleCounter = moduleCounter;

    if (stepData.questionsBySteps[sectionKey].questions?.length > 1) {
      stepData.questionsBySteps[sectionKey].duration = Math.round(
        stepData.questionsBySteps[sectionKey].duration -
          moduleCounter * stepData.questionsBySteps[sectionKey].unit_duration
      );
    }
  }

  return stepData;
}
