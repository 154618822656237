import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Text, SimpleGrid } from '@chakra-ui/core';
import { getRouteByModule } from '../../helpers/getRoutes';

import {
  Legend,
  ReferenceLine,
  Dot,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { get } from 'lodash-es';
import { customColors } from '../../theme/theme';
import { getDate } from '../../helpers/getDate';

function getColor(value) {
  if (value >= 90) return 'custom.blue';
  if (value > 79) return 'custom.green';
  if (value > 65) return 'custom.yellow';
  return 'custom.red';
}

const CustomizedDot = (props) => {
  const { cx, cy, payload, module, section } = props;

  //console.log(props);

  const color = useMemo(() => {
    return getColor(payload.y);
  }, [payload.y]);

  return (
    <Link to={getRouteByModule(payload.id, { section, module })}>
      <Box as={Dot} cx={cx} cy={cy} r={8} fill={color} />
    </Link>
  );
};

const CustomizeTooltip = memo(({ active, payload }) => {
  const color = useMemo(() => {
    return getColor(payload[0]?.value);
  }, [payload]);

  if (active) {
    return (
      <Box bg="black" py={2} px={4} textAlign="center" borderRadius="4px">
        <Text color="white">ID # {payload[0].payload.id}</Text>
        <Text color="white" fontSize="sm">
          {getDate(payload[0].payload.date)}
        </Text>
        <Text color="white" fontSize="sm">
          Your Target 90
        </Text>
        <Text color={color} fontSize="sm">
          Your Score {payload[0].value}
        </Text>
      </Box>
    );
  }
  return null;
});

const StatChart = memo(({ stats }) => {
  return (
    <Box width="100%" bg="white" mt={2} p={6} mb={8}>
      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
        <ScatterChart margin={{ top: 10, bottom: 10, left: 10, right: 10 }}>
          <ReferenceLine
            y={90}
            stroke={customColors.blue}
            strokeDasharray="5 3"
          />
          <Legend
            verticalAlign="top"
            iconSize={0}
            height={40}
            wrapperStyle={{ color: customColors.blue }}
          />
          <CartesianGrid vertical={false} strokeDasharray="5 3" />
          <YAxis
            ticks={[0, 35, 65, 79, 90]}
            domain={[0, 'dataMax']}
            interval="preserveStartEnd"
            axisLine={false}
            tickLine={false}
            width={30}
            type="number"
            dataKey="y"
            name="score"
          />
          <Tooltip content={<CustomizeTooltip />} />
          <Scatter
            name="Your Target Score 90"
            data={stats}
            shape={<CustomizedDot />}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
});

function StatBox({ count, label, bg }) {
  return (
    <Box bg={bg} color="white" pt={2} pb={3} px={6} boxShadow="custom.primary">
      <Text fontWeight="600" fontSize="6xl" lineHeight={1}>
        {count}
      </Text>
      <Text fontWeight="600" textTransform="uppercase">
        {label}
      </Text>
    </Box>
  );
}

function ExpertTips({ tips }) {
  return tips ? (
    <>
      <Text fontWeight={600} color="custom.black3" mb={3}>
        EXPERT TIPS
      </Text>
      <Box
        bg="custom.blue"
        color="white"
        p={6}
        mb={6}
        boxShadow="custom.primary"
      >
        <Text dangerouslySetInnerHTML={{ __html: tips }} />
      </Box>
    </>
  ) : (
    ''
  );
}

function Stats({ title, stats }) {
  return (
    <Box>
      <Text
        fontWeight={600}
        color="custom.black3"
        mb={3}
        textTransform="uppercase"
      >
        Stats for {title}
      </Text>
      <SimpleGrid columns={4} mt={2} mb={8} gridColumnGap={4}>
        <StatBox
          count={get(stats, 'totalQuestions')}
          label="Total Question"
          bg="custom.blue"
        />
        <StatBox
          count={get(stats, 'practicedQuestions')}
          label="Completed"
          bg="custom.green"
        />
        <StatBox
          count={get(stats, 'remainingQuestions')}
          label="Remaining"
          bg="custom.red"
        />
        <StatBox
          count={get(stats, 'averageScore')}
          label="Overall Score"
          bg="custom.blue8"
        />
      </SimpleGrid>
      <Text fontWeight={600} color="custom.black3" mb={3}>
        YOUR SCORE TRENDS
      </Text>
      <StatChart stats={get(stats, 'stats', [])} />

      <ExpertTips tips={get(stats, 'expertTips')} />
    </Box>
  );
}

export default Stats;
