import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import { UserAvatar } from '../../Avatar/Avatar';
import { getDate, DATE_TIME_FULL } from '../../../helpers/getDate';
import { get } from 'lodash-es';

function Histories({ histories }) {
  return (
    <Box>
      {histories.map(({ id, action, user, createdAt }) => (
        <Flex align="center" mb={4} key={id} color="custom.black">
          <UserAvatar
            size="24px"
            name={get(user, 'firstName')}
            src={get(user, 'avatar')}
          />
          <Text ml={2} mr={1} fontWeight={600}>
            {get(user, 'firstName')}{' '}
          </Text>
          <Text>{action} this question on</Text>
          <Text ml={1} fontWeight={600}>
            {getDate(createdAt, DATE_TIME_FULL)}
          </Text>
        </Flex>
      ))}
    </Box>
  );
}

export default Histories;
