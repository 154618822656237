import React, { useContext, useEffect } from 'react';
import { Flex } from '@chakra-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import Topbar from '../../components/Navigation/Topbar';
import { ContainerBox } from '../../components/Layout/Container';
import { Helmet } from 'react-helmet';
import AppContext from '../../Routes/AppContext';
import BlurWrapper from '../../components/BlurWrapper/BlurWrapper';
import PreviewDashboard from './Dashboard';
import DiagnosticPage from '../Dashboard/Diagnostic/Diagnostic';

function Preview() {
  const { setIsPreview, isPreview } = useContext(AppContext);

  useEffect(() => {
    setIsPreview(true);
    return () => setIsPreview(false);
  }, [setIsPreview]);

  if (!isPreview) return null;

  return (
    <Flex backgroundColor="custom.white1" minHeight="100vh" direction="column">
      <Helmet>
        <title>
          AIWAS Plus - Artificial Intelligence With Automated Scoring
        </title>
      </Helmet>
      <BlurWrapper>
        <Topbar />
      </BlurWrapper>
      <ContainerBox>
        <Switch>
          <Route path="/preview/dashboard">
            <BlurWrapper blur={2}>
              <PreviewDashboard />
            </BlurWrapper>
          </Route>
          <Route path="/preview/diagnostic">
            <DiagnosticPage />
          </Route>
          <Redirect to="/preview/dashboard" />
        </Switch>
      </ContainerBox>
    </Flex>
  );
}

export default Preview;
