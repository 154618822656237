import React, { memo, useMemo } from 'react';
import { Box, PseudoBox } from '@chakra-ui/core';
import progressBarBlue from '../../assets/progress-bar-blue.png';

function CustomProgress({
  current,
  duration,
  bgImage = progressBarBlue,
  ...props
}) {
  const width = useMemo(() => (100 * current) / duration, [current, duration]);

  return (
    <Box
      h="20px"
      w="400px"
      borderRadius="5px"
      px="2px"
      py="0.5px"
      mt={6}
      overflow="hidden"
      bg="white"
      borderWidth="1px"
      {...props}
    >
      <Box
        w="100%"
        h="100%"
        backgroundImage={`url(${bgImage})`}
        position="relative"
      >
        <PseudoBox
          position="absolute"
          left={`${width}%`}
          top="0"
          width="100%"
          height="100%"
          background="white"
        />
      </Box>
    </Box>
  );
}

export default memo(CustomProgress);
