import { PseudoBox } from '@chakra-ui/core';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { toggleFullScreen } from '../../../../helpers/toggleFullScreen';
import AppContext from '../../../../Routes/AppContext';
import MockSteps from './Steps';

const handleCopyEvent = (event) => {
  event.clipboardData.setData(
    'text/plain',
    'Copying texts in mock is not allowed.'
  );
  event.preventDefault();
};

function MockAttendStart({ mockData, ...props }) {
  const { setIsTopBarShown } = useContext(AppContext);

  useEffect(() => {
    toggleFullScreen();
  }, []);

  useEffect(() => {
    document.addEventListener('copy', handleCopyEvent);
    return () => document.removeEventListener('copy', handleCopyEvent);
  }, []);

  useEffect(() => {
    setIsTopBarShown(false);
    return () => setIsTopBarShown(true);
  }, [setIsTopBarShown]);

  return (
    <PseudoBox style={{ userSelect: 'none' }}>
      <MockSteps mockData={mockData} {...props} />
    </PseudoBox>
  );
}

export default MockAttendStart;
