import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Stack,
  Icon,
  Button,
  Badge,
} from '@chakra-ui/core';
import { usePackages } from '../../../store/package/hooks';
import Table, {
  Tr,
  Th,
  Thead,
  Tbody,
  Td,
} from '../../../components/Table/Table';
import { convertToUnlimited } from '../../../helpers/package';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../../store/user/hooks';
import { get } from 'lodash-es';
import ApplyCoupon from '../../../components/User/Subscription/ApplyCoupon';

function Price({ sellingPrice, targetPrice }) {
  if (!sellingPrice) return '';
  if (!targetPrice) {
    return (
      <Text my={2} fontSize="20px" fontWeight={400} color="custom.blue">
        A${sellingPrice}
      </Text>
    );
  }
  return (
    <Stack isInline my={2} fontSize="20px" fontWeight={400} color="custom.blue">
      <Text>A${sellingPrice}</Text>
      <Text color="custom.black4" fontSize="xs" textDecoration="line-through">
        A${targetPrice}
      </Text>
    </Stack>
  );
}

function SubscriptionPage() {
  let packages = usePackages();
  packages = packages.slice(0, 4);

  const user = useCurrentUser();

  return (
    <Box maxWidth="1140px" margin="30px auto 0">
      <Heading size="lg" color="custom.black3" mb={6}>
        SUBSCRIPTION
      </Heading>
      <Box bg="white" borderRadius="6px" boxShadow="custom.primary" p={10}>
        <Flex mb={10} justifyContent="space-between">
          <Box>
            <Badge backgroundColor="custom.gray4" variant="solid" py={2} px={6}>
              CURRENT PACKAGE:{' '}
              {get(user, 'currentPackage.package.name', 'Not Required')}
            </Badge>
          </Box>

          <ApplyCoupon userId={user.id} />
        </Flex>

        <Table w="100%" color="custom.black">
          <Thead>
            <Tr>
              <Th />
              {packages.map((item) => (
                <Th key={item.id} verticalAlign="top" textAlign="left" pb={6}>
                  <Stack spacing={2}>
                    {item.id === 1 ? null : (
                      <Button
                        as={Link}
                        to={`/dashboard/checkout?id=${item.id}&type=package`}
                        variant="link"
                        variantColor="blueVariant"
                        alignSelf="start"
                        border="1px solid"
                        borderColor="custom.orange2"
                        d="inline-block"
                        color="custom.orange2"
                        borderRadius={4}
                        _hover={{
                          textDecoration: 'none',
                          backgroundColor: 'custom.orange2',
                          color: 'white',
                        }}
                        fontSize="xs"
                        padding="5px 10px"
                      >
                        Buy Now
                      </Button>
                    )}
                    <Text
                      color="custom.black3"
                      mt={item.name === 'Free' ? '28px' : 0}
                      textTransform="uppercase"
                    >
                      {item.name}
                    </Text>
                    <Price
                      sellingPrice={item.sellingPrice}
                      targetPrice={item.targetPrice}
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="400"
                      color="custom.black3"
                    >
                      {item.validity} Days
                    </Text>
                  </Stack>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td py={2}>Speaking</Td>
              {packages.map((item) => (
                <Td key={item.id}>{convertToUnlimited(item.speaking.limit)}</Td>
              ))}
            </Tr>
            <Tr>
              <Td py={2}>Writing</Td>
              {packages.map((item) => (
                <Td key={item.id}>{convertToUnlimited(item.writing.limit)}</Td>
              ))}
            </Tr>
            <Tr>
              <Td py={2}>Reading</Td>
              {packages.map((item) => (
                <Td key={item.id}>{convertToUnlimited(item.reading.limit)}</Td>
              ))}
            </Tr>
            <Tr borderBottomWidth="1px" verticalAlign="top">
              <Td py={2} pb={4}>
                Listening
              </Td>
              {packages.map((item) => (
                <Td key={item.id}>
                  {convertToUnlimited(item.listening.limit)}
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td py={0.5} pt={2}>
                Full Mock
              </Td>
              {packages.map((item) => (
                <Td key={item.id}>{item.fullMocks ? item.fullMocks : '-'}</Td>
              ))}
            </Tr>
            <Tr borderBottomWidth="1px">
              <Td py={0.5} pb={2}>
                Custom Mock
              </Td>
              {packages.map((item) => (
                <Td key={item.id}>
                  {item.customMocks ? item.customMocks : '-'}
                </Td>
              ))}
            </Tr>

            <Tr borderBottomWidth="1px">
              <Td py={4} pb={4}>
                AI Exam Prediction
              </Td>
              {packages.map((item) => (
                <Td key={item.id}>
                  {item.id !== 1 ? <Icon name="check" /> : '-'}
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td py={2} pt={4}>
                Community Access
              </Td>
              {packages.map((item) => (
                <Td key={item.id}>
                  {item.community ? <Icon name="check" /> : '-'}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td py={2}>Automated Reminder</Td>
              {packages.map((item) => (
                <Td key={item.id}>
                  {item.remainder ? <Icon name="check" /> : '-'}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>

        <Text fontStyle="italic" fontSize="sm" color="custom.black4" mt={8}>
          **Contact for Custom Package and Coupons.
        </Text>
      </Box>
    </Box>
  );
}

export default SubscriptionPage;
