import { createSlice } from '@reduxjs/toolkit';
import { changeToCamelCase } from '../../../helpers/changeCase';
import { keyBy } from 'lodash-es';

const initialState = {
  byId: {},
};

const writingCommentSlice = createSlice({
  name: 'writing-comment',
  initialState,
  reducers: {
    commentsLoaded(state, action) {
      const { data } = action.payload;
      state.byId = {
        ...state.byId,
        ...keyBy(changeToCamelCase(data.data), 'id'),
      };
    },
    commentAdded(state, action) {
      const { data } = action.payload;

      state.byId[data.id] = changeToCamelCase(data);
    },
    commentUpdated(state, action) {
      const { data } = action.payload;
      if (data.comment_id) {
        state.byId[data.comment_id] = {
          ...state.byId[data.comment_id],
          nestedComments: state.byId[data.comment_id].nestedComments.map(
            (nestedComment) => {
              if (nestedComment.id === data.id) {
                return changeToCamelCase(data);
              }
              return nestedComment;
            }
          ),
        };
      } else {
        state.byId[data.id] = changeToCamelCase(data);
      }
    },
    commentDeleted(state, action) {},
    nestedCommentAdded(state, action) {
      const { commentId, data } = action.payload;
      state.byId[commentId] = {
        ...state.byId[commentId],
        nestedComments: [
          changeToCamelCase(data),
          ...state.byId[commentId].nestedComments,
        ],
      };
    },
    nestedCommentDeleted(state, action) {
      const { commentId, id } = action.payload;

      state.byId[commentId] = {
        ...state.byId[commentId],
        nestedComments: state.byId[commentId].nestedComments.filter(
          (nestedComment) => nestedComment.id !== id
        ),
      };
    },
  },
});

const { actions, reducer } = writingCommentSlice;

export const {
  commentsLoaded,
  commentAdded,
  commentUpdated,
  commentDeleted,
  nestedCommentDeleted,
  nestedCommentAdded,
} = actions;

export default reducer;

const apiSubstring = 'writing';

export const getComments = (id) => async (dispatch, _, api) => {
  const { data } = await api(
    'get',
    `comments/question/${id}?section=${apiSubstring}`
  );
  dispatch(commentsLoaded({ data, id }));
  return data;
};

export const addComment = (id, values) => async (dispatch, _, api) => {
  const { data } = await api('post', `comments/question/${id}`, values);
  if (values.comment_id) {
    dispatch(nestedCommentAdded({ data, commentId: data.comment_id }));
  } else {
    dispatch(commentAdded({ id, data }));
  }
  return data;
};

export const editComment = (id, values) => async (dispatch, _, api) => {
  const { data } = await api('put', `comments/${id}`, values);
  dispatch(commentUpdated({ data }));
  return data;
};

export const deleteComment = (id, commentId, questionId, values) => async (
  dispatch,
  _,
  api
) => {
  const { data } = await api('delete', `comments/${id}`, values);
  if (commentId) {
    dispatch(nestedCommentDeleted({ id, commentId }));
  } else {
    dispatch(commentDeleted({ id: questionId, commentId: id }));
  }
  return data;
};
