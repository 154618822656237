import React from 'react';
import { Box } from '@chakra-ui/core';
import AudioPlayerControl from '../../../../components/AudioPlayer/AudioPlayerControl';

function PredictionEssaySummaryView({ description, samples, section, audio }) {
  return (
    <Box>
      {section === 'listening' && (
        <Box mb={10}>
          <AudioPlayerControl audioUrl={audio} />
        </Box>
      )}
      <>
        <Box>{description}</Box>
        {/* {get(samples, '0') && (
          <>
            <Text fontWeight={600} my={2}>
              ANSWER:{' '}
            </Text>
            <Box>{get(samples, '0')}</Box>
          </>
        )} */}
      </>
    </Box>
  );
}

export default PredictionEssaySummaryView;
