import Pusher from 'pusher-js';
import { getItem } from '../helpers/localStorage';
import { API_URL, PUSHER_KEY } from '../config/metaData';

if (process.env.REACT_APP_ENV !== 'production') {
  // Pusher.logToConsole = true;
}

export const initPusher = () => {
  const pusher = new Pusher(PUSHER_KEY, {
    cluster: 'ap1',
    forceTLS: true,
    authEndpoint: `${API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${getItem('token')}`,
        Accept: 'application/json',
      },
    },
  });

  const commentsChannel = pusher.subscribe('private-comments-channel');

  const notificationsChannel = pusher.subscribe(
    'private-notifications-channel'
  );

  const disconnectPusher = () => pusher.disconnect();

  return { commentsChannel, notificationsChannel, disconnectPusher };
};
