import { routesMapper } from '../../../../../../Routes/routesMapper';
import { mcqSingle } from '../../../../../../constants/titles';

export const paths = [
  {
    name: 'Practise',
  },
  {
    name: 'Reading',
  },
  {
    name: mcqSingle,
    path: routesMapper.reading.MultipleChoiceSingle.PRACTISE,
  },
];
