import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Progress,
} from '@chakra-ui/core';
import { useCallback } from 'react';
import { GiPlainArrow } from 'react-icons/gi';

function DummyPlayer({ audio: audioFile }) {
  const [audio] = useState(audioFile);

  const [status, setStatus] = useState('Click Play to Begin');
  const [volume, setVolume] = useState(0.5);
  const [currentTime, setCurrentTime] = useState(0.01);

  const [, /* state */ setState] = useState();

  const playAudio = useCallback(async () => {
    try {
      setStatus('playing');
      await audio.play();
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        setState(() => {
          throw error;
        });
      }
    }
  }, [audio]);

  useEffect(() => {
    if (audio) {
      audio.volume = volume;
    }
  }, [volume, audio]);

  useEffect(() => {
    return () => audio.pause();
  }, [audio]);

  const onEnd = useCallback(() => {
    setStatus('Click Play to Begin');
  }, []);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(audio.currentTime);
  }, [audio]);

  const stopAudio = useCallback(() => {
    audio.pause();
    audio.currentTime = 0;
    onEnd();
  }, [audio, onEnd]);

  useEffect(() => {
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('ended', onEnd);
    return () => {
      audio.removeEventListener('timeupdate', onTimeUpdate);
      audio.removeEventListener('ended', onEnd);
    };
  }, [audio, onEnd, onTimeUpdate]);

  return (
    <Box>
      <Box borderWidth="1px" borderColor="mock.gray" mt={4} p="10px" w="350px">
        <Flex mb={6}>
          <Text mr={6}>Status:</Text>
          <Text textTransform="capitalize">{status}</Text>
        </Flex>

        <Flex align="center">
          <Text mr={6}>Volume:</Text>
          <Slider
            onChange={(value) => setVolume(value)}
            value={volume}
            min={0}
            max={1}
            step={0.1}
          >
            <SliderTrack borderWidth={1} />
            <SliderFilledTrack bg="custom.gray" />
            <SliderThumb size={4} borderWidth={0}>
              <Box color="gray" as={GiPlainArrow} />
            </SliderThumb>
          </Slider>
        </Flex>

        <Progress
          borderWidth={1}
          h={4}
          borderColor="mock.gray"
          bg="white"
          mt={6}
          value={parseInt((currentTime * 100) / audio.duration) || 0}
          color="blueVariant"
        />
        <Flex justify="space-between" mt={4}>
          <Button
            size="sm"
            onClick={playAudio}
            bg="white"
            borderWidth={1}
            borderColor="mock.gray"
          >
            Play
          </Button>
          <Button
            size="sm"
            onClick={stopAudio}
            bg="white"
            borderWidth={1}
            borderColor="mock.gray"
          >
            Stop
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default DummyPlayer;
