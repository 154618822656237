import React, { useContext, useMemo } from 'react';
import { Flex, Box, Text, Image, Stack } from '@chakra-ui/core';
import { useCurrentUser } from '../../../store/user/hooks';
import { getDate, DATE_TIME_FULL } from '../../../helpers/getDate';
import { get } from 'lodash-es';
import logoTextual from '../../../assets/images/logo-textual.svg';
import AppContext from '../../../Routes/AppContext';
import BlurWrapper from '../../../components/BlurWrapper/BlurWrapper';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../components/Button/PrimaryButton';

function DiagnosticReport({ data = [] }) {
  const { isPreview } = useContext(AppContext);

  const user = useCurrentUser();

  const [firstData, restData] = useMemo(() => {
    if (data.length) {
      return [data[0], data.slice(1)];
    }
  }, [data]);

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        borderBottomWidth="1px"
        pb={4}
        mb={6}
      >
        <Box>
          <Flex align="center">
            <Text
              mr={2}
              color="custom.blue"
              textTransform="uppercase"
              fontWeight={600}
              fontSize="xl"
            >
              PTE Diagnostic Report
            </Text>
          </Flex>
          <Text color="custom.black4">
            {get(user, 'details.firstName')} {get(user, 'details.lastName')}
            <Text color="custom.gray2" ml={isPreview ? 0 : 2} as="span">
              {getDate(new Date(), DATE_TIME_FULL)}
            </Text>
          </Text>
        </Box>
        <Image src={logoTextual} />
      </Flex>
      <Stack spacing={4} mt={6}>
        <Box>
          <Text fontWeight={600} color="custom.blue">
            {firstData.title}
          </Text>
          <Text dangerouslySetInnerHTML={{ __html: firstData.message }} />
        </Box>
      </Stack>
      <Stack spacing={4} mt={6} pos="relative">
        <BlurWrapper blur={isPreview ? 4 : 0}>
          {restData.map(({ title, message }) => (
            <Box key={title}>
              <Text fontWeight={600} color="custom.blue">
                {title}
              </Text>
              <Text dangerouslySetInnerHTML={{ __html: message }} />
            </Box>
          ))}
        </BlurWrapper>
        {isPreview && (
          <Box
            pos="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <PrimaryButton variantColor="blueVariant" as={Link} to="/signup">
              SIGNUP TO SEE FULL REPORT
            </PrimaryButton>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

export default DiagnosticReport;
