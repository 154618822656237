import { apiRequest } from '../../api/api';
import { changeToCamelCase } from '../../helpers/changeCase';
import useSWR from 'swr';

const fetcher = (url, query) =>
  apiRequest('get', url + '?' + query).then((r) => changeToCamelCase(r.data));

export function usePrediction(query = '') {
  const { data, error } = useSWR(['predictions', query], fetcher, {
    shouldRetryOnError: false,
  });

  return [data, !data && !error, error];
}
