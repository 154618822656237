import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import Table, {
  Tbody,
  Td,
  Thead,
  Tr,
} from '../../../../../components/Table/Table';
import { ActionButtons, NextTips, StepLayout } from '../../Components/Layout';
import { getConfigByVersion } from '../../Utils/getConfigsByVersion';

function PartTwoOverviewStep({ onAfterSubmit, mockVersion }) {
  const { reading } = getConfigByVersion(mockVersion);
  return (
    <StepLayout nextShortcutEnabled>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Stack spacing={4} my={10} px={6} overflowY="auto">
            <Heading size="md">Part 2: Overview</Heading>
            <Text>In Part 2 you are tested mainly on your reading skills.</Text>
            <Text>Part 2 contains a single timed section.</Text>
            <Text>
              Read the instructions to each question carefully and answer as
              directed.
            </Text>
            <Box>
              <Table minW="500px" fontWeight={600}>
                <Thead>
                  <Tr borderWidth={1} bg="mock.blue">
                    <Td borderWidth={1} p={1} colSpan={2}>
                      Part 2: Reading
                    </Td>
                  </Tr>
                  <Tr borderWidth={1} bg="mock.blue">
                    <Td borderWidth={1} p={1}>
                      Item Type
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Time allowed
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Reading & Writing: Fill in the blanks
                    </Td>
                    <Td borderWidth={1} p={1} rowSpan={5}>
                      {reading} minutes
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Multiple-choice, choose mulitple answers
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Re-order paragraphs
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Reading: Fill in the blanks
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Multiple-choice, choose single answer
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Stack>
          <Box>
            <NextTips />
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              canEndExam={false}
              onConfirmNext={(onCallback) => {
                onCallback();
                onAfterSubmit();
              }}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default PartTwoOverviewStep;
