import { Box, Heading } from '@chakra-ui/core';
import { capitalize, get } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { mockVersions } from '../../../../constants/mockTypes';
import { titlesByModule } from '../../../../constants/modules';
import { handleApiError } from '../../../../helpers/handleApiError';
import useCustomToast from '../../../../hooks/useCustomToast';
import { getLabels } from '../../../../store/label/ducks';
import {
  getMock,
  getMockQuestions,
  submitMockUpdate,
} from '../../../../store/mocks/mocksSlice';
import MockForm from '../Add/MockForm';
import { getDataMappersByType } from '../Utils/getMockDataMappers';

function MockEditPage() {
  const { id } = useParams();

  const mocks = useSelector((state) => state.mocks);

  const mock = useMemo(() => mocks.byId[id], [mocks, id]);

  const dataMappers = useMemo(
    () =>
      getDataMappersByType({
        type: mock?.type,
        questions: mocks.mockQuestions,
      }),
    [mock, mocks]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMock(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!mocks.mockQuestions.loaded) {
      dispatch(getMockQuestions());
    }
    dispatch(getLabels());
  }, [dispatch, mocks]);

  const initialState = useMemo(
    () => ({
      title: mock?.title,
      priority: { label: mock?.priority, value: mock?.priority },
      labels: mock?.labels.map((label) => ({
        label: label.name,
        value: label.id,
        ...label,
      })),
      version: {
        value: +mock.version === 2 ? mockVersions.v2 : mockVersions.v1,
        label: +mock.version === 2 ? 'v2' : 'v1',
      },
    }),
    [mock]
  );

  const { error, success, toast } = useCustomToast();

  const handleSubmit = useCallback(
    async ({ title, priority, selectedQuestions, labels }) => {
      if (!title?.trim()) {
        error({ title: 'Please Select Title.' });
        return;
      }
      if (!priority) {
        error({ title: 'Please Select Priority.' });
        return;
      }

      if (!labels.length) {
        error({ title: 'Please Select Labels.' });
        return;
      }

      let emptyModule;

      parentLoop: for (let key in selectedQuestions) {
        for (let keyByModule in selectedQuestions[key]) {
          if (!selectedQuestions[key][keyByModule].length) {
            emptyModule = [key, keyByModule];
            break parentLoop;
          }
        }
      }

      if (emptyModule) {
        const [section] = emptyModule;
        error({
          title: 'You have to select questions from all required modules.',
          description: `Hint: You have missed ${capitalize(section)}: ${get(
            titlesByModule,
            emptyModule.join('.')
          )}`,
        });
        return;
      }

      try {
        await dispatch(
          submitMockUpdate(id, {
            title,
            labels: labels.map((label) => label.value),
            priority: priority.value,
            questions: selectedQuestions,
          })
        );

        success({ title: 'Mock updated successfully.' });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [error, toast, dispatch, success, id]
  );

  if (!mock?.questions || mocks.mockQuestions.isFetching) {
    return <Loader />;
  }

  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        EDIT MOCK ({mock?.title})
      </Heading>
      <Box borderRadius="6px" bg="white" p={10} boxShadow="custom.primary">
        <MockForm
          type={mock.type}
          dataMappers={dataMappers}
          initialState={initialState}
          selectedState={mock?.questions}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
}

export default MockEditPage;
