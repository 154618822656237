import React from 'react';
import { Box, Text, SimpleGrid, Flex, Stack } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../../store/users/usersSlice';
import { get } from 'lodash-es';
import PrimaryButton from '../../Button/PrimaryButton';
import { Link } from 'react-router-dom';
import { usePackages } from '../../../store/package/hooks';
import ApplyCoupon from './ApplyCoupon';
import { getDate, hasExpired, getGreaterDate } from '../../../helpers/getDate';
import { convertToUnlimited } from '../../../helpers/package';
import SubscriptionHistory from './SubscriptionHistory';

function Price({ sellingPrice, targetPrice }) {
  if (!sellingPrice) return '';
  if (!targetPrice) {
    return (
      <Text my={2} fontSize="20px" fontWeight={400} color="white">
        A${sellingPrice}
      </Text>
    );
  }
  return (
    <Stack isInline my={2} fontSize="20px" fontWeight={400} color="white">
      <Text>A${sellingPrice}</Text>
      <Text color="white" fontSize="xs" textDecoration="line-through">
        A${targetPrice}
      </Text>
    </Stack>
  );
}

function PackageCard({
  name,
  sellingPrice,
  targetPrice,
  validity,
  speaking,
  writing,
  reading,
  listening,
  fullMocks,
  customMocks,
}) {
  return (
    <Box bg="custom.blue" borderRadius="18px" color="white" p={8}>
      <Text textTransform="uppercase">{name}</Text>
      <Text fontSize="xl" fontWeight={600}>
        <Price sellingPrice={sellingPrice} targetPrice={targetPrice} />
      </Text>
      <Text color="custom.yellow">{validity} Days</Text>
      <SimpleGrid columns={2} spacing={2}>
        <Text>Speaking</Text>
        <Text justifySelf="end">
          {convertToUnlimited(get(speaking, 'limit'))}
        </Text>
        <Text>Writing</Text>
        <Text justifySelf="end">
          {convertToUnlimited(get(writing, 'limit'))}
        </Text>
        <Text>Reading</Text>
        <Text justifySelf="end">
          {convertToUnlimited(get(reading, 'limit'))}
        </Text>
        <Text>Listening</Text>
        <Text justifySelf="end">
          {convertToUnlimited(get(listening, 'limit'))}
        </Text>
      </SimpleGrid>
      <Text mt={1}>... and much more</Text>
      <PrimaryButton as={Link} to="/dashboard/subscription" mt={4} size="sm">
        VIEW DETAILS
      </PrimaryButton>
    </Box>
  );
}

function Subscription({ userId, isSelf = true }) {
  const user = useSelector((state) => selectUserById(state, userId));

  const packages = usePackages();

  const speakingValdityStyle = {
    color: hasExpired(getDate(get(user, 'currentPackage.speakingEndsOn')))
      ? '#EB5555'
      : '',
  };

  const packageValdityStyle = {
    color: hasExpired(
      getDate(
        get(user, 'lastPackage.packageEndsOn') ??
          get(user, 'currentPackage.packageEndsOn')
      )
    )
      ? '#EB5555'
      : '',
  };

  const mockExpiresDate = getGreaterDate(
    get(user, 'currentPackage.mockEndsOn'),
    get(user, 'lastPackage.packageEndsOn') ??
      get(user, 'currentPackage.packageEndsOn')
  );
  const mockValdityStyle = {
    color: hasExpired(getDate(mockExpiresDate)) ? '#EB5555' : '',
  };

  const predictionValdityStyle = {
    color: hasExpired(
      getDate(
        get(user, 'lastPackage.predictionEndsOn') ??
          get(user, 'currentPackage.predictionEndsOn')
      )
    )
      ? '#EB5555'
      : '',
  };

  return (
    <Box color="custom.black3">
      <Flex align="center" justify="space-between">
        <Box>
          <Text mb={1} textTransform="uppercase" fontWeight={600}>
            Current Subscription
          </Text>
          <Text>{get(user, 'currentPackage.package.name', 'N/A')}</Text>
        </Box>
        <Stack isInline spacing={4}>
          <Box>
            <ApplyCoupon userId={userId} />
          </Box>
          {isSelf && (
            <PrimaryButton as={Link} to="/dashboard/subscription">
              UPGRADE
            </PrimaryButton>
          )}
        </Stack>
      </Flex>
      <Text mt={6} mb={1} textTransform="uppercase" fontWeight={600}>
        Package Includes
      </Text>
      <SimpleGrid columns={2} spacing={2} w="50%">
        <Text style={speakingValdityStyle}>Speaking</Text>
        <Text style={speakingValdityStyle}>
          {convertToUnlimited(
            get(user, 'currentPackage.package.speaking.limit', 'N/A')
          )}
        </Text>
        <Text style={packageValdityStyle}>Writing</Text>
        <Text style={packageValdityStyle}>
          {convertToUnlimited(
            get(user, 'currentPackage.package.writing.limit', 'N/A')
          )}
        </Text>
        <Text style={packageValdityStyle}>Reading</Text>
        <Text style={packageValdityStyle}>
          {convertToUnlimited(
            get(user, 'currentPackage.package.reading.limit', 'N/A')
          )}
        </Text>
        <Text style={packageValdityStyle}>Listening</Text>
        <Text style={packageValdityStyle}>
          {convertToUnlimited(
            get(user, 'currentPackage.package.listening.limit', 'N/A')
          )}
        </Text>

        <Text style={mockValdityStyle}>Full Mocks</Text>
        <Text style={mockValdityStyle}>
          {get(user, 'currentPackage.fullMocks', 'N/A')}
        </Text>
        <Text style={mockValdityStyle}>Custom Mocks</Text>
        <Text style={mockValdityStyle}>
          {get(user, 'currentPackage.customMocks', 'N/A')}
        </Text>
      </SimpleGrid>

      <Box
        backgroundColor="custom.yellow"
        px={3}
        py={2}
        mt={6}
        fontWeight={600}
        d="inline-block"
        color="white"
        fontSize="md"
      >
        Your Speaking, Writing, Reading, Listening, Mocks & Prediction Limits
        Depend on the Following Validities.
      </Box>

      <SimpleGrid columns={2} spacing={2} w="50%" mt={2}>
        <Text style={packageValdityStyle}>Package Validity</Text>
        <Text style={packageValdityStyle}>
          {get(user, 'currentPackage') ? (
            <>
              {getDate(get(user, 'currentPackage.packageStartedAt'))} -{' '}
              {getDate(
                get(user, 'lastPackage.packageEndsOn') ??
                  get(user, 'currentPackage.packageEndsOn')
              )}
            </>
          ) : (
            'N/A'
          )}
        </Text>
        <Text style={speakingValdityStyle}>Speaking Validity</Text>
        <Text style={speakingValdityStyle}>
          {get(user, 'currentPackage') ? (
            <>
              {getDate(get(user, 'currentPackage.speakingStartedAt'))} -{' '}
              {getDate(get(user, 'currentPackage.speakingEndsOn'))}
            </>
          ) : (
            'N/A'
          )}
        </Text>
        <Text style={predictionValdityStyle}>Prediction Validity</Text>
        <Text style={predictionValdityStyle}>
          {get(user, 'currentPackage') ? (
            <>
              {getDate(get(user, 'currentPackage.predictionStartedAt'))} -{' '}
              {getDate(
                get(user, 'lastPackage.predictionEndsOn') ??
                  get(user, 'currentPackage.predictionEndsOn')
              )}
            </>
          ) : (
            'N/A'
          )}
        </Text>
        <Text style={mockValdityStyle}>Mocks Validity</Text>
        <Text style={mockValdityStyle}>
          {get(user, 'currentPackage') ? (
            <>
              {getDate(
                get(user, 'currentPackage.mockStartedAt') ??
                  get(user, 'currentPackage.packageStartedAt')
              )}{' '}
              - {getDate(mockExpiresDate)}
            </>
          ) : (
            'N/A'
          )}
        </Text>
      </SimpleGrid>

      {isSelf && (
        <SimpleGrid columns={3} spacing={4} mt={6}>
          <PackageCard {...get(packages, '1')} />
          <PackageCard {...get(packages, '2')} />
          <PackageCard {...get(packages, '3')} />
        </SimpleGrid>
      )}

      <SubscriptionHistory userId={userId} mt={6} />
    </Box>
  );
}

export default Subscription;
