import React, { useCallback } from 'react';
import Form from '../../Form/Form';
import FormTextarea from '../../Form/Textarea';
import { useForm } from 'react-hook-form';
import FormButton from '../../Form/Button';
import { Flex } from '@chakra-ui/core';
import { useCurrentUser } from '../../../store/user/hooks';
import CustomAvatar from '../../Avatar/Avatar';
import { get } from 'lodash-es';
import useCustomToast from '../../../hooks/useCustomToast';
import { handleApiError } from '../../../helpers/handleApiError';

function CommentForm({ questionId, addQuestionComment }) {
  const form = useForm();

  const user = useCurrentUser();

  const { toast } = useCustomToast();

  const onSubmit = useCallback(
    async ({ comment }) => {
      try {
        await addQuestionComment(questionId, { comment });
        form.reset();
      } catch (error) {
        handleApiError(error, { form, toast });
      }
    },
    [addQuestionComment, questionId, toast, form]
  );

  return (
    <Flex>
      <CustomAvatar size="50px" src={get(user, 'details.avatar')} />
      <Form form={form} width="100%" ml={6} onSubmit={onSubmit}>
        <FormTextarea
          name="comment"
          placeholder="Type your comment here"
          minHeight="120px"
        />
        <FormButton
          _hover={{ backgroundColor: 'custom.blue8' }}
          variantColor="blueVariant"
        >
          Add Comment
        </FormButton>
      </Form>
    </Flex>
  );
}

export default CommentForm;
