import { Flex, Stack, Heading, Text, Box } from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import {
  FcSpeaker,
  FcReading,
  FcHeadset,
  FcPortraitMode,
  FcTodoList,
} from 'react-icons/fc';
import { mockTypes } from '../../../../constants/mockTypes';

function AutoGenerated() {
  return (
    <Box>
      <Heading
        textAlign="center"
        size="md"
        fontWeight={500}
        mb={4}
        color="custom.black2"
      >
        Select the type of mock you want to attend
      </Heading>
      <Flex align="center" justify="center">
        <Stack isInline spacing={4}>
          <PrimaryButton
            as={Link}
            to={`/dashboard/mocks/attend/auto?mockVersion=2&mockType=${mockTypes.full}`}
            p={6}
            variant="outline"
            variantColor="blueVariant"
            leftIcon={FcTodoList}
            size={16}
          >
            Full Mock
          </PrimaryButton>
          <PrimaryButton
            as={Link}
            to={`/dashboard/mocks/attend/auto?mockVersion=2&mockType=${mockTypes.speaking}`}
            p={6}
            variant="outline"
            variantColor="blueVariant"
            leftIcon={FcSpeaker}
            size={16}
          >
            Speaking
          </PrimaryButton>
          <PrimaryButton
            as={Link}
            to={`/dashboard/mocks/attend/auto?mockVersion=2&mockType=${mockTypes.writing}`}
            p={6}
            variant="outline"
            variantColor="blueVariant"
            leftIcon={FcPortraitMode}
            size={16}
          >
            Writing
          </PrimaryButton>
          <PrimaryButton
            as={Link}
            to={`/dashboard/mocks/attend/auto?mockVersion=2&mockType=${mockTypes.reading}`}
            p={6}
            variant="outline"
            variantColor="blueVariant"
            leftIcon={FcReading}
            size={16}
          >
            Reading
          </PrimaryButton>
          <PrimaryButton
            as={Link}
            to={`/dashboard/mocks/attend/auto?mockVersion=2&mockType=${mockTypes.listening}`}
            p={6}
            variant="outline"
            variantColor="blueVariant"
            leftIcon={FcHeadset}
            size={16}
          >
            Listening
          </PrimaryButton>
        </Stack>
      </Flex>
    </Box>
  );
}

export default AutoGenerated;
