import React from 'react';
import { useFormContext } from 'react-hook-form';
import PrimaryButton from '../Button/PrimaryButton';

function FormButton({ children, ...props }) {
  const { formState } = useFormContext();

  const { isDirty, isSubmitting } = formState;

  return (
    <PrimaryButton
      type="submit"
      isLoading={isSubmitting}
      disabled={!isDirty}
      {...props}
    >
      {children}
    </PrimaryButton>
  );
}

export default FormButton;
