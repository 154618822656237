import { Button, Stack } from '@chakra-ui/core';
import React, { useRef } from 'react';

function PractiseStartSkipButton({
  status,
  isStarted,
  startRecording,
  setIsStarted,
  canStart = true,
}) {
  const skipButtonRef = useRef();
  const startButtonRef = useRef();

  return (
    <Stack isInline>
      {isStarted && (
        <Button
          ref={skipButtonRef}
          _hover={{
            backgroundColor: 'custom.blue8',
          }}
          variantColor="blueVariant"
          onClick={() => {
            console.log('skip button log: called clicked');
            skipButtonRef.current.disabled = true;
            startRecording();
          }}
        >
          Skip
        </Button>
      )}
      {!isStarted && !status && (
        <Button
          ref={startButtonRef}
          _hover={{
            backgroundColor: 'custom.blue8',
          }}
          variantColor="blueVariant"
          onClick={() => {
            console.log('start button log: called clicked');
            startButtonRef.current.disabled = true;
            setIsStarted(true);
          }}
          isLoading={!canStart}
        >
          Start
        </Button>
      )}
    </Stack>
  );
}

export default PractiseStartSkipButton;
