import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { UserAvatar } from '../../../../components/Avatar/Avatar';
import { get } from 'lodash-es';
import { getDate, DATE_TIME_FULL } from '../../../../helpers/getDate';
import Share from '../../../../components/Community/Share/Share';
import { changeToCamelCase } from '../../../../helpers/changeCase';
import { apiRequest } from '../../../../api/api';
import Loader from '../../../../components/Loader/Loader';

function ViewReport({ id }) {
  return (
    <Button
      as={Link}
      to={`/dashboard/mocks/report/${id}`}
      ml={6}
      variantColor="blueVariant"
      _hover={{
        borderColor: 'custom.blue',
        backgroundColor: 'custom.blue',
        color: 'custom.white1',
      }}
      variant="outline"
      size="sm"
    >
      View Report
    </Button>
  );
}

function ScoreItem({ score }) {
  const { id, overall, user, createdAt } = score;

  return (
    <Flex
      bg="white"
      py={4}
      px={6}
      boxShadow="custom.primary"
      justify="space-between"
      mb={4}
      borderRadius={4}
    >
      <Flex align="center">
        <UserAvatar
          size="42px"
          src={get(user, 'avatar')}
          name={get(user, 'firstName')}
        />
        <Box ml={4}>
          <Text color="custom.black">{get(user, 'firstName')}</Text>
          <Text color="custom.gray5" fontSize="xs">
            {getDate(createdAt, DATE_TIME_FULL)}
          </Text>
        </Box>
      </Flex>
      <Flex align="center">
        <Flex align="center">
          <Text color="custom.gray5" fontWeight="600" mr={2}>
            OVERALL
          </Text>
          <Box
            borderRadius="4px"
            minWidth="42px"
            textAlign="center"
            fontSize="2xl"
            justify="center"
            align="center"
            py={1}
            px={2}
            bg="custom.blue"
            color="white"
          >
            {overall}
          </Box>
        </Flex>
        <Share ml={6} shareLink={`${window.location.href}/report/${id}`} />
        <ViewReport id={id} />
      </Flex>
    </Flex>
  );
}

function Reports() {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsFetching(true);
        const { data } = await apiRequest('get', `mocks/reports`);
        setData(changeToCamelCase(data));
        setIsFetching(false);
      } catch {}
    }
    fetchData();
  }, []);

  if (!data.length) {
    return isFetching ? (
      <Loader />
    ) : (
      <Text color="custom.gray5">
        No report yet. Attend a Mock test to generate reports.
      </Text>
    );
  }

  return data.map((score) => <ScoreItem key={score.id} score={score} />);
}

export default Reports;
