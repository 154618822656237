import React from 'react';
import { Box } from '@chakra-ui/core';

function TinyBadge({ label, children, ...props }) {
  return (
    <Box
      mb={3}
      backgroundColor="custom.white6"
      color="custom.gray5"
      px={3}
      py={1}
      display="inline-block"
      borderRadius={4}
      fontSize="14px"
      {...props}
    >
      {children ? children : label}
    </Box>
  );
}

export default TinyBadge;
