import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
} from '@chakra-ui/core';
import WaveSurfer from 'wavesurfer.js';
import { customColors } from '../../theme/theme';
import { FiPlay, FiPause } from 'react-icons/fi';

function AudioPlayerControl({
  audioUrl,
  waveHeight = 128,
  volumeContainerProps = {},
}) {
  const waveSurfer = useRef();
  const containerRef = useRef();

  const [status, setStatus] = useState('loading');
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = useCallback(() => {
    setStatus('loaded');
  }, []);

  const onFinish = useCallback(() => {
    setStatus('ended');
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    if (audioUrl) {
      setStatus('loading');
      if (waveSurfer.current) {
        waveSurfer.current.load(audioUrl);
        return;
      }

      if (containerRef.current && !waveSurfer.current) {
        waveSurfer.current = WaveSurfer.create({
          container: containerRef.current,
          waveColor: 'white',
          barWidth: 4,
          barGap: 2,
          barRadius: 2,
          progressColor: customColors.blue,
          cursorColor: customColors.blue,
          height: waveHeight,
          xhr: { mode: 'cors' },
        });

        waveSurfer.current.on('ready', onReady);

        waveSurfer.current.on('finish', onFinish);

        waveSurfer.current.load(audioUrl);
      }
    }

    return () => waveSurfer.current?.empty();
  }, [audioUrl, onReady, onFinish, waveHeight]);

  const onPlay = useCallback(() => {
    setStatus('playing');
    waveSurfer.current.playPause();
    setIsPlaying(waveSurfer.current.isPlaying());
  }, []);

  return (
    <Box position="relative" bg="custom.blue3" p={4} borderRadius="5px" mt={2}>
      <Flex justify="space-between">
        <Text color="custom.gray5">
          Current Status:
          <Text
            as="strong"
            color="custom.red"
            ml={1}
            textTransform="capitalize"
          >
            {status}
          </Text>
        </Text>
        <Box minW="200px" {...volumeContainerProps}>
          <Flex align="center">
            <Text mr={3} fontSize="sm" color="custom.gray5" fontWeight={600}>
              Volume
            </Text>
            <Slider
              onChange={(value) => waveSurfer.current?.setVolume(value)}
              color="blueVariant"
              value={waveSurfer.current?.getVolume()}
              defaultValue={1}
              min={0}
              max={1}
              step={0.1}
              mr={2}
            >
              <SliderTrack />
              <SliderFilledTrack />
              <SliderThumb />
            </Slider>
          </Flex>
        </Box>
      </Flex>
      <Box m={4} position="relative" ref={containerRef} />

      <Box
        position="absolute"
        bottom="-35px"
        left="50%"
        transform="translateX(-50%)"
      >
        <Button
          variant="ghost"
          onClick={onPlay}
          disabled={status === 'loading'}
          boxShadow="custom.tertiary"
          backgroundColor="white"
          color="custom.blue"
          width="70px"
          height="70px"
          borderRadius="35px"
          _active={{
            backgroundColor: 'custom.blue',
            color: 'white',
          }}
          _hover={{
            backgroundColor: 'custom.blue',
            color: 'white',
          }}
        >
          <Flex direction="column" justify="center" align="center">
            {isPlaying ? (
              <>
                <Box as={FiPause} />
                <Text>Pause</Text>
              </>
            ) : (
              <>
                <Box as={FiPlay} />
                <Text>Play</Text>
              </>
            )}
          </Flex>
        </Button>
      </Box>
    </Box>
  );
}

export default AudioPlayerControl;
