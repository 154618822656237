import { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import { shuffle } from 'lodash-es';
import MockDragNDropSide from '../../../Components/MockDragNDropSide';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

function MockQuestionReadingReorderView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const options = useMemo(() => shuffle(get(data, 'reorder')), [data]);

  const [items, setItems] = useState(options);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setItems(options);
    setSelected([]);
  }, [options]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error } = useCustomToast();
  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setTimerDelay(null);

      await apiRequest('post', 'mocks/autoSave', {
        answer: selected,
        type: mock.type,
        section: sections.reading,
        question_id: data.id,
        module: data.module,
        index,
      });

      setTimerDelay(1000);

      onAfterSubmit();
    } catch {
      error({
        title: 'Looks like something went wrong!',
        description: 'Please check your network connection and try reloading.',
      });
      setIsSubmitting(false);
      setTimerDelay(1000);
    }
  }, [onAfterSubmit, error, mock, index, data, selected, setTimerDelay]);

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box py={10} px={6}>
            <Heading size="sm" mb={8}>
              The text boxes in the left panel have been placed in a random
              order. Restore the original order by dragging the text boxes from
              the left panel to the right panel.
            </Heading>
            <Flex mb={4} justify="space-between">
              <Text textAlign="center" flex={1}>
                Source
              </Text>
              <Text textAlign="center" flex={1}>
                Destination
              </Text>
            </Flex>
            <MockDragNDropSide
              items={items}
              setItems={setItems}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={onSubmit}
              isSubmitting={isSubmitting}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionReadingReorderView;
