import { Box, Flex, Heading, Stack } from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import { mockTypes } from '../../../../constants/mockTypes';
import {
  FcSpeaker,
  FcReading,
  FcHeadset,
  FcPortraitMode,
  FcTodoList,
} from 'react-icons/fc';

function MocksAddPage() {
  return (
    <Box maxWidth="1140px" margin="30px auto">
      <Heading size="lg" color="custom.black3" mb={6}>
        ADD MOCK
      </Heading>
      <Box borderRadius="6px" bg="white" p={10} boxShadow="custom.primary">
        <Heading
          textAlign="center"
          size="md"
          fontWeight={500}
          mb={4}
          color="custom.black2"
        >
          Select the type of mock you want to add
        </Heading>
        <Flex align="center" justify="center">
          <Stack isInline spacing={4}>
            <PrimaryButton
              as={Link}
              to="/dashboard/mocks/add/full"
              p={6}
              variant="outline"
              variantColor="blueVariant"
              leftIcon={FcTodoList}
              size={16}
            >
              Full Mock
            </PrimaryButton>
            <PrimaryButton
              as={Link}
              to={`/dashboard/mocks/add/${mockTypes.speaking}`}
              p={6}
              variant="outline"
              variantColor="blueVariant"
              leftIcon={FcSpeaker}
              size={16}
            >
              Speaking
            </PrimaryButton>
            <PrimaryButton
              as={Link}
              to={`/dashboard/mocks/add/${mockTypes.writing}`}
              p={6}
              variant="outline"
              variantColor="blueVariant"
              leftIcon={FcPortraitMode}
              size={16}
            >
              Writing
            </PrimaryButton>
            <PrimaryButton
              as={Link}
              to={`/dashboard/mocks/add/${mockTypes.reading}`}
              p={6}
              variant="outline"
              variantColor="blueVariant"
              leftIcon={FcReading}
              size={16}
            >
              Reading
            </PrimaryButton>
            <PrimaryButton
              as={Link}
              to={`/dashboard/mocks/add/${mockTypes.listening}`}
              p={6}
              variant="outline"
              variantColor="blueVariant"
              leftIcon={FcHeadset}
              size={16}
            >
              Listening
            </PrimaryButton>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

export default MocksAddPage;
