import { routesMapper } from '../Routes/routesMapper';
import { get } from 'lodash-es';
import { notificationService } from '../constants/notificationService';

const mockRoute = '/dashboard/mocks';

export const getRouteByModule = (id, { section, module }) => {
  return `${get(routesMapper, `${section}.${module}`)?.PRACTISE}/${id}`;
};

export const getRouteByService = ({ details, service }) => {
  let baseRoute = get(details, 'questionId')
    ? getRouteByModule(get(details, 'questionId'), {
        section: get(details, 'section'),
        module: get(details, 'module'),
      })
    : mockRoute; // assuming this is for mocks

  if (service === notificationService.comment) {
    baseRoute += `/comments#${get(details, 'commentId')}`;
  }

  return baseRoute;
};

export const getRouteFromOrigin = (path) => {
  return `${window.location.origin}${path}`;
};

export const getShareRoute = (section, module, id) => {
  return `${window.location.origin}/dashboard/score/${id}?section=${section}&module=${module}`;
};
