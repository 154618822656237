import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { store } from './store/store';
import Routes from './Routes/Routes';
import theme from './theme/theme';

import './App.css';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
