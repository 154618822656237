import React from 'react';
import { Button, Box, Icon } from '@chakra-ui/core';

function PrimaryButton({ children, onClick, icon, ...props }) {
  return (
    <Button
      onClick={onClick}
      variantColor="yellow"
      position="relative"
      {...(icon && { pr: 10, pl: 4 })}
      {...props}
    >
      {children}
      {icon && (
        <Box
          height="100%"
          position="absolute"
          right="4px"
          borderLeftWidth="1px"
          borderLeftColor="custom.yellow2"
        >
          <Icon name={icon} ml={1} height="100%" verticalAlign="middle" />
        </Box>
      )}
    </Button>
  );
}

export default PrimaryButton;
