import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import Form from '../../../../../../components/Form/Form';
import FormCheckbox from '../../../../../../components/Form/Checkbox';
import MockAudioPlayerBare from '../../../Components/Audio/Player';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

function MCQMultipleFormField({
  name,
  options = [],
  idPrefix = '',
  selectedAnswer = [],
}) {
  return (
    <>
      {options.map((option, index) => (
        <Flex
          align="center"
          py={2}
          px={1}
          mb={2}
          key={index}
          bg={selectedAnswer.includes(option) ? '#FAFFD2' : ''}
        >
          <FormCheckbox
            key={index}
            id={`${idPrefix}${index}`}
            name={`${name}[${index}]`}
            value={option}
            label={option}
          />
        </Flex>
      ))}
    </>
  );
}

function MockQuestionListeningMCQMultipleView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAudioPlayingEnded, setIsAudioPlayingEnded] = useState(false);

  const form = useForm();

  const { error } = useCustomToast();
  const onSubmit = useCallback(
    async ({ answer }) => {
      try {
        setIsSubmitting(true);
        setTimerDelay(null);

        await apiRequest('post', 'mocks/autoSave', {
          answer: answer.filter(Boolean),
          type: mock.type,
          section: sections.listening,
          question_id: data.id,
          module: data.module,
          index,
        });

        setTimerDelay(1000);

        onAfterSubmit();
      } catch {
        error({
          title: 'Looks like something went wrong!',
          description:
            'Please check your network connection and try reloading.',
        });
        setIsSubmitting(false);
        setTimerDelay(1000);
      }
    },
    [onAfterSubmit, error, mock, index, data, setTimerDelay]
  );

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box py={10} px={6}>
            <Heading size="sm" mb={8}>
              Listen to the recording and answer the question by selected all
              the correct responses. You will need to select more than on
              response.
            </Heading>
            <Flex justify="center" my={6}>
              <MockAudioPlayerBare
                beginningDuration={7}
                url={get(data, 'audio')}
                onEndCallback={() => setIsAudioPlayingEnded(true)}
              />
            </Flex>
            <Text
              ml={4}
              mb={4}
              dangerouslySetInnerHTML={{ __html: get(data, 'description') }}
            />
            <Form form={form}>
              <MCQMultipleFormField
                name="answer"
                options={get(data, 'multiple_choice')}
                selectedAnswer={form.watch('answer')}
              />
            </Form>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={() => form.handleSubmit(onSubmit)()}
              isSubmitting={isSubmitting}
              isNextButtonDisabled={!isAudioPlayingEnded}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionListeningMCQMultipleView;
