import React, { memo } from 'react';
import { Stack, Box, Text } from '@chakra-ui/core';
import CustomProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { isEmpty } from 'lodash-es';

function StatBox({ icon, label, progress = {}, bottomElement, ...props }) {
  return (
    <Stack
      spacing={2}
      color="black"
      p={6}
      borderRadius="6px"
      boxShadow="custom.tertiary"
      bg="white"
      {...props}
    >
      <Box as={icon} fontSize="4xl" />
      <Text
        fontSize="2xl"
        color="custom.blue"
        textTransform="uppercase"
        fontWeight={700}
      >
        {label}
      </Text>
      {!isEmpty(progress) && (
        <CustomProgressBar
          label={progress.label}
          value={progress.value}
          progressColor={progress.color}
        />
      )}
      {bottomElement}
    </Stack>
  );
}

export default memo(StatBox);
