import React, { useMemo, useCallback, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import { useForm } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import FormInput from '../../../../../components/Form/Input';
import FormSelect from '../../../../../components/Form/Select';
import FormVisualEditor from '../../../../../components/Form/VisualEditor';
import FormMultiSelect from '../../../../../components/Form/MutliSelect';
import * as Yup from 'yup';
import { priority } from '../../../../../constants/priority';
import { map, get } from 'lodash-es';
import { useLabels } from '../../../../../store/label/hooks';
import { stripHtml } from '../../../../../helpers/stripHtml';
import {
  Text,
  Stack,
  Box,
  Flex,
  FormLabel,
  SimpleGrid,
  Button,
} from '@chakra-ui/core';
import FormTextarea from '../../../../../components/Form/Textarea';
import FormRadio from '../../../../../components/Form/Radio';
import ErrorMessage from '../../../../../components/Form/ErrorMessage';
import FileUpload from '../../../../../components/Form/FileUpload';
import { IoIosMusicalNotes } from 'react-icons/io';
import { apiRequest } from '../../../../../api/api';

const getDefaultValues = (data) => ({
  labels: get(data, 'labels', []).map((label) => label.id),
  answer: get(data, 'data.answer', ''),
  description: get(data, 'data.question', ''),
  priority: get(data, 'priority', ''),
  advice: get(data, 'data.advice', ''),
  reference: get(data, 'data.reference', ''),
  phraseMatchCheck:
    get(data, 'data.phraseMatchCheck') === true ? 'true' : 'false',
});

function AddEditForm({ data, onSubmit }) {
  const defaultValues = useMemo(() => getDefaultValues({ ...data }), [data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        answer: Yup.string().required('Required.'),
        description: Yup.string().when('phraseMatchCheck', {
          is: true,
          then: Yup.string().test(
            'is-valid',
            'Must contains brackets [] and important phrases using curly {} brackets',
            (value) => value?.match(/\[.*{+.*\}+.*]/g)
          ),
          otherwise: Yup.string().required('Required.'),
        }),
        audioQuestion: data ? Yup.mixed() : Yup.mixed().required('Required.'),
        // sample: Yup.object({
        //   audioMale: data ? Yup.mixed() : Yup.mixed().required('Required.'),
        //   audioFemale: data ? Yup.mixed() : Yup.mixed().required('Required.'),
        // }),
        sample: Yup.object({
          audioMale: Yup.mixed(),
          audioFemale: Yup.mixed(),
        }),
        labels: Yup.array().required('Required.'),
        priority: Yup.string().required('Required.'),
        advice: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
        phraseMatchCheck: Yup.boolean(),
      }),
    [data]
  );

  const form = useForm({ defaultValues, validationSchema });

  const { reset, setValue } = form;

  const { byId, allIds } = useLabels();

  const priorityOptions = useMemo(
    () => map(priority, (item) => ({ label: item, value: item })),
    []
  );

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const formOnSubmit = useCallback(
    async (values) => {
      await onSubmit(values, { reset });
    },
    [onSubmit, reset]
  );

  const audioMale = form.watch('sample.audioMale');
  const audioMaleName = useMemo(() => audioMale?.name, [audioMale]);
  const audioFemale = form.watch('sample.audioFemale');
  const audioFemaleName = useMemo(() => audioFemale?.name, [audioFemale]);

  const audioQuestion = form.watch('audioQuestion');
  const audioQuestionName = useMemo(() => audioQuestion?.name, [audioQuestion]);

  const [isFetchingText, setIsFetchingText] = useState(false);

  const onGetText = useCallback(async () => {
    try {
      setIsFetchingText(true);

      const formData = new FormData();

      formData.append('audio', audioQuestion);

      const { data } = await apiRequest(
        'post',
        'speaking/utils/speechToText',
        formData
      );

      setValue('description', data);
    } catch {}

    setIsFetchingText(false);
  }, [audioQuestion, setValue]);

  return (
    <Form form={form} onSubmit={formOnSubmit} mt={6}>
      <FormLabel mb={1}>
        Audio format (.wav - 16 bit, 16000 hz rate, mono channel. Try this{' '}
        <strong>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://audio.online-convert.com/convert-to-wav"
          >
            converter
          </a>
        </strong>
        )
      </FormLabel>
      <Box
        alignItems="center"
        borderRadius="8px"
        p={2}
        borderWidth="1px"
        borderColor="custom.gray"
        mb={4}
      >
        <Stack alignItems="center" isInline spacing={4}>
          <FileUpload
            name="audioQuestion"
            icon={IoIosMusicalNotes}
            label="Upload Question"
            accept="audio/wav"
          />
          <Text>{audioQuestionName}</Text>
          <Button
            variant="outline"
            variantColor="blueVariant"
            size="sm"
            isDisabled={!audioQuestion}
            onClick={onGetText}
            isLoading={isFetchingText}
          >
            Get Text
          </Button>
        </Stack>
        <ErrorMessage name="audioQuestion" />
      </Box>
      <FormLabel mb={1}>
        Transcript of the Answer Short Question (Click <strong>Get Text</strong>{' '}
        after upload the audio). If you enable phrase use 3rd bracket to mark
        the context and 2nd bracket to mark important word(s)
      </FormLabel>
      <FormTextarea name="description" minHeight="120px" />
      <FormLabel mb={1}>Answer of the Question</FormLabel>
      <FormTextarea placeholder="Answer" name="answer" minHeight="100px" />
      <Box mb={4}>
        <FormLabel mb={1}>Enable Phrase Match Check</FormLabel>
        <Flex>
          <FormRadio
            label="Yes"
            name="phraseMatchCheck"
            value="true"
            id="yes"
          />
          <FormRadio label="No" name="phraseMatchCheck" value="false" id="no" />
        </Flex>
      </Box>
      <FormLabel mb={1}>
        Audio format (.wav - 16 bit, 16000 hz rate, mono channel. Try this{' '}
        <strong>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://audio.online-convert.com/convert-to-wav"
          >
            converter
          </a>
        </strong>
        )
      </FormLabel>
      <SimpleGrid columns={2} spacing={6} mb={6}>
        <Box
          alignItems="center"
          borderRadius="8px"
          p={2}
          borderWidth="1px"
          borderColor="custom.gray"
        >
          <Stack alignItems="center" isInline spacing={4}>
            <FileUpload
              name="sample.audioMale"
              icon={IoIosMusicalNotes}
              label="Upload Male Audio"
              accept="audio/wav"
            />
            <Text>{audioMaleName}</Text>
          </Stack>
          <ErrorMessage name="sample.audioMale" />
        </Box>
        <Box
          p={2}
          alignItems="center"
          borderRadius="8px"
          borderWidth="1px"
          borderColor="custom.gray"
        >
          <Stack alignItems="center" isInline spacing={4}>
            <FileUpload
              name="sample.audioFemale"
              icon={IoIosMusicalNotes}
              label="Upload Female Audio"
              accept="audio/wav"
            />
            <Text>{audioFemaleName}</Text>
          </Stack>
          <ErrorMessage name="sample.audioFemale" />
        </Box>
      </SimpleGrid>
      <FormMultiSelect
        placeholder="Labels"
        name="labels"
        options={labelOptions}
      />
      <FormSelect
        name="priority"
        placeholder="Priority"
        options={priorityOptions}
      />
      <FormInput placeholder="Credit/Source" name="reference" />
      <Text fontWeight={600} color="custom.gray5" mb={2}>
        Examiner's Advice
      </Text>
      <FormVisualEditor name="advice" />
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

export default AddEditForm;
