import { combineReducers } from 'redux';
import questions from './questionSlice';
import comments from './commentSlice';

const listeningReducer = combineReducers({
  questions,
  comments,
});

export default listeningReducer;
