import React, { useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import {
  ScoreHeader,
  ScoreDetails,
  ScoreFooter,
  MockScoreHeader,
} from '../Common/CommonView';
import get from 'lodash-es/get';

function ReorderScore({ score, practiseAgain, isMockReport }) {
  const givenAnswerArray = useMemo(() => {
    if (score.details.givenAnswer.length) {
      return score.details.givenAnswer;
    }
    return [
      {
        index: 1,
        answer: 'Not Answered',
      },
    ];
  }, [score.details.givenAnswer]);

  const answerArray = useMemo(() => score.details.actualAnswer, [
    score.details.actualAnswer,
  ]);

  return (
    <Box mt={2} mb={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          CORRECT ORDER
        </Text>
        {answerArray.map((option, index) => (
          <Flex key={option} align="start" lineHeight={1.5} mb={2}>
            <Text color="custom.black3" fontWeight="700" mr={2}>
              [{index + 1}]
            </Text>
            <Text color="custom.blue">{option}</Text>
          </Flex>
        ))}
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          YOUR ANSWER
        </Text>
        {givenAnswerArray.map((option, index) => (
          <Flex
            key={get(option, 'index')}
            align="start"
            lineHeight={1.5}
            mb={2}
            color="custom.black3"
            // color={
            //   answerArray[index] === get(option, 'answer')
            //     ? 'custom.blue'
            //     : 'custom.red'
            // }
          >
            <Text color="custom.black3" fontWeight="700" mr={2}>
              [{get(option, 'index')}]
            </Text>
            <Text>{get(option, 'answer')}</Text>
          </Flex>
        ))}
      </Box>
      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        isMockReport={isMockReport}
      />
      {!isMockReport && (
        <ScoreFooter score={score} practiseAgain={practiseAgain} />
      )}
    </Box>
  );
}

export default ReorderScore;
