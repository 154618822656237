import React, { useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import { get } from 'lodash-es';
import {
  ScoreHeader,
  ScoreFooter,
  ScoreDetails,
  MockScoreHeader,
} from '../Common/CommonView';

function MCQSingleScore({ score, practiseAgain, isMockReport }) {
  const givenAnswerArray = useMemo(
    () =>
      Array.isArray(score.details.givenAnswer)
        ? score.details.givenAnswer
        : [score.details.givenAnswer],
    [score.details.givenAnswer]
  );

  const answerArray = useMemo(
    () =>
      Array.isArray(score.details.actualAnswer)
        ? score.details.actualAnswer
        : [score.details.actualAnswer],
    [score.details.actualAnswer]
  );

  return (
    <Box mt={2}>
      {isMockReport ? (
        <MockScoreHeader score={score} />
      ) : (
        <ScoreHeader score={score} />
      )}
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600}>
          QUESTION
        </Text>
        <Text
          color="custom.black"
          dangerouslySetInnerHTML={{
            __html: get(score, 'details.description'),
          }}
        />
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          OPTIONS
        </Text>
        {score.details.options.map((option) => (
          <Flex
            key={option}
            align="center"
            color={answerArray.includes(option) ? 'custom.blue' : 'custom.red'}
          >
            <Box
              as={answerArray.includes(option) ? MdCheckCircle : MdCancel}
              mr={2}
            />
            <Text>{option}</Text>
          </Flex>
        ))}
      </Box>
      <Box mt={6}>
        <Text color="custom.black3" fontWeight={600} mb={1}>
          YOUR ANSWER
        </Text>
        {givenAnswerArray.map((option) => (
          <Flex
            key={option}
            align="center"
            color={answerArray.includes(option) ? 'custom.blue' : 'custom.red'}
          >
            <Box
              as={answerArray.includes(option) ? MdCheckCircle : MdCancel}
              mr={2}
            />
            <Text>{option || 'Not Answered'}</Text>
          </Flex>
        ))}
      </Box>
      <ScoreDetails
        score={score}
        totalScore={get(score, 'details.actualScore')}
        isMockReport={isMockReport}
      />
      {!isMockReport && (
        <ScoreFooter score={score} practiseAgain={practiseAgain} />
      )}
    </Box>
  );
}

export default MCQSingleScore;
