import React from 'react';
import { Box } from '@chakra-ui/core';
import ReactSelect from 'react-select';
import {
  getCustomSelectStyles,
  getCustomSelectTheme,
} from '../helpers/selectStyles';

function DateIntervalSelect({ value, options, onChange, ...props }) {
  return (
    <Box minW="120px" {...props}>
      <ReactSelect
        styles={getCustomSelectStyles()}
        value={value}
        options={options}
        onChange={onChange}
        theme={getCustomSelectTheme}
      />
    </Box>
  );
}

export default DateIntervalSelect;
