import { Heading, Stack, Text, Image, Box } from '@chakra-ui/core';
import React from 'react';
import timeImage from '../../../../../assets/images/timer.jpg';
import timeStateImage from '../../../../../assets/images/timer-state.jpg';
import { mockVersions } from '../../../../../constants/mockTypes';

function TestInformation({ mockVersion }) {
  return (
    <Stack spacing={4}>
      <Heading size="md">Test Introduction</Heading>
      <Text>
        This test measures the Reading, Writing, Listening and Speaking skills
        in English that you will need in an academic setting.
      </Text>
      <Box as="ul" ml={8}>
        <li>
          This test is divided into 3 parts. Each part may contain a number of
          sections. The sections are individually timed. The timer will be shown
          in the top right corner of your screen. The number of items in the
          section will also be displayed
          <Image my={2} maxW="300px" width="100%" src={timeImage} />
        </li>
        <li>
          The timer will become red when there is less than 5 minutes remaining
          for that section. The timer can be hidden by clicking on the clock
          icon with the mouse. Click on the icon again to bring the timer back.
          <Image my={2} maxW="600px" width="100%" src={timeStateImage} />
        </li>
        <li>
          At the beginning of each part you will receive instructions. These
          will provide details on what to expect in that part of the test.
        </li>
        <li>
          By clicking on the Next (N) button at the bottom of each screen you
          confirm your answer and move to the next questions. If you click on
          Next (N) you will{' '}
          <Text as="strong" color="#FF0000">
            not
          </Text>{' '}
          be able to return to the previous questions. You will{' '}
          <Text as="strong" color="#FF0000">
            not
          </Text>{' '}
          be able to revisit any questions at the end of the test
        </li>
        {mockVersion === mockVersions.v1 && (
          <li>
            You will be offered a break of up to 10 minutes after Part 2. The
            break is optional
          </li>
        )}
        <li>
          This test makes use of different varieties of English, for example,
          British, American, Australian. You can answer in the standard English
          variety of your choice
        </li>
      </Box>
    </Stack>
  );
}

export default TestInformation;
