import React, { useMemo, useState, useEffect, memo, useCallback } from 'react';
import Form from '../Form/Form';
import { useForm } from 'react-hook-form';
import FormRadio from '../Form/Radio';
import { FormLabel, Stack, Flex, IconButton } from '@chakra-ui/core';
import FormButton from '../Form/Button';
import { motion, AnimatePresence } from 'framer-motion';
import { apiRequest } from '../../api/api';

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: '100%' },
};

function useExamQuestion({ section, id }) {
  const [isSet, setIsSet] = useState();

  useEffect(() => {
    async function fetchData() {
      setIsSet(true);
      const { data } = await apiRequest(
        'get',
        `flags/examQuestion?section=${section}&question_id=${id}`
      );
      setIsSet(data.status);
    }

    if (id) {
      fetchData();
    }
  }, [section, id]);

  return isSet;
}

function SeenQuestion({ section, id }) {
  const defaultValues = useMemo(() => ({ seen: 'no' }), []);
  const form = useForm({ defaultValues });

  const isSet = useExamQuestion({ section, id });

  const [isLocalSet, setIsLocalSet] = useState(isSet);

  useEffect(() => {
    if (id) setIsLocalSet(isSet);
  }, [isSet, id, section]);

  const onSubmit = useCallback(
    async ({ seen }) => {
      try {
        await apiRequest(
          'post',
          `flags/examQuestion?section=${section}&question_id=${id}`,
          { answer: seen }
        );
        setIsLocalSet(true);
      } catch (error) {}
    },
    [section, id]
  );

  return (
    <AnimatePresence>
      {isLocalSet === false && (
        <motion.div
          initial="hidden"
          style={{
            position: 'absolute',
            bottom: 20,
            right: 20,
          }}
          animate={isLocalSet !== false ? 'hidden' : 'visible'}
          variants={variants}
          transition={{ duration: 0.5, delay: 1 }}
          exit="hidden"
        >
          <Flex direction="column">
            <IconButton
              icon="triangle-down"
              bg="white"
              size="sm"
              onClick={() => setIsLocalSet(true)}
              alignSelf="flex-end"
            />
            <Form
              zIndex={999999}
              form={form}
              bg="white"
              boxShadow="custom.primary"
              p={6}
              onSubmit={onSubmit}
            >
              <FormLabel color="custom.blue" fontWeight={600}>
                Have you seen this question in real PTE exam?
              </FormLabel>
              <Stack isInline>
                <FormRadio name="seen" id="seen-yes" value="Yes" label="Yes" />
                <FormRadio name="seen" id="seen-no" value="No" label="No" />
                <FormRadio
                  id="seen-not-remember"
                  name="seen"
                  value="I don't remeber"
                  label="I don't Remember"
                />
              </Stack>
              <FormButton size="sm" mt={2}>
                Submit
              </FormButton>
            </Form>
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default memo(SeenQuestion);
