import React, { useMemo, useCallback, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import { useForm } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import FormInput from '../../../../../components/Form/Input';
import FormSelect from '../../../../../components/Form/Select';
import FormVisualEditor from '../../../../../components/Form/VisualEditor';
import FormMultiSelect from '../../../../../components/Form/MutliSelect';
import * as Yup from 'yup';
import { priority } from '../../../../../constants/priority';
import { map, get } from 'lodash-es';
import { useLabels } from '../../../../../store/label/hooks';
import { stripHtml } from '../../../../../helpers/stripHtml';
import FileUpload from '../../../../../components/Form/FileUpload';
import { Box, Button, FormLabel, Stack, Text } from '@chakra-ui/core';
import { IoIosMusicalNotes } from 'react-icons/io';
import ErrorMessage from '../../../../../components/Form/ErrorMessage';
import FormTextarea from '../../../../../components/Form/Textarea';
import { accept } from '../../../../../constants/accept';
import { apiRequest } from '../../../../../api/api';

function AddEditForm({ data, onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      labels: get(data, 'labels', []).map((label) => label.id),
      description: get(data, 'description', ''),
      priority: get(data, 'priority', ''),
      duration: get(data, 'data.duration', ''),
      advice: get(data, 'data.advice', ''),
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        description: Yup.string().required('Required.'),
        audio: data ? Yup.mixed() : Yup.mixed().required('Required.'),
        labels: Yup.array().required('Required.'),
        duration: Yup.string().required('Required.'),
        priority: Yup.string().required('Required.'),
        advice: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
      }),
    [data]
  );

  const form = useForm({ defaultValues, validationSchema });

  const { reset, setValue } = form;

  const { byId, allIds } = useLabels();

  const priorityOptions = useMemo(
    () => map(priority, (item) => ({ label: item, value: item })),
    []
  );

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const formOnSubmit = useCallback(
    async (values) => {
      await onSubmit(values, { reset });
    },
    [onSubmit, reset]
  );

  const audio = form.watch('audio');

  const audioName = useMemo(() => audio?.name, [audio]);

  const [isFetchingText, setIsFetchingText] = useState(false);

  const onGetText = useCallback(async () => {
    try {
      setIsFetchingText(true);

      const formData = new FormData();

      formData.append('audio', audio);

      const { data } = await apiRequest(
        'post',
        'speaking/utils/speechToText',
        formData
      );

      setValue('description', data);
    } catch {}

    setIsFetchingText(false);
  }, [audio, setValue]);

  return (
    <Form form={form} onSubmit={formOnSubmit} mt={6}>
      <FormLabel mb={1}>
        Audio format (.wav - 16 bit, 16000 hz rate, mono channel. Try this{' '}
        <strong>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://audio.online-convert.com/convert-to-wav"
          >
            converter
          </a>
        </strong>
        )
      </FormLabel>
      <Box
        alignItems="center"
        borderRadius="8px"
        p={2}
        borderWidth="1px"
        borderColor="custom.gray"
        mb={4}
      >
        <Stack alignItems="center" isInline spacing={4}>
          <FileUpload
            name="audio"
            icon={IoIosMusicalNotes}
            label="Upload Question"
            accept={accept.audio}
          />
          <Text>{audioName}</Text>
          <Button
            variant="outline"
            variantColor="blueVariant"
            size="sm"
            isDisabled={!audio}
            onClick={onGetText}
            isLoading={isFetchingText}
          >
            Get Text
          </Button>
        </Stack>
        <ErrorMessage name="audio" />
      </Box>
      <FormLabel mb={1}>
        Transcript of the Dictation (Click <strong>Get Text</strong> after
        upload the audio).
      </FormLabel>
      <FormTextarea name="description" minHeight="180px" />

      <FormMultiSelect
        placeholder="Labels"
        name="labels"
        options={labelOptions}
      />
      <FormSelect
        name="priority"
        placeholder="Priority"
        options={priorityOptions}
      />
      <FormInput
        type="number"
        placeholder="Duration (Target Time) (In Seconds)"
        name="duration"
      />
      <Text fontWeight={600} color="custom.gray5" mb={2}>
        Examiner's Advice
      </Text>
      <FormVisualEditor name="advice" />
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

export default AddEditForm;
