import React, { useCallback, useMemo, useEffect } from 'react';
import {
  Flex,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Box,
  FormLabel,
  Stack,
} from '@chakra-ui/core';
import { FaSortAmountUp, FaSortAmountDown } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import Form from '../Form/Form';
import FormCheckbox from '../Form/Checkbox';
import FormButton from '../Form/Button';
import { useLabels } from '../../store/label/hooks';
import { get, debounce, map } from 'lodash-es';
import SearchBar from '../Search/SearchBar';
import FormSelect from '../Form/Select';
import { priority } from '../../constants/priority';

const priorityOptions = map(priority, (value, key) => ({
  label: value,
  value: key,
}));

function Filter({ queryObject, setQuery }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { byId, allIds: labelIds } = useLabels();

  const practiced = useMemo(() => get(queryObject, 'practiced') === 'yes', [
    queryObject,
  ]);
  const notPracticed = useMemo(() => get(queryObject, 'practiced') === 'no', [
    queryObject,
  ]);
  const labels = useMemo(() => get(queryObject, 'labels', '').split(','), [
    queryObject,
  ]);

  const defaultValues = useMemo(
    () => ({
      priority: get(queryObject, 'priority'),
    }),
    [queryObject]
  );

  const form = useForm({ defaultValues });

  const { reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen, reset, defaultValues]);

  const submit = useCallback(
    ({ labels, practiced, notPracticed, priority }) => {
      const labelIds = [];
      for (let index = 0; index < labels.length; index++) {
        if (labels[index]) {
          labelIds.push(index);
        }
      }

      let isPracticed;

      if (practiced) {
        isPracticed = 'yes';
      }

      if (notPracticed) {
        isPracticed = 'no';
      }

      if (practiced && notPracticed) {
        isPracticed = undefined;
      }

      setQuery({
        labels: labelIds.length ? labelIds.join(',') : undefined,
        practiced: isPracticed,
        priority: priority || undefined,
      });
    },
    [setQuery]
  );

  return (
    <>
      <Button
        size="sm"
        variant="link"
        leftIcon={FiFilter}
        onClick={onOpen}
        color="custom.black"
        _hover={{
          color: 'custom.blue',
        }}
      >
        Filter
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={submit}>
            <ModalHeader color="custom.black3">Filter Questions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mb={6}>
                <FormLabel mb={1} color="custom.black5">
                  Filter By Label
                </FormLabel>
                <Stack isInline flexWrap="wrap">
                  {labelIds.map((id) => (
                    <FormCheckbox
                      color="custom.black"
                      key={id}
                      defaultIsChecked={labels.includes(id.toString())}
                      name={`labels.${id}`}
                      label={get(byId[id], 'name')}
                    />
                  ))}
                </Stack>
              </Box>
              <Box>
                <FormLabel mb={1} color="custom.black5">
                  Practice Status
                </FormLabel>
                <Stack isInline color="custom.black">
                  <FormCheckbox
                    defaultIsChecked={practiced}
                    name="practiced"
                    label="Practiced"
                  />
                  <FormCheckbox
                    defaultIsChecked={notPracticed}
                    name="notPracticed"
                    label="Not Practiced"
                  />
                </Stack>
              </Box>
              <Box mt={4}>
                <FormLabel mb={1} color="custom.black5">
                  Priority
                </FormLabel>
                <FormSelect
                  isClearable
                  name="priority"
                  options={priorityOptions}
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                variantColor="blueVariant"
                variant="link"
                mr={3}
                onClick={onClose}
              >
                Cancel
              </Button>
              <FormButton
                _hover={{
                  backgroundColor: 'custom.blue8',
                }}
                variantColor="blueVariant"
              >
                Apply Filter
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
}

function CustomFilter({ searchPlaceHolder, queryObject, setQuery }) {
  const fetchByOrder = useCallback(
    (order) => {
      setQuery({ order });
    },
    [setQuery]
  );

  const order = get(queryObject, 'order', 'DESC');

  const search = debounce((value) => {
    setQuery({ search: value });
  }, 500);

  return (
    <Box>
      <SearchBar
        placeholder={searchPlaceHolder}
        onChange={(e) => search(e.target.value)}
      />
      <Flex
        borderBottomWidth="1px"
        borderBottomColor="custom.white4"
        pb={4}
        mb={3}
        justify="space-between"
      >
        <Filter queryObject={queryObject} setQuery={setQuery} />
        <IconButton
          onClick={() => fetchByOrder(order === 'ASC' ? 'DESC' : 'ASC')}
          size="sm"
          p={0}
          m={0}
          icon={order === 'DESC' ? FaSortAmountDown : FaSortAmountUp}
          variant="link"
          color="custom.black"
          _hover={{
            color: 'custom.blue',
          }}
        />
      </Flex>
    </Box>
  );
}

export default CustomFilter;
