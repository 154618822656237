import { reduce, omitBy, isNil } from 'lodash-es';
import { getDate, DATE_FORMAT_SHORT } from '../../helpers/getDate';
import { changeToSnakeCase } from '../../helpers/changeCase';

export function prepareUserFilters(data) {
  const {
    residenceCountry,
    residenceState,
    citizenshipCountry,
    ...rest
  } = reduce(
    data,
    (obj, value, key) => ({
      ...obj,
      [key]: value instanceof Date ? getDate(value, DATE_FORMAT_SHORT) : value,
    }),
    {}
  );

  const preparedValues = {
    ...rest,
    residenceCountryId: residenceCountry?.id,
    residenceStateId: residenceState?.id,
    citizenshipCountryId: citizenshipCountry?.id,
  };

  return changeToSnakeCase(omitBy(preparedValues, isNil));
}
