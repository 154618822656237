import React, { useCallback, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import { get } from 'lodash-es';
import { FormControl, FormLabel, Box } from '@chakra-ui/core';
import ErrorMessage from './ErrorMessage';

const getCustomStyles = (isInvalid) => ({
  control: (provided) => ({
    ...provided,
    padding: 2,
    fontSize: '14px',
    borderColor: isInvalid ? '#EB5555' : '#DCDDE1',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
      backgroundColor: 'transparent',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: data.color,
    backgroundColor: 'transparent',
  }),
});

function SelectField({
  id,
  name,
  options,
  isLoading,
  value,
  setValue,
  isInvalid,
  ...props
}) {
  const styles = useMemo(() => getCustomStyles(isInvalid), [isInvalid]);

  const formattedValue = useMemo(() => {
    return value.map((id) => {
      const option = options.find((option) => option.value === id);
      return {
        value: id,
        label: option?.label,
        ...option,
      };
    });
  }, [value, options]);

  const onChange = useCallback(
    (valueOptions) => {
      const value = (valueOptions || []).map(({ value }) => value);
      setValue(name, value);
    },
    [name, setValue]
  );

  return (
    <ReactSelect
      {...props}
      id={id}
      name={name}
      value={formattedValue}
      options={options}
      onChange={onChange}
      styles={styles}
    />
  );
}

function FormMultiSelect({
  name,
  id = name,
  label,
  options,
  loading,
  required,
  validate,
  ...props
}) {
  const { register, errors, watch, setValue } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    register({ name });
  }, [name, register]);

  return (
    <FormControl
      isRequired={props.required}
      isDisabled={props.disabled}
      isInvalid={!!get(errors, name)}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          {...(props.labelProps && { ...props.labelProps })}
        >
          {label}
        </FormLabel>
      )}
      <Box {...props} mb={6}>
        <SelectField
          id={id}
          name={name}
          options={options}
          isLoading={loading}
          value={value}
          setValue={setValue}
          isMulti
          isInvalid={!!get(errors, name)}
          {...props}
        />

        <ErrorMessage name={name} />
      </Box>
    </FormControl>
  );
}

export default FormMultiSelect;
