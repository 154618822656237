import React, { useCallback, useMemo } from 'react';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/core';
import * as Yup from 'yup';
import { apiRequest } from '../../api/api';
import { useForm } from 'react-hook-form';
import Form from '../Form/Form';
import FormButton from '../Form/Button';
import { handleApiError } from '../../helpers/handleApiError';
import FormTextarea from '../Form/Textarea';
import { AiOutlineBug } from 'react-icons/ai';

function ReportBug({ details }) {
  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        report: Yup.string()
          .min(10, 'Write atleast 10 characters.')
          .required('Required.'),
      }),
    []
  );

  const form = useForm({ validationSchema });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const submit = useCallback(
    async ({ report }) => {
      try {
        await apiRequest('post', 'bugs/report', { report, details });

        toast({
          position: 'top',
          title: 'Bug has been reported.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        onClose();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [toast, onClose, details]
  );

  return (
    <>
      <Button
        variant="link"
        variantColor="redVariant"
        onClick={onOpen}
        leftIcon={AiOutlineBug}
      >
        Report Bug
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Report Bug</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form form={form} py={4} onSubmit={submit}>
              <FormTextarea
                name="report"
                placeholder="Write about your issue"
              />
              <FormButton>Submit</FormButton>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ReportBug;
