import React, { useMemo, useCallback } from 'react';
import {
  Button,
  useDisclosure,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
} from '@chakra-ui/core';
import Form from '../../../components/Form/Form';
import FormDatePicker from '../../../components/Form/DatePicker';
import { useForm } from 'react-hook-form';
import FormButton from '../../../components/Form/Button';
import { FiFilter } from 'react-icons/fi';
import { get } from 'lodash-es';
import FormSelect from '../../../components/Form/Select';

function FilterForm({ values, onSubmit, onClose }) {
  const defaultValues = useMemo(
    () => ({
      packageStart: get(values, 'packageStart'),
      packageEnd: get(values, 'packageEnd'),
      speakingStart: get(values, 'speakingStart'),
      speakingEnd: get(values, 'speakingEnd'),
      examStart: get(values, 'examStart'),
      examEnd: get(values, 'examEnd'),
      mfa: get(values, 'mfa'),
      userStatus: get(values, 'userStatus'),
    }),
    [values]
  );

  const form = useForm({ defaultValues });

  const onFormSubmit = useCallback(
    (values) => {
      onSubmit(values);
      onClose();
    },
    [onSubmit, onClose]
  );

  const mfaOptions = useMemo(
    () => [
      { label: 'True', value: '1' },
      { label: 'False', value: '0' },
    ],
    []
  );

  const statusOptions = useMemo(
    () => [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' },
    ],
    []
  );

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack isInline spacing={4}>
        <FormDatePicker name="packageStart" label="Package Start Date" />
        <FormDatePicker name="packageEnd" label="Package End Date" />
      </Stack>
      <Stack isInline spacing={4}>
        <FormDatePicker name="speakingStart" label="Speaking Start Date" />
        <FormDatePicker name="speakingEnd" label="Speaking End Date" />
      </Stack>
      <Stack isInline spacing={4}>
        <FormDatePicker name="examStart" label="Exam Start Date" />
        <FormDatePicker name="examEnd" label="Exam End Date" />
      </Stack>

      <Stack isInline spacing={4}>
        <Box minW="160px">
          <FormSelect name="mfa" label="MFA" options={mfaOptions} isClearable />
        </Box>
        <Box minW="160px">
          <FormSelect
            name="userStatus"
            label="Status"
            options={statusOptions}
            isClearable
          />
        </Box>
      </Stack>

      <FormButton>Submit</FormButton>
    </Form>
  );
}

function UsersFilter({ values, onSubmit, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button leftIcon={FiFilter} onClick={onOpen} variant="link" {...props}>
        Filter
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Filter Users</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FilterForm values={values} onClose={onClose} onSubmit={onSubmit} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UsersFilter;
