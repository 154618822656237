import React from 'react';
import { Flex, Button } from '@chakra-ui/core';

function NextPrevButtons({
  hasNextPage,
  hasPrevPage,
  setPage,
  fetching = false,
}) {
  if (!hasNextPage && !hasPrevPage) return null;

  return (
    <Flex justify="space-between" mt={4} borderTopWidth="1px" pt={4}>
      <Button
        onClick={() => setPage((page) => page - 1)}
        disabled={!hasPrevPage || fetching}
        variant="link"
        variantColor="blueVariant"
      >
        PREV
      </Button>
      <Button
        onClick={() => setPage((page) => page + 1)}
        disabled={!hasNextPage || fetching}
        variant="link"
        variantColor="blueVariant"
      >
        NEXT
      </Button>
    </Flex>
  );
}

export default NextPrevButtons;
