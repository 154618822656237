import React, { useCallback } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/core';
import { MdSave, MdDrafts } from 'react-icons/md';

function DraftPublish({ id, status, onConfirm }) {
  // Must pass a promise to handle async close of modal
  const { isOpen, onClose, onOpen } = useDisclosure();

  const cancelRef = React.useRef();

  const confirm = useCallback(async () => {
    onClose();

    await onConfirm(id);
  }, [onClose, id, onConfirm]);

  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        variantColor="blueVariant"
        leftIcon={status === 0 ? MdSave : MdDrafts}
      >
        {status === 0 ? 'Publish' : 'Draft'}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirm {status === 0 ? 'Publish' : 'Draft'}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to perform this action?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              variantColor="redVariant"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button variantColor="greenVariant" onClick={confirm} ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default DraftPublish;
