import React, { useState } from 'react';
import { Box, Text, Flex, Button, Progress } from '@chakra-ui/core';
import { useCallback } from 'react';
import useMockRecorder from '../../Hooks/useMockRecorder';

const recordingDuration = 10;

function DummyRecorder() {
  const [audio, setAudio] = useState();

  const onStop = useCallback((blob) => {
    const blobUrl = URL.createObjectURL(blob);
    setAudio(new Audio(blobUrl));
  }, []);

  const { start, stop, status, recordingTime } = useMockRecorder(
    recordingDuration,
    onStop
  );

  const handleStop = useCallback(async () => {
    const blob = await stop();
    onStop(blob);
  }, [onStop, stop]);

  return (
    <Box>
      <Box
        borderWidth="1px"
        bg="mock.blue"
        borderColor="mock.gray"
        mt={4}
        p="10px"
        w="350px"
      >
        <Text mb={6} textAlign="center">
          Recorded Answer
        </Text>
        <Flex>
          <Text mr={6}>Current Status:</Text>
          <Text textTransform="capitalize">
            {status === 'stopped' ? 'Recorded' : status}
          </Text>
        </Flex>
        <Text mb={4}>Click Record to Begin</Text>

        <Progress
          borderWidth={1}
          h={4}
          borderColor="mock.gray"
          bg="white"
          mt={6}
          value={
            ((recordingDuration - recordingTime) * 100) / recordingDuration
          }
          color="blueVariant"
        />
        <Flex justify="space-between" mt={4}>
          <Button
            size="sm"
            onClick={() => {
              if (audio) {
                audio.pause();
                audio.currentTime = 0;
              }
              start();
            }}
            bg="white"
            borderWidth={1}
            borderColor="mock.gray"
          >
            Record
          </Button>
          <Button
            size="sm"
            onClick={() => audio.play()}
            bg="white"
            borderWidth={1}
            borderColor="mock.gray"
            isDisabled={status === 'recording' || !status}
          >
            Playback
          </Button>
          <Button
            size="sm"
            onClick={handleStop}
            bg="white"
            borderWidth={1}
            borderColor="mock.gray"
            isDisabled={status !== 'recording'}
          >
            Stop
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default DummyRecorder;
