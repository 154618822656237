import React from 'react';
import { InputGroup, InputLeftElement, Input, Box } from '@chakra-ui/core';
import { FiSearch } from 'react-icons/fi';

function SearchBar({ placeholder, onChange, ...props }) {
  return (
    <InputGroup mt={1} mb={6} {...props}>
      <InputLeftElement
        children={<Box as={FiSearch} fontSize="24px" color="custom.gray" />}
      />
      <Input
        borderRadius="4px"
        type="text"
        placeholder={placeholder}
        focusBorderColor="custom.blue2"
        onChange={onChange}
      />
    </InputGroup>
  );
}

export default SearchBar;
