import React, { useCallback, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Flex,
} from '@chakra-ui/core';
import { get } from 'lodash-es';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormContext } from 'react-hook-form';
import { toDate } from '../../helpers/toDate';
import ErrorMessage from './ErrorMessage';

const CustomInput = React.forwardRef((props, ref) => (
  <InputGroup>
    <Input
      ref={ref}
      borderRadius="6px"
      borderColor="custom.gray"
      borderWidth="1px"
      errorBorderColor="custom.red"
      focusBorderColor="custom.blue2"
      boxShadow="none !important"
      py={5}
      fontSize="14px"
      {...props}
    />
    <InputRightElement>
      <Icon name="calendar" color="custom.gray" />
    </InputRightElement>
  </InputGroup>
));

function FormDatePicker({
  name,
  id = name,
  label,
  placeholder,
  labelProps,
  ...props
}) {
  const { errors, register, unregister, setValue, watch } = useFormContext();

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  const selected = toDate(watch(name));

  const onChange = useCallback(
    (value) => {
      setValue(name, value || null);
    },
    [setValue, name]
  );

  return (
    <FormControl
      id={id}
      isRequired={props.required}
      isInvalid={Boolean(get(errors, name))}
    >
      {label && (
        <FormLabel htmlFor={id} {...(labelProps && labelProps)}>
          {label}
        </FormLabel>
      )}
      <Flex mb={6} direction="column" {...props}>
        <ReactDatePicker
          selected={selected}
          onChange={onChange}
          placeholderText={placeholder}
          customInput={<CustomInput />}
        />
        <ErrorMessage name={name} />
      </Flex>
    </FormControl>
  );
}

export default FormDatePicker;
