import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthedUser } from '../store/user/hooks';
import { isArray, isEmpty } from 'lodash-es';

function PrivateRoute({ children, authorize = [], ...rest }) {
  if (!isArray(authorize)) {
    throw new Error('authorize must be of type array');
  }

  const {
    isAuthenticated,
    data: { role },
  } = useAuthedUser();

  const isAuthorized = useMemo(
    () => isEmpty(authorize) || authorize.includes(role),
    [authorize, role]
  );

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
