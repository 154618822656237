import { useState, useEffect } from 'react';
import { apiRequest } from '../../api/api';
import { changeToCamelCase } from '../../helpers/changeCase';

export function useCouponFormData() {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { data } = await apiRequest('get', 'coupons/form');
      setData(changeToCamelCase(data));
    }
    fetchData();
  }, []);

  return data;
}
