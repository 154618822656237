import React from 'react';
import { Box, Image, Flex, Text, SimpleGrid } from '@chakra-ui/core';
import AudioPlayerControl from '../../../../components/AudioPlayer/AudioPlayerControl';

function PredictionSpeakingView({
  description,
  audioQuestion,
  image,
  data,
  audioMale,
  audioFemale,
}) {
  return (
    <Box>
      {audioQuestion && (
        <Box mb={10}>
          <AudioPlayerControl audioUrl={audioQuestion} />
        </Box>
      )}
      {image && (
        <Flex mb={10} justify="center">
          <Image minW={300} maxW={500} w="100%" src={image} />
        </Flex>
      )}
      {audioQuestion && (
        <Text fontWeight={600} my={2}>
          Transcript:{' '}
        </Text>
      )}
      <Box>{description}</Box>
      {data.answer && (
        <Box mt={2}>
          <Text as="strong" mr={1}>
            ANSWER:
          </Text>
          {data.answer}
        </Box>
      )}

      {!data.answer && audioFemale && audioMale && (
        <Box mt={4}>
          <Text color="custom.black3" fontWeight={600} mb={1}>
            MODEL ANSWER (STUDENT)
          </Text>
          <SimpleGrid columns={2} spacing={2}>
            <Box mb={10}>
              <Text color="custom.gray5">Male - Recording</Text>
              <AudioPlayerControl
                audioUrl={audioMale}
                waveHeight={64}
                volumeContainerProps={{ minW: '100px' }}
              />
            </Box>
            <Box mb={10}>
              <Text color="custom.gray5">Female - Recording</Text>
              <AudioPlayerControl
                audioUrl={audioFemale}
                waveHeight={64}
                volumeContainerProps={{ minW: '100px' }}
              />
            </Box>
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
}

export default PredictionSpeakingView;
