import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, Flex, FormLabel } from '@chakra-ui/core';
import { GoCloudUpload } from 'react-icons/go';
import { MdCheckCircle } from 'react-icons/md';
import { get } from 'lodash-es';

function Dropzone({
  name,
  accept,
  label,
  onChange,
  uploadingProgress,
  ...props
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = [...acceptedFiles][0];

      if (file) {
        onChange(file);
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: accept,
    multiple: false,
    maxSize: 1024 * 1024 * 5,
  });

  const fileName = get(acceptedFiles, '0.name');

  const uploadingDone = useMemo(() => uploadingProgress === 100, [
    uploadingProgress,
  ]);

  return (
    <Box {...props}>
      {label && <FormLabel mb={4}>{label}</FormLabel>}
      <Box
        {...getRootProps()}
        position="relative"
        borderRadius="6px"
        px={4}
        py={10}
        borderWidth={1}
        mb={4}
        borderColor="custom.gray3"
        borderStyle="dashed"
      >
        {!!uploadingProgress && (
          <Box
            position="absolute"
            left={0}
            top={0}
            width={`${uploadingProgress}%`}
            transition="width .15s ease-out"
            bg="custom.blue"
            height="100%"
            opacity={0.15}
            zIndex={0}
          />
        )}
        <input {...getInputProps()} />
        <Flex zIndex={1} justify="center" align="center" direction="column">
          <Box
            as={uploadingDone ? MdCheckCircle : GoCloudUpload}
            color={uploadingDone ? 'green.500' : 'custom.gray3'}
            fontSize="24px"
          />
          {uploadingProgress ? (
            <Text textAlign="center" color="custom.gray3">
              Uploading {uploadingProgress}% {fileName}
            </Text>
          ) : (
            <Text color="custom.gray3">Click to upload or drop here</Text>
          )}
        </Flex>
      </Box>
    </Box>
  );
}

export default Dropzone;
