import React, { useState, useEffect } from 'react';
import { Box, Text, Button, Spinner } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { Elements } from '@stripe/react-stripe-js';
import { apiRequest } from '../../../api/api';
import PaymentForm from '../../../components/Payment/PaymentForm';
import { types } from './constants';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISH_KEY } from '../../../config/metaData';

const promise = loadStripe(STRIPE_PUBLISH_KEY);

function preparePaymentData(checkoutData) {
  return {
    type: get(checkoutData, 'type'),
    data: {
      amount: get(checkoutData, 'discountDetails')
        ? get(checkoutData, 'discountDetails.amount')
        : get(checkoutData, 'sellingPrice'),
      ...(get(checkoutData, 'type') === types.package
        ? {
            package_id: get(checkoutData, 'id'),
          }
        : {
            package_extension_id: get(checkoutData, 'id'),
          }),
      coupon_code: get(checkoutData, 'discountDetails.couponCode'),
    },
  };
}

const statusStates = {
  success: 'success',
  pending: 'pending',
  initial: 'initial',
};

function Payment({ data: checkoutData }) {
  const [status, setStatus] = useState(statusStates.initial);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (status === statusStates.pending) {
      setClientSecret(null);
      apiRequest('POST', 'payment/stripe/clientSecret', {
        ...preparePaymentData(checkoutData),
      }).then(({ data }) => {
        setClientSecret(data.client_secret);
      });
    }
  }, [checkoutData, status]);

  if (status === statusStates.initial) {
    return (
      <>
        <Text fontSize="sm" color="custom.red">
          Please note: No refund will be provided for any of the premium
          subscriptions/mocks. Enjoy 3-day free trial period.
        </Text>
        <Button
          onClick={() => {
            setStatus(statusStates.pending);
          }}
          variantColor="blueVariant"
          mt={2}
        >
          CONFIRM & PAY
        </Button>
      </>
    );
  }

  if (status === statusStates.pending) {
    if (!clientSecret) {
      return (
        <Box>
          <Spinner />
        </Box>
      );
    }
    return (
      <Elements
        stripe={promise}
        options={{
          clientSecret: clientSecret,
        }}
      >
        <PaymentForm
          amount={
            get(checkoutData, 'discountDetails.amount')
              ? get(checkoutData, 'discountDetails.amount')
              : get(checkoutData, 'sellingPrice')
          }
        />
      </Elements>
    );
  }
}

export default Payment;
