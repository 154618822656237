import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import FormRadio from '../../../../../../components/Form/Radio';
import Form from '../../../../../../components/Form/Form';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

function MCQSingleFormField({ name, options = [], selectedAnswer }) {
  return (
    <>
      {options.map((option, index) => (
        <Flex
          align="center"
          py={2}
          px={1}
          mb={2}
          key={index}
          bg={selectedAnswer === option ? '#FAFFD2' : ''}
        >
          <FormRadio id={index} value={option} name={name} label={option} />
        </Flex>
      ))}
    </>
  );
}

function MockQuestionReadingMCQSingleView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm();

  const { error } = useCustomToast();
  const onSubmit = useCallback(
    async ({ answer }) => {
      try {
        setIsSubmitting(true);
        setTimerDelay(null);

        await apiRequest('post', 'mocks/autoSave', {
          answer,
          type: mock.type,
          section: sections.reading,
          question_id: data.id,
          module: data.module,
          index,
        });

        setTimerDelay(1000);

        onAfterSubmit();
      } catch {
        error({
          title: 'Looks like something went wrong!',
          description:
            'Please check your network connection and try reloading.',
        });
        setIsSubmitting(false);
        setTimerDelay(1000);
      }
    },
    [onAfterSubmit, error, mock, index, data, setTimerDelay]
  );

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <SimpleGrid my={10} px={6} columns={2} spacing={4}>
            <Text
              dangerouslySetInnerHTML={{ __html: get(data, 'description') }}
            />
            <Box>
              <Heading size="sm" mb={8}>
                Read the text and answer the multiple-choice question by
                selecting the correct response. Only one response is correct.
              </Heading>
              <Form form={form} onSubmit={() => {}}>
                <MCQSingleFormField
                  name="answer"
                  options={get(data, 'mcq_single')}
                  selectedAnswer={form.watch('answer')}
                />
              </Form>
            </Box>
          </SimpleGrid>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={() => form.handleSubmit(onSubmit)()}
              isSubmitting={isSubmitting}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionReadingMCQSingleView;
