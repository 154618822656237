import { Flex, Image, Text } from '@chakra-ui/core';
import React from 'react';
import logo from '../../assets/images/logo.svg';

function SpeakingLoader({ isLoading }) {
  return (
    <Flex
      visibility={isLoading ? 'visible' : 'hidden'}
      align="center"
      justify="center"
      bg="rgba(255, 255, 255, 0.90)"
      h="100%"
      w="100%"
      pos="absolute"
      top={0}
      left={0}
      direction="column"
      zIndex={99}
    >
      <Image size="72px" src={logo} alt="AIWAS Plus" mb={4} />
      <Text fontSize="2xl" as="strong">
        AI is thinking, please wait...
      </Text>
    </Flex>
  );
}

export default SpeakingLoader;
