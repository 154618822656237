import axios from 'axios';
import { API_URL } from '../config/metaData';
import { clearItems, getItem } from '../helpers/localStorage';

const api = axios.create();

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const status = error?.status || error?.response?.status;
    if (status === 401) {
      clearItems();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export const apiRequest = async (
  method = 'get',
  url = '',
  data = {},
  config = {}
) => {
  const response = await api({
    method,
    url: `${API_URL}/${url}`,
    data,
    headers: {
      ...(getItem('token') && { Authorization: `Bearer ${getItem('token')}` }),
    },
    ...config,
  });
  return response;
};
