import React from 'react';
import { Box, Alert, AlertIcon, Text, Progress } from '@chakra-ui/core';

function MockRecorderView({
  isDenied,
  status,
  duration,
  reverseRecordingDuration,
}) {
  return (
    <Box>
      {isDenied && (
        <Alert status="error">
          <AlertIcon />
          Please allow your microphone to record audio.
        </Alert>
      )}
      <Box
        borderWidth="1px"
        bg="mock.blue"
        borderColor="mock.gray"
        mt={4}
        p="10px"
        w="350px"
      >
        <Text textAlign="center" fontWeight={600} mb={2} fontSize="lg">
          Recorded Answer
        </Text>

        <Text>Current Status</Text>
        <Text textTransform="capitalize">{status}</Text>

        <Progress
          borderWidth={1}
          h={4}
          borderColor="mock.gray"
          bg="white"
          my={6}
          value={parseInt((reverseRecordingDuration * 100) / duration) || 0}
          color="blueVariant"
        />
      </Box>
    </Box>
  );
}

export default MockRecorderView;
