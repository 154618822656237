import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Tooltip,
  IconButton,
} from '@chakra-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleApiError } from '../../../../helpers/handleApiError';
import useCustomToast from '../../../../hooks/useCustomToast';
import { updateMockStatus } from '../../../../store/mocks/mocksSlice';

function MockStatusUpdate({ id, status }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { toast } = useCustomToast();
  const handleClick = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await dispatch(
        updateMockStatus(id, {
          status: !status,
        })
      );
      onClose();
    } catch (error) {
      handleApiError(error, { toast });
      setIsSubmitting(false);
    }
  }, [dispatch, toast, id, status, onClose]);

  return (
    <>
      <Tooltip
        hasArrow
        label={`Mark as ${status ? 'Inactive' : 'Active'}`}
        placement="top"
        bg={status ? 'custom.red' : 'custom.green'}
      >
        <IconButton
          size="sm"
          variantColor={status ? 'redVariant' : 'greenVariant'}
          variant="outline"
          icon="lock"
          onClick={onOpen}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="sm"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p={4}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Mark as {status ? 'Inactive' : 'Active'}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to mark this mock as{' '}
            {status ? 'Inactive' : 'Active'}?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              variant="outline"
              variantColor="redVariant"
              ref={cancelRef}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClick}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              variantColor="greenVariant"
              ml={4}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default MockStatusUpdate;
