import React, { useEffect, useRef } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
  Button,
  useDisclosure,
  Input,
  Text,
  Flex,
} from '@chakra-ui/core';
import { IoMdShareAlt } from 'react-icons/io';

function Share({ shareLink, ...props }) {
  const inputRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      navigator.clipboard.writeText(shareLink);
    }
  }, [isOpen, shareLink]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="left"
      >
        <PopoverTrigger>
          <Button
            leftIcon={IoMdShareAlt}
            variantColor="blueVariant"
            variant="link"
            {...props}
          >
            Share
          </Button>
        </PopoverTrigger>
        <PopoverContent zIndex={4} p={5}>
          <Input ref={inputRef} value={shareLink} isDisabled />
          <Flex align="center" mt={2}>
            <Icon name="check" color="custom.green" mr={2} />
            <Text color="custom.green" fontSize="sm">
              Link is copied to clipboard!
            </Text>
          </Flex>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default Share;
