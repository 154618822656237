import React from 'react';
import { Checkbox, FormControl, FormLabel, Stack } from '@chakra-ui/core';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

const FormCheckbox = ({
  name,
  id = name,
  required = false,
  validate,
  label,
  containerProps = {},
  ...props
}) => {
  const { register, errors } = useFormContext();

  return (
    <FormControl
      isRequired={required}
      isDisabled={props.disabled}
      isInvalid={Boolean(get(errors, name))}
    >
      <Stack isInline alignItems="center" {...containerProps}>
        <Checkbox
          id={id}
          name={name}
          required={required}
          ref={register({ required, validate })}
          {...props}
        />
        <FormLabel htmlFor={id} display={label ? 'block' : 'none'}>
          {label}
        </FormLabel>
      </Stack>
    </FormControl>
  );
};

export default FormCheckbox;
