import React, { memo } from 'react';
import {
  Box,
  Button,
  SimpleGrid,
  Image,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/core';

import { Link } from 'react-router-dom';
import MegaMenuLink from './MegaMenuLink';
import MegaMenuList from './MegaMenuList';
import logo from '../../assets/images/logo.svg';
import { roles } from '../../constants/roles';
import Authorize from '../Authorize/Authorize';

const appLinks = [
  {
    label: 'Practise',
    path: '#',
    exact: 'true',
    megaMenu: {
      speaking: [
        {
          label: 'Read Aloud',
          path: '/dashboard/practise/speaking/readaloud',
          exact: 'true',
        },
        {
          label: 'Repeat Sentence',
          path: '/dashboard/practise/speaking/repeatsentence',
          exact: 'true',
        },
        {
          label: 'Describe Image',
          path: '/dashboard/practise/speaking/describeimage',
          exact: 'true',
        },
        {
          label: 'Re-tell Lecture',
          path: '/dashboard/practise/speaking/retelllecture',
          exact: 'true',
        },
        {
          label: 'Answer Short Question',
          path: '/dashboard/practise/speaking/answershortquestion',
          exact: 'true',
        },
      ],
      writing: [
        {
          label: 'Essay',
          path: '/dashboard/practise/writing/essay',
          exact: 'true',
        },
        {
          label: 'Summarize Written Text',
          path: '/dashboard/practise/writing/summary',
          exact: 'true',
        },
      ],
      reading: [
        {
          label: 'Fill in The Blanks (Drop-down)',
          path: '/dashboard/practise/reading/fillblanks-dropdown',
          exact: 'true',
        },
        {
          label: 'Multiple Choice, Choose Multiple Answers',
          path: '/dashboard/practise/reading/mcq-multiple',
          exact: 'true',
        },
        {
          label: 'Re-order Paragraphs',
          path: '/dashboard/practise/reading/reorder',
          exact: 'true',
        },
        {
          label: 'Fill in The Blanks (Drag & Drop)',
          path: '/dashboard/practise/reading/fillblanks-drag',
          exact: 'true',
        },
        {
          label: 'Multiple Choice, Choose Single Answer',
          path: '/dashboard/practise/reading/mcq-single',
          exact: 'true',
        },
      ],
      listening: [
        {
          label: 'Summarize Spoken Text',
          path: '/dashboard/practise/listening/summary',
          exact: 'true',
        },
        {
          label: 'Multiple Choice, Choose Multiple Answers',
          path: '/dashboard/practise/listening/mcq-multiple',
          exact: 'true',
        },
        {
          label: 'Fill in the Blanks',
          path: '/dashboard/practise/listening/fillblanks',
          exact: 'true',
        },
        {
          label: 'Highlight Correct Summary',
          path: '/dashboard/practise/listening/highlight-correct',
          exact: 'true',
        },
        {
          label: 'Multiple Choice, Choose Single Answer',
          path: '/dashboard/practise/listening/mcq-single',
          exact: 'true',
        },
        {
          label: 'Select Missing Word',
          path: '/dashboard/practise/listening/missing-words',
          exact: 'true',
        },
        {
          label: 'Highlight Incorrect Words',
          path: '/dashboard/practise/listening/highlight-incorrect',
          exact: 'true',
        },
        {
          label: 'Write From Dictation',
          path: '/dashboard/practise/listening/dictation',
          exact: 'true',
        },
      ],
    },
  },
  {
    label: 'Mocks',
    path: '/dashboard/mocks',
    exact: 'true',
  },
  {
    label: 'Prediction',
    path: '/dashboard/prediction/speaking/ReadAloud',
    exact: 'true',
  },
  {
    label: 'Diagnostic',
    path: '/dashboard/diagnostic',
    exact: 'true',
  },
  {
    label: 'Settings',
    path: '#',
    exact: 'true',
    roles: [roles.super_admin, roles.admin, roles.instructor],
    subLinks: [
      {
        label: 'Users',
        path: '/dashboard/users',
        exact: 'true',
      },
      {
        label: 'Coupons',
        path: '/dashboard/coupon',
        exact: 'true',
        roles: [roles.super_admin],
      },
    ],
  },
  // {
  //   label: 'Community',
  //   path: '/dashboard/practise/reading/mcq-single/40',
  //   exact: 'true',
  // },
];

const menuFixStyles = {
  marginBottom: '2px',
  padding: '0 1rem',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
};

function MegaMenu({ isPreview }) {
  const AuthorizeWrapper = isPreview ? 'div' : Authorize;

  return appLinks.map((link, index) => (
    <React.Fragment key={index}>
      {link.megaMenu ? (
        <Menu>
          <MenuButton
            bg="transparent"
            as={Button}
            color="custom.black4"
            _hover={{
              backgroundColor: 'none',
              color: 'custom.blue',
            }}
            _active={{
              backgroundColor: 'none',
              color: 'custom.blue',
            }}
            rightIcon="chevron-down"
          >
            {link.label}
          </MenuButton>
          <MenuList
            width="100%"
            borderRadius={0}
            px="60px"
            py="30px"
            transform="translate(0, 68px) !important"
            boxShadow="0px 9px 28px rgba(0, 0, 0, 0.07) !important"
            transition="opacity .3s"
            zIndex="2"
            border="none"
          >
            <SimpleGrid columns={[1, 2, 4, 5]} spacing="40px">
              <Box w="100%">
                <MegaMenuList
                  heading={'Speaking'}
                  list={link.megaMenu.speaking}
                />
              </Box>

              <Box w="100%">
                <MegaMenuList
                  heading={'Writing'}
                  list={link.megaMenu.writing}
                />
              </Box>

              <Box w="100%">
                <MegaMenuList
                  heading={'Reading'}
                  list={link.megaMenu.reading}
                />
              </Box>

              <Box w="100%">
                <MegaMenuList
                  heading={'Listening'}
                  list={link.megaMenu.listening}
                />
              </Box>

              <Box display={{ xs: 'none', lg: 'block' }} w="100%">
                {/*Marketing Banner goes here*/}
                <Image mt="45px" opacity=".05" size="220px" src={logo} />
              </Box>
            </SimpleGrid>
          </MenuList>
        </Menu>
      ) : link.subLinks ? (
        <AuthorizeWrapper roles={link.roles}>
          <Menu>
            <MenuButton
              bg="transparent"
              as={Button}
              color="custom.black4"
              _hover={{
                backgroundColor: 'none',
                color: 'custom.blue',
              }}
              _active={{
                backgroundColor: 'none',
                color: 'custom.blue',
              }}
              rightIcon="chevron-down"
            >
              {link.label}
            </MenuButton>
            <MenuList
              borderRadius={0}
              px="30px"
              py="15px"
              zIndex="2"
              border="none"
              transform="translate(228%, 68px) !important"
              boxShadow="0px 9px 28px rgba(0, 0, 0, 0.07) !important"
            >
              {link.subLinks.map((subLink) => (
                <AuthorizeWrapper key={subLink.label} roles={subLink.roles}>
                  <MegaMenuLink
                    link={subLink.path}
                    label={subLink.label}
                    exact={link.exact}
                  />
                </AuthorizeWrapper>
              ))}
            </MenuList>
          </Menu>
        </AuthorizeWrapper>
      ) : (
        <AuthorizeWrapper roles={link.roles}>
          <Menu>
            <MenuButton
              bg="transparent"
              as={Button}
              fontWeight="600"
              color="custom.black4"
              _hover={{
                backgroundColor: 'none',
                color: 'custom.blue',
              }}
              _active={{
                backgroundColor: 'none',
                color: 'custom.blue',
              }}
              px={0}
            >
              <Link
                style={menuFixStyles}
                to={`${link.path}`}
                exact={link.exact}
              >
                {link.label}
              </Link>
            </MenuButton>
          </Menu>
        </AuthorizeWrapper>
      )}
    </React.Fragment>
  ));
}

export default memo(MegaMenu);
