import { useCallback, useMemo, useRef, useState } from 'react';
import { get } from 'lodash-es';
import React from 'react';
import { Box, Flex, Heading, PseudoBox } from '@chakra-ui/core';
import { ActionButtons, StepLayout } from '../../../Components/Layout';
import MockAudioPlayerBare from '../../../Components/Audio/Player';
import { replaceWord } from '../../../../../../helpers/regex';
import { mockColors } from '../../../../../../theme/theme';
import useCustomToast from '../../../../../../hooks/useCustomToast';
import { apiRequest } from '../../../../../../api/api';
import { sections } from '../../../../../../constants/sections';

const dataAttribute = 'data-selected';

const spanOnClick = (event) => {
  if (event.target.hasAttribute(dataAttribute)) {
    event.target.removeAttribute(dataAttribute);
    event.target.removeAttribute('style');
    return;
  }

  event.target.setAttribute('style', `background: ${mockColors.yellow};`);
  event.target.setAttribute(dataAttribute, true);
};

function MockQuestionListeningHighlightIncorrectView({
  data,
  onAfterSubmit,
  mock,
  index,
  setTimerDelay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAudioPlayingEnded, setIsAudioPlayingEnded] = useState(false);

  const { error } = useCustomToast();
  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setTimerDelay(null);

      const answers = [];

      const words = formRef.current.getElementsByTagName('span');

      for (let word of words) {
        let answer = word.innerText;
        if (word.hasAttribute(dataAttribute)) {
          answer = '[' + answer + ']';
        }
        answers.push(answer);
      }

      await apiRequest('post', 'mocks/autoSave', {
        answer: answers,
        type: mock.type,
        section: sections.listening,
        question_id: data.id,
        module: data.module,
        index,
      });

      setTimerDelay(1000);

      onAfterSubmit();
    } catch {
      error({
        title: 'Looks like something went wrong!',
        description: 'Please check your network connection and try reloading.',
      });
      setIsSubmitting(false);
      setTimerDelay(null);
    }
  }, [onAfterSubmit, error, mock, index, data, setTimerDelay]);

  const formRef = useRef();

  const paragraph = useMemo(() => {
    const text = get(data, 'description', '');

    const replacedBrackets = text.replace(/[[\]]/g, '');

    const splitText = replacedBrackets.split(replaceWord);

    if (splitText.length <= 1) {
      return splitText;
    }

    const matches = replacedBrackets.match(replaceWord);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <PseudoBox
                userSelect="none"
                as="span"
                key={index}
                display="inline-flex"
                name="answer"
                cursor="pointer"
                lineHeight="1"
                onClick={spanOnClick}
                py={1}
                px={0.5}
              >
                {matches[index]}
              </PseudoBox>,
            ]
          : [...arr, element],
      []
    );
  }, [data]);

  return (
    <StepLayout key={data?.id}>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Box py={10} px={6}>
            <Heading size="sm" mb={8}>
              You will hear a recording. Below is a transcription of the
              recording. Some words in the transcription differ from what the
              speaker(s) said. Please click on the words that are different.
            </Heading>
            <Flex justify="center" my={6}>
              <MockAudioPlayerBare
                beginningDuration={10}
                url={get(data, 'audio')}
                onEndCallback={() => setIsAudioPlayingEnded(true)}
              />
            </Flex>
            <Box mt={6} ref={formRef} key={get(data, 'id')}>
              <Box lineHeight={2}>{paragraph}</Box>
            </Box>
          </Box>
          <Box>
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              onConfirmNext={onSubmit}
              isSubmitting={isSubmitting}
              isNextButtonDisabled={!isAudioPlayingEnded}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default MockQuestionListeningHighlightIncorrectView;
