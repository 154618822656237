import { createSlice } from '@reduxjs/toolkit';
import { changeToCamelCase } from '../../../helpers/changeCase';
import { keyBy, union, map, has, defaultsDeep, pullAll } from 'lodash-es';
import queryString from 'query-string';
import { commentsLoaded, commentAdded, commentDeleted } from './commentSlice';

const initialState = {
  byId: {},
  allIds: [],
  stats: {},
  page: null,
  nextPage: null,
  loading: {},
  queryObject: {},
};

const readingQuestionSlice = createSlice({
  name: 'reading-question',
  initialState,
  reducers: {
    moreQuestionsLoaded(state, action) {
      const { questions } = action.payload;

      state.allIds = union(
        state.allIds,
        map(questions.data, (item) => item.id)
      );
      state.byId = {
        ...state.byId,
        ...keyBy(changeToCamelCase(questions.data), 'id'),
      };
      state.page = questions.current_page;
      state.nextPage = questions.next_page_url;
    },
    questionsLoading(state, action) {
      const { queryObject } = action.payload;

      state.loading[queryObject.module] = has(state.loading, queryObject.module)
        ? false
        : true;
    },
    questionsLoaded(state, action) {
      const { queryObject, questions, stats } = action.payload;
      state.loading[queryObject.module] = false;

      state.allIds = map(questions.data, (item) => item.id);
      state.byId = defaultsDeep(
        state.byId,
        keyBy(changeToCamelCase(questions.data), 'id')
      );
      state.page = questions.current_page;
      state.nextPage = questions.next_page_url;
      state.stats = changeToCamelCase(stats);
    },
    questionLoaded(state, action) {
      const { data } = action.payload;

      state.allIds = union(state.allIds, [data.id]);
      state.byId[data.id] = {
        ...state.byId[data.id],
        ...changeToCamelCase(data),
      };
    },
    questionAdded(state, action) {
      const { data } = action.payload;
      state.allIds = union([data.id], state.allIds);
      state.byId[data.id] = changeToCamelCase(data);
    },
    questionUpdated(state, action) {
      const { id, data } = action.payload;

      state.byId[id] = {
        ...state.byId[id],
        ...changeToCamelCase(data.question),
      };
    },
    questionDeleted(state, action) {
      const { id } = action.payload;
      const { [id]: value, ...rest } = state.byId;

      state.allIds = pullAll([...state.allIds], [parseInt(id)]);
      state.byId = rest;
    },
    setQuery(state, action) {
      const { queryObject } = action.payload;

      state.queryObject = {
        ...state.queryObject,
        ...queryObject,
      };
    },
    questionEvaluated(state, action) {
      const { data, id } = action.payload;
      state.byId[id] = {
        ...state.byId[id],
        scores: [changeToCamelCase(data), ...state.byId[id].scores],
      };
    },
  },
  extraReducers: {
    [commentsLoaded]: (state, action) => {
      const { data, id } = action.payload;
      state.byId[id] = {
        ...state.byId[id],
        comments: data.data.map((item) => item.id),
      };
    },
    [commentAdded]: (state, action) => {
      const { data, id } = action.payload;
      state.byId[id] = {
        ...state.byId[id],
        comments: union([data.id], state.byId[id].comments),
      };
    },
    [commentDeleted]: (state, action) => {
      const { id, commentId } = action.payload;
      state.byId[id] = {
        ...state.byId[id],
        comments: pullAll(state.byId[id].comments, [commentId]),
      };
    },
  },
});

const { actions, reducer } = readingQuestionSlice;

export const {
  moreQuestionsLoaded,
  questionsLoading,
  questionsLoaded,
  questionLoaded,
  questionAdded,
  questionUpdated,
  questionDeleted,
  setQuery,
  questionEvaluated,
} = actions;

export default reducer;

const apiSubstring = 'reading';

export const getQuestions = (queryObject) => async (dispatch, _, api) => {
  dispatch(questionsLoading({ queryObject }));

  const query = queryString.stringify({ ...queryObject });

  const { data } = await api('get', `${apiSubstring}/questions?${query}`);

  dispatch(
    questionsLoaded({
      questions: data.questions,
      stats: data.stats,
      queryObject,
    })
  );
  return data;
};

export const getQuestion = (id) => async (dispatch, _, api) => {
  const { data } = await api('get', `${apiSubstring}/questions/${id}`);

  dispatch(questionLoaded({ id, data }));

  return data;
};

export const loadQuestions = (queryObject) => async (
  dispatch,
  getState,
  api
) => {
  const { reading } = getState();

  const query = queryString.stringify({
    ...queryObject,
    ...reading.questions.queryObject,
  });

  const { data } = await api('get', `${apiSubstring}/questions?${query}`);

  dispatch(
    moreQuestionsLoaded({
      questions: data.questions,
    })
  );

  return data;
};

export const addQuestion = (values) => async (dispatch, _, api) => {
  const { data } = await api('post', `${apiSubstring}`, values);
  dispatch(questionAdded({ data }));
  return data;
};

export const editQuestion = (id, values) => async (dispatch, _, api) => {
  const { data } = await api('put', `${apiSubstring}/${id}`, values);
  dispatch(questionUpdated({ data, id }));
  return data;
};

export const deleteQuestion = (id) => async (dispatch, _, api) => {
  const { data } = await api('delete', `${apiSubstring}/${id}`);
  dispatch(questionDeleted({ id }));
  return data;
};

export const draftPublishQuestion = (id) => async (dispatch, _, api) => {
  const { data } = await api('patch', `${apiSubstring}/draftOrPublish/${id}`);
  dispatch(
    questionUpdated({
      id,
      data: {
        question: data,
      },
    })
  );
  return data;
};

export const setQueryObject = (queryObject) => (dispatch) => {
  dispatch(setQuery({ queryObject }));
};

export const evaluate = (id, values) => async (dispatch, _, api) => {
  const { data } = await api('post', `${apiSubstring}/${id}/evaluate`, values);
  dispatch(questionEvaluated({ data, id }));
  return changeToCamelCase(data);
};
