import React from 'react';
import { Text, Box } from '@chakra-ui/core';
import FormRadio from '../../../../../components/Form/Radio';

function MCQSingleFormField({ label, name, options = [], idPrefix = '' }) {
  return (
    <>
      {typeof label === 'string' ? (
        <Text dangerouslySetInnerHTML={{ __html: label }} pb={4} />
      ) : (
        <Box pb={4}>{label}</Box>
      )}
      {options.map((option, index) => (
        <FormRadio
          key={index}
          id={`${idPrefix}${index}`}
          value={option}
          name={name}
          label={option}
        />
      ))}
    </>
  );
}

export default MCQSingleFormField;
