import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/core';
import { useDispatch } from 'react-redux';
import { addQuestion } from '../../../../../store/modules/listening/questionSlice';
import AddEditForm from './Form';
import TitleBar from '../../../../../components/Question/TitleBar';
import { handleApiError } from '../../../../../helpers/handleApiError';
import useCustomToast from '../../../../../hooks/useCustomToast';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import { modules } from '../../../../../constants/modules';
import { paths } from './utils/paths';
import { listening } from '../../../../../constants/titles';
import { objectToFormData } from 'object-to-formdata';

const customPaths = [
  ...paths,
  {
    name: 'Add Question',
  },
];

function AddPage() {
  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const submit = useCallback(
    async (
      { description, labels, priority, advice, duration, audio },
      { reset }
    ) => {
      try {
        const data = {
          advice,
          duration,
        };

        const body = objectToFormData({
          audio,
          description,
          labels,
          priority,
          data,
          module: modules.listening.WriteFromDictation,
        });

        await dispatch(addQuestion(body));

        success({ title: 'Question is added' });
        reset();
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, success]
  );

  return (
    <>
      <QuestionMetabar paths={customPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title={`New ${listening.dictation}`} />
        <AddEditForm onSubmit={submit} />
      </Box>
    </>
  );
}

export default AddPage;
