import React, { useMemo, useCallback, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import { useForm, useFieldArray } from 'react-hook-form';
import FormButton from '../../../../../components/Form/Button';
import FormInput from '../../../../../components/Form/Input';
import FormSelect from '../../../../../components/Form/Select';
import FormVisualEditor from '../../../../../components/Form/VisualEditor';
import FormMultiSelect from '../../../../../components/Form/MutliSelect';
import * as Yup from 'yup';
import { priority } from '../../../../../constants/priority';
import { map, get, has, sum } from 'lodash-es';
import { useLabels } from '../../../../../store/label/hooks';
import { stripHtml } from '../../../../../helpers/stripHtml';
import { FiEdit2 } from 'react-icons/fi';
import {
  Text,
  Stack,
  Box,
  Flex,
  FormLabel,
  Button,
  IconButton,
  Textarea,
  Input,
} from '@chakra-ui/core';
import FormTextarea from '../../../../../components/Form/Textarea';
import FormRadio from '../../../../../components/Form/Radio';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { handleApiError } from '../../../../../helpers/handleApiError';
import { apiRequest } from '../../../../../api/api';
import ErrorMessage from '../../../../../components/Form/ErrorMessage';

const getDefaultValues = (data) => ({
  labels: get(data, 'labels', []).map((label) => label.id),
  description: get(data, 'data.question', ''),
  samples: get(data, 'samples') || [],
  priority: get(data, 'priority', ''),
  duration: 600, //get(data, 'data.duration', ''),
  advice: get(data, 'data.advice', ''),
  whitelist: get(data, 'data.whitelist', ''),
  sampleCheck: get(data, 'data.sampleCheck') === false ? 'false' : 'true',
  matches: get(data, 'data.matches', {
    keywords: 70,
    phrases: 20,
    important: 10,
  }),
});

function AddEditForm({ data, onSubmit }) {
  const defaultValues = useMemo(() => getDefaultValues({ ...data }), [data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        description: Yup.string().test(
          'is-valid',
          'Must contains brackets [] and important phrases using curly {} brackets',
          (value) => value?.match(/\[.*{+.*\}+.*]/g)
        ),
        whitelist: Yup.string(),
        // samples: data
        //   ? Yup.array()
        //       .of(Yup.string().trim().required('Required.'))
        //       .required('At least one sample.')
        //   : Yup.array(),
        labels: Yup.array().required('Required.'),
        duration: Yup.string().required('Required.'),
        priority: Yup.string().required('Required.'),
        advice: Yup.string().test('is-empty', 'Required.', (value) =>
          stripHtml(value)
        ),
        sampleCheck: Yup.boolean(),
        matches: Yup.object({
          keywords: Yup.number().integer(),
          phrases: Yup.number().integer(),
          important: Yup.number().integer(),
        }).test(
          'is-equal-to-100',
          'Must be sum of 100.',
          (value) => sum(Object.values(value)) === 100
        ),
      }),
    [data]
  );

  const form = useForm({ defaultValues, validationSchema });

  const { control, reset, setValue, register } = form;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'samples',
  });

  const { byId, allIds } = useLabels();

  const priorityOptions = useMemo(
    () => map(priority, (item) => ({ label: item, value: item })),
    []
  );

  const labelOptions = useMemo(
    () =>
      allIds.map((id) => ({
        label: byId[id].name,
        value: id,
        color: byId[id].color,
      })),
    [byId, allIds]
  );

  const formOnSubmit = useCallback(
    async (values) => {
      await onSubmit(values, { reset });
    },
    [onSubmit, reset]
  );

  const { toast } = useCustomToast();

  const [sample, setSample] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onSampleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);

      await apiRequest('post', `writing/${data.id}/sample`, {
        sample: sample.value,
      });

      if (sample.id) {
        setValue(`samples[${sample.id}]`, sample.value);
      } else {
        append(sample.value);
      }
      setSample({ value: '' });
    } catch (error) {
      handleApiError(error, { toast });
    }

    setSubmitting(false);
  }, [append, sample, setValue, data, toast]);

  return (
    <Form form={form} onSubmit={formOnSubmit} mt={6}>
      <FormLabel mb={1}>
        SWT Question - use 3rd bracket to mark context and 2nd bracket to mark
        important word(s). After adding the question go to Edit to add samples.
      </FormLabel>
      <FormTextarea name="description" minHeight="120px" />
      <FormInput
        name="whitelist"
        label="Enter whitelisted words (use semicolon ; to seperate each word)"
      />
      {data && (
        <Box mb={6}>
          {fields.map((field, index) => (
            <Flex
              key={field.id}
              justify="space-between"
              align="center"
              borderWidth={1}
              borderColor="custom.gray"
              borderRadius="5px"
              boxShadow="custom.primary"
              py={1}
              px={4}
              mb={3}
            >
              <Text mr={2} color="custom.blue">
                {index + 1}#
              </Text>
              <Input
                color="custom.gray5"
                defaultValue={field.value}
                name={`samples[${index}]`}
                ref={register()}
                flex={1}
                isReadOnly
                border={0}
                p={0}
              />
              <Box>
                <IconButton
                  size="sm"
                  ml={3}
                  icon={FiEdit2}
                  variant="ghost"
                  variantColor="blueVariant"
                  onClick={() => setSample({ id: index, value: field.value })}
                />
                <IconButton
                  size="sm"
                  icon="close"
                  variant="ghost"
                  variantColor="redVariant"
                  onClick={() => remove(index)}
                />
              </Box>
            </Flex>
          ))}
          <Textarea
            mb={4}
            mt={4}
            value={sample?.value}
            onChange={(event) => {
              const value = event.target.value;
              const newSample = { ...(value && { ...sample }), value };
              setSample(newSample);
            }}
          />
          <Button
            disabled={!sample.value || submitting}
            isLoading={submitting}
            size="sm"
            variantColor="blueVariant"
            type="button"
            onClick={onSampleSubmit}
          >
            Evaluate {has(sample, 'id') ? `(#${sample.id + 1})` : `(Sample)`}
          </Button>
          <ErrorMessage name="samples" mt={2} />
        </Box>
      )}
      <Stack isInline spacing={4}>
        <Box>
          <FormInput name="matches.keywords" label="Keywords(%)" />
        </Box>
        <Box>
          <FormInput name="matches.phrases" label="Phrases(%)" />
        </Box>
        <Box>
          <FormInput name="matches.important" label="Important(%)" />
        </Box>
      </Stack>
      <ErrorMessage name="matches" mt={-6} mb={4} />
      <Box mb={4}>
        <FormLabel mb={1}>Enable Sample Check</FormLabel>
        <Flex>
          <FormRadio
            label="Yes"
            name="sampleCheck"
            value="true"
            id="sample-check-yes"
          />
          <FormRadio
            label="No"
            name="sampleCheck"
            value="false"
            id="sample-check-no"
          />
        </Flex>
      </Box>
      <FormMultiSelect
        placeholder="Labels"
        name="labels"
        options={labelOptions}
      />
      <FormSelect
        name="priority"
        placeholder="Priority"
        options={priorityOptions}
      />
      <Box d="none">
        <FormInput
          type="number"
          placeholder="Duration (Target Time) (In Seconds)"
          name="duration"
        />
      </Box>
      <Text fontWeight={600} color="custom.gray5" mb={2}>
        Examiner's Advice
      </Text>
      <FormVisualEditor name="advice" />
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

export default AddEditForm;
