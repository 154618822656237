export function getTime(seconds) {
  if (typeof seconds !== 'number') throw new Error('Must be a number');

  const hours = Math.floor(seconds / (60 * 60));

  const divisorForMinutes = seconds % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const secondsCeiled = Math.ceil(divisorForSeconds);

  const obj = {
    hours,
    minutes,
    seconds: secondsCeiled,
  };

  return obj;
}
