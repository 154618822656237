import {
  SET_USER,
  REMOVE_USER,
  USER_LOGOUT,
  USER_PHONE_VERIFIED,
  USER_META_UPDATE,
  USER_GOAL_UPDATE,
  USER_SECURITY_UPDATE,
} from './action';
import { changeToCamelCase } from '../../helpers/changeCase';

const initialState = {
  data: {},
  isAuthenticated: false,
  isLoading: true,
};

const userReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        data: { ...state.data, ...changeToCamelCase(data) },
        isLoading: false,
        isAuthenticated: true,
      };
    case USER_LOGOUT:
    case REMOVE_USER:
      return {
        ...state,
        isLoading: false,
      };
    case USER_PHONE_VERIFIED:
      return {
        ...state,
        data: {
          ...state.data,
          phoneVerifiedAt: true,
        },
      };
    case USER_GOAL_UPDATE:
    case USER_META_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          details: data,
        },
      };
    }
    case USER_SECURITY_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
