import { mockVersions } from '../../../../constants/mockTypes';

export const getConfigByVersion = (mockVersion) => {
  const isVersion1 = mockVersions.v1 === mockVersion;
  return {
    speakingAndWriting: isVersion1 ? '77 - 93' : 'approx 54 - 67',
    speaking: isVersion1 ? '30 - 35' : 'approx 24 - 27',
    reading: isVersion1 ? '32 - 41' : 'approx 29 - 30',
    optionalBreak: isVersion1,
    listening: isVersion1 ? '45 - 57' : 'approx 30 - 43',
    listeningOnly: isVersion1 ? '23 - 28' : 'approx 20 - 23',
  };
};
