import { useCurrentUser } from '../store/user/hooks';
import { useMemo } from 'react';
import { isArray, trim, get } from 'lodash-es';

export function useAuthorization({ roles, permissions }) {
  const user = useCurrentUser();

  const isAuthorizedByRoles = useMemo(() => {
    if (typeof roles === 'undefined') return true;

    const prepareRoles = isArray(roles)
      ? roles.filter(Boolean)
      : roles.split(',').filter(Boolean);

    return !!prepareRoles.find((role) => trim(role) === user.role);
  }, [user, roles]);

  const isAuthorizedByPermissions = useMemo(() => {
    if (typeof permissions === 'undefined') return true;

    const preparePermissions = isArray(permissions)
      ? permissions.filter(Boolean)
      : permissions.split(',').filter(Boolean);

    return !!preparePermissions.find((permission) =>
      get(user, `permissions.${permission}`)
    );
  }, [user, permissions]);

  return !!(isAuthorizedByRoles && isAuthorizedByPermissions);
}
