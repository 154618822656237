import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import TitleBar from '../../../../../components/Question/TitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash-es';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { handleApiError } from '../../../../../helpers/handleApiError';
import {
  // deleteQuestion,
  draftPublishQuestion,
  evaluate,
} from '../../../../../store/modules/writing/questionSlice';
import {
  getComments,
  addComment,
  deleteComment,
  editComment,
} from '../../../../../store/modules/writing/commentSlice';
import Community from '../../../../../components/Community/Community';
import Score from '../../../../../components/Community/Scores/Score';
import { useTimer } from '../../../../../hooks/useTimer';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import ReportBug from '../../../../../components/ApplicationModals/ReportBug';
import AppContext from '../../../../../Routes/AppContext';
import { LoaderWrapper } from '../../../../../components/Loader/Loader';
import useFetchQuestion from '../hooks/useFetchQuestion';
import { modules } from '../../../../../constants/modules';
import { defaultQueryWithSection } from './utils/defaultQueryObject';
import { routesMapper } from '../../../../../Routes/routesMapper';
import { paths } from './utils/paths';
import { sections } from '../../../../../constants/sections';
import SeenQuestion from '../../../../../components/SeenQuestion/SeenQuestion';
import Form from '../../../../../components/Form/Form';
import { useForm } from 'react-hook-form';
import FormTextarea from '../../../../../components/Form/Textarea';
import FormButton from '../../../../../components/Form/Button';
import { useWordCount } from '../../../../../hooks/useWordCount';
import QuestionValidityExpire from '../../QuestionValidityExpire';

function AnswerForm({ question, counter, setScore }) {
  const dispatch = useDispatch();

  const form = useForm();

  const onSubmit = useCallback(
    async ({ answer }) => {
      const data = await dispatch(
        evaluate(get(question, 'id'), {
          answer,
          duration: counter,
        })
      );

      setScore(data);
    },
    [question, dispatch, counter, setScore]
  );

  const answer = form.watch('answer');

  const wordCount = useWordCount(answer);

  return (
    <>
      <TitleBar
        serial={`ID # ${get(question, 'id')}`}
        title="Write an essay."
        targetTime={get(question, 'data.duration')}
        yourTime={counter}
      />
      <Form form={form} mt={4} onSubmit={onSubmit}>
        <Text fontWeight={600}>{get(question, 'description')}</Text>

        <FormTextarea
          name="answer"
          mt={3}
          minHeight="220px"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />

        <Flex align="center" mb={1}>
          <FormButton
            _hover={{
              backgroundColor: 'custom.blue8',
            }}
            variantColor="blueVariant"
          >
            Submit Answer
          </FormButton>
          <Text fontSize="sm" color="custom.blue" ml={4}>
            (WORD COUNT: {wordCount})
          </Text>
        </Flex>
      </Form>
    </>
  );
}

function View() {
  const [score, setScore] = useState();

  const { id, tab } = useParams();

  const tabIndex = useMemo(() => (tab === 'comments' ? 1 : 0), [tab]);

  const { counter } = useTimer(id, score);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const { isFetching, question, isNotEligible } = useFetchQuestion(id, !score);

  const { byId } = useSelector((state) => state.writing.comments);

  const comments = useMemo(
    () => ({
      allIds: get(question, 'comments'),
      byId,
    }),
    [byId, question]
  );

  // const onDelete = useCallback(
  //   async (id) => {
  //     try {
  //       await dispatch(deleteQuestion(id));
  //       success({ title: 'Deleted successfully.' });
  //     } catch (error) {
  //       handleApiError(error, { toast });
  //     }
  //   },
  //   [dispatch, toast, success]
  // );

  const onDraftPublish = useCallback(
    async (id) => {
      try {
        const data = await dispatch(draftPublishQuestion(id));
        success({
          title: `Question status has been changed to ${
            data.status ? '"Published"' : '"Draft"'
          }!`,
        });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, success]
  );

  useEffect(() => {
    if (!question && !isFetching) {
      push(routesMapper.writing.Essay.PRACTISE);
    }
  }, [question, push, isFetching]);

  const fetchComments = useCallback(() => {
    dispatch(getComments(id));
  }, [dispatch, id]);

  const addQuestionComment = useCallback(
    async (id, values) => {
      await dispatch(addComment(id, { ...values, ...defaultQueryWithSection }));
    },
    [dispatch]
  );

  const editQuestionComment = useCallback(
    async (id, values) => {
      await dispatch(
        editComment(id, { ...values, ...defaultQueryWithSection })
      );
    },
    [dispatch]
  );

  const deleteQuestionComment = useCallback(
    async (id, parentId, questionId) => {
      await dispatch(
        deleteComment(id, parentId, questionId, { ...defaultQueryWithSection })
      );
    },
    [dispatch]
  );

  const { commentsChannel } = useContext(AppContext);

  useEffect(() => {
    commentsChannel.bind('comments.posted', function (data) {
      if (parseInt(get(data, 'data.question_id')) === parseInt(id)) {
        fetchComments();
      }
    });
  }, [id, fetchComments, commentsChannel]);

  useEffect(() => {
    fetchComments();
    setScore(null);
  }, [id, fetchComments]);

  if (isNotEligible) {
    return <QuestionValidityExpire message={isNotEligible} />;
  }

  return (
    <LoaderWrapper loading={isFetching}>
      <QuestionMetabar
        paths={paths}
        rootPath={routesMapper.writing.Essay.PRACTISE}
        id={id}
        showAdd
        showEdit
        showShare
        showDraftPublish
        status={question?.status}
        onConfirmDraftPublish={onDraftPublish}
      />
      <>
        <Box bg="white" p={6} boxShadow="custom.secondary">
          {score ? (
            <Box position="relative">
              <Score
                score={score}
                module={modules.writing.Essay}
                practiseAgain={setScore}
                section={sections.writing}
              />
              <SeenQuestion
                section={sections.writing}
                id={get(question, 'id')}
              />
            </Box>
          ) : (
            <AnswerForm
              question={question}
              counter={counter}
              setScore={setScore}
            />
          )}
        </Box>
        <Flex mt={6} justify="flex-end">
          <ReportBug details={question} />
        </Flex>
        <Community
          question={question}
          comments={comments}
          tabIndex={tabIndex}
          section={sections.writing}
          module={modules.writing.Essay}
          addQuestionComment={addQuestionComment}
          editQuestionComment={editQuestionComment}
          deleteQuestionComment={deleteQuestionComment}
        />
      </>
    </LoaderWrapper>
  );
}

export default View;
