import React from 'react';
import { Progress, Box, Text } from '@chakra-ui/core';

function CustomProgressBar({
  value,
  progressColor = 'blueVariant',
  labelColor = 'custom.blue',
  label = value + '%',
  labelSize = '12px',
  ...props
}) {
  return (
    <Box mb={6} {...props}>
      <Text color={labelColor} fontSize={labelSize} fontWeight={600} mb={1}>
        {label}
      </Text>
      <Progress color={progressColor} rounded="5px" size="sm" value={value} />
    </Box>
  );
}

export default CustomProgressBar;
