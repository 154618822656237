import { Alert, AlertIcon, Button, Text } from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { packageIds } from '../../constants/packages';
import { roles } from '../../constants/roles';
import { useCurrentUser } from '../../store/user/hooks';

function FreeAlert() {
  const user = useCurrentUser();
  if (
    user.role === roles.student &&
    (!user.currentPackage ||
      user.currentPackage?.package?.id === packageIds.free)
  ) {
    return (
      <Alert status="warning" borderRadius={4} mb={6}>
        <AlertIcon />
        <Text>
          You are currently on a <strong>Free</strong> package. You can{' '}
          <Button
            as={Link}
            to="/dashboard/subscription"
            variant="link"
            verticalAlign="unset"
            variantColor="greenVariant"
            py={0}
            size="md"
          >
            upgrade your package{' '}
          </Button>{' '}
          or use Filter to get your Free questions for practise.
        </Text>
      </Alert>
    );
  }
  return null;
}

export default FreeAlert;
