import { modules } from '../../../../../../constants/modules';

export const defaultQueryObject = {
  module: modules.reading.ReorderParagraph,
};

export const defaultQueryWithSection = {
  module: modules.reading.ReorderParagraph,
  section: 'reading',
};
