import React, { useMemo } from 'react';
import Table, { Thead, Tr, Th, Tbody, Td } from '../Table/Table';

const defaultPropGetter = () => ({});

function Datatable({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  headerRowProps,
  headerCellProps,
  page,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const defaultHeaderRowProps = useMemo(
    () => ({
      borderBottomWidth: '1px',
    }),
    []
  );

  const defaultCellProps = useMemo(
    () => ({
      py: 2,
      textAlign: 'left',
    }),
    []
  );

  return (
    <Table {...getTableProps()} w="100%">
      <Thead textTransform="uppercase" fontSize="sm" color="custom.blue4">
        {headerGroups.map((headerGroup) => (
          <Tr
            {...headerGroup.getHeaderGroupProps(defaultHeaderRowProps)}
            {...headerRowProps}
          >
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps(defaultCellProps)}
                {...headerCellProps}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()} color="custom.black3">
        {(page || rows).map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                return (
                  <Td {...cell.getCellProps(defaultCellProps)}>
                    {cell.render('Cell')}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default Datatable;
