import React from 'react';
import { Box, Text, Button, Stack } from '@chakra-ui/core';
import { GoAlert } from 'react-icons/go';
import { Link } from 'react-router-dom';

function QuestionValidityExpire({ message = '' }) {
  return (
    <Box maxWidth="900px" margin="auto" p={10}>
      <Box bg="white" p={10} boxShadow="custom.primary">
        <Stack spacing={6} alignItems="center">
          <Box as={GoAlert} color="custom.red" fontSize="5xl" />
          <Text dangerouslySetInnerHTML={{ __html: message }} />
          <Button
            to="/dashboard/subscription"
            as={Link}
            variantColor="blueVariant"
            px={10}
          >
            UPGRADE
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default QuestionValidityExpire;
