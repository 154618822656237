import { useState, useCallback, useRef, useEffect } from 'react';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import hark from 'hark';
import * as FullStory from '@fullstory/browser';

function useRecorder(duration) {
  const [isDenied, setIsDenied] = useState();
  const [isStoppedDueToSilence, setIsStoppedDueToSilence] = useState();
  const recorderRef = useRef();

  const [recordingTime, setRecordingTime] = useState(duration);
  const [status, setStatus] = useState();

  const [isGettingBlob, setIsGettingBlob] = useState();

  useEffect(() => {
    console.log('Recorder hooks log: mounting the hook', recorderRef.current);
    console.log('=====================================');
  }, []);

  useEffect(() => {
    console.log('Recorder hooks log duration: ', duration);
    setRecordingTime(duration);

    return () => {
      clearTimeout(stoppedSpeakingTimeout.current);
      clearInterval(recorderTimer.current);
      if (recorderRef.current?.destory) {
        recorderRef.current.destory();
      }
    };
  }, [duration]);

  const stop = useCallback(async () => {
    setIsGettingBlob(true);
    console.log('Recorder hooks log: called stop recorder');
    clearTimeout(stoppedSpeakingTimeout.current);
    clearInterval(recorderTimer.current);

    console.log(
      'Recorder hooks log: Recorder state before',
      recorderRef.current?.recordRTC.getState()
    );

    if (recorderRef.current?.recordRTC.getState() !== 'stopped') {
      await recorderRef.current.stopRecording();
      setStatus(recorderRef.current?.recordRTC.getState());
    }

    console.log(
      'Recorder hooks log: Recorder state after',
      recorderRef.current?.recordRTC.getState()
    );

    console.time('Recorder hooks log: Getting blob time');

    const blob = await recorderRef.current.getBlob();

    console.timeEnd('Recorder hooks log: Getting blob time');

    console.log('Recorder hooks log: Blob state', blob);
    console.log('Recorder hooks log: Blob size in kb', blob?.size / 1024, 'kB');

    if (blob?.size < 50) {
      // remove later
      try {
        FullStory.event('Blob Small', {
          location_str: window?.location,
        });
      } catch {
        console.log('fs error');
      }
    }

    setIsGettingBlob(false);

    return blob;
  }, []);

  const stoppedSpeakingTimeout = useRef();
  const recorderTimer = useRef();

  useEffect(() => {
    if (recordingTime === 0) {
      if (recorderRef.current?.recordRTC.getState() !== 'stopped') {
        clearInterval(recorderTimer.current);
        console.log('Recorder hooks log: stopped by time out');
        recorderRef.current.pauseRecording().then(() => {
          setStatus('stopped');
        });
      }
    }
  }, [recordingTime]);

  const getMediaAndStart = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log(
        'recorder hooks log: stream label',
        stream.getAudioTracks()?.[0]?.label
      );
      console.log(
        'recorder hooks log: stream ready state',
        stream.getAudioTracks()?.[0]?.readyState
      );
      recorderRef.current = new RecordRTCPromisesHandler(stream, {
        recorderType: StereoAudioRecorder,
        type: 'audio',
        desiredSampRate: 16000,
        mimeType: 'audio/wav',
        numberOfAudioChannels: 1,
        // disableLogs: true,
      });
      await recorderRef.current.startRecording();

      recorderTimer.current = setInterval(() => {
        setRecordingTime((state) => state - 1);
      }, 1000);

      setStatus(recorderRef.current?.recordRTC.getState());

      const speechEvents = hark(stream);
      speechEvents.on('speaking', async () => {
        if (recorderRef.current?.recordRTC.getState() === 'stopped') {
          return;
        }
        clearTimeout(stoppedSpeakingTimeout.current);
      });
      speechEvents.on('stopped_speaking', async () => {
        if (
          ['stopped', 'paused'].includes(
            recorderRef.current?.recordRTC.getState()
          )
        ) {
          return;
        }
        console.log(
          'Recorder hooks log on stopped speaking: ',
          recorderRef.current?.recordRTC.getState()
        );
        stoppedSpeakingTimeout.current = setTimeout(() => {
          console.log('Recorder hooks log: stopped by silence time out');

          clearTimeout(stoppedSpeakingTimeout.current);
          clearInterval(recorderTimer.current);

          setIsStoppedDueToSilence(true);
          recorderRef.current.pauseRecording().then(() => {
            setStatus('stopped');
          });
        }, 3 * 1000);
      });
    } catch (error) {
      setIsDenied(true);
    }
  }, []);

  const start = useCallback(() => {
    getMediaAndStart();
  }, [getMediaAndStart]);

  return {
    status,
    start,
    stop,
    isDenied,
    isStoppedDueToSilence,
    recordingTime,
    isGettingBlob,
  };
}

export default useRecorder;
