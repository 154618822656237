import { Stack, Box } from '@chakra-ui/core';
import React from 'react';
import DashboardReportAnalyser from '../DashboardReportAnalyser';
import StudyGuide from '../StudyGuide';

function StudentDashboardSidebar({ userId, studyGuide }) {
  return (
    <Stack
      overflowY="auto"
      p={10}
      minW="400px"
      bg="white"
      boxShadow="custom.tertiary"
      maxW="400px"
      height="100%"
      spacing={6}
    >
      <Box borderBottomWidth="1px" pb={6}>
        <DashboardReportAnalyser userId={userId} />
      </Box>
      <Box flex={1}>
        <StudyGuide studyGuide={studyGuide} />
      </Box>
    </Stack>
  );
}

export default StudentDashboardSidebar;
