import React, { useMemo, useCallback } from 'react';
import Form from '../../../../components/Form/Form';
import { useForm } from 'react-hook-form';
import FormButton from '../../../../components/Form/Button';
import * as Yup from 'yup';
import { get } from 'lodash-es';
import { Box, SimpleGrid } from '@chakra-ui/core';
import QuestionMetabar from '../../../../components/Question/QuestionMetabar';
import TitleBar from '../../../../components/Question/TitleBar';
import { apiRequest } from '../../../../api/api';
import { handleApiError } from '../../../../helpers/handleApiError';
import { sections } from '../../../../constants/sections';
import useCustomToast from '../../../../hooks/useCustomToast';
import { useQuestionOptions } from '../../../../hooks/useQuestionOptions';
import { LoaderWrapper } from '../../../../components/Loader/Loader';
import FormInput from '../../../../components/Form/Input';

const getCustomPaths = (paths) => [
  ...paths,
  {
    name: 'Ranges',
  },
];

const getDefaultValues = (data) => ({
  male: get(data, 'options.ranges.male', {}),
  female: get(data, 'options.ranges.female', {}),
});

function OptionsForm({ data }) {
  const defaultValues = useMemo(() => getDefaultValues(data), [data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        male: Yup.object({
          min: Yup.number()
            .typeError('Must be a number.')
            .min(60, 'Must be at least 90')
            .max(500, 'Must be less than 500')
            .required('Required'),
          max: Yup.number()
            .typeError('Must be a number.')
            .min(60, 'Must be at least 90')
            .max(500, 'Must be less than 500')
            .required('Required'),
        }).required('Required.'),
        female: Yup.object({
          min: Yup.number()
            .typeError('Must be a number.')
            .min(60, 'Must be at least 90')
            .max(500, 'Must be less than 500')
            .required('Required'),
          max: Yup.number()
            .typeError('Must be a number.')
            .min(60, 'Must be at least 90')
            .max(500, 'Must be less than 500')
            .required('Required'),
        }).required('Required.'),
      }),
    []
  );

  const form = useForm({ defaultValues, validationSchema });

  const { success, toast } = useCustomToast();

  const onSubmit = useCallback(
    async ({ male, female }) => {
      try {
        await apiRequest('post', 'options/question', {
          options: { ranges: { male, female } },
          feature: sections.speaking,
        });
        success({ title: 'Updated successfully.' });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [toast, success]
  );

  return (
    <Form form={form} onSubmit={onSubmit} mt={6}>
      <SimpleGrid columns={2} spacing={4}>
        <FormInput
          name="male.min"
          label="Male (Minimum)"
          placeholder="Minimum"
        />
        <FormInput
          name="male.max"
          label="Male (Maximum)"
          placeholder="Maximum"
        />
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={4}>
        <FormInput
          name="female.min"
          label="Female (Minimum)"
          placeholder="Minimum"
        />
        <FormInput
          name="female.max"
          label="Female (Maximum)"
          placeholder="Maximum"
        />
      </SimpleGrid>
      <FormButton variantColor="blueVariant" mt={2}>
        Submit
      </FormButton>
    </Form>
  );
}

function Options({ paths }) {
  const { data, loading } = useQuestionOptions({ feature: sections.speaking });

  const customPaths = useMemo(() => getCustomPaths(paths), [paths]);

  return (
    <LoaderWrapper loading={loading}>
      <QuestionMetabar paths={customPaths} />
      <Box bg="white" p={6} boxShadow="custom.secondary">
        <TitleBar title="Ranges" />
        <OptionsForm data={data} />
      </Box>
    </LoaderWrapper>
  );
}

export default Options;
