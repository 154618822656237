import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Box, Flex, Button } from '@chakra-ui/core';
import TitleBar from '../../../../../components/Question/TitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get, sum } from 'lodash-es';
import useCustomToast from '../../../../../hooks/useCustomToast';
import { handleApiError } from '../../../../../helpers/handleApiError';
import {
  draftPublishQuestion,
  evaluate,
} from '../../../../../store/modules/speaking/questionSlice';
import {
  getComments,
  addComment,
  deleteComment,
  editComment,
} from '../../../../../store/modules/speaking/commentSlice';
import Community from '../../../../../components/Community/Community';
import Score from '../../../../../components/Community/Scores/Score';
import QuestionMetabar from '../../../../../components/Question/QuestionMetabar';
import ReportBug from '../../../../../components/ApplicationModals/ReportBug';
import AppContext from '../../../../../Routes/AppContext';
import { LoaderWrapper } from '../../../../../components/Loader/Loader';
import useFetchQuestion from '../hooks/useFetchQuestion';
import { modules } from '../../../../../constants/modules';
import { defaultQueryWithSection } from './utils/defaultQueryObject';
import { routesMapper } from '../../../../../Routes/routesMapper';
import { paths } from './utils/paths';
import { sections } from '../../../../../constants/sections';
import SeenQuestion from '../../../../../components/SeenQuestion/SeenQuestion';
import Recorder from '../../../../../components/Recorder/Recorder';
import RecorderView from '../../../../../components/Recorder/View';
import AudioPlayerBare from '../../../../../components/AudioPlayer/AudioPlayerBare';
import { speakingDuration } from '../../../../../constants/speakingDuration';
import AutoplayErrorBoundary from '../../../../../components/ErrorBoundary/AutoplayErrorBoundary';
import QuestionValidityExpire from '../../QuestionValidityExpire';
import PractiseStartSkipButton from '../../../../../components/PractiseStartSkipButton/PractiseStartSkipButton';
import SpeakingLoader from '../../../../../components/Loader/SpeakingLoader';

function AnswerForm({ question, setScore }) {
  const [isStarted, setIsStarted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (blob, duration) => {
      setIsSubmitting(true);

      const formData = new FormData();

      formData.append('answer', blob);
      formData.append('duration', duration);

      const data = await dispatch(evaluate(get(question, 'id'), formData));

      setIsSubmitting(false);
      setScore(data);
    },
    [question, dispatch, setScore]
  );

  const [toggleBeginning, setToggleBeginning] = useState(false);
  const [initialBeginningDuration, setInitialBeginningDuration] = useState();

  const onLoadedCallback = useCallback((audioDuration) => {
    setToggleBeginning(true);
    setInitialBeginningDuration(
      Math.ceil(
        audioDuration +
          sum(Object.values(speakingDuration.answerShortQuestion.beginning))
      )
    );
  }, []);

  return (
    <Recorder
      initialBeginningDuration={initialBeginningDuration}
      duration={get(question, 'data.duration')}
      toggleBeginning={isStarted && toggleBeginning}
    >
      {({
        duration,
        isDenied,
        status,
        beginningDuration,
        reverseRecordingDuration,
        startRecording,
        stopRecording,
        isGettingBlob,
      }) => (
        <Box pos="relative">
          <SpeakingLoader isLoading={isSubmitting} />
          <Box
            backgroundColor="custom.blue"
            px={10}
            py={2}
            mb={3}
            textAlign="center"
            color="white"
            fontSize="md"
            fontWeight={400}
          >
            For optimum Speaking Score, <strong>Chrome browser</strong>, a{' '}
            <strong>PC/Laptop</strong> and good{' '}
            <strong>Headphone with Mic</strong> are recommended.
          </Box>
          <TitleBar
            serial={`ID # ${get(question, 'id')}`}
            title={`You will hear a sentence. Please give a simple and short answer. Often just one or a few words is enough.`}
            targetTime={duration}
            yourTime={reverseRecordingDuration}
          />
          <Box mt={6}>
            <Flex align="center" direction="column">
              <AudioPlayerBare
                onLoadedCallback={onLoadedCallback}
                url={get(question, 'audioQuestion')}
                beginningDuration={
                  speakingDuration.answerShortQuestion.beginning.before
                }
                isPaused={!isStarted}
                status={status}
              />
              <RecorderView
                isDenied={isDenied}
                status={
                  status ||
                  (!isStarted && ' ') ||
                  `beginning in ${beginningDuration} seconds.`
                }
                duration={duration}
                reverseRecordingDuration={reverseRecordingDuration}
              />
            </Flex>
          </Box>
          <Box mt={4} mb={1}>
            {!status ? (
              <PractiseStartSkipButton
                isStarted={isStarted}
                status={status}
                startRecording={startRecording}
                setIsStarted={setIsStarted}
                canStart={toggleBeginning}
              />
            ) : (
              <Button
                _hover={{
                  backgroundColor: 'custom.blue8',
                }}
                variantColor="blueVariant"
                onClick={() => stopRecording(onSubmit)}
                isLoading={isSubmitting || isGettingBlob}
              >
                Finish
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Recorder>
  );
}

function View() {
  const [score, setScore] = useState();

  const { id, tab } = useParams();

  const tabIndex = useMemo(() => (tab === 'comments' ? 1 : 0), [tab]);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { success, toast } = useCustomToast();

  const { isFetching, question, isNotEligible } = useFetchQuestion(id, !score);

  const { byId } = useSelector((state) => state.speaking.comments);

  const comments = useMemo(
    () => ({
      allIds: get(question, 'comments'),
      byId,
    }),
    [byId, question]
  );

  // const onDelete = useCallback(
  //   async (id) => {
  //     try {
  //       await dispatch(deleteQuestion(id));
  //       success({ title: 'Deleted successfully.' });
  //     } catch (error) {
  //       handleApiError(error, { toast });
  //     }
  //   },
  //   [dispatch, toast, success]
  // );

  const onDraftPublish = useCallback(
    async (id) => {
      try {
        const data = await dispatch(draftPublishQuestion(id));
        success({
          title: `Question status has been changed to ${
            data.status ? '"Published"' : '"Draft"'
          }!`,
        });
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, toast, success]
  );

  useEffect(() => {
    if (!question && !isFetching) {
      push(routesMapper.speaking.AnswerShortQuestion.PRACTISE);
    }
  }, [question, push, isFetching]);

  const fetchComments = useCallback(() => {
    dispatch(getComments(id));
  }, [dispatch, id]);

  const addQuestionComment = useCallback(
    async (id, values) => {
      await dispatch(addComment(id, { ...values, ...defaultQueryWithSection }));
    },
    [dispatch]
  );

  const editQuestionComment = useCallback(
    async (id, values) => {
      await dispatch(
        editComment(id, { ...values, ...defaultQueryWithSection })
      );
    },
    [dispatch]
  );

  const deleteQuestionComment = useCallback(
    async (id, parentId, questionId) => {
      await dispatch(
        deleteComment(id, parentId, questionId, { ...defaultQueryWithSection })
      );
    },
    [dispatch]
  );

  const { commentsChannel } = useContext(AppContext);

  useEffect(() => {
    commentsChannel.bind('comments.posted', function (data) {
      if (parseInt(get(data, 'data.question_id')) === parseInt(id)) {
        fetchComments();
      }
    });
  }, [id, fetchComments, commentsChannel]);

  useEffect(() => {
    fetchComments();
    setScore(null);
  }, [id, fetchComments]);

  if (isNotEligible) {
    return <QuestionValidityExpire message={isNotEligible} />;
  }

  return (
    <LoaderWrapper loading={isFetching}>
      <QuestionMetabar
        paths={paths}
        rootPath={routesMapper.speaking.AnswerShortQuestion.PRACTISE}
        id={id}
        showAdd
        showEdit
        showShare
        showDraftPublish
        status={question?.status}
        onConfirmDraftPublish={onDraftPublish}
      />
      <>
        <Box bg="white" p={6} boxShadow="custom.secondary">
          {score ? (
            <Box position="relative">
              <Score
                score={score}
                module={modules.speaking.AnswerShortQuestion}
                practiseAgain={setScore}
                section={sections.speaking}
              />
              <SeenQuestion
                section={sections.speaking}
                id={get(question, 'id')}
              />
            </Box>
          ) : (
            <AutoplayErrorBoundary>
              <AnswerForm
                key={get(question, 'id')}
                question={question}
                setScore={setScore}
              />
            </AutoplayErrorBoundary>
          )}
        </Box>
        <Flex mt={6} justify="flex-end">
          <ReportBug details={question} />
        </Flex>
        <Community
          question={question}
          comments={comments}
          tabIndex={tabIndex}
          section={sections.speaking}
          module={modules.speaking.AnswerShortQuestion}
          addQuestionComment={addQuestionComment}
          editQuestionComment={editQuestionComment}
          deleteQuestionComment={deleteQuestionComment}
        />
      </>
    </LoaderWrapper>
  );
}

export default View;
