import React, { useContext } from 'react';
import { Stack, Flex, Image, Button } from '@chakra-ui/core';

import logoWhite from '../../assets/images/logo-white.svg';
import { Link } from 'react-router-dom';
import MegaMenu from './MegaMenu';
import AccountPopover from './AccountPopover';
import NotificationPopover from './NotificationPopover';
import AppContext from '../../Routes/AppContext';

function Topbar({ ...props }) {
  const { isTopBarShown, isPreview } = useContext(AppContext);

  return (
    <Stack
      boxShadow="custom.primary"
      zIndex="10"
      as="nav"
      width="100%"
      isInline
      bg="white"
      pr={4}
      align="center"
      spacing={10}
      borderBottomWidth="1px"
      borderBottomColor="custom.white4"
      {...(!isTopBarShown && { display: 'none' })}
      {...props}
    >
      <Link to="/dashboard">
        <Flex
          bg="custom.blue"
          justify="center"
          align="center"
          width="75px"
          height="75px"
          mr="30px"
        >
          <Image size="42px" src={logoWhite} alt="AIWAS Plus" />
        </Flex>
      </Link>
      <Flex width="100%" justify="space-between" align="center">
        <Stack isInline>
          <MegaMenu isPreview={isPreview} />
        </Stack>
        <Flex>
          <Button
            as={Link}
            to="/dashboard/subscription"
            variant="ghost"
            fontWeight="600"
            color="custom.black4"
            _hover={{
              backgroundColor: 'none',
              color: 'custom.blue',
            }}
          >
            Subscription
          </Button>
          <NotificationPopover />
          <AccountPopover />
        </Flex>
      </Flex>
    </Stack>
  );
}

export default Topbar;
