import React, { useEffect } from 'react';
import { useToast } from '@chakra-ui/core';
import { handleApiError } from '../../helpers/handleApiError';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { apiRequest } from '../../api/api';
import { useDispatch } from 'react-redux';
import { login } from '../../store/user/action';
import { useAuthedUser } from '../../store/user/hooks';
import FullLoader from '../../components/Loader/FullLoader';

function MfaPage() {
  const { push } = useHistory();

  const { token } = useParams();

  const toast = useToast();

  const dispatch = useDispatch();

  const { isAuthenticated } = useAuthedUser();

  useEffect(() => {
    async function verifyMfa() {
      try {
        const { data } = await apiRequest('post', `auth/mfa`, { token });

        dispatch(login(data.access_token));
      } catch (error) {
        handleApiError(error, { toast });
        push('/login');
      }
    }
    verifyMfa();
  }, [toast, dispatch, push, token]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return <FullLoader />;
}

export default MfaPage;
