import {
  FETCH_COUNTRY_LIST_RESOLVED,
  FETCH_STATE_LIST_RESOLVED,
} from './action';
import { keyBy, map } from 'lodash-es';

const initialState = {
  countries: { byId: {}, allIds: [] },
  statesByCountryId: {},
};

const globalReducer = (state = initialState, { type, data, countryId }) => {
  switch (type) {
    case FETCH_COUNTRY_LIST_RESOLVED: {
      return {
        ...state,
        countries: {
          byId: keyBy(data, 'id'),
          allIds: map(data, (item) => item.id),
        },
      };
    }
    case FETCH_STATE_LIST_RESOLVED: {
      return {
        ...state,
        statesByCountryId: {
          ...state.statesByCountryId,
          [countryId]: data,
        },
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
