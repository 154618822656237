import React, { useMemo, useCallback } from 'react';
import { Box, Heading, useToast, Flex, Text } from '@chakra-ui/core';
import * as Yup from 'yup';
import Form from '../../../components/Form/Form';
import FormInput from '../../../components/Form/Input';
import FormButton from '../../../components/Form/Button';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { signup } from '../../../store/signup/action';
import { changeToSnakeCase } from '../../../helpers/changeCase';
import { handleApiError } from '../../../helpers/handleApiError';
import { useSignupContext } from './SignupContext';
import { AnchorLink } from '../../../components/Navigation/Link';

function UserStep() {
  const { setStep } = useSignupContext();

  const dispatch = useDispatch();

  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email address is required.'),
        password: Yup.string()
          .min(8, 'Minimum 8 characters.')
          .required('Required.'),
        confirmPassword: Yup.string()
          .required('Enter the same password again.')
          .oneOf([Yup.ref('password'), null], "Ops! Password doesn't match"),
      }),
    []
  );

  const form = useForm({ validationSchema });

  const submit = useCallback(
    async (values) => {
      try {
        const valuesSnakeCased = changeToSnakeCase(values);
        await dispatch(signup(valuesSnakeCased));
        toast({
          position: 'top',
          title:
            'Thank you for your interest. AIWAS Plus wants to know a few more details about you to provide the best possible guideline.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setStep('assessment');
      } catch (error) {
        handleApiError(error, { toast });
      }
    },
    [dispatch, setStep, toast]
  );

  return (
    <Box mt={6}>
      <Heading mb={2} color="custom.black3">
        SIGNUP
      </Heading>
      <Form mt={6} form={form} onSubmit={submit} minWidth="500px">
        <FormInput name="email" placeholder="Email Address" />
        <FormInput name="password" type="password" placeholder="Password" />
        <FormInput
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
        />
        <FormButton px={6} icon="arrow-forward">
          NEXT
        </FormButton>
      </Form>

      <Flex mt={4} align="center">
        <Text mr={2} fontSize="sm" color="custom.black3">
          Already a member?
        </Text>
        <AnchorLink to="/login" label="Login" variant="link" size="sm" />
      </Flex>
    </Box>
  );
}

export default UserStep;
