import { createSlice } from '@reduxjs/toolkit';
import { defaultsDeep, keyBy, map, union } from 'lodash-es';
import { sections } from '../../constants/sections';

const initialState = {
  byId: {},
  allIds: [],
  isFetching: true,
  mockQuestions: {
    data: {},
    isFetching: true,
    loaded: false,
  },
};

const mocksSlice = createSlice({
  name: 'mocks',
  initialState,
  reducers: {
    mockUpdate(state, { payload }) {
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },
    mockBulkLoading(state) {
      state.isFetching = true;
    },
    mockBulkLoaded(state, { payload }) {
      defaultsDeep(state.byId, keyBy(payload, 'id'));
      state.allIds = union(state.allIds, map(payload, 'id'));
      state.isFetching = false;
    },
    mockAdd(state, { payload }) {
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
      state.allIds = union([payload.id], state.allIds);
    },
    mockQuestionsLoading(state) {
      state.mockQuestions.isFetching = true;
      state.mockQuestions.loaded = false;
    },
    mockQuestionsFailed(state) {
      state.mockQuestions.isFetching = false;
      state.mockQuestions.loaded = false;
    },
    mockQuestionsLoaded(state, { payload }) {
      state.mockQuestions.isFetching = false;
      state.mockQuestions.data = payload;
      state.mockQuestions.loaded = true;
    },
  },
});

const { actions, reducer } = mocksSlice;

export const {
  mockQuestionsLoading,
  mockQuestionsLoaded,
  mockQuestionsFailed,
  mockAdd,
  mockBulkLoading,
  mockBulkLoaded,
  mockUpdate,
} = actions;

export default reducer;

export const getMockQuestions = () => async (dispatch, _, api) => {
  dispatch(mockQuestionsLoading());

  try {
    const [
      { data: speakingQuestions },
      { data: readingQuestions },
      { data: writingQuestions },
      { data: listeningQuestions },
    ] = await Promise.all([
      api('get', `mocks/speaking`),
      api('get', `mocks/reading`),
      api('get', `mocks/writing`),
      api('get', `mocks/listening`),
    ]);

    const data = {
      [sections.speaking]: speakingQuestions,
      [sections.writing]: writingQuestions,
      [sections.reading]: readingQuestions,
      [sections.listening]: listeningQuestions,
    };

    dispatch(mockQuestionsLoaded(data));
    return data;
  } catch (error) {
    dispatch(mockQuestionsFailed());
    throw error;
  }
};

export const submitMockAdd = ({
  title,
  type,
  labels,
  priority,
  questions,
  version,
}) => async (dispatch, _, api) => {
  const { data } = await api('post', 'mocks/custom', {
    title,
    type,
    labels,
    priority,
    questions,
    version,
  });

  dispatch(mockAdd(data));
};

export const getMocks = () => async (dispatch, _, api) => {
  dispatch(mockBulkLoading());

  const { data } = await api('get', 'mocks/custom');

  dispatch(mockBulkLoaded(data));
};

export const updateMockStatus = (id, { status }) => async (
  dispatch,
  _,
  api
) => {
  await api('patch', `mocks/custom/${id}?setStatus=${status}`);

  dispatch(mockUpdate({ id, status }));
};

export const getMock = (id) => async (dispatch, _, api) => {
  const { data } = await api('get', `mocks/custom/${id}`);

  dispatch(mockAdd(data));
};

export const submitMockUpdate = (
  id,
  { title, type, labels, priority, questions }
) => async (dispatch, _, api) => {
  const { data } = await api('post', `mocks/custom/${id}`, {
    title,
    type,
    labels,
    priority,
    questions,
  });

  dispatch(mockUpdate(data.question));
};
