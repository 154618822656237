import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import Table, {
  Tbody,
  Td,
  Thead,
  Tr,
} from '../../../../../components/Table/Table';
import { ActionButtons, NextTips, StepLayout } from '../../Components/Layout';
import { getConfigByVersion } from '../../Utils/getConfigsByVersion';

function PartOneOverviewStep({ onAfterSubmit, mockVersion }) {
  const { speaking } = getConfigByVersion(mockVersion);
  return (
    <StepLayout nextShortcutEnabled>
      {({ isOpen, onOpen, onClose }) => (
        <>
          <Stack spacing={4} my={10} px={6} overflowY="auto">
            <Heading size="md">Part 1: Overview</Heading>
            <Text>
              In Part 1 you are tested mainly on your Speaking and Writing
              skills. You are reminded of what to and what not to do in the
              test. Pay close attention to this or you will lose score points or
              score zero in some cases.
            </Text>
            <Text>
              <strong>Speaking section 1</strong> contains the Personal
              introduction.
            </Text>
            <Text>
              <strong>Speaking section 2</strong> contains a number of item
              types where you first listen to an audio, then respond into the
              microphone
            </Text>
            <Text>
              <strong>Writing sections 3-6</strong> contain a number of
              individuallytimed items. You are required to write two summaries
              and one essay OR three summaries and one essay OR two summaries
              and two essays.
            </Text>
            <Box>
              <Table minW="500px" fontWeight={600}>
                <Thead>
                  <Tr borderWidth={1} bg="mock.blue">
                    <Td borderWidth={1} p={1} colSpan={3}>
                      Part 1: Speaking and Writing
                    </Td>
                  </Tr>
                  <Tr borderWidth={1} bg="mock.blue">
                    <Td borderWidth={1} p={1}>
                      Section
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Item Type
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Time allowed
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Section 1
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Personal introduction
                    </Td>
                    <Td borderWidth={1} p={1}>
                      1 minute
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1} rowSpan={5}>
                      Section 2
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Read aloud
                    </Td>
                    <Td borderWidth={1} p={1} rowSpan={5}>
                      {speaking} minutes
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Repeat sentence
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Describe image
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Re-tell lecture
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Answer short question
                    </Td>
                  </Tr>
                  <Tr borderWidth={1}>
                    <Td borderWidth={1} p={1}>
                      Section 3
                    </Td>
                    <Td borderWidth={1} p={1}>
                      Summarize written text OR Essay
                    </Td>
                    <Td borderWidth={1} p={1}>
                      10 or 20 minutes
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Stack>
          <Box>
            <NextTips />
            <ActionButtons
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              canEndExam={false}
              onConfirmNext={(onCallback) => {
                onCallback();
                onAfterSubmit();
              }}
            />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

export default PartOneOverviewStep;
