import { customColors } from '../theme/theme';

function breakText(text = '') {
  return text.replace(/\r\n|\r|\n/g, '<br />');
}

export function linkify(text = '') {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

  const textWithBreaks = breakText(text);

  return textWithBreaks.replace(urlRegex, function (url) {
    return `<a style="color: ${customColors.blue}" target="_blank" href="${url}">${url}</a>`;
  });
}
