import React from 'react';
import { Flex, Spinner } from '@chakra-ui/core';

function FullLoader() {
  return (
    <Flex height="100vh" justify="center" align="center">
      <Spinner />
    </Flex>
  );
}

export default FullLoader;
